import * as React from 'react';

import { Card, CardHeader, Divider, CardContent } from '@material-ui/core';

import { useStyles } from './HistoryStyles';
import { NoData } from 'app/components/common';
import { IIntegrationResult, IIntegrationResultStatus } from 'app/models';
import { IntegrationTableRow } from 'app/views/Settings/Integration/IntegrationTableRow';

export interface IProps {
  items: IIntegrationResult[];
}

export const History: React.SFC<IProps> = (props) => {
  const classes = useStyles();

  const visibleItems = props.items.filter(
    (item) =>
      item.status === IIntegrationResultStatus.Completed ||
      item.status === IIntegrationResultStatus.Failed,
  );

  return (
    <Card style={{ marginTop: 24 }}>
      <CardHeader title={'History'} />
      <Divider />
      <CardContent className={classes.cardContent}>
        {visibleItems.length > 0 ? (
          visibleItems.map((item, index) => {
            return (
              <IntegrationTableRow title={item.description} value={item.dateStr!} key={index} />
            );
          })
        ) : (
          <NoData message={'No History found'} />
        )}
      </CardContent>
    </Card>
  );
};
