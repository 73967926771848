import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { DashBoard } from 'app/views';
import { IRootState } from 'app/reducers/AppState';
import { IDashboardFilterModel, IDashboardModel, IRegion } from 'app/models';
import {
  updateDashboardFilter,
  fetchDashboardData,
  fetchDashboardVehicleTypes,
  fetchDashboardSpareParts,
} from 'app/actions';

interface StateFromProps {
  dashboard: IDashboardModel;
  selectedRegion: IRegion | null;
}

interface DispatchFromProps {
  fetchDashboardData: () => void;
  updateDashboardFilter: (filter: IDashboardFilterModel) => void;
  fetchVehicleTypes: () => void;
  fetchSpareParts: () => void;
}

const mapStateToProps = (state: IRootState): StateFromProps => ({
  dashboard: state.dashboard,
  selectedRegion: state.userDetails.selectedRegion,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => ({
  fetchDashboardData: bindActionCreators(fetchDashboardData, dispatch),
  updateDashboardFilter: bindActionCreators(updateDashboardFilter, dispatch),
  fetchVehicleTypes: bindActionCreators(fetchDashboardVehicleTypes, dispatch),
  fetchSpareParts: bindActionCreators(fetchDashboardSpareParts, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashBoard);
