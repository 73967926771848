import { ILanguage } from 'app/models';

export const languages: ILanguage[] = [
  {
    label: 'French',
    value: 'fr',
  },
  {
    label: 'German',
    value: 'de',
  },
  {
    label: 'Greek',
    value: 'el',
  },
  {
    label: 'Italian',
    value: 'it',
  },
  {
    label: 'Polish',
    value: 'pl'
  },
  {
    label: 'Portuguese',
    value: 'pt',
  },
  {
    label: 'Spanish',
    value: 'es',
  },
];
