import { handleActions, Reducer } from 'redux-actions';
import { IRootState } from 'app/reducers/AppState';
import { ISparePartsActionsType } from 'app/actions';
import { ISparePartsModel, ISparePartModel } from 'app/models';

const initialState: ISparePartsModel = {
  sparePartsList: [],
  selectedSparePart: null,
  isEditing: false,
  isLoading: false,
  isAdding: false,
  isDeleting: false,
  removingPartsIds: [],
};

// tslint:disable-next-line:typedef
export const sparePartsReducer: Reducer<
  IRootState.SparePartsState,
  ISparePartsModel
> = handleActions<
  IRootState.SparePartsState,
  ISparePartsModel | ISparePartModel | ISparePartModel[]
>(
  {
    [ISparePartsActionsType.REQUEST_SPARE_PARTS_LIST]: (
      state = initialState,
    ): IRootState.SparePartsState => ({
      ...state,
      isLoading: true,
      sparePartsList: [],
    }),
    [ISparePartsActionsType.RECEIVE_SPARE_PARTS_LIST]: (
      state = initialState,
      action,
    ): IRootState.SparePartsState => ({
      ...state,
      isLoading: false,
      sparePartsList: action.payload as ISparePartModel[],
    }),
    [ISparePartsActionsType.FAILURE_SPARE_PARTS_LIST]: (
      state = initialState,
    ): IRootState.SparePartsState => ({
      ...state,
      isLoading: false,
    }),

    [ISparePartsActionsType.REQUEST_ADD_SPARE_PART]: (
      state = initialState,
    ): IRootState.SparePartsState => ({
      ...state,
      isAdding: true,
    }),
    [ISparePartsActionsType.RECEIVE_ADD_SPARE_PART]: (
      state = initialState,
    ): IRootState.SparePartsState => ({
      ...state,
      isAdding: false,
    }),
    [ISparePartsActionsType.FAILURE_ADD_SPARE_PART]: (
      state = initialState,
    ): IRootState.SparePartsState => ({
      ...state,
      isAdding: false,
    }),
    [ISparePartsActionsType.RECEIVE_UPDATE_SPARE_PART]: (
      state = initialState,
      action,
    ): IRootState.SparePartsState => ({
      ...state,
      isAdding: false,
      sparePartsList: action.payload as ISparePartModel[],
    }),

    [ISparePartsActionsType.OPEN_SPARE_PART_MODAL]: (
      state = initialState,
      action,
    ): IRootState.SparePartsState => ({
      ...state,
      isEditing: true,
      selectedSparePart: action.payload as ISparePartModel,
    }),
    [ISparePartsActionsType.CLOSE_SPARE_PART_MODAL]: (
      state = initialState,
    ): IRootState.SparePartsState => ({
      ...state,
      isEditing: false,
      selectedSparePart: null,
    }),

    [ISparePartsActionsType.REQUEST_DELETE_SPARE_PART]: (
      state = initialState,
    ): IRootState.SparePartsState => ({
      ...state,
      isDeleting: true,
    }),
    [ISparePartsActionsType.RECEIVE_DELETE_SPARE_PART]: (
      state = initialState,
      action,
    ): IRootState.SparePartsState => ({
      ...state,
      isDeleting: false,
    }),
    [ISparePartsActionsType.FAILURE_DELETE_SPARE_PART]: (
      state = initialState,
    ): IRootState.SparePartsState => ({
      ...state,
      isDeleting: false,
    }),

    [ISparePartsActionsType.REQUEST_DELETE_SPARE_PARTS]: (
      state = initialState,
      action,
    ): IRootState.SparePartsState => ({
      ...state,
      removingPartsIds: (action.payload as ISparePartsModel).removingPartsIds,
    }),
    [ISparePartsActionsType.RECEIVE_DELETE_SPARE_PARTS]: (
      state = initialState,
    ): IRootState.SparePartsState => ({
      ...state,
      sparePartsList: state.sparePartsList.filter((part) => {
        return state.removingPartsIds.indexOf(part.id) === -1;
      }),
      removingPartsIds: [],
    }),
    [ISparePartsActionsType.FAILURE_DELETE_SPARE_PARTS]: (
      state = initialState,
    ): IRootState.SparePartsState => ({
      ...state,
      removingPartsIds: [],
    }),
  },
  initialState,
);
