import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {},

  navLoadingItem: {
    display: 'flex',
    margin: '20px 0',
    padding: '0 8px',
  },
  divider: {
    marginBottom: theme.spacing(1.5),
  },
}));
