import { colors } from '@material-ui/core';
import { Palette } from '@material-ui/core/styles/createPalette';

const white = '#FFFFFF';
const black = '#000000';
const MAIN_COLOR = '#263238';

export default {
  common: {
    black,
    white,
  },
  primary: {
    contrastText: MAIN_COLOR,
    dark: colors.blue[800],
    main: colors.blue[500],
    light: colors.blue[100],
  },
  secondary: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue.A400,
    light: colors.blue.A400,
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
  },
  background: {
    default: '#F4F6F8',
    paper: white,
  },
  divider: colors.grey[200],
} as Palette;
