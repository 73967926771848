import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import {
  IResponseHandlerModel,
  IMethods,
  IFleetListModel,
  IFleetDetails,
  VehicleStateType,
  IFleetStateModel,
  ISparePartModel,
} from 'app/models';
import { ApiHelper } from 'app/helpers/api-helper';
import { IRootState } from 'app/reducers/AppState';

export enum IFleetActionsType {
  REQUEST_FLEET_LIST = 'REQUEST_FLEET_LIST',
  RECEIVE_FLEET_LIST = 'RECEIVE_FLEET_LIST',
  FAILURE_FLEET_LIST = 'FAILURE_FLEET_LIST',

  REQUEST_FLEET_DETAILS = 'REQUEST_FLEET_DETAILS',
  RECEIVE_FLEET_DETAILS = 'RECEIVE_FLEET_DETAILS',
  FAILURE_FLEET_DETAILS = 'FAILURE_FLEET_DETAILS',

  REQUEST_FLEET_STATES = 'REQUEST_FLEET_STATES',
  RECEIVE_FLEET_STATES = 'RECEIVE_FLEET_STATES',
  FAILURE_FLEET_STATES = 'FAILURE_FLEET_STATES',

  REQUEST_CHANGE_FLEET_STATE = 'REQUEST_CHANGE_FLEET_STATE',
  RECEIVE_CHANGE_FLEET_STATE = 'RECEIVE_CHANGE_FLEET_STATE',
  FAILURE_CHANGE_FLEET_STATE = 'FAILURE_CHANGE_FLEET_STATE',

  RECEIVE_FLEET_SPARE_PARTS = 'RECEIVE_FLEET_SPARE_PARTS',
}

// tslint:disable:typedef

export const requestFleetList = createAction(IFleetActionsType.REQUEST_FLEET_LIST);
export const receiveFleetList = createAction<IFleetListModel[]>(
  IFleetActionsType.RECEIVE_FLEET_LIST,
);
export const failureFleetList = createAction(IFleetActionsType.FAILURE_FLEET_LIST);

export const requestFleetDetails = createAction(IFleetActionsType.REQUEST_FLEET_DETAILS);
export const receiveFleetDetails = createAction<IFleetDetails>(
  IFleetActionsType.RECEIVE_FLEET_DETAILS,
);
export const failureFleetDetails = createAction(IFleetActionsType.FAILURE_FLEET_DETAILS);

export const requestFleetStates = createAction(IFleetActionsType.REQUEST_FLEET_STATES);
export const receiveFleetStates = createAction<IFleetStateModel[]>(
  IFleetActionsType.RECEIVE_FLEET_STATES,
);
export const failureFleetStates = createAction(IFleetActionsType.FAILURE_FLEET_STATES);

export const requestChangeFleetState = createAction(IFleetActionsType.REQUEST_CHANGE_FLEET_STATE);
export const receiveChangeFleetState = createAction<IFleetDetails>(
  IFleetActionsType.RECEIVE_CHANGE_FLEET_STATE,
);
export const failureChangeFleetState = createAction(IFleetActionsType.FAILURE_CHANGE_FLEET_STATE);

export const receiveFleetSpareParts = createAction(IFleetActionsType.RECEIVE_FLEET_SPARE_PARTS);

export const fetchFleetList = () => {
  return async (dispatch: Dispatch) => {
    dispatch(requestFleetList());

    try {
      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        '/vehicles',
        IMethods.GET,
        true,
      );

      if (response.isError) {
        dispatch(failureFleetList());
        return;
      }

      const fleetList = response.data.data as IFleetListModel[];
      dispatch(receiveFleetList(fleetList));
    } catch (e) {
      dispatch(failureFleetList());
    }
  };
};

export const fetchFleetDetails = (id: string) => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    dispatch(requestFleetDetails());

    try {
      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        '/vehicles/' + id,
        IMethods.GET,
        true,
        { history: true },
      );

      if (response.isError) {
        dispatch(failureFleetDetails());
        return;
      }
      const fleetDertails = response.data.data as IFleetDetails;
      dispatch(receiveFleetDetails(fleetDertails));
    } catch (e) {
      dispatch(failureFleetDetails());
    }
  };
};

export const getFleetStates = () => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    dispatch(requestFleetStates());
    try {
      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        '/states',
        IMethods.GET,
        true,
      );
      if (response.isError) {
        dispatch(failureFleetStates());
        return;
      }

      const fleetStates = response.data.data as IFleetStateModel[];
      dispatch(receiveFleetStates(fleetStates));
    } catch (e) {
      dispatch(failureFleetStates());
    }
  };
};

export const changeFleetState = (stateId: VehicleStateType) => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    const {
      fleet: { fleetDetails },
    } = getState();
    if (fleetDetails) {
      dispatch(requestChangeFleetState());

      try {
        const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
          '/vehicles/' + fleetDetails.remoteId,
          IMethods.PUT,
          true,
          undefined,
          { stateId },
        );

        if (response.isError) {
          dispatch(failureChangeFleetState());
          return;
        }

        const newVehicleDetails = {
          ...response.data.data,
          history: fleetDetails.history,
        } as IFleetDetails;
        dispatch(receiveChangeFleetState(newVehicleDetails));
      } catch (e) {
        dispatch(failureChangeFleetState());
      }
    }
  };
};

export const fetchFleetSpareparts = () => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    try {
      const response = await new ApiHelper().fetchSpareParts();
      if (!response.isError) {
        const spareParts = response.data.data as ISparePartModel[];
        dispatch(
          receiveFleetSpareParts({
            spareParts,
          }),
        );
      }
    } catch (e) {
      console.error(e);
    }
  };
};
