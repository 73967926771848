import { handleActions, Reducer } from 'redux-actions';
import { IRootState } from 'app/reducers/AppState';
import { IFleetActionsType } from 'app/actions';
import { IFleetModel, IFleetListModel, IFleetDetails, IFleetStateModel } from 'app/models';

const initialState: IFleetModel = {
  fleetList: [],
  isLoading: false,
  fleetDetails: null,
  isAdding: false,
  fleetStates: [],
  spareParts: [],
};

// tslint:disable-next-line:typedef
export const fleetReducer: Reducer<IRootState.FleetState, IFleetModel> = handleActions<
  IRootState.FleetState,
  IFleetModel | IFleetListModel[] | IFleetDetails | IFleetStateModel[]
>(
  {
    [IFleetActionsType.REQUEST_FLEET_LIST]: (state = initialState): IRootState.FleetState => ({
      ...state,
      isLoading: true,
    }),
    [IFleetActionsType.RECEIVE_FLEET_LIST]: (
      state = initialState,
      action,
    ): IRootState.FleetState => ({
      ...state,
      isLoading: false,
      fleetList: action.payload as IFleetListModel[],
    }),
    [IFleetActionsType.FAILURE_FLEET_LIST]: (state = initialState): IRootState.FleetState => ({
      ...state,
      isLoading: false,
    }),

    [IFleetActionsType.REQUEST_FLEET_DETAILS]: (state = initialState): IRootState.FleetState => ({
      ...state,
      isLoading: true,
    }),
    [IFleetActionsType.RECEIVE_FLEET_DETAILS]: (
      state = initialState,
      action,
    ): IRootState.FleetState => ({
      ...state,
      isLoading: false,
      fleetDetails: action.payload as IFleetDetails,
    }),
    [IFleetActionsType.FAILURE_FLEET_DETAILS]: (state = initialState): IRootState.FleetState => ({
      ...state,
      isLoading: false,
    }),

    [IFleetActionsType.REQUEST_FLEET_STATES]: (state = initialState): IRootState.FleetState => ({
      ...state,
    }),
    [IFleetActionsType.RECEIVE_FLEET_STATES]: (
      state = initialState,
      action,
    ): IRootState.FleetState => ({
      ...state,
      fleetStates: action.payload as IFleetStateModel[],
    }),
    [IFleetActionsType.FAILURE_FLEET_STATES]: (state = initialState): IRootState.FleetState => ({
      ...state,
    }),

    [IFleetActionsType.REQUEST_CHANGE_FLEET_STATE]: (
      state = initialState,
    ): IRootState.FleetState => ({
      ...state,
      isAdding: true,
    }),
    [IFleetActionsType.RECEIVE_CHANGE_FLEET_STATE]: (
      state = initialState,
      action,
    ): IRootState.FleetState => ({
      ...state,
      isAdding: false,
      fleetDetails: action.payload as IFleetDetails,
    }),
    [IFleetActionsType.FAILURE_CHANGE_FLEET_STATE]: (
      state = initialState,
    ): IRootState.FleetState => ({
      ...state,
      isAdding: false,
    }),

    [IFleetActionsType.RECEIVE_FLEET_SPARE_PARTS]: (
      state = initialState,
      action,
    ): IRootState.FleetState => ({
      ...state,
      spareParts: (action.payload as IFleetModel).spareParts,
    }),
  },
  initialState,
);
