import Geocode from 'react-geocode';
import { MapBox } from 'app/constants/mapBox';
import { ILocation } from 'app/models';

Geocode.enableDebug();
Geocode.setApiKey(MapBox.API_KEY);

export const geoCoordinatesFromAddress = async (address: string): Promise<ILocation> => {
  try {
    const response = await Geocode.fromAddress(address);
    const googleLoc = response.results[0].geometry.location as google.maps.LatLngLiteral;
    const location = { lat: googleLoc.lat, long: googleLoc.lng };
    return location;
  } catch (error) {
    console.error(error);
    return { lat: 0, long: 0 };
  }
};

export const getFirstLineAddressFromCoordinates = async (
  coordinates: ILocation,
): Promise<string> => {
  try {
    const response = await Geocode.fromLatLng(coordinates.lat, coordinates.long);
    if (response.results) {
      const firstResult = response.results[0];
      if (firstResult.address_components && firstResult.address_components.length > 1) {
        return `${firstResult.address_components[1].long_name}, ${firstResult.address_components[0].long_name}`;
      }
      return firstResult.formatted_address;
    }
    return '';
  } catch (error) {
    return '';
    // throw error;
  }
};
