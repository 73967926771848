import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    padding: '2px 0 18px',
  },
  title: {
    color: '#90A4AE',
    fontWeight: 600,
    marginBottom: theme.spacing(1),
    fontSize: 11,
    letterSpacing: 0.4,
  },

  addButton: {
    backgroundColor: '#F4F4F4',
    textTransform: 'unset',
    padding: 6,
    fontWeight: 400,
    fontSize: 14,
    letterSpacing: 0.2,
    marginTop: theme.spacing(1),
  },
}));

export const usePriorityPeriodItemStyles = makeStyles((theme: Theme) => ({
  item: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: '0px 1px 0px #EEEEEE',
  },

  period: {
    fontSize: 14,
    marginBottom: 0,
  },

  icon: {
    color: '#546E7A',
    marginRight: theme.spacing(1),
  },

  leftSide: {
    display: 'flex',
    alignItems: 'center',
  },

  actionIcon: {
    padding: 6,
  },
}));
