export const payloadWithFormData = (payload: any) => {
  const formData = new FormData();
  Object.keys(payload).forEach((item) => {
    if (typeof payload[item] !== 'number' && !payload[item]) return;
    if (typeof payload[item] === 'object') {
      payload[item] = JSON.stringify(payload[item]);
    }
    formData.append(`${item}`, payload[item]);
  });
  return formData;
};
