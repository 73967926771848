import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import {
  IMethods,
  IResponseHandlerModel,
  IFleetStateModel,
  IVehicleTypeModel,
  IRemoteMapping121,
  IIntegrationResult,
} from 'app/models';
import { ApiHelper } from 'app/helpers/api-helper';
import moment from 'moment';
import {
  DeleteIntegrationMappingFunction,
  IMappingType,
  SaveIntegrationMappingFunction,
} from '../models/SettingsModel';
import {
  IAutoLockingRule,
  SaveIntegrationFunction,
  ChangeAutoLockingRuleFunction,
} from '../models/SettingsModel';

export enum ISettingsActionsType {
  REQUEST_INTEGRATION_INFO = 'REQUEST_INTEGRATION_INFO',
  RECEIVE_INTEGRATION_INFO = 'RECEIVE_INTEGRATION_INFO',
  FAILURE_INTEGRATION_INFO = 'FAILURE_INTEGRATION_INFO',

  REQUEST_SAVE_INTEGRATION_INFO = 'REQUEST_SAVE_INTEGRATION_INFO',
  RECEIVE_SAVE_INTEGRATION_INFO = 'RECEIVE_SAVE_INTEGRATION_INFO',
  FAILURE_SAVE_INTEGRATION_INFO = 'FAILURE_SAVE_INTEGRATION_INFO',

  REQUEST_INTEGRATION_LOCAL_VEHICLE_TYPES = 'REQUEST_INTEGRATION_LOCAL_VEHICLE_TYPES',
  RECEIVE_INTEGRATION_LOCAL_VEHICLE_TYPES = 'RECEIVE_INTEGRATION_LOCAL_VEHICLE_TYPES',
  FAILURE_INTEGRATION_LOCAL_VEHICLE_TYPES = 'FAILURE_INTEGRATION_LOCAL_VEHICLE_TYPES',

  REQUEST_INTEGRATION_VEHICLE_TYPES = 'REQUEST_INTEGRATION_VEHICLE_TYPES',
  RECEIVE_INTEGRATION_VEHICLE_TYPES = 'RECEIVE_INTEGRATION_VEHICLE_TYPES',
  FAILURE_INTEGRATION_VEHICLE_TYPES = 'FAILURE_INTEGRATION_VEHICLE_TYPES',

  REQUEST_INTEGRATION_LOCAL_VEHICLE_STATES = 'REQUEST_INTEGRATION_LOCAL_VEHICLE_STATES',
  RECEIVE_INTEGRATION_LOCAL_VEHICLE_STATES = 'RECEIVE_INTEGRATION_LOCAL_VEHICLE_STATES',
  FAILURE_INTEGRATION_LOCAL_VEHICLE_STATES = 'FAILURE_INTEGRATION_LOCAL_VEHICLE_STATES',

  REQUEST_INTEGRATION_VEHICLE_STATES = 'REQUEST_INTEGRATION_VEHICLE_STATES',
  RECEIVE_INTEGRATION_VEHICLE_STATES = 'RECEIVE_INTEGRATION_VEHICLE_STATES',
  FAILURE_INTEGRATION_VEHICLE_STATES = 'FAILURE_INTEGRATION_VEHICLE_STATES',

  RECEIVE_INTEGRATION_REGIONS = 'RECEIVE_INTEGRATION_REGIONS',

  RECEIVE_INTEGRATION_HISTORY = 'RECEIVE_INTEGRATION_HISTORY',

  RECEIVE_AUTOLOCKING_RULES = 'RECEIVE_AUTOLOCKING_RULES',

  REQUEST_CHANGE_AUTOLOCKING_RULE = 'REQUEST_CHANGE_AUTOLOCKING_RULE',
  RECEIVE_CHANGE_AUTOLOCKING_RULE = 'RECEIVE_CHANGE_AUTOLOCKING_RULE',
  FAILURE_CHANGE_AUTOLOCKING_RULE = 'FAILURE_CHANGE_AUTOLOCKING_RULE',

  REQUEST_SAVE_MAPPING = 'REQUEST_SAVE_MAPPING',
  RECEIVE_SAVE_MAPPING = 'RECEIVE_SAVE_MAPPING',
  FAILURE_SAVE_MAPPING = 'FAILURE_SAVE_MAPPING',

  REQUEST_DELETE_MAPPING = 'REQUEST_DELETE_MAPPING',
  RECEIVE_DELETE_MAPPING = 'RECEIVE_DELETE_MAPPING',
  FAILURE_DELETE_MAPPING = 'FAILURE_DELETE_MAPPING',

  RESET_INTEGRATION_ERRORS = 'RESET_INTEGRATION_ERRORS',
}

// tslint:disable:typedef

export const requestIntegrationInfo = createAction(ISettingsActionsType.REQUEST_INTEGRATION_INFO);
export const receiveIntegrationInfo = createAction(ISettingsActionsType.RECEIVE_INTEGRATION_INFO);
export const failureIntegrationInfo = createAction<string>(
  ISettingsActionsType.FAILURE_INTEGRATION_INFO,
);

export const requestSaveIntegrationInfo = createAction(
  ISettingsActionsType.REQUEST_SAVE_INTEGRATION_INFO,
);
export const receiveSaveIntegrationInfo = createAction(
  ISettingsActionsType.RECEIVE_SAVE_INTEGRATION_INFO,
);
export const failureSaveIntegrationInfo = createAction(
  ISettingsActionsType.FAILURE_SAVE_INTEGRATION_INFO,
);

export const requestIntegrationLocalVehicleTypes = createAction(
  ISettingsActionsType.REQUEST_INTEGRATION_LOCAL_VEHICLE_TYPES,
);
export const receiveIntegrationLocalVehicleTypes = createAction(
  ISettingsActionsType.RECEIVE_INTEGRATION_LOCAL_VEHICLE_TYPES,
);
export const failureIntegrationLocalVehicleTypes = createAction(
  ISettingsActionsType.FAILURE_INTEGRATION_LOCAL_VEHICLE_TYPES,
);

export const requestIntegrationVehicleTypes = createAction(
  ISettingsActionsType.REQUEST_INTEGRATION_VEHICLE_TYPES,
);
export const receiveIntegrationVehicleTypes = createAction(
  ISettingsActionsType.RECEIVE_INTEGRATION_VEHICLE_TYPES,
);
export const failureIntegrationVehicleTypes = createAction<string>(
  ISettingsActionsType.FAILURE_INTEGRATION_VEHICLE_TYPES,
);

export const requestIntegrationLocalVehicleStates = createAction(
  ISettingsActionsType.REQUEST_INTEGRATION_LOCAL_VEHICLE_STATES,
);
export const receiveIntegrationLocalVehicleStates = createAction(
  ISettingsActionsType.RECEIVE_INTEGRATION_LOCAL_VEHICLE_STATES,
);
export const failureIntegrationLocalVehicleStates = createAction(
  ISettingsActionsType.FAILURE_INTEGRATION_LOCAL_VEHICLE_STATES,
);

export const requestIntegrationVehicleStates = createAction(
  ISettingsActionsType.REQUEST_INTEGRATION_VEHICLE_STATES,
);
export const receiveIntegrationVehicleStates = createAction(
  ISettingsActionsType.RECEIVE_INTEGRATION_VEHICLE_STATES,
);
export const failureIntegrationVehicleStates = createAction<string>(
  ISettingsActionsType.FAILURE_INTEGRATION_VEHICLE_STATES,
);

export const receiveIntegrationRegions = createAction(
  ISettingsActionsType.RECEIVE_INTEGRATION_REGIONS,
);

export const receiveIntegrationHistory = createAction(
  ISettingsActionsType.RECEIVE_INTEGRATION_HISTORY,
);

export const receiveAutoLockingRules = createAction(ISettingsActionsType.RECEIVE_AUTOLOCKING_RULES);

export const requestChangeAutoLockingRule = createAction(
  ISettingsActionsType.REQUEST_CHANGE_AUTOLOCKING_RULE,
);
export const receiveChangeAutoLockingRule = createAction(
  ISettingsActionsType.RECEIVE_CHANGE_AUTOLOCKING_RULE,
);
export const failureChangeAutoLockingRule = createAction(
  ISettingsActionsType.FAILURE_CHANGE_AUTOLOCKING_RULE,
);

export const requestSaveMapping = createAction(ISettingsActionsType.REQUEST_SAVE_MAPPING);
export const receiveSaveMapping = createAction(ISettingsActionsType.RECEIVE_SAVE_MAPPING);
export const failureSaveMapping = createAction(ISettingsActionsType.FAILURE_SAVE_MAPPING);

export const requestDeleteMapping = createAction(ISettingsActionsType.REQUEST_DELETE_MAPPING);
export const receiveDeleteMapping = createAction(ISettingsActionsType.RECEIVE_DELETE_MAPPING);
export const failureDeleteMapping = createAction(ISettingsActionsType.FAILURE_DELETE_MAPPING);

export const resetIntegrationErrors = createAction(ISettingsActionsType.RESET_INTEGRATION_ERRORS);

export const fetchIntegrationInfo = () => {
  return async (dispatch: Dispatch) => {
    dispatch(requestIntegrationInfo());
    try {
      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        '/integrations/api',
        IMethods.GET,
        true,
      );
      dispatch(receiveIntegrationInfo(response.data.data));
    } catch (e) {
      dispatch(failureIntegrationInfo('An unexpected error occured. Try Again'));
    }
  };
};

export const saveIntegrationInfo: SaveIntegrationFunction = (key: string, url: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(requestSaveIntegrationInfo());
      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        '/integrations/api',
        IMethods.POST,
        true,
        undefined,
        {
          key,
          url,
        },
      );
      if (response.isError || response.code === 409) {
        dispatch(
          failureSaveIntegrationInfo({
            accountError: response.data.message,
          }),
        );
      } else {
        dispatch(receiveSaveIntegrationInfo());
      }
    } catch (e) {
      dispatch(
        failureSaveIntegrationInfo({
          accountError: 'An unexpected error occured. Try Again',
        }),
      );
    }
  };
};

export const fetchIntegrationLocalVehicleTypes = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(requestIntegrationLocalVehicleTypes());
      const response = await new ApiHelper().fetchVehicleTypes();
      if (response.isError) {
        dispatch(failureIntegrationLocalVehicleTypes(response.messages![0]));
        return;
      }
      const localVehicleTypes = response.data.data as IVehicleTypeModel[];
      dispatch(
        receiveIntegrationLocalVehicleTypes({
          localVehicleTypes,
        }),
      );
    } catch (e) {
      dispatch(failureIntegrationLocalVehicleTypes('An unexpected error occured. Try Again'));
    }
  };
};

export const fetchIntegrationVehicleTypes = () => {
  return async (dispatch: Dispatch) => {
    dispatch(requestIntegrationVehicleTypes());
    try {
      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        '/integrations/vehicle-types',
        IMethods.GET,
        true,
      );
      const remoteVehicleTypes = response.data.data as IRemoteMapping121[];
      dispatch(
        receiveIntegrationVehicleTypes({
          remoteVehicleTypes,
        }),
      );
    } catch (e) {
      dispatch(failureIntegrationVehicleTypes('An unexpected error occured. Try Again'));
    }
  };
};

export const fetchIntegrationLocalVehicleStates = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(requestIntegrationLocalVehicleStates());
      const response = await new ApiHelper().fetchVehicleStates();
      if (response.isError) {
        dispatch(failureIntegrationLocalVehicleStates(response.messages![0]));
        return;
      }
      const fleetStates = response.data.data as IFleetStateModel[];
      dispatch(
        receiveIntegrationLocalVehicleStates({
          localVehicleStates: fleetStates,
        }),
      );
    } catch (e) {
      dispatch(failureIntegrationLocalVehicleStates('An unexpected error occured. Try Again'));
    }
  };
};

export const fetchIntegrationVehicleStates = () => {
  return async (dispatch: Dispatch) => {
    dispatch(requestIntegrationVehicleStates());
    try {
      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        '/integrations/states',
        IMethods.GET,
        true,
      );
      const remoteVehicleStates = response.data.data as IRemoteMapping121[];
      dispatch(
        receiveIntegrationVehicleStates({
          remoteVehicleStates,
        }),
      );
    } catch (e) {
      dispatch(failureIntegrationVehicleStates('An unexpected error occured. Try Again'));
    }
  };
};

export const fetchIntegrationRegions = () => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await new ApiHelper().FetchFromPortal(
        '/integrations/region',
        IMethods.GET,
        true,
      );
      const remoteRegions = response.data.data as IRemoteMapping121[];
      dispatch(
        receiveIntegrationRegions({
          remoteRegions,
        }),
      );
    } catch (e) {
      console.error(e);
    }
  };
};

export const fetchIntegrationHistory = () => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await new ApiHelper().FetchFromPortal(
        '/integrations/history',
        IMethods.GET,
        true,
      );
      const integrationHistory = response.data.data as IIntegrationResult[];
      integrationHistory.forEach((history) => {
        history.dateStr = moment(history.completedAt).fromNow();
      });
      dispatch(
        receiveIntegrationHistory({
          integrationHistory,
        }),
      );
    } catch (e) {
      console.error(e);
    }
  };
};

export const fetchAutoLockingRules = () => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await new ApiHelper().FetchFromPortal(
        '/integrations/auto-lock',
        IMethods.GET,
        true,
      );
      const autoLockingRules = response.data.data as IAutoLockingRule[];
      dispatch(
        receiveAutoLockingRules({
          autoLockingRules,
        }),
      );
    } catch (e) {
      console.error(e);
    }
  };
};

export const changeAutoLockingRule: ChangeAutoLockingRuleFunction = (
  stateId: number,
  locked: boolean,
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(requestChangeAutoLockingRule({ stateId, locked }));
      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        '/integrations/auto-lock',
        IMethods.PUT,
        true,
        undefined,
        {
          stateId,
          locked,
        },
      );
      if (!response.isError) {
        dispatch(receiveChangeAutoLockingRule());
        return;
      }
      dispatch(failureChangeAutoLockingRule(response.messages![0]));
    } catch (e) {
      dispatch(failureChangeAutoLockingRule('An unexpected error occured. Try Again'));
    }
    dispatch((fetchAutoLockingRules as any)());
  };
};

export const saveIntegrationMapping: SaveIntegrationMappingFunction = (
  type: IMappingType,
  localId: string | number,
  remoteId: string | number,
  mapId: number | null,
) => {
  let endpoint = '';
  let reloadFunction: Function;
  switch (type) {
    case IMappingType.REGION:
      endpoint = '/integrations/region/';
      reloadFunction = fetchIntegrationRegions;
      break;
    case IMappingType.VEHICLE:
      endpoint = '/integrations/vehicle-types/';
      reloadFunction = fetchIntegrationVehicleTypes;
      break;
    case IMappingType.STATE:
      endpoint = '/integrations/states/';
      reloadFunction = fetchIntegrationVehicleStates;
      break;
  }
  return async (dispatch: Dispatch) => {
    dispatch(requestSaveMapping());
    let response: IResponseHandlerModel;
    try {
      if (mapId) {
        response = await new ApiHelper().FetchFromPortal(
          endpoint + mapId,
          IMethods.PUT,
          true,
          undefined,
          {
            localId,
            remoteId,
          },
        );
      } else {
        response = await new ApiHelper().FetchFromPortal(endpoint, IMethods.POST, true, undefined, {
          localId,
          remoteId,
        });
      }
      if (!response.isError) {
        if (response.code === 409) {
          dispatch(
            failureSaveMapping({
              modalError: response.data.message,
            }),
          );
        } else {
          dispatch(receiveSaveMapping());
          dispatch(reloadFunction());
        }
      }
    } catch (e) {
      console.error(e);
    }
  };
};

export const deleteIntegrationMapping: DeleteIntegrationMappingFunction = (
  type: IMappingType,
  mapId: number,
) => {
  let endpoint = '';
  let reloadFunction: Function;
  switch (type) {
    case IMappingType.REGION:
      endpoint = '/integrations/region/';
      reloadFunction = fetchIntegrationRegions;
      break;
    case IMappingType.VEHICLE:
      endpoint = '/integrations/vehicle-types/';
      reloadFunction = fetchIntegrationVehicleTypes;
      break;
    case IMappingType.STATE:
      endpoint = '/integrations/states/';
      reloadFunction = fetchIntegrationVehicleStates;
      break;
  }
  return async (dispatch: Dispatch) => {
    dispatch(requestDeleteMapping());
    try {
      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        endpoint + mapId,
        IMethods.DELETE,
        true,
        undefined,
      );
      if (!response.isError) {
        dispatch(reloadFunction());
        dispatch(receiveDeleteMapping());
        return;
      }
      dispatch(failureDeleteMapping(response.messages![0]));
    } catch (e) {
      dispatch(failureDeleteMapping('An unexpected error occured. Try Again'));
    }
  };
};
