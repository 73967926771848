import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';

import { IPriorityPeriod } from 'app/models';

import { PriorityPeriodItem } from './PriorityPeriodItem';

import { useStyles } from './styles';
import { AddPriorityPeriodModal } from '../Modals/AddPriorityPeriod/AddPriorityPeriodModal';

export interface IProps {
  priorityPeriods: IPriorityPeriod[];
  addOrUpdatePriorityPeriod: (period: IPriorityPeriod[]) => void;
}

export const PriorityPeriod: React.FC<IProps> = ({
  priorityPeriods,
  addOrUpdatePriorityPeriod,
}) => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [selectedPriorityPeriod, setSelectedPriorityPeriod] = useState<IPriorityPeriod | null>(
    null,
  );
  const [index, setIndex] = useState<number>(-1);

  useEffect(() => {
    if (!openModal) {
      setTimeout(() => {
        setSelectedPriorityPeriod(null);
        setIsEditing(false);
        setIndex(-1);
      }, 400);
    }
  }, [openModal]);

  const handleUpdatePriority = (index: number) => {
    setOpenModal(true);
    setIsEditing(true);
    setIndex(index);
    setSelectedPriorityPeriod(priorityPeriods[index]);
  };

  const handleAddOrUpdatePriorityPeriod = (period: IPriorityPeriod) => {
    let newPriorityPeriodsState = [...priorityPeriods];
    if (isEditing && index !== -1) {
      newPriorityPeriodsState[index] = period;
    } else {
      newPriorityPeriodsState.push(period);
    }
    addOrUpdatePriorityPeriod(newPriorityPeriodsState);
    setOpenModal(false);
  };

  const deletePriorityPeriod = (index: number) => {
    let newPriorityPeriodsState = [...priorityPeriods];
    newPriorityPeriodsState.splice(index, 1);
    addOrUpdatePriorityPeriod(newPriorityPeriodsState);
  };

  return (
    <>
      <div className={classes.container}>
        {priorityPeriods.length ? (
          <>
            <p className={classes.title}>DAY/TIME PRIORITIES</p>
            {priorityPeriods.map((priorityPeriod: IPriorityPeriod, index: number) => {
              return (
                <PriorityPeriodItem
                  key={index}
                  priorityPeriod={priorityPeriod}
                  openEditModal={() => handleUpdatePriority(index)}
                  openDeleteModal={() => {
                    deletePriorityPeriod(index);
                  }}
                />
              );
            })}
          </>
        ) : null}
        <Button
          color="default"
          size="small"
          className={classes.addButton}
          onClick={() => setOpenModal(true)}
        >
          Add day/time priority
        </Button>
      </div>
      {openModal && (
        <AddPriorityPeriodModal
          openModal={openModal}
          isEditing={isEditing}
          handleClose={() => setOpenModal(false)}
          handleContinue={handleAddOrUpdatePriorityPeriod}
          selectedPriorityPeriod={selectedPriorityPeriod}
        />
      )}
    </>
  );
};
