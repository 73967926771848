import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';

import {
  IResponseHandlerModel,
  IMethods,
  IUserDetail,
  IRegion,
  IMaintainer,
  IUserRole,
} from 'app/models';
import { ApiHelper } from 'app/helpers/api-helper';
import { IRootState } from 'app/reducers/AppState';

export enum IUserType {
  REQUEST_USER_DETAILS = 'REQUEST_USER_DETAILS',
  RECEIVE_USER_DETAILS = 'RECEIVE_USER_DETAILS',
  FAILURE_USER_DETAILS = 'FAILURE_USER_DETAILS',

  CLEAR_USER_DETAILS = 'CLEAR_USER_DETAILS',

  RECEIVE_REGIONS = 'RECEIVE_REGIONS',

  CHANGE_REGION = 'CHANGE_REGION',

  CHANGE_MAINTAINER = 'CHANGE_MAINTAINER',
}

// tslint:disable:typedef

export const requestUserDetails = createAction(IUserType.REQUEST_USER_DETAILS);
export const recieveUserDetails = createAction<IUserDetail>(IUserType.RECEIVE_USER_DETAILS);
export const failureUserDetails = createAction(IUserType.FAILURE_USER_DETAILS);

export const clearUserDetails = createAction(IUserType.CLEAR_USER_DETAILS);

export const receiveRegion = createAction<IRegion[]>(IUserType.RECEIVE_REGIONS);

export const changeRegion = createAction<IRegion>(IUserType.CHANGE_REGION);
export const changeMaintainer = createAction<IMaintainer>(IUserType.CHANGE_MAINTAINER);

export const fetchUserDetails = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(requestUserDetails());
      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        '/login',
        IMethods.GET,
        true,
      );
      if (response.isError) {
        dispatch(failureUserDetails());
        return;
      }

      const userDetail = response.data as IUserDetail;
      if (userDetail.company && userDetail.company.regions && userDetail.company.regions.length) {
        const newRegions = userDetail.company.regions as IRegion[];
        dispatch(receiveRegion(newRegions));
      }
      dispatch(recieveUserDetails(userDetail));
    } catch (e) {
      dispatch(failureUserDetails());
    }
  };
};

export const removeUserDetails = () => {
  return (dispatch: Dispatch) => {
    dispatch(clearUserDetails());
  };
};

export const updateRegion = (region: IRegion, maintainer?: IMaintainer) => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    const userDetails = getState().userDetails.userDetails;
    if (userDetails) {
      const role = userDetails.role;
      if (role === IUserRole.FleetOwner) {
        // TODO: check if path is new or edit hotspot and in case exit from it
      }
    }

    dispatch(changeRegion(region));
    if (maintainer) {
      dispatch(changeMaintainer(maintainer));
    }
  };
};
