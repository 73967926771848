import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { IRootState } from 'app/reducers/AppState';
import { Employees } from 'app/views';
import {
  fetchEmployeesList,
  fetchActivitiesList,
  addNewEmployee,
  openEditEmplyeeModal,
  closeEditEmplyeeModal,
  editEmployee,
  deleteEmployee,
} from 'app/actions';
import {
  IEmployeesModel,
  INewEmployeeDetails,
  IEmployeeModel,
  IRegion,
  IUserDetail,
} from 'app/models';

interface StateFromProps {
  userDetails: IUserDetail | null;
  employeesData: IEmployeesModel;
  selectedRegion: IRegion | null;
}

interface DispatchFromProps {
  fetchAllEmployees: () => void;
  fetchAllActivitiesList: () => void;
  addNewEmployee: (employee: INewEmployeeDetails) => void;
  openEditEmployeeModal: (employee: IEmployeeModel) => void;
  closeEditEmployeeModal: () => void;
  editEmployee: (employee: INewEmployeeDetails) => void;
  deleteEmployee: (employeeId: string) => void;
}

const mapStateToProps = (state: IRootState): StateFromProps => ({
  userDetails: state.userDetails.userDetails,
  employeesData: state.employees,
  selectedRegion: state.userDetails.selectedRegion,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => ({
  fetchAllEmployees: bindActionCreators(fetchEmployeesList, dispatch),
  fetchAllActivitiesList: bindActionCreators(fetchActivitiesList, dispatch),
  addNewEmployee: bindActionCreators(addNewEmployee, dispatch),
  openEditEmployeeModal: bindActionCreators(openEditEmplyeeModal, dispatch),
  closeEditEmployeeModal: bindActionCreators(closeEditEmplyeeModal, dispatch),
  editEmployee: bindActionCreators(editEmployee, dispatch),
  deleteEmployee: bindActionCreators(deleteEmployee, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Employees);
