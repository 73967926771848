import * as React from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Paper,
  Divider,
  Checkbox,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import {
  ServeedoTableHeader,
  ITableHeaderCells,
  FilterMenuItem,
  ServeedoTableToolbar,
  ITableFilter,
  NoData,
} from 'app/components/common';
import { IRepairGuideModel, IVehicleTypeListModel } from 'app/models';

import { getSorting, stableSort } from 'app/helpers/common';
import { ConfirmationModal } from '../Modals/ConfirmationModal/ConfirmationModal';
import { togglePresenceItem } from '../../helpers/common';
import { DeleteItemsFunction } from '../../models/RepairGuidesModel';
import { makeDateRelative } from 'app/helpers/time-helper';

export interface IRepairGuideTableModel {
  id: string;
  title: string;
  vehicleType: string;
  updatedAt: string;
  vehicleTypeId: string;
}

const headerCells: ITableHeaderCells[] = [
  { id: 'title', numeric: false, disablePadding: false, label: 'Title' },
  { id: 'vehicleType', numeric: true, disablePadding: false, label: 'Vehicle Type' },
  { id: 'updatedAt', numeric: true, disablePadding: false, label: 'Last Update' },
];

export interface IProps {
  repairGuidesList: IRepairGuideModel[];
  vehicleTypesList: IVehicleTypeListModel[];
  isLoading: boolean;
  editRepairGuide: (repairGuide: IRepairGuideModel) => void;
  deleteRepairGuides: DeleteItemsFunction;
  deletingGuidesIds: string[];
}

export const RepairGuidesList: React.SFC<IProps> = ({
  repairGuidesList,
  vehicleTypesList,
  isLoading,
  editRepairGuide,
  deleteRepairGuides,
  deletingGuidesIds,
}) => {
  const [order, setOrder] = React.useState<any>('asc');
  const [orderBy, setOrderBy] = React.useState('title');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  const [selectedOption, setSelectedOption] = React.useState<FilterMenuItem>({
    label: 'All',
    value: 'all',
  });
  const [selected, setSelected] = React.useState<string[]>([]);
  const [openConfirmModal, setOpenConfirmModal] = React.useState<boolean>(false);

  const handleClick = (event: any, name: string) => {
    event.stopPropagation();

    setSelected(togglePresenceItem(selected, name));
  };

  const handleRequestSort = (event: any, property: string) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditRepairGuide = (id: string) => {
    const repairGuide = [...repairGuidesList].find((r) => r.id === id)!;
    if (repairGuide) {
      editRepairGuide(repairGuide);
    }
  };

  const modifiedRepairGuide: IRepairGuideTableModel[] = [...repairGuidesList]
    .map((r) => ({
      id: r.id,
      title: r.translations['en'].title
        ? (r.translations['en'].title as string)
        : (('' + r.translations['en'] + '') as string),
      vehicleType: [...vehicleTypesList].find((v) => v.id === r.vehicleTypeId)
        ? [...vehicleTypesList].find((v) => v.id === r.vehicleTypeId)!.name
        : 'Vehicle Type Deleted',
      vehicleTypeId: r.vehicleTypeId,
      updatedAt: makeDateRelative(r.updatedAt),
    }))
    .filter((s) =>
      selectedOption.value !== 'all' ? selectedOption.value === s.vehicleTypeId : true,
    )
    .filter((s) => deletingGuidesIds.indexOf(s.id) === -1);

  // const handleSelectAllClick = (event: any) => {
  //   if (event.target.checked) {
  //     const newSelecteds: string[] = modifiedRepairGuide.map((n) => n.id);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  const list = stableSort<IRepairGuideTableModel>(
    modifiedRepairGuide,
    getSorting(order, orderBy),
  ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const tableFilter: ITableFilter[] = [
    {
      menuTitle: 'Vehicle Types',
      items: [
        { label: 'All', value: 'all' },
        ...vehicleTypesList.map((v) => {
          const item = {
            label: v.name,
            value: v.id,
          };
          return item;
        }),
      ],
      onSelect: (value) => setSelectedOption(value),
      selectedOption,
    },
  ];

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  return (
    <>
      <Paper>
        <ServeedoTableToolbar
          title={'All Guides'}
          tableFilter={tableFilter}
          numSelected={selected.length}
          onDelectButtonClick={() => setOpenConfirmModal(true)}
        />
        <div className="table-wrapper">
          <Table aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table">
            <ServeedoTableHeader
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={repairGuidesList.length}
              headerCells={headerCells}
              selecting
              isLoading={isLoading}
            />
            <TableBody>
              {isLoading
                ? [1, 2, 3].map((item) => (
                    <TableRow key={item}>
                      <TableCell padding="checkbox">
                        <Checkbox checked={false} inputProps={{ 'aria-labelledby': '' }} disabled />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <React.Fragment>
                          <Skeleton
                            height={10}
                            width="55%"
                            style={{ marginTop: 6, marginBottom: 6 }}
                          />
                        </React.Fragment>
                      </TableCell>
                      <TableCell>
                        <React.Fragment>
                          <Skeleton
                            height={10}
                            width="80%"
                            style={{ marginTop: 6, marginBottom: 6 }}
                          />
                        </React.Fragment>
                      </TableCell>
                      <TableCell>
                        <React.Fragment>
                          <Skeleton
                            height={10}
                            width="60%"
                            style={{ marginTop: 6, marginBottom: 6 }}
                          />
                        </React.Fragment>
                      </TableCell>
                    </TableRow>
                  ))
                : list.map((repairGuide: IRepairGuideTableModel, index: number) => {
                    const isItemSelected = isSelected(repairGuide.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        aria-checked={isItemSelected}
                        key={index}
                        onClick={() => handleEditRepairGuide(repairGuide.id)}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                            onClick={(e) => handleClick(e, repairGuide.id)}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {repairGuide.title}
                        </TableCell>
                        <TableCell>{repairGuide.vehicleType}</TableCell>
                        <TableCell>{repairGuide.updatedAt}</TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </div>
        {!isLoading && !modifiedRepairGuide.length && (
          <>
            <NoData />
            <Divider />
          </>
        )}
        <TablePagination
          rowsPerPageOptions={[50, 100, 250]}
          component="div"
          count={modifiedRepairGuide.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <ConfirmationModal
        openModal={openConfirmModal}
        modalBody={
          <span>
            <strong>{selected.length}</strong> repair guides will be deleted
          </span>
        }
        handleClose={() => setOpenConfirmModal(false)}
        handleContinue={() => {
          setOpenConfirmModal(false);
          deleteRepairGuides(selected);
          setSelected([]);
        }}
      />
    </>
  );
};
