import * as React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { ListItem, Button, Collapse } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { useNavigationListStyles } from '../../LeftNavigationStyles';
import { ISupportedRoutes } from 'app/models';

export interface IProps {
  title: string;
  href: ISupportedRoutes;
  depth: number;
  icon: any;
  className?: string;
  open?: boolean;
  label: string | undefined;
}

export const NavigationListItem: React.SFC<IProps> = (props) => {
  const {
    title,
    href,
    depth,
    children,
    icon: Icon,
    className,
    open: openProp,
    label: Label,
    ...rest
  } = props;

  const classes = useNavigationListStyles();
  const [open, setOpen] = React.useState(openProp);

  const handleToggle = () => {
    setOpen((open: any) => !open);
  };

  const logOut = () => {
    localStorage.clear();
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = {
    paddingLeft,
  };

  if (children) {
    return (
      <ListItem {...rest} className={clsx(classes.item, className)} disableGutters>
        <Button className={classes.button} onClick={handleToggle} style={style}>
          {Icon && <Icon className={classes.icon} />}
          {title}
          {open ? (
            <ExpandLessIcon className={classes.expandIcon} color="inherit" />
          ) : (
            <ExpandMoreIcon className={classes.expandIcon} color="inherit" />
          )}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }
  return (
    <ListItem {...rest} className={clsx(classes.itemLeaf, className)} disableGutters>
      <RouterLink
        activeClassName={classes.active}
        className={clsx(classes.buttonLeaf, `depth-${depth}`)}
        to={href}
        onClick={href === ISupportedRoutes.LOGIN ? logOut : () => null}
      >
        <Button className={classes.button} style={style}>
          {Icon && <Icon className={classes.icon} />}
          {title}
          {Label && (
            <span className={classes.label}>
              <Label />
            </span>
          )}
        </Button>
      </RouterLink>
    </ListItem>
  );
};
