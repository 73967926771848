import * as React from 'react';
import { WidgetHeader } from 'app/components/WidgetHeader/WidgetHeader';
import { IFleetModel, IVehicleTypeListModel, IRegion, ISupportedRoutes } from 'app/models';
import { FleetList } from 'app/components/FleetList/FleetList';
import { RouteComponentProps } from 'react-router';

export interface IProps extends RouteComponentProps<any> {
  fleet: IFleetModel;
  vehicleTypesList: IVehicleTypeListModel[];
  fetchFleetList: () => void;
  fetchVehiclesList: () => void;
  selectedRegion: IRegion | null;
  regions: IRegion[];
  getFleetStates: () => void;
}
export interface IState {}
class Fleet extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getFleetStates();
    this.props.fetchVehiclesList();
    this.props.fetchFleetList();
  }

  componentDidUpdate(prevProps: IProps) {
    const { selectedRegion } = this.props;
    if (prevProps.selectedRegion !== selectedRegion && selectedRegion) {
      let newUrl =
        `${selectedRegion.key !== 'all' ? '/' + selectedRegion.key : ''}` + ISupportedRoutes.FLEET;
      this.props.history.push(newUrl);
    }
  }

  render(): JSX.Element {
    const {
      fleet: { fleetList, isLoading, fleetStates },
      vehicleTypesList,
      selectedRegion,
      regions,
    } = this.props;
    return (
      <div className={'container'}>
        <WidgetHeader
          headerText={'Fleet'}
          headerSmallText={selectedRegion ? selectedRegion.name.toUpperCase() : 'All'}
        />
        <FleetList
          fleetList={fleetList}
          isLoading={isLoading}
          vehicleTypesList={vehicleTypesList}
          selectedRegion={selectedRegion}
          regions={regions}
          fleetStates={fleetStates}
        />
      </div>
    );
  }
}

export default Fleet;
