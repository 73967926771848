import * as React from 'react';

import AddIcon from '@material-ui/icons/Add';

import { WidgetHeader } from 'app/components/WidgetHeader/WidgetHeader';
import { ServeedoButton } from 'app/components/common';
import {
  IRepairGuidesModel,
  IVehicleTypeListModel,
  ISupportedRoutes,
  IRepairGuideModel,
} from 'app/models';
import { RepairGuidesList } from 'app/components/RepairGuidesList/RepairGuidesList';
import { RouteComponentProps } from 'react-router';
import { DeleteItemsFunction } from '../../models/RepairGuidesModel';
export interface IProps extends RouteComponentProps<any> {
  repairGuides: IRepairGuidesModel;
  vehicleTypesList: IVehicleTypeListModel[];
  fetchRepairGuides: () => void;
  fetchVehicleTypes: () => void;
  editRepairGuide: (repairGuide: IRepairGuideModel) => void;
  deleteRepairGuides: DeleteItemsFunction;
}

export interface IState {}

class RepairGuide extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.fetchVehicleTypes();
    this.props.fetchRepairGuides();
  }

  gotoAddNewRepairGuide = () => {
    this.props.history.push(ISupportedRoutes.NEW_REPAIR_GUIDES);
  };

  renderButton = (): JSX.Element => {
    return (
      <ServeedoButton
        buttonText={'New Guide'}
        onClick={this.gotoAddNewRepairGuide}
        buttonIcon={<AddIcon />}
      />
    );
  };

  render(): JSX.Element {
    const {
      repairGuides: { repaireGuidesList, isLoading, removingGuidesIds },
      vehicleTypesList,
      editRepairGuide,
      deleteRepairGuides,
    } = this.props;
    return (
      <div className={'container'}>
        <WidgetHeader
          headerText={'Repair Guides'}
          headerSmallText={'GENERAL'}
          rightPanel={this.renderButton()}
        />
        <RepairGuidesList
          repairGuidesList={repaireGuidesList}
          isLoading={isLoading}
          vehicleTypesList={vehicleTypesList}
          editRepairGuide={editRepairGuide}
          deleteRepairGuides={deleteRepairGuides}
          deletingGuidesIds={removingGuidesIds}
        />
      </div>
    );
  }
}

export default RepairGuide;
