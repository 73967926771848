import { createMuiTheme } from '@material-ui/core';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

const theme: ThemeOptions = createMuiTheme({
  palette,
  typography,
  overrides,
});

export default theme;
