import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { IRootState } from 'app/reducers/AppState';
import { RepairGuides } from 'app/views';
import { IRepairGuidesModel, IVehicleTypeListModel, IRepairGuideModel } from 'app/models';
import {
  fetchRepairGuidesList,
  fetchVehiclesTypesList,
  editRepairGuide,
  deleteRepairGuides,
} from 'app/actions';
import { DeleteItemsFunction } from '../../models/RepairGuidesModel';

interface StateFromProps {
  repairGuides: IRepairGuidesModel;
  vehicleTypesList: IVehicleTypeListModel[];
}

interface DispatchFromProps {
  fetchRepairGuides: () => void;
  fetchVehicleTypes: () => void;
  editRepairGuide: (repairGuide: IRepairGuideModel) => void;
  deleteRepairGuides: DeleteItemsFunction;
}

const mapStateToProps = (state: IRootState): StateFromProps => ({
  repairGuides: state.repairGuides,
  vehicleTypesList: state.vehicleTypes.vehicleTypesList,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => ({
  fetchVehicleTypes: bindActionCreators(fetchVehiclesTypesList, dispatch),
  fetchRepairGuides: bindActionCreators(fetchRepairGuidesList, dispatch),
  editRepairGuide: bindActionCreators(editRepairGuide, dispatch),
  deleteRepairGuides: bindActionCreators(deleteRepairGuides, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RepairGuides);
