import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import { push } from 'react-router-redux';
import {
  IResponseHandlerModel,
  IMethods,
  IRepairGuideModel,
  IAddRepairGuideModel,
  ISupportedRoutes,
} from 'app/models';
import { ApiHelper } from 'app/helpers/api-helper';
import { IRootState } from 'app/reducers/AppState';
import { DeleteItemsFunction } from '../models/RepairGuidesModel';

export enum IRepairGuidesActionsType {
  REQUEST_REPAIR_GUIDES_LIST = 'REQUEST_REPAIR_GUIDES_LIST',
  RECEIVE_REPAIR_GUIDES_LIST = 'RECEIVE_REPAIR_GUIDES_LIST',
  FAILURE_REPAIR_GUIDES_LIST = 'FAILURE_REPAIR_GUIDES_LIST',

  REQUEST_ADD_REPAIR_GUIDE = 'REQUEST_ADD_REPAIR_GUIDE',
  RECEIVE_ADD_REPAIR_GUIDE = 'RECEIVE_ADD_REPAIR_GUIDE',
  FAILURE_ADD_REPAIR_GUIDE = 'FAILURE_ADD_REPAIR_GUIDE',

  HANDLE_EDIT_REPAIR_GUIDE = 'HANDLE_EDIT_REPAIR_GUIDE',

  REQUEST_FETCH_REPAIR_GUIDE = 'REQUEST_FETCH_REPAIR_GUIDE',
  RECEIVE_FETCH_REPAIR_GUIDE = 'RECEIVE_FETCH_REPAIR_GUIDE',
  FAILURE_FETCH_REPAIR_GUIDE = 'FAILURE_FETCH_REPAIR_GUIDE',

  REQUEST_DELETE_REPAIR_GUIDE = 'REQUEST_DELETE_REPAIR_GUIDE',
  RECEIVE_DELETE_REPAIR_GUIDE = 'RECEIVE_DELETE_REPAIR_GUIDE',
  FAILURE_DELETE_REPAIR_GUIDE = 'FAILURE_DELETE_REPAIR_GUIDE',

  REQUEST_DELETE_REPAIR_GUIDES = 'REQUEST_DELETE_REPAIR_GUIDES',
  RECEIVE_DELETE_REPAIR_GUIDES = 'RECEIVE_DELETE_REPAIR_GUIDES',
  FAILURE_DELETE_REPAIR_GUIDES = 'FAILURE_DELETE_REPAIR_GUIDES',
}

// tslint:disable:typedef

export const requestRepairGuidesList = createAction(
  IRepairGuidesActionsType.REQUEST_REPAIR_GUIDES_LIST,
);
export const receiveRepairGuidesList = createAction<IRepairGuideModel[]>(
  IRepairGuidesActionsType.RECEIVE_REPAIR_GUIDES_LIST,
);
export const failureRepairGuidesList = createAction(
  IRepairGuidesActionsType.FAILURE_REPAIR_GUIDES_LIST,
);

export const requestAddRepairGuide = createAction(
  IRepairGuidesActionsType.REQUEST_ADD_REPAIR_GUIDE,
);
export const receiveAddRepairGuide = createAction(
  IRepairGuidesActionsType.RECEIVE_ADD_REPAIR_GUIDE,
);
export const failureAddRepairGuide = createAction<string>(
  IRepairGuidesActionsType.FAILURE_ADD_REPAIR_GUIDE,
);

export const handleEditRepairGuide = createAction<IRepairGuideModel>(
  IRepairGuidesActionsType.HANDLE_EDIT_REPAIR_GUIDE,
);

export const requestFetchRepairGuide = createAction(
  IRepairGuidesActionsType.REQUEST_FETCH_REPAIR_GUIDE,
);
export const receiveFetchRepairGuide = createAction<IRepairGuideModel>(
  IRepairGuidesActionsType.RECEIVE_FETCH_REPAIR_GUIDE,
);
export const failureFetchRepairGuide = createAction(
  IRepairGuidesActionsType.FAILURE_FETCH_REPAIR_GUIDE,
);

export const requestDeleteRepairGuide = createAction(
  IRepairGuidesActionsType.REQUEST_DELETE_REPAIR_GUIDE,
);
export const receiveDeleteRepairGuide = createAction(
  IRepairGuidesActionsType.RECEIVE_DELETE_REPAIR_GUIDE,
);
export const failureDeleteRepairGuide = createAction(
  IRepairGuidesActionsType.FAILURE_DELETE_REPAIR_GUIDE,
);

export const requestDeleteRepairGuides = createAction(
  IRepairGuidesActionsType.REQUEST_DELETE_REPAIR_GUIDES,
);
export const receiveDeleteRepairGuides = createAction(
  IRepairGuidesActionsType.RECEIVE_DELETE_REPAIR_GUIDES,
);
export const failureDeleteRepairGuides = createAction(
  IRepairGuidesActionsType.FAILURE_DELETE_REPAIR_GUIDES,
);

export const fetchRepairGuidesList = () => {
  return async (dispatch: Dispatch) => {
    dispatch(requestRepairGuidesList());

    try {
      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        '/repair-guides',
        IMethods.GET,
        true,
      );
      if (response.isError) {
        dispatch(failureRepairGuidesList());
        return;
      }
      const repaireGuidesList = response.data.data as IRepairGuideModel[];
      dispatch(receiveRepairGuidesList(repaireGuidesList));
    } catch (e) {
      dispatch(failureRepairGuidesList());
    }
  };
};

export const addNewRepairGuide = (repairGuide: IAddRepairGuideModel) => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    const {
      userDetails: { userDetails },
      repairGuides: { selectedRepairGuide, isEditing },
    } = getState();
    dispatch(requestAddRepairGuide());

    try {
      if (selectedRepairGuide && isEditing) {
        const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
          '/repair-guides/' + selectedRepairGuide.id,
          IMethods.PUT,
          true,
          undefined,
          {
            ...repairGuide,
            ...(userDetails && userDetails.company && { ownerId: userDetails.company.id }),
          },
        );

        if (response.isError) {
          dispatch(failureAddRepairGuide(response.messages![0]));
          return;
        }
      } else {
        const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
          '/repair-guides',
          IMethods.POST,
          true,
          undefined,
          {
            ...repairGuide,
            ...(userDetails && userDetails.company && { ownerId: userDetails.company.id }),
          },
        );

        if (response.isError) {
          dispatch(failureAddRepairGuide(response.messages![0]));
          return;
        }
      }

      dispatch(receiveAddRepairGuide());
      dispatch(push(ISupportedRoutes.REPAIR_GUIDES));
    } catch (e) {
      dispatch(failureAddRepairGuide('An unexpected error occured. Try Again'));
    }
  };
};

export const editRepairGuide = (repairGuide: IRepairGuideModel) => {
  return (dispatch: Dispatch) => {
    dispatch(handleEditRepairGuide(repairGuide));
    dispatch(push(ISupportedRoutes.REPAIR_GUIDES + '/' + repairGuide.id));
  };
};

export const fetchRepairGuide = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestFetchRepairGuide());
    try {
      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        '/repair-guides/' + id,
        IMethods.GET,
        true,
      );

      if (response.isError) {
        dispatch(failureFetchRepairGuide());
        return;
      }
      const selectedRepairGuide = response.data.data as IRepairGuideModel;
      dispatch(receiveFetchRepairGuide(selectedRepairGuide));
    } catch (e) {
      dispatch(failureFetchRepairGuide());
    }
  };
};

export const deleteRepairGuide = () => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    const {
      repairGuides: { selectedRepairGuide },
    } = getState();
    if (selectedRepairGuide) {
      dispatch(requestDeleteRepairGuide());

      try {
        const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
          '/repair-guides/' + selectedRepairGuide.id,
          IMethods.DELETE,
          true,
        );

        if (response.isError) {
          dispatch(failureDeleteRepairGuide());
          return;
        }
        dispatch(receiveDeleteRepairGuide());
        dispatch(push(ISupportedRoutes.REPAIR_GUIDES));
      } catch (e) {
        dispatch(failureDeleteRepairGuide());
      }
    }
  };
};

export const deleteRepairGuides: DeleteItemsFunction = (ids: string[]) => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    try {
      dispatch(requestDeleteRepairGuides({ removingGuidesIds: ids }));
      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        '/repair-guides/',
        IMethods.DELETE,
        true,
        undefined,
        {
          repairGuides: ids,
        },
      );
      if (response.isError) {
        dispatch(failureDeleteRepairGuides());
        return;
      }
      dispatch(receiveDeleteRepairGuides());
    } catch (e) {
      dispatch(failureDeleteRepairGuides());
    }
  };
};
