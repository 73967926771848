import * as React from 'react';

import { Divider } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import { useStyles } from './NavigationLoadingSytles';

export const NavigationLoading = () => {
  const classes = useStyles();
  return (
    <div style={{ marginBottom: 26 }}>
      <Divider className={classes.divider} />
      <Skeleton variant="text" width={'35%'} height={18} style={{ marginBottom: '1.3rem' }} />
      {[1, 2, 3].map((item) => {
        return (
          <div key={item} className={classes.navLoadingItem}>
            <Skeleton
              variant="text"
              width={28}
              height={28}
              style={{ marginRight: 10, marginTop: 'auto', marginBottom: 'auto' }}
            />
            <Skeleton
              variant="text"
              width={`${Math.floor(Math.random() * 20) + 50}%`}
              height={14}
              style={{ marginTop: 'auto', marginBottom: 'auto' }}
            />
          </div>
        );
      })}
    </div>
  );
};
