import * as React from 'react';

import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Check } from '@material-ui/icons';
import { ILanguage } from 'app/models';

export interface IProps {
  languages: ILanguage[];
  onChangeLanguage: (language: ILanguage) => void;
  selectedLanguage: ILanguage;
}

export const SelectLanguageButton: React.SFC<IProps> = ({
  languages,
  onChangeLanguage,
  selectedLanguage,
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<any>(null);

  const handleMenuItemClick = (region: ILanguage) => {
    setOpen(false);
    onChangeLanguage(region);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef && anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  return (
    <div>
      <Button
        color="default"
        size="small"
        aria-controls={open ? 'split-button-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-label="select merge strategy"
        aria-haspopup="menu"
        onClick={handleToggle}
        ref={anchorRef}
        style={{
          width: '150px',
          justifyContent: 'space-between',
          backgroundColor: '#eee',
          textTransform: 'unset',
          margin: '15px 0 0',
          padding: 6,
          fontWeight: 600,
          fontSize: 14,
        }}
      >
        <span>{selectedLanguage.label} </span>
        {selectedLanguage.value === 'en' && <span>(Default)</span>}
        <ArrowDropDownIcon />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 1, width: '150px' }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {languages.map((language: ILanguage, index: number) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        if (selectedLanguage.value === language.value) return;
                        handleMenuItemClick(language);
                      }}
                      style={{ padding: 10, paddingLeft: 30 }}
                    >
                      {selectedLanguage.value === language.value && (
                        <ListItemIcon style={{ position: 'absolute', left: 5 }}>
                          <Check fontSize="small" />
                        </ListItemIcon>
                      )}
                      {language.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
