import * as React from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Paper,
  Divider,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import {
  ServeedoTableHeader,
  ITableHeaderCells,
  ServeedoTableToolbar,
  FilterMenuItem,
  ITableFilter,
  NoData,
} from 'app/components/common';
import {
  IVehicleTypeListModel,
  IFleetListModel,
  ISupportedRoutes,
  IRegion,
  IFleetStateModel,
} from 'app/models';

import useRouter from 'app/helpers/use-router';
import { getSorting, stableSort, dynamicSort } from 'app/helpers/common';
import { makeDateRelative } from 'app/helpers/time-helper';

export interface IFleetTableModel {
  id: string;
  state: string;
  stateId: number;
  vehicleType: string;
  vehicleTypeId: string;
  remoteId: number;
  regionId: string;
  updatedAt: Date;
}

const headerCells: ITableHeaderCells[] = [
  { id: 'remoteId', numeric: false, disablePadding: false, label: 'Id' },
  { id: 'state', numeric: false, disablePadding: false, label: 'State' },
  { id: 'vehicleType', numeric: true, disablePadding: false, label: 'Vehicle Type' },
  { id: 'location', numeric: true, disablePadding: false, label: 'Location' },
  { id: 'updatedAt', numeric: true, disablePadding: false, label: 'Last Status Change' },
];

export interface IProps {
  fleetList: IFleetListModel[];
  vehicleTypesList: IVehicleTypeListModel[];
  isLoading: boolean;
  selectedRegion: IRegion | null;
  regions: IRegion[];
  fleetStates: IFleetStateModel[];
}

export const FleetList: React.SFC<IProps> = ({
  fleetList,
  vehicleTypesList,
  isLoading,
  selectedRegion,
  regions,
  fleetStates,
}) => {
  const [order, setOrder] = React.useState<any>('asc');
  const [orderBy, setOrderBy] = React.useState('remoteId');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [selectedOption, setSelectedOption] = React.useState<FilterMenuItem>({
    label: 'All',
    value: 'all',
  });
  const [selectedState, setSelectedState] = React.useState<FilterMenuItem>({
    label: 'All',
    value: 'all',
  });
  const [selected, setSelected] = React.useState([]);
  const [searchKey, setSearchKey] = React.useState<string>('');

  const router = useRouter();

  const handleRequestSort = (event: any, property: string) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds: any = fleetList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const modifiedFleetList: IFleetTableModel[] = [...fleetList]
    .map((f) => ({
      ...f,
      state: fleetStates.find((s) => s.id === f.stateId)
        ? fleetStates.find((s) => s.id === f.stateId)!.translations.en
        : 'unknown',
      vehicleType: vehicleTypesList.length
        ? [...vehicleTypesList].find((v) => v.id === f.vehicleTypeId)!.name
        : 'Vehicle Type is not available',
    }))
    .filter((s) => s.remoteId.toString().includes(searchKey))
    .filter((s) =>
      selectedRegion
        ? selectedRegion.key === 'all'
          ? true
          : s.regionId === selectedRegion.id
        : true,
    )
    .filter((s) =>
      selectedOption.value !== 'all' ? selectedOption.value === s.vehicleTypeId : true,
    )
    .filter((s) => (selectedState.value !== 'all' ? selectedState.value === s.stateId : true));

  let list = stableSort<IFleetTableModel>([...modifiedFleetList], getSorting(order, orderBy)).slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  const tableFilter: ITableFilter[] = [
    {
      menuTitle: 'Vehicle Types',
      items: [
        { label: 'All', value: 'all' },
        ...vehicleTypesList.map((v) => {
          const item = {
            label: v.name,
            value: v.id,
          };
          return item;
        }),
      ],
      onSelect: (value) => setSelectedOption(value),
      selectedOption,
    },
    {
      menuTitle: 'States',
      items: [
        { label: 'All', value: 'all' },
        ...fleetStates
          .map((f) => {
            const item = {
              label: f.translations.en,
              value: f.id,
            };
            return item;
          })
          .sort(dynamicSort('label')),
      ],
      onSelect: (value) => setSelectedState(value),
      selectedOption: selectedState,
    },
  ];

  return (
    <Paper>
      <ServeedoTableToolbar
        title={'All Vehicles'}
        tableFilter={tableFilter}
        tableSearch
        searchFind={(value) => setSearchKey(value)}
        searchInputPlaceholder={'Search by Id'}
      />
      <div className="table-wrapper">
        <Table aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table">
          <ServeedoTableHeader
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={fleetList.length}
            headerCells={
              selectedRegion
                ? selectedRegion.key === 'all'
                  ? headerCells
                  : headerCells.filter((h) => h.id !== 'location')
                : headerCells
            }
            selecting={false}
          />
          <TableBody>
            {isLoading
              ? [1, 2, 3].map((item) => (
                  <TableRow key={item} role="checkbox">
                    <TableCell component="th" scope="row">
                      <React.Fragment>
                        <Skeleton
                          height={10}
                          width="50%"
                          style={{ marginTop: 6, marginBottom: 6 }}
                        />
                      </React.Fragment>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <React.Fragment>
                        <Skeleton
                          height={10}
                          width="50%"
                          style={{ marginTop: 6, marginBottom: 6 }}
                        />
                      </React.Fragment>
                    </TableCell>
                    <TableCell>
                      <React.Fragment>
                        <Skeleton
                          height={10}
                          width="70%"
                          style={{ marginTop: 6, marginBottom: 6 }}
                        />
                      </React.Fragment>
                    </TableCell>
                    {selectedRegion && selectedRegion.id === 'all' && (
                      <TableCell>
                        <React.Fragment>
                          <Skeleton
                            height={10}
                            width="60%"
                            style={{ marginTop: 6, marginBottom: 6 }}
                          />
                        </React.Fragment>
                      </TableCell>
                    )}
                    <TableCell>
                      <React.Fragment>
                        <Skeleton
                          height={10}
                          width="60%"
                          style={{ marginTop: 6, marginBottom: 6 }}
                        />
                      </React.Fragment>
                    </TableCell>
                  </TableRow>
                ))
              : list.map((fleet: IFleetTableModel, index: number) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      onClick={() =>
                        router.history.push(
                          `${
                            selectedRegion && selectedRegion.key !== 'all'
                              ? '/' + selectedRegion.key
                              : ''
                          }` +
                            ISupportedRoutes.FLEET +
                            '/' +
                            fleet.remoteId,
                        )
                      }
                    >
                      <TableCell component="th" scope="row">
                        {fleet.remoteId}
                      </TableCell>
                      <TableCell>{fleet.state}</TableCell>
                      <TableCell>{fleet.vehicleType}</TableCell>
                      {selectedRegion && selectedRegion.key === 'all' && (
                        <TableCell>
                          {[...regions].find((r) => r.id === fleet.regionId) &&
                          regions.find((r) => r.id === fleet.regionId)!.name
                            ? [...regions].find((r) => r.id === fleet.regionId)!.name
                            : 'Location unavailable'}
                        </TableCell>
                      )}
                      <TableCell>{makeDateRelative(fleet.updatedAt)}</TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </div>
      {!isLoading && !modifiedFleetList.length && (
        <>
          <NoData />
          <Divider />
        </>
      )}
      <TablePagination
        rowsPerPageOptions={[50, 100, 250]}
        component="div"
        count={modifiedFleetList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
