import * as React from 'react';
import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  Paper,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import {
  IFleetHistoryModel,
  IFleetStateModel,
  ISparePartModel,
  VehicleStateType,
} from 'app/models';

import { makeStyles, Theme } from '@material-ui/core/styles';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  listItem: {
    padding: '14px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: 0,
    marginBottom: 0,
  },
}));

export interface IProps {
  items: IFleetHistoryModel[];
  localStates: IFleetStateModel[];
  spareParts: ISparePartModel[];
  isLoading: boolean;
}

enum EventType {
  VEHICLE_STATE_UPDATED = 'VEHICLE.STATE_UPDATED',
  VEHICLE_PICKED_UP = 'VEHICLE.PICKED_UP',
  VEHICLE_DROPPED = 'VEHICLE.DROPPED',
  REPAIR_GUIDE_STARTED = 'REPAIR_GUIDE.STARTED',
  REPAIR_GUIDE_COMPLETED = 'REPAIR_GUIDE.COMPLETED',
  SPARE_PART_CHANGED = 'SPARE_PART.CHANGED',
  CHECKLIST_STARTED = 'CHECKLIST.STARTED',
  CHECKLIST_COMPLETED = 'CHECKLIST.COMPLETED',
  CHECKLIST_ITEM_COMPLETED = 'CHECKLIST.ITEM.COMPLETED',
}

const SUPPORTED_EVENTS: string[] = [
  EventType.VEHICLE_STATE_UPDATED,
  // EventType.REPAIR_GUIDE_COMPLETED,
  EventType.CHECKLIST_COMPLETED,
  EventType.SPARE_PART_CHANGED,
];

export const FleetHistory: React.SFC<IProps> = ({ items, isLoading, localStates, spareParts }) => {
  const classes = useStyles();

  const validItems = items.filter((item) => {
    return SUPPORTED_EVENTS.indexOf(item.type) > -1;
  });

  const getItemDescription = (item: IFleetHistoryModel): string => {
    switch (item.type) {
      case EventType.VEHICLE_STATE_UPDATED:
        const state = localStates.find((s) => {
          return s.id === item.data.newState;
        });
        if (state) {
          let suffix = '';
          if (state.id === VehicleStateType.waitingForParts && item.data.sparePartId) {
            const part = spareParts.find((p) => p.id === item.data.sparePartId);
            if (part) {
              suffix = ` (${part.translations.en})`;
            }
          }
          return `Changed state to ${state.translations.en}${suffix}`;
        }
        break;
      case EventType.REPAIR_GUIDE_COMPLETED:
        return 'Performed a repair'; // TODO: add guide
      case EventType.CHECKLIST_COMPLETED:
        return 'Ordinary service';
      case EventType.SPARE_PART_CHANGED:
        const part = spareParts.find((p) => {
          return p.id === item.data.sparePartId;
        });
        if (part) {
          return `Replaced ${part.translations.en}`;
        }
        return 'Replaced part';
    }
    return item.type;
  };

  return (
    <Card style={{ marginTop: 24 }}>
      <CardHeader title={'History'} />
      <Divider />
      <CardContent className={classes.cardContent}>
        {isLoading ? (
          <div
            style={{
              minHeight: '200px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <CircularProgress disableShrink size={40} thickness={4} />
            <Typography>Loading...</Typography>
          </div>
        ) : validItems.length ? (
          validItems.map((item, index) => {
            return (
              <Paper className={classes.listItem} key={`item-${index}`}>
                <Typography>{getItemDescription(item)}</Typography>
                <Typography>
                  {moment(item.occurredOn)
                    .startOf('hour')
                    .fromNow()}
                </Typography>
              </Paper>
            );
          })
        ) : (
          <div
            style={{
              textAlign: 'center',
              padding: 24,
              color: '#546e7a',
            }}
          >
            <h3>No history found</h3>
          </div>
        )}
      </CardContent>
    </Card>
  );
};
