import { ISupportedRoutes } from 'app/models';
import ExtensionOutlinedIcon from '@material-ui/icons/ExtensionOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

export default [
  {
    title: 'Settings',
    pages: [
      {
        title: 'Integration',
        href: ISupportedRoutes.INTEGRATION_SETTINGS,
        icon: ExtensionOutlinedIcon,
      },
      {
        title: 'Auto-locking',
        href: ISupportedRoutes.AUTO_LOCKING_SETTINGS,
        icon: LockOutlinedIcon,
      },
    ],
  },
];
