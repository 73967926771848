import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import { IRootState } from 'app/reducers/AppState';
import {
  ISystemErrorModel,
  IResponseHandlerModel,
  IMethods,
  IAddSystemErrorModel,
} from 'app/models';
import { ApiHelper } from 'app/helpers/api-helper';

export enum ISystemErrorsType {
  REQUEST_SYSTEM_ERRORS_LIST = 'REQUEST_SYSTEM_ERRORS_LIST',
  RECEIVE_SYSTEM_ERRORS_LIST = 'RECEIVE_SYSTEM_ERRORS_LIST',
  FAILURE_SYSTEM_ERRORS_LIST = 'FAILURE_SYSTEM_ERRORS_LIST',

  REQUEST_ADD_SYSTEM_ERROR = 'REQUEST_ADD_SYSTEM_ERROR',
  RECEIVE_ADD_SYSTEM_ERROR = 'RECEIVE_ADD_SYSTEM_ERROR',
  FAILURE_ADD_SYSTEM_ERROR = 'FAILURE_ADD_SYSTEM_ERROR',

  REQUEST_UPDATE_SYSTEM_ERROR = 'REQUEST_UPDATE_SYSTEM_ERROR',
  RECEIVE_UPDATE_SYSTEM_ERROR = 'RECEIVE_UPDATE_SYSTEM_ERROR',
  FAILURE_UPDATE_SYSTEM_ERROR = 'FAILURE_UPDATE_SYSTEM_ERROR',

  REQUEST_DELETE_SYSTEM_ERROR = 'REQUEST_DELETE_SYSTEM_ERROR',
  RECEIVE_DELETE_SYSTEM_ERROR = 'RECEIVE_DELETE_SYSTEM_ERROR',
  FAILURE_DELETE_SYSTEM_ERROR = 'FAILURE_DELETE_SYSTEM_ERROR',

  OPEN_SYSTEM_ERROR_MODAL = 'OPEN_SYSTEM_ERROR_MODAL',
  CLOSE_SYSTEM_ERROR_MODAL = 'CLOSE_SYSTEM_ERROR_MODAL',
}

// tslint:disable:typedef

export const requestSystemErrorsList = createAction(ISystemErrorsType.REQUEST_SYSTEM_ERRORS_LIST);
export const receiveSystemErrorsList = createAction<ISystemErrorModel[]>(
  ISystemErrorsType.RECEIVE_SYSTEM_ERRORS_LIST,
);
export const failureSystemErrorsList = createAction(ISystemErrorsType.FAILURE_SYSTEM_ERRORS_LIST);

export const requestAddSystemError = createAction(ISystemErrorsType.REQUEST_ADD_SYSTEM_ERROR);
export const receiveAddSystemError = createAction(ISystemErrorsType.RECEIVE_ADD_SYSTEM_ERROR);
export const failureAddSystemError = createAction(ISystemErrorsType.FAILURE_ADD_SYSTEM_ERROR);

export const requestUpdateSystemError = createAction(ISystemErrorsType.REQUEST_UPDATE_SYSTEM_ERROR);
export const receiveUpdateSystemError = createAction(ISystemErrorsType.RECEIVE_UPDATE_SYSTEM_ERROR);
export const failureUpdateSystemError = createAction(ISystemErrorsType.FAILURE_UPDATE_SYSTEM_ERROR);

export const requestDeleteSystemError = createAction(ISystemErrorsType.REQUEST_DELETE_SYSTEM_ERROR);
export const receiveDeleteSystemError = createAction(ISystemErrorsType.RECEIVE_DELETE_SYSTEM_ERROR);
export const failureDeleteSystemError = createAction(ISystemErrorsType.FAILURE_DELETE_SYSTEM_ERROR);

export const openSystemErrorModal = createAction<ISystemErrorModel>(
  ISystemErrorsType.OPEN_SYSTEM_ERROR_MODAL,
);
export const closeSystemErrorModal = createAction(ISystemErrorsType.CLOSE_SYSTEM_ERROR_MODAL);

export const fetchSystemErrorsList = () => {
  return async (dispatch: Dispatch) => {
    dispatch(requestSystemErrorsList());

    try {
      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        '/system-errors',
        IMethods.GET,
        true,
      );
      if (response.isError) {
        dispatch(failureSystemErrorsList());
        return;
      }
      const systemErrorsList = response.data.data as ISystemErrorModel[];
      dispatch(receiveSystemErrorsList(systemErrorsList));
    } catch (e) {
      dispatch(failureSystemErrorsList());
    }
  };
};

export const addNewSystemError = (systemError: IAddSystemErrorModel) => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    dispatch(requestAddSystemError());
    try {
      await new ApiHelper().FetchFromPortal(
        '/system-errors',
        IMethods.POST,
        true,
        undefined,
        systemError,
      );
      dispatch(receiveAddSystemError());
      fetchSystemErrorsList()(dispatch);
    } catch (e) {
      dispatch(failureAddSystemError());
    }
  };
};

/**
 * Triggers the edit state updates
 * @param employee Employee to edit
 */
export const openEditSystemErrorModal = (systemError: ISystemErrorModel) => {
  return (dispatch: Dispatch) => {
    dispatch(openSystemErrorModal(systemError));
  };
};

export const closeEditSystemErrorModal = () => {
  return (dispatch: Dispatch) => {
    dispatch(closeSystemErrorModal());
  };
};

export const updateSystemError = (systemError: IAddSystemErrorModel) => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    const {
      systemErrors: { selectedSystemError },
    } = getState();
    if (selectedSystemError) {
      dispatch(requestUpdateSystemError());

      try {
        const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
          '/system-errors/' + selectedSystemError.id,
          IMethods.PUT,
          true,
          undefined,
          systemError,
        );

        if (response.isError) {
          dispatch(failureUpdateSystemError());
          return;
        }
        dispatch(receiveUpdateSystemError());
        fetchSystemErrorsList()(dispatch);
      } catch (e) {
        dispatch(failureUpdateSystemError());
      }
    }
  };
};

export const deleteSystemError = () => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    const { selectedSystemError } = getState().systemErrors;
    if (selectedSystemError) {
      dispatch(requestDeleteSystemError());
      try {
        const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
          '/system-errors/' + selectedSystemError.id,
          IMethods.DELETE,
          true,
        );

        if (response.isError) {
          dispatch(failureDeleteSystemError());
          return;
        }

        dispatch(receiveDeleteSystemError());
        fetchSystemErrorsList()(dispatch);
      } catch (e) {
        dispatch(failureDeleteSystemError());
      }
    }
  };
};
