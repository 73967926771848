import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import { makeStyles } from '@material-ui/core/styles';
import { ModalHeader } from '../ModalHeader/ModalHeader';

const useStyles = makeStyles(() => ({
  confirmFooter: {
    paddingTop: 5,
    paddingBottom: 5,
    justifyContent: 'flex-end',
  },
}));

export interface IProps {
  openModal: boolean;
  modalTitle?: string;
  modalBody?: string | JSX.Element;
  handleClose: () => void;
  handleContinue: () => void;
  isLoading?: boolean;
}

export const ConfirmationModal: React.SFC<IProps> = ({
  openModal,
  modalTitle,
  modalBody,
  handleClose,
  handleContinue,
  isLoading,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={openModal}
      onClose={!isLoading ? handleClose : () => null}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={false}
    >
      <ModalHeader id="form-dialog-title" title={modalTitle ? modalTitle : 'Are you sure?'} />
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {modalBody ? modalBody : 'Do you to do this?'}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.confirmFooter}>
        <Button
          disabled={isLoading}
          onClick={isLoading ? () => null : handleClose}
          color="primary"
          autoFocus
        >
          No
        </Button>
        <Button
          onClick={handleContinue}
          color="primary"
          disabled={isLoading}
          style={{ color: 'rgb(220, 0, 78)' }}
        >
          {isLoading ? 'Deleting...' : 'Yes'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
