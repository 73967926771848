import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { TextField } from '@material-ui/core';
import { ModalHeader } from '../ModalHeader/ModalHeader';
import { ModalFooter } from '../ModalFooter/ModalFooter';

export interface IProps {
  openModal: boolean;
  title: string;
  value: number;
  handleChangeValue: (value: number) => void;
  handleClose: () => void;
  handleContinue: () => void;
  isLoading?: boolean;
}

export const PartStockModal: React.SFC<IProps> = ({
  openModal,
  title,
  handleClose,
  handleContinue,
  isLoading,
  value,
  handleChangeValue,
}) => {
  return (
    <Dialog
      open={openModal}
      onClose={!isLoading ? handleClose : () => null}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <ModalHeader id="form-dialog-title" title={title} />
      <DialogContent>
        <TextField
          margin="normal"
          id="amount"
          label="Amount"
          type="number"
          fullWidth
          variant="outlined"
          value={value}
          onChange={(e) => handleChangeValue(Number(e.target.value))}
        />
      </DialogContent>
      <ModalFooter
        isLoading={isLoading}
        onContinueClick={handleContinue}
        onCancelClick={handleClose}
      />
    </Dialog>
  );
};
