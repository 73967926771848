import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    background: '#FFFFFF',
    borderRadius: 4,
    boxShadow: '0px 1px 3px rgba(63, 63, 68, 0.15), 0px 0px 0px rgba(63, 63, 68, 0.05)',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  listItem: {
    boxShadow: '0px 1px 0px #EEEEEE',
    padding: 10,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#f4f7f9',
    },
  },

  fabIcon: {
    height: 45,
    width: 45,
    marginRight: 5,
    backgroundColor: '#ECEFF1 !important',
    color: '#546E7A !important',
  },
}));
