import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import { push } from 'react-router-redux';
import { ApiHelper } from 'app/helpers/api-helper';
import {
  ILoginCredentials,
  IResponseHandlerModel,
  IMethods,
  ILoginResponse,
  ISupportedRoutes,
} from 'app/models';
import { fetchUserDetails } from './UserDetailsActions';

export enum ILoginType {
  REQUEST_USER_LOGIN = 'REQUEST_USER_LOGIN',
  RECEIVE_USER_LOGIN = 'RECEIVE_USER_LOGIN',
  FAILURE_USER_LOGIN = 'FAILURE_USER_LOGIN',
}

// tslint:disable:typedef

export const requestUserLogin = createAction(ILoginType.REQUEST_USER_LOGIN);
export const receiveUserLogin = createAction(ILoginType.RECEIVE_USER_LOGIN);
export const failureUserLogin = createAction(ILoginType.FAILURE_USER_LOGIN);

export const userLogin = (loginDetails: ILoginCredentials) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestUserLogin());
    try {
      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        '/login',
        IMethods.POST,
        false,
        undefined,
        loginDetails,
      );
      if (response.isError) {
        dispatch(failureUserLogin());
        return;
      }
      const loginResponse = response.data as ILoginResponse;
      localStorage.setItem('jwtToken', loginResponse.jwtToken);
      await fetchUserDetails()(dispatch);
      dispatch(push(ISupportedRoutes.DASHBOARD));
      dispatch(receiveUserLogin());
    } catch (e) {
      dispatch(failureUserLogin('An unexpected error occured. Try Again'));
    }
  };
};

export const logout = () => {
  localStorage.removeItem('jwtToken');
  // TODO: remove token from axio
  return {
    type: 'LOGOUT',
  };
};
