import palette from '../palette';

export default {
  root: {},
  colorPrimary: {
    '&$checked': {
      color: palette.primary.main,
    },
  },
};
