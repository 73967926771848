import * as React from 'react';
import { Paper, Toolbar, Typography, Grid, TextField, Button } from '@material-ui/core';

import { useStyles } from './AccountFormStyles';
import { ContextualError } from 'app/components/common/Errors/ContextualError';

interface IProps {
  apiKey: string;
  apiUrl: string;
  canEdit: boolean;
  onEdit: (apiKey: string, apiUrl: string) => void;
  canSave: boolean;
  onSave: () => void;
  error?: string;
}

export const AccountForm: React.SFC<IProps> = ({
  apiKey,
  apiUrl,
  canEdit,
  onEdit,
  canSave,
  onSave,
  error,
}) => {
  const classes = useStyles();

  const didEditUrl = (e: any) => {
    onEdit(apiKey, e.target.value);
  };

  const didEditKey = (e: any) => {
    onEdit(e.target.value, apiUrl);
  };

  return (
    <Paper style={{ marginTop: 24 }}>
      <Toolbar className={classes.toolBar}>
        <Typography variant="h4" noWrap>
          Account
        </Typography>
        <Button variant="contained" color="primary" disabled={!canSave} onClick={onSave}>
          Save
        </Button>
      </Toolbar>
      <form className={classes.container} autoComplete="off">
        <Grid container spacing={1}>
          <Grid container item xs={12}>
            <TextField
              key={'apiUrl'}
              id="outlined-required-url"
              label="API Url"
              margin="normal"
              variant="outlined"
              className={classes.textField}
              value={apiUrl}
              onChange={didEditUrl}
              disabled={!canEdit}
            />
          </Grid>
          <Grid container item xs={12}>
            <TextField
              key={'apiKey'}
              id="outlined-required-key"
              label="API Key"
              margin="normal"
              variant="outlined"
              className={classes.textField}
              value={apiKey}
              onChange={didEditKey}
              disabled={!canEdit}
            />
          </Grid>
          {error && <ContextualError message={error} />}
        </Grid>
      </form>
    </Paper>
  );
};
