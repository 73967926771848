import * as React from 'react';

import {
  Divider,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Toolbar,
  Chip,
  TextField,
  Grid,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { ISparePartModel, IVehicleTypeListModel, IAddRepairGuideModel } from 'app/models';
import { useStyles } from './MetaDataFormStyles';

export interface IProps {
  vehicleTypesList: IVehicleTypeListModel[];
  repairGuide: IAddRepairGuideModel;
  isError: boolean;
  handleChange: (repairGuide: IAddRepairGuideModel) => void;
  sparePartsOptions: ISparePartModel[];
  handleSetOptions: (id: string) => void;
  isDisplayErrorCode: string | null;
}

export const MetaDataForm: React.SFC<IProps> = ({
  vehicleTypesList,
  repairGuide,
  repairGuide: { vehicleTypeId, displayErrorCodes, videoUrl, frequent },
  sparePartsOptions,
  handleSetOptions,
  handleChange,
  isError,
  isDisplayErrorCode,
}) => {
  const classes = useStyles();
  let selectedRelatedParts: ISparePartModel[] = [];
  repairGuide.relatedPartsIds.forEach((r: string) => {
    sparePartsOptions.forEach((s: ISparePartModel) => {
      if (s.id === r) {
        selectedRelatedParts.push(s);
      }
    });
  });

  return (
    <Paper style={{ marginTop: 24 }}>
      <Toolbar>
        <Typography variant="h4" noWrap>
          Metadata
        </Typography>
      </Toolbar>
      <Divider />
      <form className={classes.container} autoComplete="off" onSubmit={() => console.log('okkk')}>
        <Grid container spacing={1}>
          <Grid container item xs={12} md={4}>
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel
                error={!vehicleTypeId && isError}
                htmlFor="outlined-age-native-simple"
                className={classes.label}
              >
                Vehicle Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                onChange={(e) => {
                  let vehicleType = [...vehicleTypesList].find((v) => v.id === e.target.value);
                  if (vehicleType) {
                    handleChange({ ...repairGuide, vehicleTypeId: vehicleType.id });
                    handleSetOptions(vehicleType.id);
                  }
                }}
                value={vehicleTypeId}
                defaultValue={vehicleTypeId}
                error={!vehicleTypeId && isError}
                inputProps={{
                  name: 'vehicelType',
                  id: 'outlined-age-native-simple',
                }}
                className={classes.select}
              >
                {[...vehicleTypesList].map((v: any, index: number) => (
                  <MenuItem key={index} value={v.id}>
                    {v.name}
                  </MenuItem>
                ))}
              </Select>
              {!vehicleTypeId && isError && (
                <FormHelperText style={{ color: '#e53935' }}>
                  Please select Vehicle Type
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid container item xs={12} md={8}>
            <Autocomplete
              className={classes.autoComplete}
              multiple
              id="related-display-codes"
              getOptionLabel={(option) => option}
              value={displayErrorCodes}
              freeSolo
              onChange={(obj, value) => {
                handleChange({ ...repairGuide, displayErrorCodes: value });
              }}
              renderTags={(value, getTagProps) => {
                return value.map((option: any, index: number) => {
                  if (!option) return null;
                  return (
                    <Chip label={option} {...getTagProps({ index })} className={classes.chip} />
                  );
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!isDisplayErrorCode}
                  name="relatedDisplayError"
                  variant="outlined"
                  label="Related Display Errors"
                  margin="normal"
                  helperText={
                    isDisplayErrorCode ? 'Atleast one Display Error is required!' : undefined
                  }
                />
              )}
            />
          </Grid>
          <Grid container item xs={12} md={12}>
            <Autocomplete
              className={classes.autoComplete}
              multiple
              id="related-parts"
              options={[...sparePartsOptions]}
              getOptionLabel={(option) => option.translations['en']}
              value={selectedRelatedParts}
              filterSelectedOptions
              onChange={(obj, value) => {
                const relatedPartsIds = value.map((i: ISparePartModel) => i.id);
                handleChange({ ...repairGuide, relatedPartsIds });
              }}
              renderTags={(value, getTagProps) => {
                return value.map((option: any, index: number) => {
                  if (!option) return null;
                  return (
                    <Chip
                      label={option.translations['en']}
                      {...getTagProps({ index })}
                      className={classes.chip}
                    />
                  );
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="relatedParts"
                  variant="outlined"
                  label="Related parts"
                  margin="normal"
                />
              )}
            />
          </Grid>
          <Grid container item>
            <TextField
              key={'videoUrl'}
              id="video-required"
              label="Video Url"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              value={videoUrl}
              onChange={(e) => handleChange({ ...repairGuide, videoUrl: e.target.value })}
            />
          </Grid>
          <Grid container item>
            <FormControlLabel
              key="frequent"
              label="Frequently accessed"
              labelPlacement="end"
              control={
                <Checkbox
                  checked={frequent}
                  onChange={(e, frequent) => handleChange({ ...repairGuide, frequent })}
                />
              }
            />
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};
