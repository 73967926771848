import * as React from 'react';
import clsx from 'clsx';

import { Button, Menu, MenuItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { Check, FilterList } from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';

export interface FilterMenuItem {
  label: string;
  value: string | number;
}

export interface ITableFilter {
  selectedOption: FilterMenuItem;
  menuTitle: string;
  onSelect: (value: FilterMenuItem) => any;
  items: FilterMenuItem[];
}

interface IProps {
  tableFilter: ITableFilter[];
  disabled?: boolean;
  width?: number;
  className?: string;
}

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: any) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(() => ({
  root: {
    position: 'relative',
    paddingLeft: 40,
    paddingRight: 30,
  },
}))(MenuItem);

export const ServeedoTableFilter: React.SFC<IProps> = ({
  tableFilter,
  disabled,
  className,
  width,
}) => {
  const selected = tableFilter.some((t) => t.selectedOption.value !== 'all');
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={clsx('select-btn', className)}>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        onClick={handleClick}
        color={selected ? 'primary' : 'default'}
        disabled={disabled}
        startIcon={<FilterList />}
      >
        Filter
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ width: width ? width : 400 }}
      >
        {tableFilter.map((menu: ITableFilter, index) => {
          return (
            <div key={index}>
              <Typography style={{ padding: '5px 10px', fontWeight: 500, color: '#607D8B' }}>
                {menu.menuTitle}
              </Typography>
              {menu.items.map((item: FilterMenuItem, index: number) => {
                return (
                  <StyledMenuItem
                    key={index}
                    onClick={() => {
                      if (menu.onSelect) {
                        menu.onSelect(item);
                        handleClose();
                      }
                    }}
                  >
                    {menu.selectedOption.value === item.value && (
                      <ListItemIcon style={{ position: 'absolute', left: 10 }}>
                        <Check fontSize="small" />
                      </ListItemIcon>
                    )}
                    <ListItemText primary={item.label} />
                  </StyledMenuItem>
                );
              })}
            </div>
          );
        })}
      </StyledMenu>
    </div>
  );
};
