import { handleActions, Reducer } from 'redux-actions';
import { IRootState } from 'app/reducers/AppState';
import { IRepairGuidesActionsType } from 'app/actions';
import { IRepairGuidesModel, IRepairGuideModel } from 'app/models';

const initialState: IRepairGuidesModel = {
  repaireGuidesList: [],
  selectedRepairGuide: null,
  errorMessage: null,
  isLoading: false,
  isAdding: false,
  isEditing: false,
  isDeleting: false,
  removingGuidesIds: [],
};

// tslint:disable-next-line:typedef
export const repairGuidesReducer: Reducer<
  IRootState.RepairGuidesState,
  IRepairGuidesModel
> = handleActions<
  IRootState.RepairGuidesState,
  IRepairGuidesModel | IRepairGuideModel[] | string | IRepairGuideModel
>(
  {
    [IRepairGuidesActionsType.REQUEST_REPAIR_GUIDES_LIST]: (
      state = initialState,
    ): IRootState.RepairGuidesState => ({
      ...state,
      isLoading: true,
      isEditing: false,
      selectedRepairGuide: null,
    }),
    [IRepairGuidesActionsType.RECEIVE_REPAIR_GUIDES_LIST]: (
      state = initialState,
      actions,
    ): IRootState.RepairGuidesState => ({
      ...state,
      isLoading: false,
      repaireGuidesList: actions.payload as IRepairGuideModel[],
    }),
    [IRepairGuidesActionsType.FAILURE_REPAIR_GUIDES_LIST]: (
      state = initialState,
    ): IRootState.RepairGuidesState => ({
      ...state,
      isLoading: false,
    }),

    [IRepairGuidesActionsType.REQUEST_ADD_REPAIR_GUIDE]: (
      state = initialState,
    ): IRootState.RepairGuidesState => ({
      ...state,
      isAdding: true,
      errorMessage: null,
    }),
    [IRepairGuidesActionsType.RECEIVE_ADD_REPAIR_GUIDE]: (
      state = initialState,
      action,
    ): IRootState.RepairGuidesState => ({
      ...state,
      isAdding: false,
    }),
    [IRepairGuidesActionsType.FAILURE_ADD_REPAIR_GUIDE]: (
      state = initialState,
      action,
    ): IRootState.RepairGuidesState => ({
      ...state,
      isAdding: false,
      errorMessage: action.payload as string,
    }),

    [IRepairGuidesActionsType.HANDLE_EDIT_REPAIR_GUIDE]: (
      state = initialState,
      action,
    ): IRootState.RepairGuidesState => ({
      ...state,
      isEditing: true,
      selectedRepairGuide: action.payload as IRepairGuideModel,
    }),

    [IRepairGuidesActionsType.REQUEST_FETCH_REPAIR_GUIDE]: (
      state = initialState,
    ): IRootState.RepairGuidesState => ({
      ...state,
      isLoading: true,
    }),
    [IRepairGuidesActionsType.RECEIVE_FETCH_REPAIR_GUIDE]: (
      state = initialState,
      action,
    ): IRootState.RepairGuidesState => ({
      ...state,
      isLoading: false,
      selectedRepairGuide: action.payload as IRepairGuideModel,
      isEditing: true,
    }),
    [IRepairGuidesActionsType.FAILURE_FETCH_REPAIR_GUIDE]: (
      state = initialState,
    ): IRootState.RepairGuidesState => ({
      ...state,
      isLoading: false,
    }),

    [IRepairGuidesActionsType.REQUEST_DELETE_REPAIR_GUIDE]: (
      state = initialState,
    ): IRootState.RepairGuidesState => ({
      ...state,
      isDeleting: true,
    }),
    [IRepairGuidesActionsType.RECEIVE_DELETE_REPAIR_GUIDE]: (
      state = initialState,
    ): IRootState.RepairGuidesState => ({
      ...state,
      isDeleting: false,
    }),
    [IRepairGuidesActionsType.FAILURE_DELETE_REPAIR_GUIDE]: (
      state = initialState,
    ): IRootState.RepairGuidesState => ({
      ...state,
      isDeleting: false,
    }),

    [IRepairGuidesActionsType.REQUEST_DELETE_REPAIR_GUIDES]: (
      state = initialState,
      action,
    ): IRootState.RepairGuidesState => ({
      ...state,
      removingGuidesIds: (action.payload as IRepairGuidesModel).removingGuidesIds,
    }),
    [IRepairGuidesActionsType.RECEIVE_DELETE_REPAIR_GUIDES]: (
      state = initialState,
    ): IRootState.RepairGuidesState => ({
      ...state,
      repaireGuidesList: state.repaireGuidesList.filter((guide) => {
        return state.removingGuidesIds.indexOf(guide.id) === -1;
      }),
      removingGuidesIds: [],
    }),
    [IRepairGuidesActionsType.FAILURE_DELETE_REPAIR_GUIDES]: (
      state = initialState,
    ): IRootState.RepairGuidesState => ({
      ...state,
      removingGuidesIds: [],
    }),
  },
  initialState,
);
