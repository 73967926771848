import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  title: {
    padding: '25px 0',
    textAlign: 'center',
  },
  titleText: {
    fontSize: 18,
    fontWeight: 600,
  },
  modalBody: {
    minWidth: 600,
  },
  formRow: {
    display: 'flex',
    width: '100%',
  },
  formControl: {
    flex: 5,
    marginTop: 16,
    marginBottom: 8,
    marginRight: theme.spacing(1),
  },
  textField: {
    flex: 2,
  },
  select: {
    '& .MuiSelect-select:focus': {
      backgroundColor: '#fff !important',
    },
    '& .label': {
      color: '#fff !important',
    },
  },
  label: {
    backgroundColor: '#fff !important',
    padding: '0 2px',
  },
  modalFooter: {
    display: 'flex',
    backgroundColor: '#FAFAFA',
    padding: '20px 12px',
    justifyContent: 'flex-end',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dltButton: {
    color: 'rgb(220, 0, 78)',
  },
}));
