import * as React from 'react';
import clsx from 'clsx';
import { Map, GoogleApiWrapper, MapProps, Marker } from 'google-maps-react';
import { Tooltip } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';

import { MapBox } from 'app/constants/mapBox';
import { ILocation } from 'app/models';
import { MapLoading } from './MapLoading';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  expandIcon: {
    position: 'absolute',
    zIndex: 1,
    right: 5,
    top: 5,
    color: '#fff',
  },
}));

export interface IProps extends MapProps {
  location: ILocation;
  isExpandable?: boolean;
  changeLocation?: (location: ILocation) => void;
  isMarkerDraggable?: boolean;
}

const GoogleMap: React.SFC<IProps> = (props) => {
  const { location } = props;
  const classes = useStyles();

  const onHandleClick = () => {
    window.open(`https://maps.google.com?q=${location.lat},${location.long}`);
  };

  const handleChangeLocation = (coord: any) => {
    const { latLng } = coord;
    const lat = latLng.lat().toFixed(6) as number;
    const long = latLng.lng().toFixed(6) as number;
    if (props.changeLocation) {
      props.changeLocation({ lat, long });
    }
  };

  return (
    <div className={clsx('google-maps', classes.root)}>
      {props.isExpandable && (
        <Tooltip title="Open Map in new tab" aria-label="add" placement="top">
          <Fab
            color="primary"
            aria-label="add"
            size="medium"
            className={classes.expandIcon}
            onClick={onHandleClick}
          >
            <OpenInNewOutlinedIcon />
          </Fab>
        </Tooltip>
      )}
      {location.lat !== 0 && location.long !== 0 && (
        <Map
          google={props.google}
          zoom={MapBox.ZOOM}
          initialCenter={{ ...location, lng: location.long }}
          onClick={(t, map, coord) => handleChangeLocation(coord)}
          mapTypeControl={false}
          streetViewControl={false}
        >
          <Marker
            onDragend={(t, map, coord) => handleChangeLocation(coord)}
            draggable={props.isMarkerDraggable}
            position={{ ...props.location, lng: location.long }}
          />
        </Map>
      )}
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: MapBox.API_KEY,
  LoadingContainer: MapLoading,
})(GoogleMap);
