import { connect } from 'react-redux';
import { IRootState } from 'app/reducers/AppState';
import { Dispatch, bindActionCreators } from 'redux';
import { FleetView } from 'app/components/FleetView/FleetView';
import { IFleetModel, IVehicleTypeListModel, IRegion, VehicleStateType } from 'app/models';
import {
  fetchFleetDetails,
  fetchVehiclesTypesList,
  changeFleetState,
  getFleetStates,
  fetchFleetSpareparts,
} from 'app/actions';

interface StateFromProps {
  fleet: IFleetModel;
  vehicleTypesList: IVehicleTypeListModel[];
  selectedRegion: IRegion | null;
}

interface DispatchFromProps {
  fetchFleetDetails: (id: string) => void;
  fetchVehiclesList: () => void;
  changeFleetState: (state: VehicleStateType) => void;
  getFleetStates: () => void;
  fetchFleetSpareparts: () => void;
}

const mapStateToProps = (state: IRootState): StateFromProps => ({
  fleet: state.fleet,
  vehicleTypesList: state.vehicleTypes.vehicleTypesList,
  selectedRegion: state.userDetails.selectedRegion,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => ({
  fetchFleetDetails: bindActionCreators(fetchFleetDetails, dispatch),
  fetchVehiclesList: bindActionCreators(fetchVehiclesTypesList, dispatch),
  changeFleetState: bindActionCreators(changeFleetState, dispatch),
  getFleetStates: bindActionCreators(getFleetStates, dispatch),
  fetchFleetSpareparts: bindActionCreators(fetchFleetSpareparts, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FleetView);
