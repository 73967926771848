import palette from '../palette';

export default {
  root: {
    color: 'rgba(0, 0, 0, 0.54)',
    '&$focused': {
      color: palette.primary.main,
    },
  },
};
