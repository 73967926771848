import { handleActions, Reducer } from 'redux-actions';
import { IRootState } from 'app/reducers/AppState';
import { IOnroadTaskType } from 'app/actions';
import {
  IOnroadTasksModel,
  IOnroadTaskModel,
  IOnroadTaskStatusModel,
  IOnroadTasksFilter,
  IOnroadTasksListModel,
} from 'app/models';

const initialState: IOnroadTasksModel = {
  onRoadTasksList: {
    onRoadTasksList: [],
    pagination: null,
  },
  isLoading: false,
  onRoadTaskStatus: {
    deployableVehicles: [],
    batteriesToReplace: [],
    availablePlaces: [],
  },
  isUpdatingTasks: false,
  onRoadTasksFilter: {
    perPage: 50,
    page: 0,
    type: {
      label: 'All',
      value: 'all',
    },
    status: {
      label: 'All',
      value: 'all',
    },
  },
};

// tslint:disable-next-line:typedef
export const onRoadTasksReducer: Reducer<
  IRootState.OnRoadTasksState,
  IOnroadTasksModel
> = handleActions<
  IRootState.OnRoadTasksState,
  | IOnroadTasksModel
  | IOnroadTaskModel[]
  | IOnroadTaskStatusModel
  | IOnroadTasksFilter
  | IOnroadTasksListModel
>(
  {
    [IOnroadTaskType.REQUEST_ONROAD_TASKS]: (
      state = initialState,
    ): IRootState.OnRoadTasksState => ({
      ...state,
      isLoading: true,
    }),
    [IOnroadTaskType.RECEIVE_ONROAD_TASKS]: (
      state = initialState,
      action,
    ): IRootState.OnRoadTasksState => ({
      ...state,
      isLoading: false,
      onRoadTasksList: action.payload as IOnroadTasksListModel,
    }),
    [IOnroadTaskType.FAILURE_ONROAD_TASKS]: (
      state = initialState,
    ): IRootState.OnRoadTasksState => ({
      ...state,
      isLoading: false,
    }),

    [IOnroadTaskType.REQUEST_FLEET_STATUS]: (
      state = initialState,
    ): IRootState.OnRoadTasksState => ({
      ...state,
    }),
    [IOnroadTaskType.RECEIVE_FLEET_STATUS]: (
      state = initialState,
      action,
    ): IRootState.OnRoadTasksState => ({
      ...state,
      onRoadTaskStatus: action.payload as IOnroadTaskStatusModel,
    }),
    [IOnroadTaskType.FAILURE_FLEET_STATUS]: (
      state = initialState,
    ): IRootState.OnRoadTasksState => ({
      ...state,
    }),

    [IOnroadTaskType.REQUEST_UPDATE_TASKS]: (
      state = initialState,
    ): IRootState.OnRoadTasksState => ({
      ...state,
      isUpdatingTasks: true,
    }),
    [IOnroadTaskType.RECEIVE_UPDATE_TASKS]: (
      state = initialState,
      action,
    ): IRootState.OnRoadTasksState => ({
      ...state,
      isUpdatingTasks: false,
    }),
    [IOnroadTaskType.FAILURE_FLEET_STATUS]: (
      state = initialState,
    ): IRootState.OnRoadTasksState => ({
      ...state,
      isUpdatingTasks: false,
    }),
    [IOnroadTaskType.UPDATE_ONROAD_TASKS_FILTER]: (
      state = initialState,
      action,
    ): IRootState.OnRoadTasksState => ({
      ...state,
      onRoadTasksFilter: action.payload as IOnroadTasksFilter,
    }),
  },
  initialState,
);
