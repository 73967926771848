export default {
  popper: {
    maxHeight: 0,
  },
  paper: {
    '& > ul': {
      maxHeight: '18vh',
      overflow: 'auto',
    },
  },
};
