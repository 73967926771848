import * as React from 'react';

import { Card, CardHeader, Divider, CardContent, Typography } from '@material-ui/core';

export interface IProps {
  stateDescription: any | undefined;
}

/*
    "photo": "https://serveedo-assets-eu-central-1-dev.s3.eu-central-1.amazonaws.com/photos/hive-staging/13461646-284f-42d7-9a58-998654f5049e/c77823c649ecedadb79a0b7b16e44c78.jpeg",
    "message": "burned to hell"
*/

export const DamagedBeyondRepairProof: React.SFC<IProps> = (props) => {
  if (!props.stateDescription || !props.stateDescription.photo) {
    return null;
  }

  const photoUrl = props.stateDescription.photo as string;
  const message = props.stateDescription.message as string;

  return (
    <Card style={{ marginTop: 24 }}>
      <CardHeader title={'Damage Proof'} />
      <Divider />
      <CardContent>
        <img alt="Proof" src={photoUrl} style={{ width: '100%', display: 'block' }} />
        <Typography variant="body1" noWrap>
          Comment: {message || 'n/a'}
        </Typography>
      </CardContent>
    </Card>
  );
};
