import * as React from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Paper,
  Divider,
  Checkbox,
} from '@material-ui/core';

import {
  ServeedoTableHeader,
  ITableHeaderCells,
  ServeedoTableToolbar,
  NoData,
  ServeedoTableLoading,
  ITableFilter,
  FilterMenuItem,
} from 'app/components/common';
import { IVehicleTypeListModel, IHotspotModel, IRegion, ISupportedRoutes } from 'app/models';

import { getSorting, stableSort } from 'app/helpers/common';
import useRouter from 'app/helpers/use-router';
import { makeDateRelative } from 'app/helpers/time-helper';

const headerCells: ITableHeaderCells[] = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'current', numeric: false, disablePadding: false, label: 'Current level' },
  { id: 'priority', numeric: false, disablePadding: false, label: 'Current priority' },
  { id: 'incoming', numeric: false, disablePadding: false, label: 'Projected level' },
  { id: 'vehicleType', numeric: true, disablePadding: false, label: 'Vehicle Type' },
  { id: 'updatedAt', numeric: true, disablePadding: false, label: 'Last Update' },
];

export interface ITableListModel extends IHotspotModel {
  currently?: string | number;
  incoming?: string | number;
}

export interface IProps {
  hotspotsList: IHotspotModel[];
  vehicleTypesList: IVehicleTypeListModel[];
  isLoading: boolean;
  selectedRegion: IRegion | null;
  handleEditHotSpot: (hotspot: IHotspotModel) => void;
  isReadOnly: boolean;
  gotoHotspotView: (hotspot: IHotspotModel) => void;
}

export const HotspotsList: React.SFC<IProps> = ({
  hotspotsList,
  vehicleTypesList,
  isLoading,
  selectedRegion,
  handleEditHotSpot,
  isReadOnly,
  gotoHotspotView,
}) => {
  const router = useRouter();
  const [order, setOrder] = React.useState<any>('desc');
  const [orderBy, setOrderBy] = React.useState('updatedAt');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [selected, setSelected] = React.useState([]);
  const [selectedOption, setSelectedOption] = React.useState<FilterMenuItem>({
    label: 'All',
    value: 'all',
  });
  const [selectedStatus, setSelectedStatus] = React.useState<FilterMenuItem>({
    label: 'All',
    value: 'all',
  });

  const handleRequestSort = (event: any, property: string) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds: any = hotspotsList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const modifiedList: ITableListModel[] = [...hotspotsList]
    .map((hotspot) => ({
      ...hotspot,
      priority: hotspot.currentPriority || hotspot.priority,
      currently: hotspot.nearbyVehicles + ' / ' + hotspot.capacity,
      incoming:
        (hotspot.incomingVehicles || 0) +
        (hotspot.nearbyVehicles || 0) +
        (hotspot.waitingForAssignment || 0) +
        ' / ' +
        hotspot.capacity,
    }))
    .filter(
      (hotspot) => selectedOption.value === hotspot.vehicleTypeId || selectedOption.value === 'all',
    )
    .filter((hotspot) => hotspot.incomingVehicles || selectedStatus.value === 'all');

  let list = stableSort<ITableListModel>(modifiedList, getSorting(order, orderBy)).slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  const tableFilter: ITableFilter[] = [
    {
      menuTitle: 'Vehicle Types',
      items: [
        { label: 'All', value: 'all' },
        ...vehicleTypesList.map((v) => ({
          label: v.name,
          value: v.id,
        })),
      ],
      onSelect: (value) => setSelectedOption(value),
      selectedOption,
    },
    {
      menuTitle: 'Status',
      items: [
        { label: 'All', value: 'all' },
        { label: 'Has incoming vehicles', value: 'incomingVehicles' },
      ],
      onSelect: (value) => setSelectedStatus(value),
      selectedOption: selectedStatus,
    },
  ];

  return (
    <Paper>
      <ServeedoTableToolbar title={'All Hotspots'} tableFilter={tableFilter} />
      <div className="table-wrapper">
        <Table aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table">
          <ServeedoTableHeader
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={hotspotsList.length}
            headerCells={headerCells}
            selecting
          />
          <TableBody>
            {isLoading ? (
              <ServeedoTableLoading hasCheckbox numberOfColumns={5} />
            ) : (
              list.map((hotspot: ITableListModel, index: number) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    onClick={() => {
                      gotoHotspotView(hotspot);
                      router.history.push(
                        `${selectedRegion ? '/' + selectedRegion.key : ''}${
                          ISupportedRoutes.HOT_SPOTS
                        }/${hotspot.id}`,
                      );
                    }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        inputProps={{ 'aria-labelledby': labelId }}
                        onClick={(e) => e.stopPropagation()}
                        disabled={isReadOnly}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row" style={{ width: '30%' }}>
                      {hotspot.name}
                    </TableCell>
                    <TableCell>
                      {hotspot.currently}{' '}
                      <span style={{ opacity: 0.5 }}>({hotspot.nearbyRadius}m)</span>
                    </TableCell>
                    <TableCell>{hotspot.priority}</TableCell>
                    <TableCell>{hotspot.incoming}</TableCell>
                    <TableCell>
                      {[...vehicleTypesList].find((v) => v.id === hotspot.vehicleTypeId)
                        ? [...vehicleTypesList].find((v) => v.id === hotspot.vehicleTypeId)!.name
                        : 'Vehicle Type Deleted'}
                    </TableCell>
                    <TableCell>{makeDateRelative(hotspot.updatedAt)}</TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </div>
      {!isLoading && !modifiedList.length && (
        <>
          <NoData />
          <Divider />
        </>
      )}
      <TablePagination
        rowsPerPageOptions={[50, 100, 250]}
        component="div"
        count={modifiedList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
