import { Dispatch } from 'redux';
import { createAction } from 'redux-actions';
import { IRootState } from 'app/reducers/AppState';
import {
  IDashboardFilterModel,
  IResponseHandlerModel,
  IMethods,
  IVehicleTypeModel,
  ISparePartModel,
  IDashboardDataModel,
} from 'app/models';
import { ApiHelper } from 'app/helpers/api-helper';

export enum IDashboardActionsType {
  REQUEST_DASHBOARD_DATA = 'REQUEST_DASHBOARD_DATA',
  RECEIVE_DASHBOARD_DATA = 'RECEIVE_DASHBOARD_DATA',
  FAILURE_DASHBOARD_DATA = 'FAILURE_DASHBOARD_DATA',

  UPDATE_DASHBOARD_FILTER = 'UPDATE_DASHBOARD_FILTER',

  RECEIVE_DASHBOARD_VEHICLE_TYPES = 'RECEIVE_DASHBOARD_VEHICLE_TYPES',

  RECEIVE_DASHBOARD_SPARE_PARTS = 'RECEIVE_DASHBOARD_SPARE_PARTS',
}

// tslint:disable:typedef

export const requestDashboardData = createAction(IDashboardActionsType.REQUEST_DASHBOARD_DATA);
export const receiveDashboardData = createAction(IDashboardActionsType.RECEIVE_DASHBOARD_DATA);
export const failureDashboardData = createAction(IDashboardActionsType.FAILURE_DASHBOARD_DATA);

export const updateDashboardFilter = createAction<IDashboardFilterModel>(
  IDashboardActionsType.UPDATE_DASHBOARD_FILTER,
);

export const fetchDashboardVehicleTypes = () => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    try {
      const response = await new ApiHelper().fetchVehicleTypes();
      if (response.isError) {
        console.error(response.messages![0]);
        return;
      }
      const vehicleTypes = response.data.data as IVehicleTypeModel[];
      dispatch({
        type: IDashboardActionsType.RECEIVE_DASHBOARD_VEHICLE_TYPES,
        payload: {
          vehicleTypes,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
};

export const fetchDashboardSpareParts = () => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    try {
      const response = await new ApiHelper().fetchSpareParts();
      if (response.isError) {
        console.error(response.messages![0]);
        return;
      }
      const spareParts = response.data.data as ISparePartModel[];
      dispatch({
        type: IDashboardActionsType.RECEIVE_DASHBOARD_SPARE_PARTS,
        payload: {
          spareParts,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
};

export const fetchDashboardData = () => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    const {
      dashboard: {
        dashboardFilter: { period },
      },
      userDetails: { selectedRegion },
    } = getState();
    dispatch(requestDashboardData());
    try {
      let body: any = {
        interval: period,
      };
      if (selectedRegion && selectedRegion.id !== 'all') {
        body.regionId = selectedRegion.id;
      }
      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        '/statistics',
        IMethods.GET,
        true,
        body,
      );

      if (response.isError) {
        dispatch(failureDashboardData());
        return;
      }
      const data = response.data as IDashboardDataModel;
      dispatch(receiveDashboardData(data));
    } catch (e) {
      dispatch(failureDashboardData());
    }
  };
};

export const changeDashboardFilter = (filter: IDashboardFilterModel) => {
  return (dispatch: Dispatch) => {
    dispatch(updateDashboardFilter(filter));
  };
};
