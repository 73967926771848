import { IBaseModel, ILocation } from './common';
import { ITranslationModel } from './VehicleTypesModel';
import { ISparePartModel } from './SparePartsModel';

export interface IFleetModel {
  fleetList: IFleetListModel[];
  isLoading: boolean;
  fleetDetails: IFleetDetails | null;
  isAdding: boolean;
  fleetStates: IFleetStateModel[];
  spareParts: ISparePartModel[];
}

export interface IFleetStateModel {
  id: number;
  translations: ITranslationModel;
  locked: boolean;
}

export interface IFleetListModel extends IBaseModel {
  fuelLevel: number;
  location: ILocation;
  mileage: number;
  ownerId: string;
  regionId: string;
  remoteId: number;
  stateId: VehicleStateType;
  vehicleTypeId: string;
}

export enum VehicleStateType {
  unknown,
  settingUp,
  active,
  atRisk,
  fuelLevelLow,
  reserved,
  faulty,
  reposition,
  pickedUp,
  warehouse,
  maintenance,
  waitingForParts,
  readyToGo,
  deploy,
  lost,
  damagedBeyondRepair,
}

export interface IFleetDetails extends IBaseModel {
  ownerId: string;
  regionId: string;
  vehicleTypeId: string;
  remoteId: number;
  stateId: number;
  location: ILocation;
  mileage: number;
  fuelLevel: number;
  stateDescription?: any;
  retrievalAttempts: number;
  lastRetrievalAttempt?: Date;
  history: IFleetHistoryModel[];
}

export interface IFleetHistoryModel {
  type: string;
  employeeId: string;
  vehicleId: string;
  vehicleRemoteId: number;
  occurredOn: string;
  data: any;
}
