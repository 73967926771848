import * as React from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  MenuItem,
  ListItemIcon,
  IconButton,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuList,
  Chip,
} from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ICheckListItemModel, ITranslationModel, ILanguage, ISparePartModel } from 'app/models';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Autocomplete } from '@material-ui/lab';
import { languages } from 'app/constants/languages';
import { ModalFooter } from '../ModalFooter/ModalFooter';
import { ModalHeader } from '../ModalHeader/ModalHeader';

const newLanguages: ILanguage[] = [
  {
    label: 'English',
    value: 'en',
  },
  ...languages,
];

const useStyles = makeStyles((theme: Theme) => ({
  textFieldWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  deleteButton: {
    height: 40,
    width: 40,
    borderRadius: 100,
    minWidth: 'unset !important',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginTop: 7,
    marginLeft: 5,
  },
  autoComplete: {
    width: '100%',
  },
  chip: {
    height: 24,
    marginRight: 4,
  },
}));

export interface IProps {
  showModal: boolean;
  handleClose: () => void;
  addCheckListItem: (checkListItems: ICheckListItemModel[]) => void;
  checkListItems: ICheckListItemModel[];
  selectedCheckListItem: ICheckListItemModel | null;
  isEditing: boolean;
  sparePartsList: ISparePartModel[];
}

export const AddNewCheckListItemModal: React.SFC<IProps> = ({
  showModal,
  handleClose,
  checkListItems,
  addCheckListItem,
  selectedCheckListItem,
  isEditing,
  sparePartsList,
}) => {
  const classes = useStyles();
  const [name, setName] = React.useState('');
  const [validationError, setValidationError] = React.useState(false);
  const [translations, setTranslations] = React.useState<ITranslationModel>({ en: '' });
  const [relatedPartsIds, setRelatedPartsIds] = React.useState<string[]>([]);

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<any>(null);

  React.useEffect(() => {
    if (selectedCheckListItem && isEditing) {
      setName(selectedCheckListItem.key);
      setTranslations(selectedCheckListItem.translations);
      setRelatedPartsIds(
        selectedCheckListItem.relatedPartsIds ? selectedCheckListItem.relatedPartsIds : [],
      );
    }
  }, [selectedCheckListItem, isEditing]);

  const stringToSlug = (str: string) => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = 'åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
    var to = 'aaaaaaeeeeiiiioooouuuunc------';

    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

    return str;
  };

  const addNewCheckListItem = () => {
    if (!translations.en) {
      setValidationError(true);
      return;
    }
    let checkListItemsState: ICheckListItemModel[] = [...checkListItems];
    if (selectedCheckListItem && isEditing) {
      const index = checkListItemsState.findIndex((c) => c.id === selectedCheckListItem.id);
      checkListItemsState.splice(index, 1, {
        id: selectedCheckListItem.id,
        key: translations.en,
        translations,
        order: selectedCheckListItem.order,
        relatedPartsIds,
      });
    } else {
      checkListItemsState.push({
        id: new Date().getTime().toString(10),
        key: stringToSlug(translations.en),
        translations,
        order: checkListItemsState.length + 1,
        relatedPartsIds,
      });
    }
    addCheckListItem(checkListItemsState);
    closeModal();
  };

  const closeModal = () => {
    handleClose();
    setName('');
    setValidationError(false);
    setTranslations({ en: '' });
    setRelatedPartsIds([]);
  };

  const handleDelete = (id: string) => {
    let newTranslation = { ...translations };
    delete newTranslation[id];
    setTranslations(newTranslation);
  };

  const handleTranslationChange = (e: any, item: string) => {
    let newTranslation = { ...translations };
    newTranslation[item] = e.target.value;
    setTranslations(newTranslation);
  };

  const handleAddTransaltion = (value: string) => {
    if (Object.keys(translations).find((i: string) => i === value)) return;
    let translationsState = { ...translations, [value]: '' };
    setTranslations(translationsState);
    handleToggle();
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleSelectClose = (event: any) => {
    if (anchorRef && anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  let selectedRelatedParts: ISparePartModel[] = [];
  relatedPartsIds.forEach((r: string) => {
    sparePartsList.forEach((s: ISparePartModel) => {
      if (s.id === r) {
        selectedRelatedParts.push(s);
      }
    });
  });
  return (
    <Dialog open={showModal} maxWidth="xs" onClose={closeModal} aria-labelledby="form-dialog-title">
      <ModalHeader
        id={'form-dialog-title'}
        title={
          selectedCheckListItem && isEditing
            ? `Edit ${selectedCheckListItem.translations.en}`
            : 'Add New Checklist Item'
        }
      />
      <DialogContent style={{ minHeight: '400px' }}>
        <TextField
          margin="normal"
          id="name"
          label="Name in English"
          type="email"
          value={translations.en}
          fullWidth
          error={name === '' && validationError}
          variant="outlined"
          onChange={(e) => {
            handleTranslationChange(e, 'en');
          }}
          helperText={validationError && !name ? 'Name is required!' : undefined}
          autoFocus
        />
        <Button
          disabled={Object.keys(translations).length - 1 === languages.length}
          color="default"
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          ref={anchorRef}
          style={{
            width: '40%',
            justifyContent: 'space-between',
            backgroundColor: '#eee',
            textTransform: 'unset',
            margin: '15px 0 0',
            padding: '6px 8px 6px 14px',
          }}
        >
          Add translation
          <ArrowDropDownIcon />
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{ zIndex: 2, width: '36.5%' }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleSelectClose}>
                  <MenuList id="split-button-menu">
                    {[...newLanguages]
                      .filter((item) => !Object.keys(translations).find((t) => t === item.value))
                      .map((language: ILanguage, index: number) => (
                        <MenuItem
                          key={index}
                          onClick={() => handleAddTransaltion(language.value)}
                          style={{ padding: 10, paddingLeft: 14 }}
                        >
                          {language.label}
                        </MenuItem>
                      ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        {Object.keys(translations)
          .filter((i) => i !== 'en')
          .map((item: string, index: number) => {
            return (
              <div className={classes.textFieldWrapper} key={index}>
                <TextField
                  key={index}
                  margin="normal"
                  id={item}
                  label={[...newLanguages].find((i) => i.value === item)!.label}
                  type="text"
                  value={translations[item]}
                  name={item}
                  fullWidth
                  variant="outlined"
                  onChange={(e) => handleTranslationChange(e, item)}
                />
                <ListItemIcon className={classes.deleteButton}>
                  <IconButton onClick={() => handleDelete(item)}>
                    <DeleteOutline />
                  </IconButton>
                </ListItemIcon>
              </div>
            );
          })}
        <Autocomplete
          className={classes.autoComplete}
          multiple
          id="tags-relatedParts"
          options={[...sparePartsList]}
          getOptionLabel={(option) => option.translations['en']}
          defaultValue={selectedRelatedParts}
          filterSelectedOptions
          onChange={(obj, value) => {
            const newRelatedPartsIds = value.map((i: ISparePartModel) => i.id);
            setRelatedPartsIds(newRelatedPartsIds);
          }}
          renderTags={(value, getTagProps) => {
            return value.map((option: any, index: number) => {
              if (!option) return null;
              return (
                <Chip
                  label={option.translations['en']}
                  {...getTagProps({ index })}
                  className={classes.chip}
                />
              );
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              name="relatedParts"
              variant="outlined"
              label="Related parts"
              margin="normal"
            />
          )}
        />
      </DialogContent>
      <ModalFooter
        isEditing={!!selectedCheckListItem && isEditing}
        onContinueClick={addNewCheckListItem}
        onCancelClick={closeModal}
      />
    </Dialog>
  );
};
