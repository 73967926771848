import * as React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  Dialog,
  DialogContent,
} from '@material-ui/core';

import { IUserDetail, CompanyRoleType } from 'app/models';

import { ConfirmationModal } from '../ConfirmationModal/ConfirmationModal';
import { ModalFooter } from '../ModalFooter/ModalFooter';
import { validateEmail } from 'app/helpers/validation-helper';
import { ModalHeader } from '../ModalHeader/ModalHeader';

export interface IProps {
  showModal: boolean;
  handleClose: () => void;
  addOrUpdateUser: (user: IUserDetail) => void;
  deleteUser: () => void;
  selectedUser: IUserDetail | null;
  isLoading?: boolean;
  isDeleting?: boolean;
}

export const AddUserModal: React.SFC<IProps> = ({
  showModal,
  handleClose,
  addOrUpdateUser,
  isLoading,
  selectedUser,
  deleteUser,
  isDeleting,
}) => {
  const [fullname, setFullName] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [companyRole, setCompanyRole] = React.useState<CompanyRoleType>(
    CompanyRoleType.Collaborator,
  );

  const [error, setError] = React.useState<boolean>(false);

  const [openConfirmModal, setOpenConfirmModal] = React.useState<boolean>(false);

  React.useEffect(() => {
    setTimeout(() => {
      if (!showModal) {
        setError(false);
        setFullName('');
        setEmail('');
        setCompanyRole(CompanyRoleType.Collaborator);
      }
    }, 100);
  }, [showModal]);

  React.useEffect(() => {
    if (selectedUser) {
      setError(false);
      setFullName(selectedUser.fullname);
      setEmail(selectedUser.email);
      setCompanyRole(selectedUser.companyRole);
    }
  }, [selectedUser]);

  React.useEffect(() => {
    if (!isDeleting) {
      setOpenConfirmModal(false);
    }
  }, [isDeleting]);

  const handleAddOrUpdateUser = () => {
    const isEmailValid = validateEmail(email);
    if (!fullname || !companyRole || !email || !isEmailValid) {
      setError(true);
      return;
    }
    let payload: IUserDetail;
    if (selectedUser) {
      payload = {
        ...selectedUser,
        fullname,
        email,
        companyRole,
      };
    } else {
      payload = {
        id: '',
        fullname,
        email,
        companyRole,
        createdAt: new Date(),
        updatedAt: new Date(),
      };
    }
    addOrUpdateUser(payload);
  };

  const companyRoles = Object.keys(CompanyRoleType).map((key: any) => key);

  return (
    <>
      <Dialog
        open={showModal}
        onClose={() => (isLoading ? null : handleClose())}
        aria-labelledby="form-dialog-title"
      >
        <ModalHeader
          title={selectedUser ? `Edit ${selectedUser.fullname}` : 'Add New User'}
          id={'form-dialog-title'}
        />
        <DialogContent>
          <TextField
            margin="normal"
            id="fullname"
            label="Full Name"
            type="text"
            fullWidth
            variant="outlined"
            value={fullname}
            onChange={(e) => setFullName(e.target.value)}
            error={!fullname && error}
            helperText={!fullname && error && 'Please enter full name'}
          />
          <TextField
            margin="normal"
            id="email"
            label="Email Address"
            type="text"
            fullWidth
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={(!email && error) || (!validateEmail(email) && error)}
            helperText={
              (!email && error) || (!validateEmail(email) && error)
                ? !email
                  ? 'Please enter email'
                  : 'Please enter valid email'
                : undefined
            }
          />
          <FormControl variant="outlined">
            <InputLabel id="demo-simple-select-label" error={!companyRole && error}>
              Role
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              value={companyRole ? companyRole : ''}
              defaultValue={companyRole ? companyRole : ''}
              onChange={(e) => {
                const newCompanyRole = e.target.value as CompanyRoleType;
                setCompanyRole(newCompanyRole);
              }}
              error={!companyRole && error}
              inputProps={{
                name: 'companyRole',
                id: 'outlined-age-native-simple',
              }}
            >
              {companyRoles.map((c: string, index: number) => (
                <MenuItem key={index} value={c}>
                  {c}
                </MenuItem>
              ))}
            </Select>
            {!companyRole && error && (
              <FormHelperText style={{ color: '#e53935' }}>Please select Role</FormHelperText>
            )}
          </FormControl>
        </DialogContent>
        <ModalFooter
          isLoading={isLoading}
          isEditing={!!selectedUser}
          onCancelClick={handleClose}
          onContinueClick={handleAddOrUpdateUser}
          onDeleteClick={() => setOpenConfirmModal(true)}
          hasDelete
        />
      </Dialog>
      {selectedUser && (
        <ConfirmationModal
          openModal={openConfirmModal}
          handleClose={() => setOpenConfirmModal(false)}
          handleContinue={deleteUser}
          modalBody={
            <span>
              Do you want to remove <strong>{selectedUser.fullname}</strong>?
            </span>
          }
          isLoading={isDeleting}
        />
      )}
    </>
  );
};
