import palette from '../palette';

export default {
  underline: {
    '&:hover': {
      '&:before': {
        borderBottom: '2px solid ' + palette.primary.main,
      },
    },
    '&:after': {
      borderBottom: '2px solid ' + palette.primary.main,
    },
  },
};
