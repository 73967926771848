import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  formControl: {
    width: '30%',
  },
  formControlLabel: {
    marginBottom: 15,
    color: '#90A4AE',
  },
  selectFields: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  autoComplete: {
    '& .MuiFormControl-root': {
      width: '100%',
      marginTop: 0,
      float: 'right',
    },
    width: '65%',
    '& .label': {
      marginBottom: 20,
    },
  },

  chip: {
    height: 24,
    marginRight: 4,
  },
  error: {
    color: '#e53935',
    fontSize: 11,
  },
}));
