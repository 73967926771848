import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  listItem: {
    padding: '14px 24px',
    borderRadius: 0,
  },
  url: {
    display: 'flex',
    margin: '14px 0',
  },
  methodBadge: {
    borderRadius: 4,
    backgroundColor: '#26A69A',
    color: theme.palette.common.white,
    marginRight: 5,
  },
  requestSample: {
    marginTop: theme.spacing(0.5),
    borderRadius: 4,
    padding: theme.spacing(1.5),
    backgroundColor: 'black',
    boxShadow: 'none',
  },
  responseSample: {
    marginTop: theme.spacing(0.5),
    borderRadius: 4,
    padding: theme.spacing(1.5),
    backgroundColor: '#e6e6e6',
    boxShadow: 'none',
  },
}));
