import * as React from 'react';
import Chip from '@material-ui/core/Chip';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useStyles } from './SystemErrorModalStyles';
import {
  ISystemErrorModel,
  IVehicleTypeListModel,
  ISparePartModel,
  IAddSystemErrorModel,
  ITranslationModel,
} from 'app/models';
import { ConfirmationModal } from '../ConfirmationModal/ConfirmationModal';
import { ModalHeader } from '../ModalHeader/ModalHeader';
import { ModalFooter } from '../ModalFooter/ModalFooter';

export interface IProps {
  showModal: boolean;
  handleClose: () => void;
  onContinueClick: (systemError: IAddSystemErrorModel) => void;
  selectedSystemError: ISystemErrorModel | null;
  isEditing: boolean;
  handleUpdateSystemError: (systemError: IAddSystemErrorModel) => void;
  handleDeleteSystemError: () => void;
  vehicleTypesList: IVehicleTypeListModel[];
  isLoading: boolean;
  isDeleting: boolean;
}

export const SystemErrorModal: React.SFC<IProps> = ({
  showModal,
  handleClose,
  onContinueClick,
  selectedSystemError,
  isEditing,
  handleUpdateSystemError,
  handleDeleteSystemError,
  vehicleTypesList,
  isLoading,
  isDeleting,
}) => {
  const classes = useStyles();
  const [code, setCode] = React.useState<string>('');
  const [vehicleTypeId, setVehicleTypeId] = React.useState<string>('');
  const [relatedParts, setRelatedParts] = React.useState<ISparePartModel[]>([]);
  const [translations, setTranslations] = React.useState<ITranslationModel>({ en: '' });

  const [error, setError] = React.useState<boolean>(false);

  const [options, setOptions] = React.useState<ISparePartModel[]>([]);

  const [confirmModal, setConfirmModal] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!showModal) {
      setCode('');
      setVehicleTypeId('');
      setRelatedParts([]);
      setError(false);
      setTranslations({ en: '' });
    }
    if (selectedSystemError && isEditing) {
      setCode(selectedSystemError.code);
      setTranslations(
        selectedSystemError.translations ? selectedSystemError.translations : { en: '' },
      );
      setVehicleTypeId(selectedSystemError.vehicleTypeId);
      setError(false);
      const selectedVehicleType = vehicleTypesList.find(
        (v: IVehicleTypeListModel) => v.id === selectedSystemError.vehicleTypeId,
      );
      if (selectedVehicleType) {
        setOptions(selectedVehicleType.spareParts || []);
        let selectedRelatedParts: ISparePartModel[] = [];
        selectedSystemError.relatedPartsIds.forEach((r: string) => {
          selectedVehicleType.spareParts.forEach((s: ISparePartModel) => {
            if (s.id === r) {
              selectedRelatedParts.push(s);
            }
          });
        });
        setRelatedParts(selectedRelatedParts);
      }
    }
    if (!isDeleting) {
      setConfirmModal(false);
    }
  }, [showModal, selectedSystemError, isEditing, vehicleTypesList, isDeleting]);

  const validate = (): boolean => {
    let validation = false;
    if (!code || !!!vehicleTypeId) {
      validation = true;
    }
    setError(validation);
    return validation;
  };

  const addNewError = () => {
    if (validate()) return;

    const requestObject: IAddSystemErrorModel = {
      code,
      relatedPartsIds: relatedParts.map((r: ISparePartModel) => r.id),
      vehicleTypeId,
      translations,
    };
    if (isEditing) {
      handleUpdateSystemError(requestObject);
    } else {
      onContinueClick(requestObject);
    }
  };

  const deleteSystemError = () => {
    if (selectedSystemError) {
      handleDeleteSystemError();
    }
  };

  return (
    <>
      <Dialog
        open={showModal}
        onClose={isLoading ? () => null : handleClose}
        aria-labelledby="form-dialog-title"
      >
        <ModalHeader
          id="form-dialog-title"
          title={
            selectedSystemError && isEditing ? `Edit ${selectedSystemError.code}` : 'Add New Error'
          }
        />
        <DialogContent>
          <TextField
            margin="normal"
            id="code"
            label="Code"
            type="text"
            fullWidth
            variant="outlined"
            value={code}
            disabled={!!selectedSystemError && isEditing}
            onChange={(e) => setCode(e.target.value)}
            error={!code && error}
            helperText={!code && error && 'Please enter code'}
          />
          <TextField
            margin="normal"
            id="title"
            label="Title"
            type="text"
            fullWidth
            error={!translations['en'] && error}
            variant="outlined"
            helperText={!translations['en'] && error ? 'Name is required!' : undefined}
            value={translations['en']}
            onChange={(e) => {
              let newTranslations = { ...translations };
              newTranslations['en'] = e.target.value;
              setTranslations(newTranslations);
            }}
          />
          <div className={classes.selectFields}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label" error={!vehicleTypeId && error}>
                Vehicle
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={(e) => {
                  let vehicleType = [...vehicleTypesList].find((v) => v.id === e.target.value);
                  if (vehicleType) {
                    setVehicleTypeId(vehicleType.id);
                    setOptions(vehicleType.spareParts || []);
                    setRelatedParts([]);
                  }
                }}
                value={vehicleTypeId ? vehicleTypeId : ''}
                defaultValue={vehicleTypeId ? vehicleTypeId : ''}
                error={!vehicleTypeId && error}
              >
                {vehicleTypesList.map((v: IVehicleTypeListModel, index: number) => (
                  <MenuItem key={index} value={v.id}>
                    {v.name}
                  </MenuItem>
                ))}
              </Select>
              {!vehicleTypeId && error && (
                <FormHelperText style={{ color: '#e53935' }}>Please select vehicle</FormHelperText>
              )}
            </FormControl>
            <Autocomplete
              className={classes.autoComplete}
              multiple
              id="tags-standard"
              options={[...options]}
              getOptionLabel={(option) => option.code}
              defaultValue={relatedParts}
              value={relatedParts}
              filterSelectedOptions
              onChange={(obj, value) => {
                setRelatedParts(value);
              }}
              renderTags={(value, getTagProps) => {
                return value.map((option: any, index: number) => {
                  if (!option) return null;
                  return (
                    <Chip
                      label={option.code}
                      {...getTagProps({ index })}
                      className={classes.chip}
                    />
                  );
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Related parts"
                  placeholder="Parts"
                  margin="normal"
                />
              )}
            />
          </div>
        </DialogContent>
        <ModalFooter
          isLoading={isLoading}
          isEditing={isEditing}
          hasDelete
          onCancelClick={handleClose}
          onContinueClick={addNewError}
          onDeleteClick={() => setConfirmModal(true)}
        />
      </Dialog>
      {selectedSystemError && (
        <ConfirmationModal
          openModal={confirmModal}
          handleClose={() => setConfirmModal(false)}
          handleContinue={deleteSystemError}
          modalBody={
            <span>
              Do you want to remove <strong>{selectedSystemError.code}</strong>?
            </span>
          }
          isLoading={isDeleting}
        />
      )}
    </>
  );
};
