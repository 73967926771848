import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import {
  IHotspotModel,
  ISupportedRoutes,
  IMethods,
  INewHotspotModel,
  IResponseHandlerModel,
  ToastTypes,
  DeleteItemsFunction,
  IOnroadTaskModel,
} from 'app/models';
import { IRootState } from 'app/reducers/AppState';
import { push } from 'react-router-redux';
import { ApiHelper } from 'app/helpers/api-helper';
import { showToaster } from './ToastActions';

const nativeProperties = ['location', 'priorityPeriods'];

export enum IHotspotsActionsType {
  REQUEST_HOT_SPOTS = 'REQUEST_HOT_SPOTS',
  RECEIVE_HOT_SPOTS = 'RECEIVE_HOT_SPOTS',
  FAILURE_HOT_SPOTS = 'FAILURE_HOT_SPOTS',

  REQUEST_ADD_OR_UPDATE_HOTSPOT = 'REQUEST_ADD_OR_UPDATE_HOTSPOT',
  RECEIVE_ADD_OR_UPDATE_HOTSPOT = 'RECEIVE_ADD_OR_UPDATE_HOTSPOT',
  FAILURE_ADD_OR_UPDATE_HOTSPOT = 'FAILURE_ADD_OR_UPDATE_HOTSPOT',

  REQUEST_HOT_SPOT_DETAILS = 'REQUEST_HOT_SPOT_DETAILS',
  RECEIVE_HOT_SPOT_DETAILS = 'RECEIVE_HOT_SPOT_DETAILS',
  FAILURE_HOT_SPOT_DETAILS = 'FAILURE_HOT_SPOT_DETAILS',

  HANDLE_VIEW_HOT_SPOTS = 'HANDLE_VIEW_HOT_SPOTS',

  REQUEST_DROP_TASKS = 'REQUEST_DROP_TASKS',
  RECEIVE_DROP_TASKS = 'RECEIVE_DROP_TASKS',
  FAILURE_DROP_TASKS = 'FAILURE_DROP_TASKS',
}

// tslint:disable:typedef

export const requestHotspots = createAction(IHotspotsActionsType.REQUEST_HOT_SPOTS);
export const receiveHotspots = createAction<IHotspotModel[]>(
  IHotspotsActionsType.RECEIVE_HOT_SPOTS,
);
export const failureHotspots = createAction(IHotspotsActionsType.FAILURE_HOT_SPOTS);

export const requestAddHotspot = createAction(IHotspotsActionsType.REQUEST_ADD_OR_UPDATE_HOTSPOT);
export const receiveAddHotspot = createAction<IHotspotModel>(
  IHotspotsActionsType.RECEIVE_ADD_OR_UPDATE_HOTSPOT,
);
export const failureAddHotspot = createAction(IHotspotsActionsType.FAILURE_ADD_OR_UPDATE_HOTSPOT);

export const requestHotspotDetails = createAction(IHotspotsActionsType.REQUEST_HOT_SPOT_DETAILS);
export const receiveHotspotDetails = createAction<IHotspotModel>(
  IHotspotsActionsType.RECEIVE_HOT_SPOT_DETAILS,
);
export const failureHotspotDetails = createAction(IHotspotsActionsType.FAILURE_HOT_SPOT_DETAILS);

export const handleViewHotspots = createAction<IHotspotModel>(
  IHotspotsActionsType.HANDLE_VIEW_HOT_SPOTS,
);

export const requestDropTasks = createAction(IHotspotsActionsType.REQUEST_DROP_TASKS);
export const receiveDropTasks = createAction<IOnroadTaskModel[]>(
  IHotspotsActionsType.RECEIVE_DROP_TASKS,
);
export const failureDropTasks = createAction(IHotspotsActionsType.FAILURE_DROP_TASKS);

export const fetchHotspots = () => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    const {
      userDetails: { selectedRegion },
    } = getState();

    if (selectedRegion && selectedRegion.key === 'all') {
      dispatch(push(ISupportedRoutes.DASHBOARD));
      return;
    }

    try {
      dispatch(requestHotspots());

      const response = await new ApiHelper().FetchFromPortal('/hotspots', IMethods.GET, true, {
        ...(selectedRegion && { regionId: selectedRegion.id }),
        includeWaiting: true,
        returnCurrentPriority: true,
      });

      if (response.isError) {
        dispatch(failureHotspots());
        return;
      }

      dispatch(receiveHotspots(response.data.data as IHotspotModel[]));
    } catch (e) {
      console.error(e);
      dispatch(failureHotspots());
    }
  };
};

export const saveHotspot = (hotspot: INewHotspotModel, file: any) => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    const {
      userDetails: { selectedRegion },
      hotspots: { selectedHotspot },
    } = getState();
    if (!selectedRegion) {
      showToaster({
        show: true,
        type: ToastTypes.ERROR,
        message: 'Region is undefined',
      })(dispatch);
      return;
    }
    if (selectedRegion.key === 'all') {
      alert(`Before saving, please choose a location on the sidebar navigation`);
      return;
    }
    dispatch(requestAddHotspot());
    try {
      let response: IResponseHandlerModel;
      const formData = new FormData();
      const data: any = {
        ...hotspot,
        ...(!selectedHotspot && { regionId: selectedRegion.id }),
      };
      if (file) {
        formData.append('file', file);
      }
      const keysToExclude = ['nearbyRadius', 'nearbyVehicles', 'incomingVehicles']; // can't be set by the user
      Object.keys(data).forEach((item) => {
        if (keysToExclude.includes(item)) return;
        if (data[item] === undefined) return;
        if (data[item] === null && item !== 'photoUrl') return;
        if (nativeProperties.includes(item)) {
          data[item] = JSON.stringify(data[item]);
        }
        formData.append(`${item}`, data[item]);
      });
      if (selectedHotspot) {
        response = await new ApiHelper().FetchFromPortal(
          '/hotspots/' + selectedHotspot.id,
          IMethods.PUT,
          true,
          undefined,
          formData,
          true,
        );
      } else {
        response = await new ApiHelper().FetchFromPortal(
          '/hotspots',
          IMethods.POST,
          true,
          undefined,
          formData,
          true,
        );
      }
      if (response.isError) {
        dispatch(failureAddHotspot());
        return;
      }
      const newHotspot = response.data.data as IHotspotModel;
      dispatch(receiveAddHotspot(newHotspot));
    } catch (e) {
      console.error(e);
      dispatch(failureAddHotspot());
    }
  };
};

export const gotoHotspotView = (hotspot: IHotspotModel) => {
  return (dispatch: Dispatch) => {
    dispatch(handleViewHotspots(hotspot));
  };
};

export const fetchHotspotDetails = (id: string) => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    dispatch(requestHotspotDetails());
    try {
      const response = await new ApiHelper().FetchFromPortal('/hotspots/' + id, IMethods.GET, true);

      if (response.isError) {
        console.error(response.messages![0]);
        dispatch(failureHotspotDetails());
        return;
      }

      const hotspot = response.data.data as IHotspotModel;
      dispatch(handleViewHotspots(hotspot));
    } catch (e) {
      dispatch(failureHotspotDetails());
      console.error(e);
    }
  };
};

export const deleteHotspot = () => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    const {
      userDetails: { selectedRegion },
      hotspots: { selectedHotspot },
    } = getState();
    if (selectedHotspot && selectedRegion) {
      try {
        const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
          '/hotspots/' + selectedHotspot.id,
          IMethods.DELETE,
          true,
        );

        if (response.isError) {
          console.error(response.messages![0]);
          return;
        }
        dispatch(push(`/${selectedRegion.key}${ISupportedRoutes.HOT_SPOTS}`));
      } catch (e) {
        console.error(e);
      }
    }
  };
};

export const deleteHotspots: DeleteItemsFunction = (ids: string[]) => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    const {
      userDetails: { selectedRegion },
    } = getState();
    if (!selectedRegion) {
      showToaster({
        show: true,
        type: ToastTypes.ERROR,
        message: 'Region is undefined',
      })(dispatch);
      return;
    }
    try {
      // dispatch(requestDeleteRepairGuides({ removingGuidesIds: ids }));
      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        '/hotspots/',
        IMethods.DELETE,
        true,
        undefined,
        {
          repairGuides: ids,
        },
      );
      if (response.isError) {
        // dispatch(failureDeleteRepairGuides());
        console.error(response.messages![0]);
        return;
      }
      dispatch(push(`/${selectedRegion.key}${ISupportedRoutes.HOT_SPOTS}`));
      // dispatch(receiveDeleteRepairGuides());
    } catch (e) {
      // dispatch(failureDeleteRepairGuides());
    }
  };
};

export const fetchDropTasks = (id: string) => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    try {
      dispatch(requestDropTasks());
      const response = await new ApiHelper().FetchFromPortal(
        '/hotspots/' + id,
        IMethods.GET,
        true,
        { tasks: true },
      );

      if (response.isError) {
        dispatch(failureDropTasks());
        return;
      }
      const tasks = response.data.data.tasks as IOnroadTaskModel[];
      dispatch(receiveDropTasks(tasks));
    } catch (e) {
      console.error(e);
      dispatch(failureDropTasks());
    }
  };
};
