import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';

export enum IDevelopmentActionsType {
  REQUEST_DEVELOPMENT = 'REQUEST_DEVELOPMENT',
}

// tslint:disable:typedef

export const requestDevelopment = createAction(IDevelopmentActionsType.REQUEST_DEVELOPMENT);

export const fetchDevelopmentDetails = () => {
  return async (dispatch: Dispatch) => {
    dispatch(requestDevelopment());
  };
};
