import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { Hotspots } from 'app/views';
import { IRootState } from 'app/reducers/AppState';
import { IHotspotsModel, IRegion, IVehicleTypeListModel, IHotspotModel, IUserDetail } from 'app/models';
import { fetchVehiclesTypesList, fetchHotspots, gotoHotspotView } from 'app/actions';

interface StateFromProps {
  hotspots: IHotspotsModel;
  selectedRegion: IRegion | null;
  vehicleTypesList: IVehicleTypeListModel[];
  userDetails: IUserDetail | null
}

interface DispatchFromProps {
  fetchHotspots: () => void;
  fetchVehicleTypesList: () => void;
  gotoHotspotView: (hotspot: IHotspotModel) => void;
}

const mapStateToProps = (state: IRootState): StateFromProps => ({
  hotspots: state.hotspots,
  selectedRegion: state.userDetails.selectedRegion,
  vehicleTypesList: state.vehicleTypes.vehicleTypesList,
  userDetails: state.userDetails.userDetails
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => ({
  fetchHotspots: bindActionCreators(fetchHotspots, dispatch),
  fetchVehicleTypesList: bindActionCreators(fetchVehiclesTypesList, dispatch),
  gotoHotspotView: bindActionCreators(gotoHotspotView, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Hotspots);
