import React from 'react';

import { IPriorityPeriod, DayModel } from 'app/models';

import { IconButton, Typography } from '@material-ui/core';
import { AccessTime, Edit, DeleteOutline } from '@material-ui/icons';

import { usePriorityPeriodItemStyles } from './styles';

export interface IProps {
  priorityPeriod: IPriorityPeriod;
  openEditModal: () => void;
  openDeleteModal: () => void;
}

export const PriorityPeriodItem: React.SFC<IProps> = ({
  priorityPeriod,
  openEditModal,
  openDeleteModal,
}) => {
  const classes = usePriorityPeriodItemStyles();
  return (
    <div className={classes.item}>
      <div className={classes.leftSide}>
        <AccessTime className={classes.icon} />
        <Typography variant="body1" gutterBottom className={classes.period}>
          <span>
            {DayModel[priorityPeriod.start_day]} {priorityPeriod.start_hour}:
            {('0' + priorityPeriod.start_minute).slice(-2)}
          </span>
          <span> - </span>
          <span>
            {DayModel[priorityPeriod.end_day]} {priorityPeriod.end_hour}:
            {('0' + priorityPeriod.end_minute).slice(-2)}
          </span>
          <span> — </span>
          <span>
            Priority: <strong>{priorityPeriod.priority}</strong>
          </span>
        </Typography>
        {/* </p> */}
      </div>
      <div>
        <IconButton aria-label="edit" className={classes.actionIcon} onClick={openEditModal}>
          <Edit />
        </IconButton>
        <IconButton aria-label="delete" className={classes.actionIcon} onClick={openDeleteModal}>
          <DeleteOutline />
        </IconButton>
      </div>
    </div>
  );
};
