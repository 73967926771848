import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Development } from 'app/views';
import { IRootState } from 'app/reducers/AppState';
import { IUserDetail } from 'app/models';

interface StateFromProps {
  userDetails: IUserDetail | null;
}

interface DispatchFromProps {}

const mapStateToProps = (state: IRootState): StateFromProps => ({
  userDetails: state.userDetails.userDetails,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Development);
