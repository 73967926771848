import * as React from 'react';

import { TableCell, TableHead, TableRow, TableSortLabel, Checkbox } from '@material-ui/core';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export interface ITableHeaderCells {
  id: string;
  numeric: boolean;
  disablePadding: boolean;
  label: string;
}

interface IProps {
  numSelected: number;
  onRequestSort: any;
  onSelectAllClick?: any;
  order: 'asc' | 'desc';
  orderBy: string;
  rowCount: number;
  headerCells: ITableHeaderCells[];
  selecting?: boolean;
  isLoading?: boolean;
}

export const ServeedoTableHeader: React.SFC<IProps> = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headerCells,
    selecting,
    isLoading,
  } = props;
  const classes = useStyles();
  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {selecting ? (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={!isLoading && numSelected > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
              disabled={true}
            />
          </TableCell>
        ) : null}
        {headerCells.map((headCell: ITableHeaderCells) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
