import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import { Button, CircularProgress, Typography } from '@material-ui/core';

import { WidgetHeader } from 'app/components/WidgetHeader/WidgetHeader';
import {
  IVehicleTypeListModel,
  ISupportedRoutes,
  ISparePartModel,
  IAddRepairGuideModel,
  ILanguage,
  IRepairGuidesModel,
} from 'app/models';
import { MetaDataForm } from './MetaDataForm/MetaDataForm';
import { ContentForm } from './ContentForm/ContentForm';

import { languages } from 'app/constants/languages';
import { ConfirmationModal } from '../Modals/ConfirmationModal/ConfirmationModal';

let newLanguages: ILanguage[] = [
  {
    label: 'English',
    value: 'en',
  },
  ...languages,
];

export interface IProps extends RouteComponentProps<any> {
  repairGuides: IRepairGuidesModel;
  vehicleTypesList: IVehicleTypeListModel[];
  sparePartsList: ISparePartModel[];
  fetchVehicleTypes: () => void;
  fetchSparePartsList: () => void;
  addNewRepairGuide: (repairGuide: IAddRepairGuideModel) => void;
  fetchRepairGuide: (id: string) => void;
  deleteRepairGuide: () => void;
}

export interface IState {
  repairGuide: IAddRepairGuideModel;
  sparePartsOptions: ISparePartModel[];
  isError: boolean;
  selectedlanguage: ILanguage;
  isDisplayErrorCode: string | null;
  confirmModal: boolean;
}

export class AddNewRepairGuide extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      repairGuide: {
        displayErrorCodes: [],
        vehicleTypeId: '',
        videoUrl: '',
        translations: {
          en: {
            title: '',
            description: '',
          },
        },
        relatedPartsIds: [],
        frequent: false,
      },
      sparePartsOptions: [],
      isError: false,
      selectedlanguage: newLanguages[0],
      isDisplayErrorCode: null,
      confirmModal: false,
    };
  }

  componentDidMount() {
    this.props.fetchSparePartsList();
    this.props.fetchVehicleTypes();
    const {
      repairGuides: { selectedRepairGuide, isEditing },
      fetchRepairGuide,
      match: { params },
    } = this.props;
    if (selectedRepairGuide && isEditing) {
      this.setState({
        repairGuide: {
          ...selectedRepairGuide,
          relatedPartsIds: selectedRepairGuide.relatedParts.map((r) => r.id),
        },
      });
    }
    if (!selectedRepairGuide && params.id) {
      fetchRepairGuide(params.id);
    }
    if (this.props.sparePartsList.length && selectedRepairGuide) {
      let selectedRelatedParts: ISparePartModel[] = [...this.props.sparePartsList].filter(
        (i) => i.vehicleTypeId === selectedRepairGuide.vehicleTypeId,
      );

      this.setState({
        sparePartsOptions: selectedRelatedParts,
      });
    }
    if (!selectedRepairGuide && !isEditing) {
      this.resetState();
    }
  }

  resetState = () => {
    this.setState({
      repairGuide: {
        displayErrorCodes: [],
        vehicleTypeId: '',
        videoUrl: '',
        translations: {
          en: {
            title: '',
            description: '',
          },
        },
        relatedPartsIds: [],
        frequent: false,
      },
      sparePartsOptions: [],
      isError: false,
      selectedlanguage: newLanguages[0],
      isDisplayErrorCode: null,
      confirmModal: false,
    });
  };

  componentDidUpdate(prevProps: IProps) {
    const {
      repairGuides: { errorMessage, isLoading, selectedRepairGuide, isEditing, isDeleting },
    } = this.props;
    if (
      prevProps.repairGuides.errorMessage !== errorMessage &&
      errorMessage &&
      errorMessage.length &&
      errorMessage.includes('Display error code')
    ) {
      this.setState({
        isDisplayErrorCode: errorMessage,
      });
    }
    if (
      prevProps.repairGuides.isLoading !== isLoading &&
      !isLoading &&
      selectedRepairGuide &&
      isEditing
    ) {
      this.setState({
        repairGuide: {
          ...selectedRepairGuide,
          relatedPartsIds: selectedRepairGuide.relatedParts.map((r) => r.id),
        },
      });
    }

    if (prevProps.repairGuides.isDeleting !== isDeleting && !isDeleting) {
      this.setState({
        confirmModal: false,
      });
    }
    if (
      (prevProps.sparePartsList !== this.props.sparePartsList && selectedRepairGuide) ||
      (prevProps.repairGuides.selectedRepairGuide !== selectedRepairGuide && selectedRepairGuide)
    ) {
      let selectedRelatedParts: ISparePartModel[] = [...this.props.sparePartsList].filter(
        (i) => i.vehicleTypeId === selectedRepairGuide.vehicleTypeId,
      );
      this.setState({
        sparePartsOptions: selectedRelatedParts,
      });
    }
  }

  validation = (): boolean => {
    const {
      repairGuide: { vehicleTypeId, translations },
    } = this.state;
    let validation = true;
    if (!vehicleTypeId || !translations['en'].title) {
      validation = false;
    }
    if (!translations['en'].title) {
      this.setState({
        selectedlanguage: newLanguages[0],
      });
    }
    this.setState({
      isError: true,
    });

    return validation;
  };

  gotToRepairGuides = () => {
    this.props.history.push(ISupportedRoutes.REPAIR_GUIDES);
  };

  handleChangeLanguage = (language: ILanguage) => {
    this.setState({
      selectedlanguage: language,
    });
    const { translations } = this.state.repairGuide;
    let newTranslation = { ...translations };
    if (Object.keys(newTranslation).find((i) => i === language.value)) return;
    newTranslation = { ...newTranslation, [language.value]: { title: '', description: '' } };
    this.setState({
      repairGuide: {
        ...this.state.repairGuide,
        translations: newTranslation,
      },
    });
  };

  addNewRepairGuide = () => {
    if (!this.validation()) return;
    this.props.addNewRepairGuide(this.state.repairGuide);
  };

  renderButton = (): JSX.Element => {
    const {
      repairGuides: { isAdding, isEditing },
    } = this.props;
    return (
      <div>
        {isEditing && (
          <Button
            color="secondary"
            style={{ color: 'rgb(220, 0, 78)' }}
            disabled={isAdding}
            onClick={() => this.setState({ confirmModal: true })}
          >
            DELETE
          </Button>
        )}{' '}
        <Button
          variant="contained"
          size="medium"
          onClick={this.gotToRepairGuides}
          disabled={isAdding}
        >
          CANCEL
        </Button>{' '}
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={this.addNewRepairGuide}
          disabled={isAdding}
        >
          {isAdding ? (isEditing ? 'Updating...' : 'Saving...') : isEditing ? 'Update' : 'Save'}
          {''}
          {isAdding && <CircularProgress disableShrink size={18} thickness={4} />}
        </Button>
      </div>
    );
  };

  handleChangeRepairGuide = (repairGuide: IAddRepairGuideModel) => {
    this.setState({
      repairGuide,
      isDisplayErrorCode: null,
    });
  };

  handleChangeOptions = (id: string) => {
    const sparePartsOptions = [...this.props.sparePartsList].filter((i) => i.vehicleTypeId === id);
    this.setState({
      sparePartsOptions,
    });
  };

  render(): JSX.Element {
    const {
      vehicleTypesList,
      repairGuides: { selectedRepairGuide, isEditing, isLoading, isDeleting },
      deleteRepairGuide,
    } = this.props;
    const {
      repairGuide,
      isError,
      sparePartsOptions,
      selectedlanguage,
      isDisplayErrorCode,
      confirmModal,
    } = this.state;

    return (
      <div className={'container'}>
        <div className="medium-width">
          {isLoading ? (
            <div
              style={{
                minHeight: '50vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <CircularProgress disableShrink size={40} thickness={4} />
              <Typography>Loading...</Typography>
            </div>
          ) : (
            <>
              <WidgetHeader
                headerText={
                  selectedRepairGuide && isEditing
                    ? `Edit ${
                        selectedRepairGuide.translations['en'].title
                          ? selectedRepairGuide.translations['en'].title
                          : selectedRepairGuide.translations['en']
                      }`
                    : 'Create Repair Guide'
                }
                headerSmallText={'GENERAL'}
                rightPanel={this.renderButton()}
              />
              <MetaDataForm
                vehicleTypesList={vehicleTypesList}
                repairGuide={repairGuide}
                isError={isError}
                sparePartsOptions={sparePartsOptions}
                handleChange={this.handleChangeRepairGuide}
                handleSetOptions={this.handleChangeOptions}
                isDisplayErrorCode={isDisplayErrorCode}
              />
              <ContentForm
                repairGuide={repairGuide}
                isError={isError}
                handleChange={this.handleChangeRepairGuide}
                selectedLanguage={selectedlanguage}
                languages={newLanguages}
                onChangeLanguage={this.handleChangeLanguage}
              />
            </>
          )}
          {selectedRepairGuide && (
            <ConfirmationModal
              openModal={confirmModal}
              handleClose={() => this.setState({ confirmModal: false })}
              handleContinue={deleteRepairGuide}
              modalTitle={`Delete ${
                selectedRepairGuide.translations['en'].title
                  ? selectedRepairGuide.translations['en'].title
                  : selectedRepairGuide.translations['en']
              }?`}
              modalBody={
                <span>Are you sure you want to delete it? This operation cannot be undone.</span>
              }
              isLoading={isDeleting}
            />
          )}
        </div>
      </div>
    );
  }
}
