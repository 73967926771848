import PeopleIcon from '@material-ui/icons/People';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import InputIcon from '@material-ui/icons/Input';
import { ISupportedRoutes, IConfigRoutesModel } from 'app/models';

export default [
  {
    title: 'Pages',
    pages: [
      {
        title: 'Dashboard',
        href: ISupportedRoutes.DASHBOARD,
        icon: DashboardIcon,
      },
      {
        title: 'Customers',
        href: ISupportedRoutes.CUSTOMERS,
        icon: PeopleIcon,
      },
      {
        title: 'Invoices',
        href: ISupportedRoutes.INVOICES,
        icon: CreditCardIcon,
      },
    ],
  },
  {
    title: 'Account',
    pages: [
      {
        title: 'Users',
        href: ISupportedRoutes.USERS,
        icon: PeopleOutlineOutlinedIcon,
      },
      {
        title: 'Sign Out',
        href: ISupportedRoutes.LOGIN,
        icon: InputIcon,
      },
    ],
  },
] as IConfigRoutesModel[];
