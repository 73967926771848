import * as React from 'react';
import {
  TextField,
  Dialog,
  DialogContent,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormLabel,
} from '@material-ui/core';

import { IActivityModel, INewEmployeeDetails, IEmployeeModel } from 'app/models';

import { useStyles } from './AddEmployeeModalStyles';
import { ConfirmationModal } from '../ConfirmationModal/ConfirmationModal';
import { ModalHeader } from '../ModalHeader/ModalHeader';
import { ModalFooter } from '../ModalFooter/ModalFooter';

export interface IActivity {
  isChecked: boolean;
  value: number;
}

export interface IProps {
  showModal: boolean;
  handleClose: () => void;
  activitiesList: IActivityModel[];
  addNewEmployee: (employee: INewEmployeeDetails) => void;
  isAdding: boolean;
  isEditing: boolean;
  selectedEmployee: IEmployeeModel | null;
  editEmployee: (employee: INewEmployeeDetails) => void;
  deleteEmployee: (employeeId: string) => void;
  isDeletingEmployee: boolean;
}

export const AddEmployeeModal: React.SFC<IProps> = ({
  showModal,
  handleClose,
  activitiesList,
  addNewEmployee,
  isAdding,
  isEditing,
  selectedEmployee,
  editEmployee,
  deleteEmployee,
  isDeletingEmployee,
}) => {
  const classes = useStyles();

  const [fullname, setFullName] = React.useState<string>('');
  const [username, setUsername] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [allowedActivitiesIds, setActivities] = React.useState<number[]>([]);
  const [hasVanFull, setHasVanFull] = React.useState<boolean>(false);

  const [confirmModal, setConfirmModal] = React.useState<boolean>(false);

  const [error, setError] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (isEditing && selectedEmployee) {
      setError(false);
      setFullName(selectedEmployee.fullname);
      setUsername(selectedEmployee.username);
      setPassword('');
      setActivities(selectedEmployee.allowedActivities.map((i) => i.id));
      setHasVanFull(selectedEmployee.hasVanFull);
      return;
    }
    setError(false);
    setFullName('');
    setUsername('');
    setPassword('');
    setActivities([]);
    setHasVanFull(false);
    setConfirmModal(false);
  }, [showModal, isEditing, selectedEmployee, isDeletingEmployee]);

  const addEmployee = () => {
    const newEmployeeObject: INewEmployeeDetails = {
      fullname,
      username,
      password,
      allowedActivitiesIds,
      hasVanFull,
      active: true
    };
    if (!newEmployeeObject.allowedActivitiesIds.length || !fullname || !username || !password) {
      setError(true);
      return;
    }
    addNewEmployee(newEmployeeObject);
  };

  const updateEmployee = () => {
    if (selectedEmployee) {
      const newEmployeeObject: INewEmployeeDetails = {
        fullname,
        username,
        allowedActivitiesIds,
        hasVanFull,
        regionId: selectedEmployee.regionId,
        ...(password && { password }),
      };
      if (!newEmployeeObject.allowedActivitiesIds.length || !fullname || !username) {
        setError(true);
        return;
      }
      editEmployee(newEmployeeObject);
    }
  };

  const deleteSelectedEmployee = () => {
    if (selectedEmployee) {
      deleteEmployee(selectedEmployee.id);
    }
  };

  const handleActivities = (activity: IActivity) => {
    let activitiesState: number[] = [...allowedActivitiesIds];
    if (activity.isChecked) {
      activitiesState.push(activity.value);
      setActivities(activitiesState);
    } else {
      activitiesState.splice(activitiesState.findIndex((i) => i === activity.value), 1);
      setActivities(activitiesState);
    }
  };

  return (
    <>
      <Dialog open={showModal} onClose={handleClose} aria-labelledby="form-dialog-title">
        <ModalHeader
          id="form-dialog-title"
          title={
            isEditing && selectedEmployee ? `Edit ${selectedEmployee.fullname}` : 'Add New Employee'
          }
        />
        <DialogContent>
          <TextField
            margin="normal"
            id="fullname"
            label="Full Name"
            type="text"
            fullWidth
            variant="outlined"
            value={fullname}
            onChange={(e) => setFullName(e.target.value)}
            error={!fullname && error}
            helperText={!fullname && error && 'Please enter full name'}
          />
          <TextField
            margin="normal"
            id="username"
            label="Username"
            type="text"
            fullWidth
            variant="outlined"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            error={!username && error}
            helperText={!username && error && 'Please enter username'}
          />
          <TextField
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
            margin="normal"
            id="password"
            label={!isEditing ? 'Password' : 'New Password'}
            type="password"
            fullWidth
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!password && error}
            helperText={!password && error && 'Please enter password'}
          />
          <div className={classes.formControl}>
            <FormLabel component="legend" className={classes.formControlLabel}>
              ACTIVITIES
            </FormLabel>
            <FormGroup aria-label="position" row>
              {activitiesList.map((activity: IActivityModel, index: number) => (
                <FormControlLabel
                  key={index}
                  value={activity.id}
                  control={
                    <Checkbox
                      checked={!!allowedActivitiesIds.filter((i) => i === activity.id).length}
                      onChange={(e) =>
                        handleActivities({
                          isChecked: e.target.checked,
                          value: Number(e.target.value),
                        })
                      }
                    />
                  }
                  label={activity.translations['en']}
                  labelPlacement="end"
                  className={classes.activity}
                />
              ))}
              {error && !(allowedActivitiesIds as number[]).length && (
                <span className={classes.error}>Please select at least one activity!</span>
              )}
            </FormGroup>
            <FormLabel component="legend" className={classes.formControlLabel}>
              STATUS
            </FormLabel>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hasVanFull}
                    onChange={(e) => setHasVanFull(e.target.checked)}
                  />
                }
                label={'Has Van Full'}
                labelPlacement="end"
                className={classes.activity}
              />
            </FormGroup>
          </div>
        </DialogContent>
        <ModalFooter
          isLoading={isAdding}
          isEditing={isEditing}
          hasDelete
          onCancelClick={handleClose}
          onContinueClick={isEditing ? updateEmployee : addEmployee}
          onDeleteClick={() => setConfirmModal(true)}
        />
      </Dialog>
      {selectedEmployee && (
        <ConfirmationModal
          openModal={confirmModal}
          handleClose={() => setConfirmModal(false)}
          handleContinue={deleteSelectedEmployee}
          modalBody={
            <span>
              Do you want to remove <strong>{selectedEmployee.fullname}</strong>?
            </span>
          }
          isLoading={isDeletingEmployee}
        />
      )}
    </>
  );
};
