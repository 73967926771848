export default {
  root: {
    padding: '16px 24px',
  },
  action: {
    alignSelf: 'center',
    marginTop: 0,
    marginRight: 0,
  },
};
