import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { IRootState } from 'app/reducers/AppState';
import { PartsStock } from 'app/views';
import {
  fetchPartsStockList,
  fetchSparePartsList,
  fetchVehiclesTypesList,
  updateSpareStock,
} from 'app/actions';
import {
  ISparePartModel,
  IVehicleTypeListModel,
  IPartsStockModel,
  IUserDetail,
  IRegion,
} from 'app/models';

interface StateFromProps {
  partsStock: IPartsStockModel;
  sparePartsList: ISparePartModel[];
  vehicleTypesList: IVehicleTypeListModel[];
  userDetails: IUserDetail | null;
  selectedRegion: IRegion | null;
}

interface DispatchFromProps {
  fetchPartsStockList: () => void;
  fetchSparePartsList: () => void;
  fetchVehicleList: () => void;
  handleUpdateSpareStock: (id: string, amount: number) => void;
}

const mapStateToProps = (state: IRootState): StateFromProps => ({
  partsStock: state.partsStock,
  sparePartsList: state.spareParts.sparePartsList,
  vehicleTypesList: state.vehicleTypes.vehicleTypesList,
  userDetails: state.userDetails.userDetails,
  selectedRegion: state.userDetails.selectedRegion,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => ({
  fetchPartsStockList: bindActionCreators(fetchPartsStockList, dispatch),
  fetchSparePartsList: bindActionCreators(fetchSparePartsList, dispatch),
  fetchVehicleList: bindActionCreators(fetchVehiclesTypesList, dispatch),
  handleUpdateSpareStock: bindActionCreators(updateSpareStock, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PartsStock);
