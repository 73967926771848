import { ToastActionsType, ToastAction } from 'app/actions';
import { IToastStateModel, IToastModel } from 'app/models';

const DEFAULT_STATE: IToastStateModel = {
  toasts: [],
};

const toastReducer = (
  state: IToastStateModel = DEFAULT_STATE,
  action: ToastAction,
): IToastStateModel => {
  switch (action.type) {
    case ToastActionsType.HIDE_TOAST:
      return {
        ...state,
        toasts: state.toasts.filter(
          (toast: IToastModel) => toast.timestamp !== action.toast.timestamp,
        ),
      };
    case ToastActionsType.SHOW_TOAST:
      return { ...state, toasts: [...state.toasts, action.toast] };
    default:
      return state;
  }
};

export default toastReducer;
