import { IBaseModel } from './common';
import { CompanyRoleType } from './UsersManagementModel';

export interface IUserDetailsModel {
  userDetails: IUserDetail | null;
  isLoading: boolean;
  regions: IRegion[];
  selectedRegion: IRegion | null;
  selectedMaintainer: IMaintainer | null;
}

export interface IUserDetail extends IBaseModel {
  fullname: string;
  email: string;
  role?: IUserRole;
  companyRole: CompanyRoleType;
  active?: boolean;
  company?: ICompanyDetails;
  companyId?: string;
}

export interface ICompanyDetails {
  name: string;
  handle: string;
  id: string;
  logoUrl?: string;
  ownerId?: string;
  regions?: IRegion[];
  maintainers?: IMaintainer[];
  apiKey: string;
}

export interface IRegion {
  geonameId: number;
  key: string;
  name: string;
  type: string;
  id: string;
  country: string;
}

export interface IMaintainer {
  id: string;
  ownerId: string;
  name: string;
  handle: string;
  regionsIds: string[];
}

export enum IUserRole {
  Admin = 'admin',
  FleetOwner = 'owner',
  Maintainer = 'maintainer',
}

export interface IDropdownItem {
  regionId: string;
  maintainerId?: string;
  regionName: string;
  maintainerName?: string;
}
