import * as React from 'react';
import { IUserDetail, IRegion, IMaintainer, IToastStateModel, IToastModel } from 'app/models';
import { useStyles } from './AppLayoutStyle';
import { TopNavigation } from 'app/components/TopNavigation/TopNavigation';
import LeftNavigation from 'app/components/LeftNavigation/LeftNavigation';
import { ServeedoToast, Loader } from 'app/components/common';
import useRouter from 'app/helpers/use-router';

export interface IProps {
  user: IUserDetail | null;
  fetchLocalStorage: () => void;
  fetchUserDetails: () => void;
  regions: IRegion[];
  onChangeRegion: (region: IRegion, maintainer?: IMaintainer) => void;
  selectedRegion: IRegion | null;
  isLoading: boolean;
  selectedMaintainer: IMaintainer | null;
  toasts: IToastStateModel;
  hideToast: (toast: IToastModel) => void;
}

const AppLayout: React.SFC<IProps> = ({
  user,
  fetchUserDetails,
  regions,
  onChangeRegion,
  selectedRegion,
  isLoading,
  selectedMaintainer,
  toasts,
  hideToast,
  children,
}) => {
  const classes = useStyles();

  const [loading, setLoading] = React.useState(true)

  const maintainers = user && user.company ? user.company.maintainers : null

  const router = useRouter();
  

  const [mobileOpen, setMobileOpen] = React.useState(false);

  React.useEffect(() => {
    if (!user) {
      fetchUserDetails();
    }
  }, [user, fetchUserDetails]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const token = localStorage.getItem('jwtToken');
  const loadingUser = token !== null && user === null;

  React.useEffect(() => {
    if(!loadingUser) {
      if ((router.match.params as any).selectedLocation && !loadingUser) {
        const region = regions.find((r) => r.key === (router.match.params as any).selectedLocation);
        if (region) {
          const maintainer: IMaintainer | undefined = maintainers
            ? maintainers!.find((m) => m.regionsIds.find((r) => r === region.id))
            : undefined;
          onChangeRegion(region, maintainer);
        }
      }
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingUser]);



  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <TopNavigation className={classes.topBar} handleDrawerToggle={handleDrawerToggle} />
        {!loading ? (
          <React.Fragment>
            <LeftNavigation
              className={classes.navBar}
              user={user}
              mobileOpen={mobileOpen}
              handleDrawerToggle={handleDrawerToggle}
              regions={regions}
              onChangeRegion={onChangeRegion}
              selectedRegion={selectedRegion}
              isLoading={isLoading}
              selectedMaintainer={selectedMaintainer}
            />
            <main className={classes.content}>
              <div className={classes.contentChildren}>{children}</div>
            </main>
          </React.Fragment>
        ) : <Loader/>}
      </div>
      <ServeedoToast toasts={toasts} hideToast={hideToast} />
    </div>
  );
};

export default AppLayout;
