import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.common.white,
  },
  logoutButton: {
    position: 'relative',
    fontWeight: 500,
    fontSize: 20,
    textTransform: 'none',
  },
  logo: {
    marginRight: theme.spacing(1),
  },
  menuButton: {
    marginRight: 0,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
      marginRight: theme.spacing(2),
    },
  },
}));
