import * as React from 'react';
import { Button } from '@material-ui/core';

interface IProps {
  buttonIcon?: any;
  buttonText: string;
  onClick?: () => void;
  disabled?: boolean;
}

export const ServeedoButton: React.SFC<IProps> = ({
  buttonIcon,
  buttonText,
  onClick,
  disabled,
}) => {
  return (
    <Button variant="contained" onClick={onClick} color={'primary'} disabled={disabled}>
      {buttonIcon} {buttonText}
    </Button>
  );
};
