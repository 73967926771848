import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { AddVehicle } from 'app/components/AddVehicle/AddVehicle';
import { IRootState } from 'app/reducers/AppState';
import {
  IVehicleTypesModel,
  ICheckListItemModel,
  IAddVehicleTypeModel,
  ISparePartModel,
} from 'app/models';
import {
  updateCheckListItem,
  revertAddVehicleType,
  addNewVehicleType,
  updateVehicle,
  removeCheckListItem,
  deleteVehicleType,
  fetchVehicleType,
  fetchSparePartsList,
} from 'app/actions';

interface StateFromProps {
  vehicleTypes: IVehicleTypesModel;
  sparePartsList: ISparePartModel[];
}

interface DispatchFromProps {
  fetchVehicleType: (id: string) => void;
  addCheckListItems: (checkListItems: ICheckListItemModel[]) => void;
  cancelAddOrEditVehicle: () => void;
  addNewVehicle: (vehicle: IAddVehicleTypeModel, photo: File | null, diagram: File | null) => void;
  handleUpdateVehicle: (
    vehicle: IAddVehicleTypeModel,
    photo: File | null,
    diagram: File | null,
  ) => void;
  removeCheckListItem: (id: string) => void;
  deleteVehicleType: () => void;
  fetchSpareParts: () => void;
}

const mapStateToProps = (state: IRootState): StateFromProps => ({
  vehicleTypes: state.vehicleTypes,
  sparePartsList: state.spareParts.sparePartsList,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => ({
  fetchVehicleType: bindActionCreators(fetchVehicleType, dispatch),
  addCheckListItems: bindActionCreators(updateCheckListItem, dispatch),
  cancelAddOrEditVehicle: bindActionCreators(revertAddVehicleType, dispatch),
  addNewVehicle: bindActionCreators(addNewVehicleType, dispatch),
  handleUpdateVehicle: bindActionCreators(updateVehicle, dispatch),
  removeCheckListItem: bindActionCreators(removeCheckListItem, dispatch),
  deleteVehicleType: bindActionCreators(deleteVehicleType, dispatch),
  fetchSpareParts: bindActionCreators(fetchSparePartsList, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddVehicle);
