import * as React from 'react';
import clsx from 'clsx';
import { TextField, Fab } from '@material-ui/core';

import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';

import { useStyles } from './ServeedoSearchStyles';

interface IProps {
  searchFind?: (searchVal: string) => void;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  defaultValue?: string;
}

export const ServeedoSearch: React.SFC<IProps> = ({
  defaultValue,
  searchFind,
  className,
  disabled,
  placeholder,
}) => {
  let searchTimeOut: any;

  const classes = useStyles();

  const [searchValue, setSearchValue] = React.useState<string>('');

  React.useEffect(() => {
    if (defaultValue) {
      setSearchValue(defaultValue);
    }
  }, [defaultValue]);

  React.useEffect(() => {
    return () => {
      clearTimeout(searchTimeOut);
    };
  }, [searchTimeOut]);

  const updateValue = (e: any): void => {
    e.preventDefault();
    const value = e.target.value;
    setSearchValue(value);

    clearTimeout(searchTimeOut);
    searchTimeOut = setTimeout(() => {
      if (searchFind) {
        searchFind(value);
      }
    }, 400);
  };

  const clearSearch = () => {
    setSearchValue('');
    clearTimeout(searchTimeOut);
    if (searchFind) {
      searchFind('');
    }
  };

  return (
    <div className={clsx(classes.root, className)}>
      <SearchOutlinedIcon className={classes.searchIcon} fontSize={'small'} />
      <TextField
        disabled={disabled}
        id="standard-search"
        placeholder={placeholder || 'Search'}
        type="text"
        value={searchValue}
        onChange={updateValue}
        className={classes.searchField}
      />
      <Fab
        size="small"
        aria-label="add"
        onClick={clearSearch}
        className={clsx(classes.clearSearchIcon, { [classes.disabled]: !searchValue })}
        disabled={!searchValue}
      >
        <ClearOutlinedIcon fontSize={'small'} />
      </Fab>
    </div>
  );
};
