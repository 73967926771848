import * as React from 'react';

import {
  Card,
  CardHeader,
  CardContent,
  Paper,
  Typography,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

import { useStyles } from './APIKeysStyles';
import { IUserDetail } from 'app/models';
import { NoData } from 'app/components/common';

interface IProps {
  userDetails: IUserDetail | null;
}

export const APIKeys: React.SFC<IProps> = ({ userDetails }) => {
  const classes = useStyles();

  const copyToClipBoard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <Card style={{ marginTop: 24 }}>
      <CardHeader title={'API Key'} />
      <CardContent className={classes.cardContent}>
        {userDetails && userDetails.company ? (
          [userDetails.company.apiKey].map((item, index) => {
            return (
              <Paper className={classes.listItem} key={`item-${index}`}>
                <Typography>{item}</Typography>
                <Tooltip title="Copy to clipboard" placement="top">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    onClick={() => copyToClipBoard(item)}
                  >
                    <FileCopyOutlinedIcon fontSize="small" color="action" />
                  </IconButton>
                </Tooltip>
              </Paper>
            );
          })
        ) : (
          <Paper>
            <NoData message={'No API Key Found'} />
          </Paper>
        )}
      </CardContent>
    </Card>
  );
};
