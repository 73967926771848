import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { Login } from 'app/views';
import { IRootState } from 'app/reducers/AppState';
import { userLogin, hideToast } from 'app/actions';
import { ILoginCredentials, ILoginModel, IToastStateModel, IToastModel } from 'app/models';

interface StateFromProps {
  loginData: ILoginModel;
  toasts: IToastStateModel;
}

interface DispatchFromProps {
  login: (loginDetails: ILoginCredentials) => void;
  hideToast: (toast: IToastModel) => void;
}

const mapStateToProps = (state: IRootState): StateFromProps => ({
  loginData: state.login,
  toasts: state.toasts,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => ({
  login: bindActionCreators(userLogin, dispatch),
  hideToast: bindActionCreators(hideToast, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
