import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import AddIcon from '@material-ui/icons/Add';

import { VehicleTypesList } from 'app/components/VehicleTypesList/VehicleTypesList';
import { WidgetHeader } from 'app/components/WidgetHeader/WidgetHeader';
import { ServeedoButton } from 'app/components/common';
import { IVehicleTypesModel, IVehicleTypeListModel, ISupportedRoutes } from 'app/models';

export interface IProps extends RouteComponentProps<any> {
  vehicleTypes: IVehicleTypesModel;
  fetchVehicleTypesList: () => void;
  handleEditVehicleType: (vehicle: IVehicleTypeListModel) => void;
  cancelAddOrEditVehicleType: () => void;
}
export interface IState {}

class VehicleTypes extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {
      vehicleTypes: { selectedVehicleType },
      cancelAddOrEditVehicleType,
      fetchVehicleTypesList,
    } = this.props;
    if (selectedVehicleType) {
      cancelAddOrEditVehicleType();
    }
    fetchVehicleTypesList();
  }

  renderButton = (): JSX.Element => {
    return (
      <ServeedoButton
        buttonText={'New Type'}
        buttonIcon={<AddIcon />}
        onClick={() => this.props.history.push(ISupportedRoutes.NEW_VEHICLE_TYPE)}
        disabled={this.props.vehicleTypes.isLoading}
      />
    );
  };

  handleEditVehicleType = (vehicleType: IVehicleTypeListModel) => {
    const { handleEditVehicleType } = this.props;
    handleEditVehicleType(vehicleType);
  };

  render(): JSX.Element {
    const {
      vehicleTypes: { vehicleTypesList, isLoading },
    } = this.props;
    return (
      <div className={'container'}>
        <WidgetHeader
          headerText={'Vehicle Types'}
          headerSmallText={'GENERAL'}
          rightPanel={this.renderButton()}
        />
        <VehicleTypesList
          vehicleTypesList={vehicleTypesList}
          handleEditVehicleType={this.handleEditVehicleType}
          isLoading={isLoading}
        />
      </div>
    );
  }
}

export default VehicleTypes;
