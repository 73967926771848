import * as React from 'react';
import { Button, Typography } from '@material-ui/core';

import { AddVehicleTypeForm } from './AddVehicleTypeForm/AddVehicleTypeForm';
import CircularProgress from '@material-ui/core/CircularProgress';

import { WidgetHeader } from 'app/components/WidgetHeader/WidgetHeader';
import {
  ICheckListItemModel,
  IAddVehicleTypeModel,
  IVehicleTypesModel,
  ISupportedRoutes,
  ISparePartModel,
} from 'app/models';
import { ServicesCheckList } from './ServicesCheckList/ServicesCheckList';
import { AddNewCheckListItemModal } from 'app/components/Modals/AddNewCheckListItemModal/AddNewCheckListItemModal';

import { RouteComponentProps } from 'react-router';
import { ConfirmationModal } from '../Modals/ConfirmationModal/ConfirmationModal';

// eslint-disable-next-line

export interface IProps extends RouteComponentProps<any> {
  vehicleTypes: IVehicleTypesModel;
  sparePartsList: ISparePartModel[];
  fetchVehicleType: (id: string) => void;
  addCheckListItems: (checkListItems: ICheckListItemModel[]) => void;
  cancelAddOrEditVehicle: () => void;
  addNewVehicle: (vehicle: IAddVehicleTypeModel, photo: File | null, diagram: File | null) => void;
  handleUpdateVehicle: (
    vehicle: IAddVehicleTypeModel,
    photo: File | null,
    diagram: File | null,
  ) => void;
  removeCheckListItem: (id: string) => void;
  deleteVehicleType: () => void;
  fetchSpareParts: () => void;
}

export interface IState {
  openModal: boolean;
  vehicleType: IAddVehicleTypeModel;
  vehicleTypeError: boolean | undefined;
  selectedCheckListItem: ICheckListItemModel | null;
  isEditingCheckListItem: boolean;
  confirmModal: boolean;
  photo: File | null;
  diagram: File | null;
}

export class AddVehicle extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      openModal: false,
      vehicleType: {
        name: '',
        description: '',
        hasSwappableBattery: false,
        imageUrl: null,
        diagramImageUrl: null,
      },
      vehicleTypeError: undefined,
      selectedCheckListItem: null,
      isEditingCheckListItem: false,
      confirmModal: false,
      photo: null,
      diagram: null,
    };
  }

  componentDidMount() {
    const {
      vehicleTypes: { selectedVehicleType },
      match: { params },
      fetchVehicleType,
      fetchSpareParts,
    } = this.props;
    fetchSpareParts();
    if (!selectedVehicleType && params.id) {
      fetchVehicleType(params.id);
    }
    if (selectedVehicleType) {
      this.setState({
        vehicleType: {
          name: selectedVehicleType.name,
          description: selectedVehicleType.description,
          hasSwappableBattery: selectedVehicleType.hasSwappableBattery,
          imageUrl: selectedVehicleType.imageUrl,
          diagramImageUrl: selectedVehicleType.diagramImageUrl,
        },
      });
    }
  }

  componentDidUpdate(prevProps: IProps) {
    const {
      vehicleTypes: { isDeleting, selectedVehicleType },
    } = this.props;
    if (prevProps.vehicleTypes.isDeleting !== isDeleting && !isDeleting) {
      this.setState({
        confirmModal: false,
      });
    }
    if (prevProps.vehicleTypes.selectedVehicleType !== selectedVehicleType && selectedVehicleType) {
      if (selectedVehicleType) {
        this.setState({
          vehicleType: {
            name: selectedVehicleType.name,
            description: selectedVehicleType.description,
            hasSwappableBattery: selectedVehicleType.hasSwappableBattery,
            imageUrl: selectedVehicleType.imageUrl,
            diagramImageUrl: selectedVehicleType.diagramImageUrl,
          },
        });
      }
    }
  }

  closeModal = () => {
    this.setState({
      openModal: false,
      isEditingCheckListItem: false,
      selectedCheckListItem: null,
    });
  };

  handleUpdateOrAddVehicle = () => {
    if (!this.validation()) return;
    const { vehicleType, photo, diagram } = this.state;
    const {
      vehicleTypes: { isEditing },
      handleUpdateVehicle,
      addNewVehicle,
    } = this.props;
    if (isEditing) {
      handleUpdateVehicle(vehicleType, photo, diagram);
      return;
    }
    addNewVehicle(vehicleType, photo, diagram);
  };

  gotoVehicleTypes = () => {
    const { history, cancelAddOrEditVehicle } = this.props;
    history.push(ISupportedRoutes.VEHICLES_TYPES);
    cancelAddOrEditVehicle();
  };

  validation = (): boolean => {
    const {
      vehicleType: { name, description },
    } = this.state;
    let validation = true;
    if (!name || !description) {
      validation = false;
    }
    this.setState({
      vehicleTypeError: true,
    });

    return validation;
  };

  editCheckListItem = (item: ICheckListItemModel) => {
    this.setState({
      openModal: true,
      selectedCheckListItem: item,
      isEditingCheckListItem: true,
    });
  };

  renderRightPanel = (): JSX.Element => {
    const {
      vehicleTypes: { isEditing, isAdding },
    } = this.props;
    return (
      <div>
        {/* {isEditing && (
          <Button
            onClick={() => this.setState({ confirmModal: true })}
            color="secondary"
            disabled={isAdding}
            style={{ color: 'rgb(220, 0, 78)', marginRight: 5 }}
          >
            DELETE
          </Button>
        )} */}
        <Button
          variant="contained"
          size="medium"
          onClick={this.gotoVehicleTypes}
          disabled={isAdding}
          style={{ marginRight: 5 }}
        >
          CANCEL
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={this.handleUpdateOrAddVehicle}
          disabled={isAdding}
        >
          {isAdding ? (isEditing ? 'Updating...' : 'Saving...') : isEditing ? 'Update' : 'Save'}
          {''}
          {isAdding && <CircularProgress disableShrink size={18} thickness={4} />}
        </Button>
      </div>
    );
  };

  handleChange = (vehicleType: IAddVehicleTypeModel) => {
    this.setState({
      vehicleType,
    });
  };

  render(): JSX.Element {
    const {
      vehicleTypes: { selectedVehicleType, isEditing, checklist, isDeleting, isLoading },
      sparePartsList,
      addCheckListItems,
      removeCheckListItem,
      deleteVehicleType,
    } = this.props;
    const {
      vehicleType,
      vehicleTypeError,
      openModal,
      selectedCheckListItem,
      isEditingCheckListItem,
      confirmModal,
    } = this.state;

    const sparePartsOptions =
      isEditing && selectedVehicleType
        ? sparePartsList.filter((i) => i.vehicleTypeId === selectedVehicleType.id)
        : [];

    return (
      <div className={'container'}>
        <div className="reduced-width">
          {isLoading ? (
            <div
              style={{
                minHeight: '50vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <CircularProgress disableShrink size={40} thickness={4} />
              <Typography>Loading...</Typography>
            </div>
          ) : (
            <>
              <WidgetHeader
                headerText={
                  isEditing && selectedVehicleType
                    ? `Edit ${selectedVehicleType.name}`
                    : 'Add New Vehicle Type'
                }
                headerSmallText={'VEHICLE TYPES'}
                rightPanel={this.renderRightPanel()}
              />
              <AddVehicleTypeForm
                handleChange={this.handleChange}
                vehicleType={vehicleType}
                vehicleTypeError={vehicleTypeError}
                handleChangePhoto={(photo) => {
                  this.setState({
                    photo,
                  });
                }}
                handleChangeDiagrams={(diagram) => {
                  this.setState({
                    diagram,
                  });
                }}
              />
              <ServicesCheckList
                handleOpenModal={() => this.setState({ openModal: true })}
                checkListItems={checklist}
                handleChangeListOrder={addCheckListItems}
                removeCheckListItem={removeCheckListItem}
                editCheckListItem={this.editCheckListItem}
              />
              <AddNewCheckListItemModal
                showModal={openModal}
                handleClose={this.closeModal}
                checkListItems={checklist}
                addCheckListItem={addCheckListItems}
                selectedCheckListItem={selectedCheckListItem}
                isEditing={isEditingCheckListItem}
                sparePartsList={sparePartsOptions}
              />
              {selectedVehicleType && (
                <ConfirmationModal
                  openModal={confirmModal}
                  handleClose={() =>
                    this.setState({
                      confirmModal: false,
                    })
                  }
                  handleContinue={deleteVehicleType}
                  modalTitle={`Delete ${selectedVehicleType.name}?`}
                  modalBody={
                    <span>
                      Deleting this will delete also all the related <strong>System Errors</strong>,{' '}
                      <strong>Repair Guides</strong> and <strong>Spare Parts</strong>.
                      <br />
                      Are you sure you want to delete it? This operation cannot be undone.
                    </span>
                  }
                  isLoading={isDeleting}
                />
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}
