import { handleActions, Reducer } from 'redux-actions';
import { IRootState } from 'app/reducers/AppState';
import { ISettingsActionsType } from 'app/actions';
import { ISettingsModel } from 'app/models';

const initialState: ISettingsModel = {
  wunderApiKey: '',
  wunderApiUrl: '',
  isSavingIntegrationInfo: false,
  localVehicleTypes: [],
  remoteVehicleTypes: [],
  localVehicleStates: [],
  remoteVehicleStates: [],
  remoteRegions: [],
  integrationHistory: [],
  autoLockingRules: [],
  isProcessingModal: false,
};

// tslint:disable-next-line:typedef
export const settingsReducer: Reducer<IRootState.SettingsState, ISettingsModel> = handleActions<
  IRootState.SettingsState,
  ISettingsModel
>(
  {
    [ISettingsActionsType.REQUEST_INTEGRATION_INFO]: (
      state = initialState,
    ): IRootState.SettingsState => ({
      ...state,
      accountError: undefined,
    }),
    [ISettingsActionsType.RECEIVE_INTEGRATION_INFO]: (
      state = initialState,
      action,
    ): IRootState.SettingsState => ({
      ...state,
      wunderApiUrl: (action.payload as any).url,
      wunderApiKey: (action.payload as any).apiKey,
    }),

    [ISettingsActionsType.REQUEST_SAVE_INTEGRATION_INFO]: (
      state = initialState,
    ): IRootState.SettingsState => ({
      ...state,
      isSavingIntegrationInfo: true,
      accountError: undefined,
    }),
    [ISettingsActionsType.RECEIVE_SAVE_INTEGRATION_INFO]: (
      state = initialState,
    ): IRootState.SettingsState => ({
      ...state,
      isSavingIntegrationInfo: false,
    }),
    [ISettingsActionsType.FAILURE_SAVE_INTEGRATION_INFO]: (
      state = initialState,
      action,
    ): IRootState.SettingsState => ({
      ...state,
      isSavingIntegrationInfo: false,
      accountError: action.payload.accountError,
    }),

    [ISettingsActionsType.RECEIVE_INTEGRATION_LOCAL_VEHICLE_TYPES]: (
      state = initialState,
      action,
    ): IRootState.SettingsState => ({
      ...state,
      localVehicleTypes: action.payload.localVehicleTypes,
    }),
    [ISettingsActionsType.RECEIVE_INTEGRATION_VEHICLE_TYPES]: (
      state = initialState,
      action,
    ): IRootState.SettingsState => ({
      ...state,
      remoteVehicleTypes: action.payload.remoteVehicleTypes,
    }),

    [ISettingsActionsType.RECEIVE_INTEGRATION_LOCAL_VEHICLE_STATES]: (
      state = initialState,
      action,
    ): IRootState.SettingsState => ({
      ...state,
      localVehicleStates: action.payload.localVehicleStates,
    }),
    [ISettingsActionsType.RECEIVE_INTEGRATION_VEHICLE_STATES]: (
      state = initialState,
      action,
    ): IRootState.SettingsState => ({
      ...state,
      remoteVehicleStates: action.payload.remoteVehicleStates,
    }),

    [ISettingsActionsType.RECEIVE_INTEGRATION_REGIONS]: (
      state = initialState,
      action,
    ): IRootState.SettingsState => ({
      ...state,
      remoteRegions: action.payload.remoteRegions,
    }),

    [ISettingsActionsType.RECEIVE_INTEGRATION_HISTORY]: (
      state = initialState,
      action,
    ): IRootState.SettingsState => ({
      ...state,
      integrationHistory: action.payload.integrationHistory,
    }),

    [ISettingsActionsType.RECEIVE_AUTOLOCKING_RULES]: (
      state = initialState,
      action,
    ): IRootState.SettingsState => ({
      ...state,
      autoLockingRules: action.payload.autoLockingRules,
    }),

    [ISettingsActionsType.REQUEST_CHANGE_AUTOLOCKING_RULE]: (
      state = initialState,
      action,
    ): IRootState.SettingsState => {
      const { stateId, locked } = (action as any).payload;
      state.autoLockingRules.forEach((rule) => {
        if (rule.id === stateId) {
          rule.locked = locked;
        }
      });
      return {
        ...state,
      };
    },

    [ISettingsActionsType.REQUEST_SAVE_MAPPING]: (
      state = initialState,
    ): IRootState.SettingsState => {
      return {
        ...state,
        isProcessingModal: true,
        modalError: undefined,
      };
    },
    [ISettingsActionsType.RECEIVE_SAVE_MAPPING]: (
      state = initialState,
    ): IRootState.SettingsState => {
      return {
        ...state,
        isProcessingModal: false,
      };
    },
    [ISettingsActionsType.FAILURE_SAVE_MAPPING]: (
      state = initialState,
      action,
    ): IRootState.SettingsState => {
      return {
        ...state,
        modalError: action.payload.modalError,
      };
    },

    [ISettingsActionsType.REQUEST_DELETE_MAPPING]: (
      state = initialState,
    ): IRootState.SettingsState => {
      return {
        ...state,
        isProcessingModal: true,
        modalError: undefined,
      };
    },
    [ISettingsActionsType.RECEIVE_DELETE_MAPPING]: (
      state = initialState,
    ): IRootState.SettingsState => {
      return {
        ...state,
        isProcessingModal: false,
      };
    },
    [ISettingsActionsType.FAILURE_DELETE_MAPPING]: (
      state = initialState,
    ): IRootState.SettingsState => {
      return {
        ...state,
        isProcessingModal: false,
      };
    },

    [ISettingsActionsType.RESET_INTEGRATION_ERRORS]: (
      state = initialState,
    ): IRootState.SettingsState => {
      return {
        ...state,
        modalError: undefined,
      };
    },
  },
  initialState,
);
