import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Dialog } from '@material-ui/core';
import { Loader } from '../Loader/Loader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      height: '80vh',
      minWidth: 300,
      maxWidth: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    photo: {
      height: '100%',
      width: 'auto',
    },
  }),
);

interface IProps {
  modalOpen: boolean;
  photoUrl: string;
  closeModal?: () => void;
}

export const ServeedoPhotoViewer: React.FC<IProps> = ({ modalOpen, photoUrl, closeModal }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [photo, setPhoto] = useState<string>('');

  useEffect(() => {
    setOpen(modalOpen);
    if (modalOpen) {
      setPhoto(photoUrl);
    }
  }, [modalOpen, photoUrl]);

  useEffect(() => {
    if (photo) {
      preloadImage(photo);
    }
  }, [photo]);

  const preloadImage = (imgSrc: string) => {
    var objImagePreloader = new Image();

    objImagePreloader.src = imgSrc;
    setIsLoading(true);
    objImagePreloader.onload = function() {
      setIsLoading(false);
    };
  };

  const handleClose = () => {
    if (closeModal) {
      closeModal();
    } else {
      setOpen(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth={false}>
      <div className={classes.paper}>
        {isLoading ? <Loader /> : <img className={classes.photo} src={photo} alt="proof-img" />}
      </div>
    </Dialog>
  );
};
