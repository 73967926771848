import { IUserDetail } from './UserDetails';

export interface IUsersManagementModel {
  usersList: IUserDetail[];
  selectedUser: IUserDetail | null;
  isLoading: boolean;
  isProcessing: boolean;
  isDeleting: boolean;
}

export enum CompanyRoleType {
  Administrator = 'Administrator',
  Collaborator = 'Collaborator',
}
