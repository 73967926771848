import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { AppLayout } from 'app/views';
import { fetchUserDetails, updateRegion, hideToast } from 'app/actions';
import { IUserDetail, IRegion, IMaintainer, IToastModel, IToastStateModel } from 'app/models';
import { IRootState } from 'app/reducers/AppState';

interface StateFromProps {
  user: IUserDetail | null;
  isLoading: boolean;
  regions: IRegion[];
  selectedRegion: IRegion | null;
  selectedMaintainer: IMaintainer | null;
  toasts: IToastStateModel;
}

interface DispatchFromProps {
  fetchUserDetails: () => void;
  onChangeRegion: (region: IRegion, maintainer?: IMaintainer) => void;
  hideToast: (toast: IToastModel) => void;
}

const mapStateToProps = (state: IRootState): StateFromProps => ({
  user: state.userDetails.userDetails,
  isLoading: state.userDetails.isLoading,
  regions: state.userDetails.regions,
  selectedRegion: state.userDetails.selectedRegion,
  selectedMaintainer: state.userDetails.selectedMaintainer,
  toasts: state.toasts,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => ({
  fetchUserDetails: bindActionCreators(fetchUserDetails, dispatch),
  onChangeRegion: bindActionCreators(updateRegion, dispatch),
  hideToast: bindActionCreators(hideToast, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppLayout);
