import * as React from 'react';
import { WidgetHeader } from 'app/components/WidgetHeader/WidgetHeader';
import { APIKeys, Endpoints } from 'app/components/Development';
import { IUserDetail } from 'app/models';

export interface IProps {
  userDetails: IUserDetail | null;
}

export interface IState {}

class Development extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    return (
      <div className={'container'}>
        <div className="medium-width">
          <WidgetHeader headerText={'Development'} headerSmallText={'ACCOUNT'} />
          <APIKeys userDetails={this.props.userDetails} />
          <Endpoints userDetails={this.props.userDetails} />
        </div>
      </div>
    );
  }
}

export default Development;
