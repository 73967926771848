import { IBaseModel, ILocation } from './common';
import moment from 'moment';
import { IHotspotModel } from './HotspotsModel';
import { IEmployeeModel } from './EmployeesModel';
import { IVehicleCountModel } from '.';
import { IPagination } from './ResponseHandlerModel';
import { FilterMenuItem } from 'app/components/common';

export interface IOnroadTasksModel {
  onRoadTasksList: IOnroadTasksListModel;
  isLoading: boolean;
  onRoadTaskStatus: IOnroadTaskStatusModel;
  isUpdatingTasks: boolean;
  onRoadTasksFilter: IOnroadTasksFilter;
}

export interface IOnroadTasksListModel {
  onRoadTasksList: IOnroadTaskModel[];
  pagination: IPagination | null;
}

export interface IOnroadTasksFilter {
  perPage: number;
  page: number;
  type: FilterMenuItem;
  status: FilterMenuItem;
}

export interface IOnroadTaskModel extends IBaseModel {
  completeVehicleStateId: number;
  employeeId: string;
  employee?: IEmployeeModel;
  location: ILocation | IHotspotModel;
  needsProof: boolean;
  ownerCompanyId: string;
  initialQuantity?: number;
  quantity: number;
  vehicleIds?: number[];
  regionId: string;
  startedAt: moment.Moment;
  status: OnroadTaskStatus;
  type: OnroadTaskType;
  comment?: string;
  proofPhotoUrl?: string;
  vehicleTypeId?: string;
}

export enum OnroadTaskType {
  PickUp = 'pickup',
  PickupNotFound = 'pickup-not-found',
  Drop = 'drop',
  SwapBattery = 'swap-battery',
  Relocate = 'relocate', // only for unassigned tasks
  DropToWarehouse = 'drop-to-warehouse',
}

export enum OnroadTaskStatus {
  NotStarted = 'not-started',
  InProgress = 'in-progress',
  Completed = 'completed',
  Cancelled = 'cancelled',
  PartiallyCancelled = 'partially-cancelled',
  NeedsProof = 'needs-proof',
  UnderAssignment = 'under-assignment',
}

export interface IOnroadTaskStatusModel {
  [key: string]: IVehicleCountModel[];
}
