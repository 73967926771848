import { Store, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'react-router-redux';
import { History } from 'history';
import { logger } from 'app/middleware';
import { rootReducer } from 'app/reducers';
import { IRootState } from 'app/reducers/AppState';

const constructStore = (initialState: IRootState | undefined, middleware: any) =>
  createStore(rootReducer as any, initialState as any, middleware) as Store<IRootState>;

function constructMiddleware(history: History) {
  const middlewares: any[] = [routerMiddleware(history), thunk];
  middlewares.push(logger);
  let middleware: any = applyMiddleware(...middlewares);
  middleware = composeWithDevTools(middleware);
  return middleware;
}

export function configureStore(history: History, initialState?: IRootState): Store<IRootState> {
  const middleware = constructMiddleware(history);
  const store = constructStore(initialState, middleware);
  return store;
}
