import { Overrides } from '@material-ui/core/styles/overrides';
import MuiButton from './MuiButton';
import MuiCardActions from './MuiCardActions';
import MuiCardContent from './MuiCardContent';
import MuiCardHeader from './MuiCardHeader';
import MuiChip from './MuiChip';
import MuiIconButton from './MuiIconButton';
import MuiInputBase from './MuiInputBase';
import MuiLinearProgress from './MuiLinearProgress';
import MuiListItemIcon from './MuiListItemIcon';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiPaper from './MuiPaper';
import MuiTable from './MuiTable';
import MuiTableHead from './MuiTableHead';
import MuiTableCell from './MuiTableCell';
import MuiTableRow from './MuiTableRow';
import MuiTableSortLabel from './MuiTableSortLabel';
import MuiToggleButton from './MuiToggleButton';
import MuiTypography from './MuiTypography';
import MuiCheckbox from './MuiCheckbox';
import MuiFormLabel from './MuiFormLabel';
import MuiCircularProgress from './MuiCircularProgress';
import MuiInput from './MuiInput';
import MuiDialog from './MuiDialog';
import MuiAutocomplete from './MuiAutocomplete';
import MuiDialogActions from './MuiDialogActions';
import MuiDialogTitle from './MuiDialogTitle';
import MuiFormControl from './MuiFormControl';
import MuiSelect from './MuiSelect';
import MuiInputLabel from './MuiInputLabel';
import MuiMenu from './MuiMenu';

export default {
  MuiButton,
  MuiCardActions,
  MuiCardContent,
  MuiCardHeader,
  MuiChip,
  MuiIconButton,
  MuiInputBase,
  MuiLinearProgress,
  MuiListItemIcon,
  MuiOutlinedInput,
  MuiPaper,
  MuiTable,
  MuiTableHead,
  MuiTableCell,
  MuiTableRow,
  MuiTableSortLabel,
  MuiToggleButton,
  MuiTypography,
  MuiCheckbox,
  MuiFormLabel,
  MuiCircularProgress,
  MuiInput,
  MuiDialog,
  MuiAutocomplete,
  MuiDialogActions,
  MuiDialogTitle,
  MuiFormControl,
  MuiSelect,
  MuiInputLabel,
  MuiMenu,
} as Overrides;
