import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 15,
  },
  formControl: {
    flex: 2,
    marginTop: 16,
    marginBottom: 8,
  },
  textField: {
    flex: 5,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  textFieldWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  deleteButton: {
    height: 40,
    width: 40,
    borderRadius: 100,
    minWidth: 'unset !important',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginTop: 7,
    marginLeft: 5,
  },
  title: {
    padding: '25px 0',
    textAlign: 'center',
  },
  titleText: {
    fontSize: 18,
    fontWeight: 600,
  },
  footer: {
    backgroundColor: '#FAFAFA',
    padding: '20px 12px',
  },
  select: {
    '& .MuiSelect-select:focus': {
      backgroundColor: '#fff !important',
    },
    '& .label': {
      color: '#fff !important',
    },
  },
  label: {
    backgroundColor: '#fff !important',
    padding: '0 2px',
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dltButton: {
    color: 'rgb(220, 0, 78)',
  },
  chip: {
    height: 24,
    marginRight: 4,
  },
  autoComplete: {
    width: '100%',
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
}));
