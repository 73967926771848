import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { IRootState } from 'app/reducers/AppState';
import { AddNewRepairGuide } from 'app/components/AddNewRepairGuide/AddNewRepairGuide';
import {
  IVehicleTypeListModel,
  IAddRepairGuideModel,
  IRepairGuidesModel,
  ISparePartModel,
} from 'app/models';
import {
  fetchVehiclesTypesList,
  addNewRepairGuide,
  fetchRepairGuide,
  deleteRepairGuide,
  fetchSparePartsList,
} from 'app/actions';

interface StateFromProps {
  repairGuides: IRepairGuidesModel;
  vehicleTypesList: IVehicleTypeListModel[];
  sparePartsList: ISparePartModel[];
}

interface DispatchFromProps {
  fetchVehicleTypes: () => void;
  fetchSparePartsList: () => void;
  addNewRepairGuide: (repairGuide: IAddRepairGuideModel) => void;
  fetchRepairGuide: (id: string) => void;
  deleteRepairGuide: () => void;
}

const mapStateToProps = (state: IRootState): StateFromProps => ({
  vehicleTypesList: state.vehicleTypes.vehicleTypesList,
  repairGuides: state.repairGuides,
  sparePartsList: state.spareParts.sparePartsList,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => ({
  fetchVehicleTypes: bindActionCreators(fetchVehiclesTypesList, dispatch),
  fetchSparePartsList: bindActionCreators(fetchSparePartsList, dispatch),
  addNewRepairGuide: bindActionCreators(addNewRepairGuide, dispatch),
  fetchRepairGuide: bindActionCreators(fetchRepairGuide, dispatch),
  deleteRepairGuide: bindActionCreators(deleteRepairGuide, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddNewRepairGuide);
