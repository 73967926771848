import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  formRow: {
    display: 'flex',
    width: '100%',
  },
  formControl: {
    flex: 2,
    marginTop: 16,
    marginBottom: 8,
    marginRight: theme.spacing(1),
  },
  textField: {
    flex: 5,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  textFieldWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  deleteButton: {
    height: 40,
    width: 40,
    borderRadius: 100,
    minWidth: 'unset !important',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginTop: 7,
    marginLeft: 5,
  },
}));
