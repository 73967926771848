import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core';
import { VehicleStateType, IFleetStateModel } from 'app/models';
import { dynamicSort } from 'app/helpers/common';
import { ModalHeader } from '../ModalHeader/ModalHeader';
import { ModalFooter } from '../ModalFooter/ModalFooter';

export interface IProps {
  openModal: boolean;
  title: string;
  value: VehicleStateType;
  fleetStates: IFleetStateModel[];
  handleChangeValue: (value: VehicleStateType) => void;
  handleClose: () => void;
  handleContinue: () => void;
  isLoading?: boolean;
}

export const ChangeFleetStateModal: React.SFC<IProps> = ({
  openModal,
  title,
  handleClose,
  handleContinue,
  isLoading,
  value,
  fleetStates,
  handleChangeValue,
}) => {
  const sortedStates = [...fleetStates]
    .map((s) => ({ value: s.id, label: s.translations.en }))
    .sort(dynamicSort('label'));

  return (
    <Dialog
      open={openModal}
      onClose={!isLoading ? handleClose : () => null}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
    >
      <ModalHeader id="form-dialog-title" title={title} />
      <DialogContent>
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">State</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            onChange={(e) => {
              const newVehicleState = e.target.value as VehicleStateType;
              handleChangeValue(newVehicleState);
            }}
            value={value}
            defaultValue={value}
            inputProps={{
              name: 'vehicelSTate',
              id: 'outlined-age-native-simple',
            }}
          >
            {sortedStates.map((v: any, index: number) => (
              <MenuItem key={index} value={v.value}>
                {v.label}
              </MenuItem>
            ))}
          </Select>
          {false && (
            <FormHelperText style={{ color: '#e53935' }}>Please select Vehicle Type</FormHelperText>
          )}
        </FormControl>
      </DialogContent>
      <ModalFooter
        isLoading={isLoading}
        onContinueClick={handleContinue}
        onCancelClick={handleClose}
      />
    </Dialog>
  );
};
