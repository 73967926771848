import * as React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Paper,
  Divider,
} from '@material-ui/core';

import {
  ServeedoTableHeader,
  ITableHeaderCells,
  ServeedoTableToolbar,
  NoData,
  ServeedoTableLoading,
  ITableFilter,
  ServeedoStatus,
} from 'app/components/common';
import {
  IOnroadTaskModel,
  OnroadTaskStatus,
  IHotspotModel,
  INewHotspotModel,
  OnroadTaskType,
  IOnroadTasksFilter,
} from 'app/models';

import { getSorting, stableSort, capitalizeFirstLetter } from 'app/helpers/common';
import { makeDateRelative } from 'app/helpers/time-helper';
import { useDispatch } from 'react-redux';
import { changeOnRoadTaskFilter } from 'app/actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: '#607D8B',
    },
  }),
);

const headerCells: ITableHeaderCells[] = [
  { id: 'type', numeric: false, disablePadding: false, label: 'Type' },
  { id: 'quantity', numeric: false, disablePadding: false, label: 'Vehicles' },
  { id: 'employeeId', numeric: true, disablePadding: false, label: 'Employee' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'comment', numeric: false, disablePadding: false, label: 'Comment' },
  { id: 'updatedAt', numeric: false, disablePadding: false, label: 'Last Update' },
];

export interface IProps {
  onRoadTasksList: IOnroadTaskModel[];
  isLoading: boolean;
  filter: IOnroadTasksFilter;
  totalResultsCount: number | null;
}

export const OnRoadTasksList: React.SFC<IProps> = ({
  onRoadTasksList,
  totalResultsCount,
  isLoading,
  filter,
  filter: { perPage, page, type, status },
}) => {
  const classes = useStyles();

  const d = useDispatch();

  const [order, setOrder] = React.useState<any>('desc');
  const [orderBy, setOrderBy] = React.useState('updatedAt');
  const [selected, setSelected] = React.useState([]);

  const handleRequestSort = (event: any, property: string) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event: any, newPage: any) => {
    d(changeOnRoadTaskFilter({ ...filter, page: newPage }));
  };

  const handleChangeRowsPerPage = (event: any) => {
    d(changeOnRoadTaskFilter({ ...filter, perPage: parseInt(event.target.value, 10) }));
  };

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds: any = onRoadTasksList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const onroadTaskStatus = Object.values(OnroadTaskStatus).map((key: OnroadTaskStatus) => key);
  const onroadTaskType = Object.values(OnroadTaskType).map((key: OnroadTaskType) => key);

  const tableFilter: ITableFilter[] = [
    {
      menuTitle: 'Task type',
      items: [
        { label: 'All', value: 'all' },
        ...onroadTaskType.map((s) => ({ label: capitalizeFirstLetter(s), value: s })),
      ],
      onSelect: (value) => d(changeOnRoadTaskFilter({ ...filter, type: value, page: 0 })),
      selectedOption: type,
    },
    {
      menuTitle: 'Status',
      items: [
        { label: 'All', value: 'all' },
        ...onroadTaskStatus.map((s) => ({ label: capitalizeFirstLetter(s), value: s })),
      ],
      onSelect: (value) => d(changeOnRoadTaskFilter({ ...filter, status: value, page: 0 })),
      selectedOption: status,
    },
  ];

  const getLocation = (onRoadTask: IOnroadTaskModel): string => {
    let location = (onRoadTask.location as INewHotspotModel).location || onRoadTask.location;
    return location.address || location.lat + ', ' + location.long;
  };

  let list = stableSort<IOnroadTaskModel>(onRoadTasksList, getSorting(order, orderBy));

  return (
    <Paper>
      <ServeedoTableToolbar title={'All Tasks'} tableFilter={tableFilter} />
      <div className="table-wrapper">
        <Table aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table">
          <ServeedoTableHeader
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={onRoadTasksList.length}
            headerCells={headerCells}
          />
          <TableBody>
            {isLoading ? (
              <ServeedoTableLoading numberOfColumns={6} />
            ) : (
              list.map((onRoadTask: IOnroadTaskModel, index: number) => {
                let typeStr = capitalizeFirstLetter(onRoadTask.type);
                let quantity: string | JSX.Element = '';
                if (onRoadTask.vehicleIds) {
                  quantity = (
                    <React.Fragment>
                      {onRoadTask.vehicleIds.map((id) => (
                        <span style={{ display: 'block' }} key={id}>
                          {id}
                        </span>
                      ))}
                    </React.Fragment>
                  );
                } else {
                  quantity += onRoadTask.initialQuantity || onRoadTask.quantity;
                }
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    style={{
                      cursor: 'default',
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <span>{typeStr}</span>
                      {onRoadTask.type === OnroadTaskType.Drop &&
                        onRoadTask.location &&
                        (onRoadTask.location as IHotspotModel).name && (
                          <span>
                            {' '}
                            to <span>{(onRoadTask.location as IHotspotModel).name}</span>
                          </span>
                        )}
                      <br />
                      <span className={classes.label}>{getLocation(onRoadTask)}</span>
                    </TableCell>
                    <TableCell>{quantity}</TableCell>
                    <TableCell>
                      {onRoadTask.employee ? onRoadTask.employee.fullname : onRoadTask.employeeId}
                    </TableCell>
                    <TableCell>
                      <ServeedoStatus type={onRoadTask.status} />
                    </TableCell>
                    <TableCell style={{ width: '30%' }}>{onRoadTask.comment}</TableCell>
                    <TableCell>{makeDateRelative(onRoadTask.updatedAt)}</TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </div>
      {!isLoading && !onRoadTasksList.length && (
        <>
          <NoData />
          <Divider />
        </>
      )}
      <TablePagination
        rowsPerPageOptions={[50, 100, 250]}
        component="div"
        count={totalResultsCount || onRoadTasksList.length}
        rowsPerPage={perPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
