import * as React from 'react';
import Typography from '@material-ui/core/Typography';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(3),
  },
  leftContainer: {
    flexWrap: 'wrap',
    overflow: 'hidden',
    overflowY: 'hidden',
  },
  rightContainer: {
    flex: '0 0 auto',
  },
  headerSmallText: {
    marginBottom: 8,
    color: '#607d8b',
    fontSize: '11px',
    lineHeight: '13px',
    letterSpacing: 0.3,
  },
  headerText: {
    color: '#263238',
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 500,
    overflow: 'hidden',
  },
}));

export interface IProps {
  headerText: string;
  headerSmallText?: string;
  rightPanel?: JSX.Element;
}

export const WidgetHeader: React.SFC<IProps> = ({ headerText, headerSmallText, rightPanel }) => {
  const classes = useStyles();
  return (
    <div className={classes.header}>
      <div className={classes.leftContainer}>
        <Typography className={classes.headerSmallText} paragraph>
          {headerSmallText}
        </Typography>
        <Typography className={classes.headerText} variant="h6" noWrap>
          {headerText}
        </Typography>
      </div>
      <div className={classes.rightContainer}>{rightPanel}</div>
    </div>
  );
};
