import { handleActions, Reducer } from 'redux-actions';
import { IRootState } from 'app/reducers/AppState';
import { IUserDetailsModel, IUserDetail, IRegion, IMaintainer } from 'app/models';
import { IUserType } from 'app/actions';

const initialState: IUserDetailsModel = {
  userDetails: null,
  isLoading: false,
  regions: [],
  selectedRegion: null,
  selectedMaintainer: null,
};

// tslint:disable-next-line:typedef
export const userDetailsReducer: Reducer<
  IRootState.UserDetailsState,
  IUserDetailsModel
> = handleActions<
  IRootState.UserDetailsState,
  IUserDetailsModel | IUserDetail | IRegion | IRegion[] | IMaintainer
>(
  {
    [IUserType.REQUEST_USER_DETAILS]: (state = initialState): IRootState.UserDetailsState => ({
      ...state,
      isLoading: true,
      selectedRegion: null,
    }),
    [IUserType.RECEIVE_USER_DETAILS]: (
      state = initialState,
      action,
    ): IRootState.UserDetailsState => {
      const userDetails = action.payload as IUserDetail;

      return {
        ...state,
        userDetails: userDetails,
        isLoading: false,
        selectedMaintainer:
          userDetails.company &&
          userDetails.company.regions &&
          userDetails.company.maintainers &&
          userDetails.company.maintainers.length === 1 &&
          userDetails.company.regions.length &&
          userDetails.company.regions.length === 1
            ? userDetails.company.maintainers[0]
            : null,
      };
    },
    [IUserType.FAILURE_USER_DETAILS]: (state = initialState): IRootState.UserDetailsState => ({
      ...state,
      isLoading: false,
    }),

    [IUserType.CLEAR_USER_DETAILS]: (state = initialState): IRootState.UserDetailsState => ({
      ...state,
      userDetails: null,
      regions: [],
      selectedRegion: null,
      selectedMaintainer: null,
    }),

    [IUserType.RECEIVE_REGIONS]: (state = initialState, action): IRootState.UserDetailsState => ({
      ...state,
      regions: [
        ...(((action.payload as IRegion[]).length > 1 && [
          {
            id: 'all',
            key: 'all',
            name: 'All',
            geonameId: 2,
            type: 'all',
            country: '',
          },
        ]) ||
          []),
        ...(action.payload as IRegion[]),
      ],
      selectedRegion:
        (action.payload as IRegion[]).length > 1
          ? {
              id: 'all',
              key: 'all',
              name: 'All',
              geonameId: 2,
              type: 'all',
              country: '',
            }
          : (action.payload as IRegion[]).length
          ? (action.payload as IRegion[])[0]
          : null,
    }),

    [IUserType.CHANGE_REGION]: (state = initialState, action): IRootState.UserDetailsState => ({
      ...state,
      selectedRegion: action.payload as IRegion,
      selectedMaintainer: null,
    }),
    [IUserType.CHANGE_MAINTAINER]: (state = initialState, action): IRootState.UserDetailsState => ({
      ...state,
      selectedMaintainer: action.payload as IMaintainer,
    }),
  },
  initialState,
);
