import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import { WidgetHeader } from '../WidgetHeader/WidgetHeader';

import {
  IFleetModel,
  IVehicleTypeListModel,
  IRegion,
  VehicleStateType,
  ISupportedRoutes,
} from 'app/models';
import { FleetDetails } from './FleetDetails/FleetDetails';
import { FleetHistory } from './FleetHistory/FleetHistory';
import { ServeedoButton } from '../common';
import { ChangeFleetStateModal } from '../Modals/ChangeFleetStateModal/ChangeFleetStateModal';
import { DamagedBeyondRepairProof } from './DamagedBeyondRepairProof';

export interface IProps extends RouteComponentProps<any> {
  fleet: IFleetModel;
  fetchFleetDetails: (id: string) => void;
  vehicleTypesList: IVehicleTypeListModel[];
  fetchVehiclesList: () => void;
  selectedRegion: IRegion | null;
  changeFleetState: (state: VehicleStateType) => void;
  getFleetStates: () => void;
  fetchFleetSpareparts: () => void;
}
export interface IState {
  openModal: boolean;
  vehicleState: VehicleStateType | null;
}

export class FleetView extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { openModal: false, vehicleState: null };
  }

  componentDidMount() {
    this.props.fetchVehiclesList();
    this.props.getFleetStates();
    this.props.fetchFleetSpareparts();
    this.props.fetchFleetDetails(this.props.match.params.id);
  }

  componentDidUpdate(prevProps: IProps) {
    const {
      fleet: { isAdding },
      selectedRegion,
      match,
    } = this.props;
    if (prevProps.fleet.isAdding !== isAdding && !isAdding) {
      this.setState({
        openModal: false,
        vehicleState: null,
      });
    }
    if (prevProps.selectedRegion !== selectedRegion && selectedRegion) {
      let newUrl =
        `${selectedRegion.key !== 'all' ? '/' + selectedRegion.key : ''}` +
        ISupportedRoutes.FLEET +
        '/' +
        match.params.id;
      this.props.history.push(newUrl);
    }
  }

  renderButton = (): JSX.Element => {
    const {
      fleet: { fleetDetails, isLoading },
    } = this.props;
    return (
      <ServeedoButton
        buttonText={'CHANGE STATE'}
        onClick={() =>
          this.setState({
            openModal: true,
            vehicleState: fleetDetails ? fleetDetails.stateId : null,
          })
        }
        disabled={isLoading}
      />
    );
  };

  render(): JSX.Element {
    const {
      fleet: { isLoading, fleetDetails, isAdding, fleetStates, spareParts },
      vehicleTypesList,
      selectedRegion,
      changeFleetState,
    } = this.props;
    const { openModal, vehicleState } = this.state;
    return (
      <div className={'container'}>
        <div className={'medium-width'}>
          <WidgetHeader
            headerText={
              fleetDetails &&
              vehicleTypesList.find((v) => v.id === fleetDetails.vehicleTypeId) &&
              vehicleTypesList.find((v) => v.id === fleetDetails.vehicleTypeId)!.name
                ? vehicleTypesList.find((v) => v.id === fleetDetails.vehicleTypeId)!.name +
                  ' ' +
                  fleetDetails.remoteId
                : 'loading...'
            }
            headerSmallText={selectedRegion ? selectedRegion.name.toUpperCase() : 'ALL'}
            rightPanel={this.renderButton()}
          />
          <FleetDetails
            isLoading={isLoading}
            fleetDetails={fleetDetails}
            vehicleTypesList={vehicleTypesList}
            fleetStates={fleetStates}
          />
          {fleetDetails && fleetDetails.stateId === VehicleStateType.damagedBeyondRepair && (
            <DamagedBeyondRepairProof stateDescription={fleetDetails.stateDescription} />
          )}
          <FleetHistory
            items={fleetDetails ? fleetDetails.history : []}
            isLoading={isLoading}
            localStates={fleetStates}
            spareParts={spareParts}
          />
        </div>
        <ChangeFleetStateModal
          openModal={openModal}
          title={`Change ${
            fleetDetails &&
            vehicleTypesList.find((v) => v.id === fleetDetails.vehicleTypeId) &&
            vehicleTypesList.find((v) => v.id === fleetDetails.vehicleTypeId)!.name
              ? vehicleTypesList.find((v) => v.id === fleetDetails.vehicleTypeId)!.name
              : ''
          } State`}
          fleetStates={fleetStates}
          value={vehicleState || VehicleStateType.active}
          handleChangeValue={(value) => this.setState({ vehicleState: value })}
          handleClose={() => this.setState({ openModal: false, vehicleState: null })}
          handleContinue={() => {
            changeFleetState(vehicleState || VehicleStateType.active);
          }}
          isLoading={isAdding}
        />
      </div>
    );
  }
}
