import { handleActions, Reducer } from 'redux-actions';
import { IRootState } from 'app/reducers/AppState';
import { ILoginType } from 'app/actions';
import { ILoginModel } from 'app/models/Login';

const initialState: ILoginModel = {
  isAuthenticated: false,
  isLoggingIn: false,
};

// tslint:disable-next-line:typedef
export const loginReducer: Reducer<IRootState.LoginState, ILoginModel> = handleActions<
  IRootState.LoginState,
  ILoginModel | string
>(
  {
    [ILoginType.REQUEST_USER_LOGIN]: (state = initialState): IRootState.LoginState => ({
      ...state,
      isLoggingIn: true,
    }),
    [ILoginType.RECEIVE_USER_LOGIN]: (state = initialState): IRootState.LoginState => ({
      ...state,
      isLoggingIn: false,
    }),
    [ILoginType.FAILURE_USER_LOGIN]: (state = initialState, action): IRootState.LoginState => ({
      ...state,
      isLoggingIn: false,
    }),
  },
  initialState,
);
