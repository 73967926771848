import React from 'react';

import clsx from 'clsx';

import { IconButton } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

import { useStyles } from './styles';

interface IProps {
  url: string;
  removeImage: () => void;
}

export const ImagePreview: React.SFC<IProps> = ({ url, removeImage }) => {
  const classes = useStyles();

  return (
    <>
      <div className={clsx(classes.imageWrap, classes.bgDark)}>
        <div className={classes.clearIcon}>
          <IconButton className={classes.icon} onClick={removeImage}>
            <Clear />
          </IconButton>
        </div>
        <img className={classes.image} src={url} alt="img" />
      </div>
    </>
  );
};
