import { push } from 'react-router-redux';
import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import {
  IResponseHandlerModel,
  IMethods,
  IVehicleTypeModel,
  ICheckListItemModel,
  IVehicleTypeListModel,
  IAddVehicleTypeModel,
  ISupportedRoutes,
} from 'app/models';
import { ApiHelper } from 'app/helpers/api-helper';
import { IRootState } from 'app/reducers/AppState';
import { payloadWithFormData } from 'app/helpers/form-data-helper';

export enum IVehicleTypesActionsType {
  REQUEST_VEHICLE_TYPES_LIST = 'REQUEST_VEHICLE_TYPES_LIST',
  RECEIVE_VEHICLE_TYPES_LIST = 'RECEIVE_VEHICLE_TYPES_LIST',
  FAILURE_VEHICLE_TYPES_LIST = 'FAILURE_VEHICLE_TYPES_LIST',

  REQUEST_ADD_VEHICLE_TYPE = 'REQUEST_ADD_VEHICLE_TYPE',
  RECEIVE_ADD_VEHICLE_TYPE = 'RECEIVE_ADD_VEHICLE_TYPE',
  FAILURE_ADD_VEHICLE_TYPE = 'FAILURE_ADD_VEHICLE_TYPE',

  CANCEL_ADD_VEHICLE_TYPE = 'CANCEL_ADD_VEHICLE_TYPE',

  ADD_CHECK_LIST_ITEM = 'ADD_CHECK_LIST_ITEM',

  DELETE_CHECK_LIST_ITEM = 'DELETE_CHECK_LIST_ITEM',

  HANDLE_EDIT_VEHICLE_TYPE = 'HANDLE_EDIT_VEHICLE_TYPE',

  REQUEST_UPDATE_VEHICLE_TYPE = 'REQUEST_UPDATE_VEHICLE_TYPE',
  RECEIVE_UPDATE_VEHICLE_TYPE = 'RECEIVE_UPDATE_VEHICLE_TYPE',
  FAILURE_UPDATE_VEHICLE_TYPE = 'FAILURE_UPDATE_VEHICLE_TYPE',

  REQUEST_DELETE_VEHICLE_TYPE = 'REQUEST_DELETE_VEHICLE_TYPE',
  RECEIVE_DELETE_VEHICLE_TYPE = 'RECEIVE_DELETE_VEHICLE_TYPE',
  FAILURE_DELETE_VEHICLE_TYPE = 'FAILURE_DELETE_VEHICLE_TYPE',

  REQUEST_FETCH_VEHICLE_TYPE = 'REQUEST_FETCH_VEHICLE_TYPE',
  RECEIVE_FETCH_VEHICLE_TYPE = 'RECEIVE_FETCH_VEHICLE_TYPE',
  FAILURE_FETCH_VEHICLE_TYPE = 'FAILURE_FETCH_VEHICLE_TYPE',
}

// tslint:disable:typedef

export const requestVehicleTypesList = createAction(
  IVehicleTypesActionsType.REQUEST_VEHICLE_TYPES_LIST,
);
export const receiveVehicleTypesList = createAction<IVehicleTypeModel[]>(
  IVehicleTypesActionsType.RECEIVE_VEHICLE_TYPES_LIST,
);
export const failureVehicleTypesList = createAction(
  IVehicleTypesActionsType.FAILURE_VEHICLE_TYPES_LIST,
);

export const requestAddVehicleType = createAction(
  IVehicleTypesActionsType.REQUEST_ADD_VEHICLE_TYPE,
);
export const receiveAddVehicleType = createAction(
  IVehicleTypesActionsType.RECEIVE_ADD_VEHICLE_TYPE,
);
export const failureAddVehicleType = createAction(
  IVehicleTypesActionsType.FAILURE_ADD_VEHICLE_TYPE,
);

export const cancelAddVehicleType = createAction(IVehicleTypesActionsType.CANCEL_ADD_VEHICLE_TYPE);

export const addCheckListItems = createAction<ICheckListItemModel[]>(
  IVehicleTypesActionsType.ADD_CHECK_LIST_ITEM,
);

export const deleteCheckListItem = createAction<ICheckListItemModel[]>(
  IVehicleTypesActionsType.DELETE_CHECK_LIST_ITEM,
);

export const editVehicleType = createAction<IVehicleTypeModel>(
  IVehicleTypesActionsType.HANDLE_EDIT_VEHICLE_TYPE,
);

export const requestUpdateVehicleType = createAction(
  IVehicleTypesActionsType.REQUEST_UPDATE_VEHICLE_TYPE,
);
export const receiveUpdateVehicleType = createAction(
  IVehicleTypesActionsType.RECEIVE_UPDATE_VEHICLE_TYPE,
);
export const failureUpdateVehicleType = createAction(
  IVehicleTypesActionsType.FAILURE_UPDATE_VEHICLE_TYPE,
);

export const requestDeleteVehicleType = createAction(
  IVehicleTypesActionsType.REQUEST_DELETE_VEHICLE_TYPE,
);
export const receiveDeleteVehicleType = createAction(
  IVehicleTypesActionsType.RECEIVE_DELETE_VEHICLE_TYPE,
);
export const failureDeleteVehicleType = createAction(
  IVehicleTypesActionsType.FAILURE_DELETE_VEHICLE_TYPE,
);

export const requestFetchVehicleType = createAction(
  IVehicleTypesActionsType.REQUEST_FETCH_VEHICLE_TYPE,
);
export const receiveFetchVehicleType = createAction<IVehicleTypeModel>(
  IVehicleTypesActionsType.RECEIVE_FETCH_VEHICLE_TYPE,
);
export const failureFetchVehicleType = createAction(
  IVehicleTypesActionsType.FAILURE_FETCH_VEHICLE_TYPE,
);

export const fetchVehiclesTypesList = () => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    dispatch(requestVehicleTypesList());

    try {
      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        '/vehicle-types',
        IMethods.GET,
        true,
      );

      if (response.isError) {
        dispatch(failureVehicleTypesList());
        return;
      }
      const vehicleTypesList = response.data.data as IVehicleTypeListModel[];
      dispatch(receiveVehicleTypesList(vehicleTypesList));
    } catch (e) {
      dispatch(failureVehicleTypesList());
    }
  };
};

export const addNewVehicleType = (
  vehicle: IAddVehicleTypeModel,
  photo: File | null,
  diagram: File | null,
) => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    const { checklist } = getState().vehicleTypes;
    const newCheckList = [...checklist].map((i) => ({
      key: i.key,
      translations: i.translations,
      order: i.order,
      relatedPartsIds: i.relatedPartsIds,
    }));
    const requestObject: any = {
      ...vehicle,
      checklist: newCheckList,
    };
    const formData = payloadWithFormData(requestObject);
    if (photo) {
      formData.append('photo', photo);
    }
    if (diagram) {
      formData.append('diagram', diagram);
    }
    try {
      dispatch(requestAddVehicleType());
      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        '/vehicle-types',
        IMethods.POST,
        true,
        undefined,
        formData,
        true,
      );
      if (response.isError) {
        dispatch(failureAddVehicleType());
        return;
      }
      dispatch(receiveAddVehicleType());
      dispatch(push(ISupportedRoutes.VEHICLES_TYPES));
    } catch (e) {
      dispatch(failureAddVehicleType());
    }
  };
};

export const updateCheckListItem = (checkListItems: ICheckListItemModel[]) => {
  return (dispatch: Dispatch) => {
    dispatch(addCheckListItems(checkListItems));
  };
};

export const removeCheckListItem = (key: string) => {
  return (dispatch: Dispatch, getState: () => IRootState) => {
    const { checklist } = getState().vehicleTypes;
    const checkListItemsState = [...checklist];
    const index = checkListItemsState.findIndex((i) => i.key === key);
    checkListItemsState.splice(index, 1);
    dispatch(deleteCheckListItem(checkListItemsState));
  };
};

export const revertAddVehicleType = () => {
  return (dispatch: Dispatch) => {
    dispatch(cancelAddVehicleType());
  };
};

export const handleEditVehicle = (vehicle: IVehicleTypeListModel) => {
  return (dispatch: Dispatch) => {
    dispatch(addCheckListItems(vehicle.checklist));
    dispatch(editVehicleType(vehicle));
    dispatch(push(ISupportedRoutes.VEHICLES_TYPES + '/' + vehicle.id));
  };
};

export const updateVehicle = (
  vehicle: IAddVehicleTypeModel,
  photo: File | null,
  diagram: File | null,
) => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    const { checklist, selectedVehicleType } = getState().vehicleTypes;
    const newCheckList = [...checklist].map((i) => ({
      key: i.key,
      translations: i.translations,
      order: i.order,
      relatedPartsIds: i.relatedPartsIds,
    }));
    const requestObject: any = {
      ...vehicle,
      checklist: newCheckList,
    };
    const formData = payloadWithFormData(requestObject);
    if (photo) {
      formData.append('photo', photo);
    }
    if (diagram) {
      formData.append('diagram', diagram);
    }
    dispatch(requestUpdateVehicleType());
    if (selectedVehicleType) {
      try {
        const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
          '/vehicle-types/' + selectedVehicleType.id,
          IMethods.PUT,
          true,
          undefined,
          formData,
          true,
        );
        if (response.isError) {
          dispatch(failureUpdateVehicleType());
          return;
        }
        dispatch(receiveUpdateVehicleType());
        dispatch(push(ISupportedRoutes.VEHICLES_TYPES));
      } catch (e) {
        dispatch(failureUpdateVehicleType());
      }
    }
  };
};

export const deleteVehicleType = () => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    const { selectedVehicleType } = getState().vehicleTypes;
    dispatch(requestDeleteVehicleType());
    if (selectedVehicleType) {
      try {
        const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
          '/vehicle-types/' + selectedVehicleType.id,
          IMethods.DELETE,
          true,
        );
        if (response.isError) {
          dispatch(failureDeleteVehicleType());
          return;
        }
        dispatch(receiveDeleteVehicleType());
        dispatch(push(ISupportedRoutes.VEHICLES_TYPES));
      } catch (e) {
        dispatch(failureUpdateVehicleType());
      }
    }
  };
};

export const fetchVehicleType = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestFetchVehicleType());
    try {
      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        '/vehicle-types/' + id,
        IMethods.GET,
        true,
      );
      const selectedVehicleType = response.data.data as IVehicleTypeListModel;
      dispatch(receiveFetchVehicleType(selectedVehicleType));
      dispatch(addCheckListItems(selectedVehicleType.checklist));
    } catch (e) {
      dispatch(failureFetchVehicleType());
    }
  };
};
