import { makeStyles } from '@material-ui/styles';
import { colors, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    overflowY: 'hidden',
    paddingTop: 0,
    marginTop: 0,
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100vh - 64px);',
    },
  },
  content: {
    padding: 0,
    [theme.breakpoints.up('sm')]: {
      height: '100%',
      overflow: 'auto',
    },
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  avatar: {
    width: 60,
    height: 60,
    backgroundColor: 'linear-gradient(0deg, #989898, #989898)',
  },
  name: {
    marginTop: theme.spacing(1),
    fontSize: 14,
    maxWidth: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  navigation: {
    padding: theme.spacing(1),
    paddingBottom: 0,
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: 240,
      flexShrink: 0,
      zIndex: 1,
    },
  },
  drawerPaper: {
    width: 240,
    [theme.breakpoints.up('sm')]: {
      top: 'unset',
      bottom: '-64px',
    },
  },
}));

export const useNavigationListStyles = makeStyles((theme: Theme) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
  },
  buttonLeaf: {
    color: colors.blueGrey[800],
    padding: 0,
    fontWeight: 400,
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    display: 'flex',
    '&.depth-0': {
      fontWeight: 500,
      padding: 0,
      fontSize: 14,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      display: 'flex',
      alignItems: 'center',
    },
  },
  icon: {
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  expandIcon: {
    marginLeft: 'auto',
    height: 16,
    width: 16,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $button': {
      color: theme.palette.primary.main,
    },
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
}));
