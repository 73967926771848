import { handleActions, Reducer } from 'redux-actions';
import { IRootState } from 'app/reducers/AppState';
import { IPartsStockActionsType } from 'app/actions';
import { IPartsStockModel, IPartStockModel } from 'app/models';

const initialState: IPartsStockModel = {
  partsStockList: [],
  isLoading: false,
  isUpdating: false,
};

// tslint:disable-next-line:typedef
export const partsStockReducer: Reducer<
  IRootState.PartsStockState,
  IPartsStockModel
> = handleActions<IRootState.PartsStockState, IPartsStockModel | IPartStockModel[]>(
  {
    [IPartsStockActionsType.REQUEST_PARTS_STOCK_LIST]: (
      state = initialState,
    ): IRootState.PartsStockState => ({
      ...state,
      isLoading: true,
    }),
    [IPartsStockActionsType.RECEIVE_PARTS_STOCK_LIST]: (
      state = initialState,
      action,
    ): IRootState.PartsStockState => ({
      ...state,
      isLoading: false,
      partsStockList: action.payload as IPartStockModel[],
    }),
    [IPartsStockActionsType.FAILURE_PARTS_STOCK_LIST]: (
      state = initialState,
    ): IRootState.PartsStockState => ({
      ...state,
      isLoading: false,
    }),

    [IPartsStockActionsType.REQUEST_UPDATE_SPARE_STOCK_AMOUNT]: (
      state = initialState,
    ): IRootState.PartsStockState => ({
      ...state,
      isUpdating: true,
    }),
    [IPartsStockActionsType.RECEIVE_UPDATE_SPARE_STOCK_AMOUNT]: (
      state = initialState,
    ): IRootState.PartsStockState => ({
      ...state,
      isUpdating: false,
    }),
    [IPartsStockActionsType.FAILURE_UPDATE_SPARE_STOCK_AMOUNT]: (
      state = initialState,
    ): IRootState.PartsStockState => ({
      ...state,
      isUpdating: false,
    }),
  },
  initialState,
);
