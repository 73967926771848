import * as React from 'react';
import { SettingsNavigation } from 'app/components/Settings/SettingsNavigation/SettingsNavigation';
import { useStyles } from './SettingsStyle';

// There is no type check of the props below, do we really need them?
export interface IProps {
  children: any;
}

const Settings: React.SFC<IProps> = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <SettingsNavigation />
      <div className={classes.settingsContainer}>
        <main className={'medium-width'}>{React.cloneElement(props.children, { ...props })}</main>
      </div>
    </React.Fragment>
  );
};

export default Settings;
