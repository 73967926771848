import * as React from 'react';
import { IToastStateModel, IToastModel } from 'app/models';
import { SnackBar } from './SnackBar/SnackBar';

interface IProps {
  toasts: IToastStateModel;
  hideToast: (toast: IToastModel) => void;
}
export const ServeedoToast: React.SFC<IProps> = ({ toasts, hideToast }) => {
  return (
    <div>
      {toasts.toasts.map((toast: IToastModel, index: number) => (
        <SnackBar key={index} toast={toast} hideToast={hideToast} index={index} />
      ))}
    </div>
  );
};
