import * as React from 'react';

interface IProps {
  message: string;
}

export const ContextualError: React.SFC<IProps> = ({ message }) => {
  return (
    <p
      style={{
        marginTop: 8,
        color: 'rgb(229, 57, 53)',
        fontSize: 11,
      }}
    >
      {message}
    </p>
  );
};
