import * as React from 'react';
import { Snackbar, SnackbarContent, IconButton, Theme } from '@material-ui/core';
import { IToastModel, ToastPositions } from 'app/models';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { amber, green } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

let TOAST_POSITION = ToastPositions.TOP_CENTER;

export interface IProps {
  toast: IToastModel;
  hideToast: (toast: IToastModel) => void;
  index: number;
}

export const SnackBar: React.SFC<IProps> = ({ toast, hideToast, index }) => {
  const classes = useStyles();
  const Icon = variantIcon[toast.type];
  return (
    <Snackbar
      anchorOrigin={{
        vertical: TOAST_POSITION.split('-')[0] as any,
        horizontal: TOAST_POSITION.split('-')[1] as any,
      }}
      open={toast.show}
      autoHideDuration={6000}
      style={{
        [TOAST_POSITION &&
        (TOAST_POSITION === ToastPositions.BOTTOM_CENTER ||
          TOAST_POSITION === ToastPositions.BOTTOM_RIGHT ||
          TOAST_POSITION === ToastPositions.BOTTOM_LEFT)
          ? 'bottom'
          : 'top']: (index + 1) * 70 + 10,
      }}
    >
      <SnackbarContent
        className={classes[toast.type]}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {toast.message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={() => hideToast(toast)}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

/**
 * The function returns toast JSX.Element. Please refer to ToastProps.
 **/
// const Toast = ({ toast, hideToast, index }: ToastProps) => (
//     <div
//         className={classNames(
//             Style.mainContainer,
//             Style.errorContainer,
//             Style[toast.position ? toast.position : FixerToastPositions.TOP_CENTER]
//         )}
//         style={{
//             [toast.position &&
//                 (toast.position === FixerToastPositions.BOTTOM_CENTER ||
//                     toast.position === FixerToastPositions.BOTTOM_RIGHT ||
//                     toast.position === FixerToastPositions.BOTTOM_LEFT)
//                 ? "bottom"
//                 : "top"]:
//                 (index + 1) * 70 + 10,
//         }}
//     >
//         {toast.type === FixerToastTypes.SUCCESS ? (
//             <Image src={SuccessIcon} className={Style.indicatorIcon} />
//         ) : null}
//         {toast.type === FixerToastTypes.ERROR ? (
//             <Image src={ErrorIcon} className={Style.indicatorIcon} />
//         ) : null}
//         {toast.type === FixerToastTypes.INFO ? (
//             <Image src={WarningIcon} className={Style.indicatorIcon} />
//         ) : null}
//         {toast.type === FixerToastTypes.WARNING ? (
//             <Image src={WarningIcon} className={Style.indicatorIcon} />
//         ) : null}
//         <span className={Style.text}>
//             {toast.text && toast.text.length
//                 ? toast.text.join(". ")
//                 : `This is ${toast.type} message`}
//         </span>
//         {toast.showButton ? (
//             <div className={Style.buttonContainer}>
//                 <FixerButton
//                     size={FixerButtonSizing.Small}
//                     style={toast.buttonStyling || FixerButtonStyling.Blue}
//                     width={100}
//                     text={toast.buttonText || "Reload"}
//                     onClick={(event: any) => {
//                         if (toast.onButtonClick) {
//                             toast.onButtonClick(event);
//                         }
//                         if (toast.hideToastOnButtonClick) {
//                             hideToast(event);
//                         }
//                     }}
//                     className={Style.toastBtn}
//                 />
//             </div>
//         ) : null}
//         <span onClick={hideToast} className={classNames(Style.close, "pull-right")}>
//             <CloseButton />
//         </span>
//     </div>
// );

// export default Toast;
