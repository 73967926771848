import * as React from 'react';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';

import { ISupportedRoutes, CompanyRoleType } from './models';
import AppLayout from './containers/AppLayout';

import Login from 'app/containers/Login';
import DashBoard from 'app/containers/DashBoard';
import Customers from 'app/containers/Customers';
import Invoices from 'app/containers/Invoices';
import Billing from 'app/containers/Billing';
import Fleet from 'app/containers/Fleet';
import Operators from 'app/containers/Operators';
import Settings from 'app/containers/Settings';
import SpareParts from 'app/containers/SpareParts';
import Employees from 'app/containers/Employees';
import VehicleTypes from 'app/containers/VehicleTypes';
import AddVehicleType from 'app/containers/AddVehicleType';
import PartsStock from 'app/containers/PartsStock';
import RepairGuides from 'app/containers/RepairGuides';
import AddNewRepairGuide from 'app/containers/AddNewRepairGuide';
import FleetView from 'app/containers/FleetView';
import Integration from './views/Settings/Integration/Integration';
import AutoLocking from './views/Settings/AutoLocking/AutoLocking';
import Development from 'app/containers/Development';
import Hotspots from 'app/containers/Hotspots';
import AddHotspot from 'app/containers/AddHotspot';
import UsersManagement from 'app/containers/UsersManagement';
import OnRoadTasks from 'app/containers/OnRoadTasks';
import { useSelector } from 'react-redux';
import { IRootState } from './reducers/AppState';

/**
 * Renders the Layout and component, passing props as new object.
 * @param Component A React Component
 */
const renderWithLayout: any = (Component: any) => (props: any) => (
  <AppLayout {...props}>
    <Component {...props} />
  </AppLayout>
);

/**
 * Renders the Settings and component, passing props as new object.
 * @param Component A React Component
 */
const renderSettingsNavigation: any = (Component: any) => (props: any) => (
  <Settings {...props}>
    <Component {...props} />
  </Settings>
);

/**
 * Checks that we have a token/token expiry. This at least validates the previous login.
 *
 * Updates are needed to support proper validation on routes through backend.
 * @param param0 React Component to be rendered through Route.
 */
const AuthenticatedRoute = ({ path, component: Component, roles, ...rest }: any) => {
  const userDetails = useSelector((state: IRootState) => state.userDetails.userDetails);
  const token = localStorage.getItem('jwtToken');
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!token && path !== ISupportedRoutes.LOGIN) {
          // not logged in so redirect to login page
          return <Redirect to={{ pathname: ISupportedRoutes.LOGIN }} />;
        }

        if (token && path === ISupportedRoutes.LOGIN) {
          // logged in so redirect to Dashboard page
          return <Redirect to={{ pathname: ISupportedRoutes.DASHBOARD }} />;
        }

        // check if route is restricted by role
        if (userDetails && roles && roles.indexOf(userDetails.companyRole) === -1) {
          // role not authorised so redirect to Dashboard page
          return <Redirect to={{ pathname: ISupportedRoutes.DASHBOARD }} />;
        }

        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};

/**
 * Switch managing routing throughout application.
 *
 * Uses react-intl to inject localization into app.
 */
export class App extends React.PureComponent {
  render(): JSX.Element {
    return (
      <Switch>
        <Route exact path="/" render={() => <Redirect to={ISupportedRoutes.LOGIN} />} />
        <AuthenticatedRoute exact path={ISupportedRoutes.LOGIN} component={Login} />
        <AuthenticatedRoute
          exact
          path={'/:selectedLocation?' + ISupportedRoutes.DASHBOARD}
          component={renderWithLayout(DashBoard)}
        />
        <AuthenticatedRoute
          exact
          path={ISupportedRoutes.CUSTOMERS}
          component={renderWithLayout(Customers)}
        />
        <AuthenticatedRoute
          exact
          path={ISupportedRoutes.INVOICES}
          component={renderWithLayout(Invoices)}
        />
        <AuthenticatedRoute
          exact
          path={ISupportedRoutes.INVOICES}
          component={renderWithLayout(Invoices)}
        />
        <AuthenticatedRoute
          exact
          path={ISupportedRoutes.BILLING}
          component={renderWithLayout(Billing)}
        />
        <AuthenticatedRoute
          exact
          path={'/:selectedLocation?' + ISupportedRoutes.FLEET}
          component={renderWithLayout(Fleet)}
        />
        <AuthenticatedRoute
          exact
          path={'/:selectedLocation?' + ISupportedRoutes.VIEW_FLEET}
          component={renderWithLayout(FleetView)}
        />
        <AuthenticatedRoute
          exact
          path={ISupportedRoutes.OPERATORS}
          component={renderWithLayout(Operators)}
        />
        <Route
          path={ISupportedRoutes.SETTINGS}
          render={renderWithLayout(({ match: { url } }: RouteComponentProps<any>) => {
            return (
              <Switch>
                <Route
                  exact
                  path={url}
                  render={() => <Redirect to={ISupportedRoutes.INTEGRATION_SETTINGS} />}
                />
                <AuthenticatedRoute
                  exact
                  path={ISupportedRoutes.INTEGRATION_SETTINGS}
                  component={renderSettingsNavigation(Integration)}
                />
                <AuthenticatedRoute
                  exact
                  path={ISupportedRoutes.AUTO_LOCKING_SETTINGS}
                  component={renderSettingsNavigation(AutoLocking)}
                />
              </Switch>
            );
          })}
        />
        <AuthenticatedRoute
          exact
          path={ISupportedRoutes.SPARE_PARTS}
          component={renderWithLayout(SpareParts)}
        />
        <AuthenticatedRoute
          exact
          path={ISupportedRoutes.REPAIR_GUIDES}
          component={renderWithLayout(RepairGuides)}
        />
        <AuthenticatedRoute
          exact
          path={ISupportedRoutes.NEW_REPAIR_GUIDES}
          component={renderWithLayout(AddNewRepairGuide)}
        />
        <AuthenticatedRoute
          exact
          path={ISupportedRoutes.EDIT_REPAIR_GUIDES}
          component={renderWithLayout(AddNewRepairGuide)}
        />
        <AuthenticatedRoute
          exact
          path={'/:selectedLocation' + ISupportedRoutes.EMPLOYEES}
          component={renderWithLayout(Employees)}
        />
        <AuthenticatedRoute
          exact
          path={ISupportedRoutes.VEHICLES_TYPES}
          component={renderWithLayout(VehicleTypes)}
        />
        <AuthenticatedRoute
          exact
          path={ISupportedRoutes.NEW_VEHICLE_TYPE}
          component={renderWithLayout(AddVehicleType)}
        />
        <AuthenticatedRoute
          exact
          path={ISupportedRoutes.UPDATE_VEHICLE_TYPE}
          component={renderWithLayout(AddVehicleType)}
        />
        <AuthenticatedRoute
          exact
          path={'/:selectedLocation' + ISupportedRoutes.PARTS_STOCK}
          component={renderWithLayout(PartsStock)}
        />
        <AuthenticatedRoute
          exact
          path={ISupportedRoutes.DEVELOPMENT}
          component={renderWithLayout(Development)}
        />
        <AuthenticatedRoute
          exact
          path={'/:selectedLocation' + ISupportedRoutes.HOT_SPOTS}
          component={renderWithLayout(Hotspots)}
        />
        <AuthenticatedRoute
          exact
          path={'/:selectedLocation' + ISupportedRoutes.NEW_HOT_SPOTS}
          component={renderWithLayout(AddHotspot)}
        />
        <AuthenticatedRoute
          exact
          path={'/:selectedLocation' + ISupportedRoutes.UPDATE_HOT_SPOTS}
          component={renderWithLayout(AddHotspot)}
        />
        <AuthenticatedRoute
          exact
          roles={[CompanyRoleType.Administrator]}
          path={ISupportedRoutes.USERS}
          component={renderWithLayout(UsersManagement)}
        />
        <AuthenticatedRoute
          exact
          path={'/:selectedLocation' + ISupportedRoutes.TASKS}
          component={renderWithLayout(OnRoadTasks)}
        />
      </Switch>
    );
  }
}

export default App;
