import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import {
  IResponseHandlerModel,
  IMethods,
  IEmployeeModel,
  IEmployeesModel,
  IActivityModel,
  INewEmployeeDetails,
  ISupportedRoutes,
  IUserRole,
} from 'app/models';
import { ApiHelper } from 'app/helpers/api-helper';
import { IRootState } from 'app/reducers/AppState';
import { push } from 'react-router-redux';

export enum IEmployeesType {
  REQUEST_EMPLOYEES_LIST = 'REQUEST_EMPLOYEES_LIST',
  RECEIVE_EMPLOYEES_LIST = 'RECEIVE_EMPLOYEES_LIST',
  FAILURE_EMPLOYEES_LIST = 'FAILURE_EMPLOYEES_LIST',

  REQUEST_ADDING_NEW_EMPLOYEE = 'REQUEST_ADDING_NEW_EMPLOYEE',
  RECEIVE_ADDING_NEW_EMPLOYEE = 'RECEIVE_ADDING_NEW_EMPLOYEE',
  FAILURE_ADDING_NEW_EMPLOYEE = 'FAILURE_ADDING_NEW_EMPLOYEE',

  REQUEST_ACTIVITIES_LIST = 'REQUEST_ACTIVITIES_LIST',
  RECEIVE_ACTIVITIES_LIST = 'RECEIVE_ACTIVITIES_LIST',
  FAILURE_ACTIVITIES_LIST = 'FAILURE_ACTIVITIES_LIST',

  OPEN_EMPLOYEE_MODAL = 'OPEN_EMPLOYEE_MODAL',
  CLOSE_EMPLOYEE_MODAL = 'CLOSE_EMPLOYEE_MODAL',

  REQUEST_DELETE_EMPLOYEE = 'REQUEST_DELETE_EMPLOYEE',
  RECEIVE_DELETE_EMPLOYEE = 'RECEIVE_DELETE_EMPLOYEE',
  FAILURE_DELETE_EMPLOYEE = 'FAILURE_DELETE_EMPLOYEE',
}

// tslint:disable:typedef

export const requestEmployeesList = createAction(IEmployeesType.REQUEST_EMPLOYEES_LIST);
export const receiveEmployeesList = createAction<IEmployeesModel>(
  IEmployeesType.RECEIVE_EMPLOYEES_LIST,
);
export const failureEmployeesList = createAction(IEmployeesType.FAILURE_EMPLOYEES_LIST);

export const requestAddingNewEmployee = createAction(IEmployeesType.REQUEST_ADDING_NEW_EMPLOYEE);
export const receiveAddingNewEmployee = createAction(IEmployeesType.RECEIVE_ADDING_NEW_EMPLOYEE);
export const failureAddingNewEmployee = createAction(IEmployeesType.FAILURE_ADDING_NEW_EMPLOYEE);

export const requestActivitiesList = createAction(IEmployeesType.REQUEST_ACTIVITIES_LIST);
export const receiveActivitiesList = createAction<IActivityModel[]>(
  IEmployeesType.RECEIVE_ACTIVITIES_LIST,
);
export const failureActivitiesList = createAction(IEmployeesType.FAILURE_EMPLOYEES_LIST);

export const openEmployeeModal = createAction<IEmployeeModel>(IEmployeesType.OPEN_EMPLOYEE_MODAL);
export const closeEmployeeModal = createAction(IEmployeesType.CLOSE_EMPLOYEE_MODAL);

export const requestDeleteEmployee = createAction(IEmployeesType.REQUEST_DELETE_EMPLOYEE);
export const receiveDeleteEmployee = createAction(IEmployeesType.RECEIVE_DELETE_EMPLOYEE);
export const failureDeleteEmployee = createAction(IEmployeesType.FAILURE_DELETE_EMPLOYEE);

export const fetchEmployeesList = () => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    const {
      employees,
      userDetails: { selectedRegion },
    } = getState();
    if (selectedRegion && selectedRegion.key === 'all') {
      dispatch(push(ISupportedRoutes.DASHBOARD));
      return;
    }

    dispatch(requestEmployeesList());

    try {
      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        '/employees',
        IMethods.GET,
        true,
        { ...(selectedRegion && { regionId: selectedRegion.id }) },
      );

      if (response.isError) {
        dispatch(failureEmployeesList());
        return;
      }

      const employeesList = response.data.employees as IEmployeeModel[];

      dispatch(receiveEmployeesList({ ...employees, employeesList }));
    } catch (e) {
      dispatch(failureEmployeesList());
    }
  };
};

export const addNewEmployee = (employee: INewEmployeeDetails) => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    dispatch(requestAddingNewEmployee());
    const { selectedRegion, userDetails } = getState().userDetails;
    let regionId = '';
    if (selectedRegion) {
      regionId = selectedRegion.id;
    } else if (
      userDetails &&
      userDetails.company &&
      userDetails.company.regions &&
      userDetails.company.regions.length
    ) {
      regionId = userDetails.company.regions[0].id;
    }

    try {
      employee.regionId = regionId;
      if (userDetails!.role === IUserRole.Maintainer) {
        employee.maintainerId = userDetails!.company!.id;
      } else {
        employee.ownerId = userDetails!.company!.id;
      }
      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        '/employees',
        IMethods.POST,
        true,
        undefined,
        employee,
      );

      if (response.isError) {
        dispatch(failureAddingNewEmployee());
        return;
      }
      dispatch(receiveAddingNewEmployee());
      fetchEmployeesList()(dispatch, getState);
    } catch (e) {
      dispatch(failureAddingNewEmployee());
    }
  };
};

export const fetchActivitiesList = () => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    dispatch(requestActivitiesList());

    try {
      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        '/activities',
        IMethods.GET,
        true,
      );

      if (response.isError) {
        dispatch(failureActivitiesList());
        return;
      }
      const activitiesList = response.data.activities as IActivityModel[];
      dispatch(receiveActivitiesList(activitiesList));
    } catch (e) {
      dispatch(failureActivitiesList());
    }
  };
};

/**
 * Triggers the edit state updates
 * @param employee Employee to edit
 */
export const openEditEmplyeeModal = (employee: IEmployeeModel) => {
  return (dispatch: Dispatch) => {
    dispatch(openEmployeeModal(employee));
  };
};

export const closeEditEmplyeeModal = () => {
  return (dispatch: Dispatch) => {
    dispatch(closeEmployeeModal());
  };
};

export const editEmployee = (employee: INewEmployeeDetails, employeeId?: string) => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    dispatch(requestAddingNewEmployee());
    const {
      employees: { selectedEmployee },
    } = getState();
    if (selectedEmployee || employeeId) {
      try {
        const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
          `/employees/${selectedEmployee ? selectedEmployee.id : employeeId}`,
          IMethods.PUT,
          true,
          undefined,
          employee,
        );

        if (response.isError) {
          dispatch(failureAddingNewEmployee());
          return;
        }
        dispatch(receiveAddingNewEmployee());
        fetchEmployeesList()(dispatch, getState);
      } catch (e) {
        dispatch(failureAddingNewEmployee());
      }
    }
  };
};

export const deleteEmployee = (employeeId: string) => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    dispatch(requestDeleteEmployee());
    try {
      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        `/employees/${employeeId}`,
        IMethods.DELETE,
        true,
      );

      if (response.isError) {
        dispatch(failureDeleteEmployee());
        return;
      }
      dispatch(receiveDeleteEmployee());
      fetchEmployeesList()(dispatch, getState);
    } catch (e) {
      dispatch(failureDeleteEmployee());
    }
  };
};
