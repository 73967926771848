import React, { useState, useEffect } from 'react';

import { IconButton } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { useStyles } from './styles';
import { ImagePreview } from './ImagePreview';

interface IProps {
  name: string;
  defaultImage?: string | null;
  onChange: (file: File | File[]) => void;
  onRemove: () => void;
  multiple?: boolean;
}

export const ImageUpload: React.SFC<IProps> = ({
  name,
  onChange,
  onRemove,
  defaultImage,
  multiple,
}) => {
  const classes = useStyles();

  const [imagePreviewUrl, setImagePreviewUrl] = useState<any>('');
  const [imagePreviewUrls, setImagePreviewUrls] = useState<any[]>([]);
  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    if (defaultImage) {
      if (multiple) {
        setImagePreviewUrls([defaultImage]);
      } else {
        setImagePreviewUrl(defaultImage);
      }
    }
  }, [defaultImage, multiple]);

  const readAndPreview = (file: File) => {
    // Make sure `file.name` matches our extensions criteria
    if (!/\.(jpe?g|png|gif)$/i.test(file.name)) {
      return alert(file.name + ' is not an image');
    }

    const reader = new FileReader();

    reader.onload = () => {
      var imagePreviewUrlsState = imagePreviewUrls;
      imagePreviewUrlsState.push(reader.result);

      setImagePreviewUrls([...imagePreviewUrlsState]);
    };

    reader.onerror = () => {
      alert('Something went wrong, please try again!');
    };

    reader.readAsDataURL(file);
  };

  const photoUpload = (e: any) => {
    e.preventDefault();
    if (multiple) {
      const files = e.target.files;
      [].forEach.call(files, readAndPreview);
      setFiles([...files]);
      onChange([...files]);
    } else {
      const file = e.target.files[0];

      // Make sure `file.name` matches our extensions criteria
      if (!/\.(jpe?g|png|gif)$/i.test(file.name)) {
        return alert(file.name + ' is not an image');
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
      onChange(file);
    }
  };

  const removePhoto = () => {
    setImagePreviewUrl('');
    onRemove();
  };

  const removeMultipleImage = (index: number) => {
    const imagePreviewUrlsState = [...imagePreviewUrls];
    imagePreviewUrlsState.splice(index, 1);
    setImagePreviewUrls(imagePreviewUrlsState);
    let filesState = [...files];
    if (filesState.length === 1) {
      filesState = [];
    } else {
      filesState.splice(index, 1);
    }
    setFiles(filesState);
    onChange(filesState);
    if (index === 0) {
      onRemove();
    }
  };

  return (
    <div>
      <div className={classes.root}>
        {imagePreviewUrl && !multiple && (
          <ImagePreview url={imagePreviewUrl} removeImage={removePhoto} />
        )}
        {multiple && (
          <>
            {imagePreviewUrls.map((url: string, index: number) => (
              <ImagePreview key={index} url={url} removeImage={() => removeMultipleImage(index)} />
            ))}
          </>
        )}

        {(multiple || !imagePreviewUrl) && (
          <div className={classes.imageWrap}>
            <input
              accept="image/*"
              className={classes.input}
              id={`icon-button-file-${name}`}
              type="file"
              onChange={photoUpload}
              multiple={multiple}
            />
            <label htmlFor={`icon-button-file-${name}`}>
              <IconButton className={classes.icon} aria-label="upload picture" component="span">
                <Add />
              </IconButton>
            </label>
          </div>
        )}
      </div>
    </div>
  );
};
