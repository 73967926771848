import React, { useEffect } from 'react';
import { HotspotDetails } from './HotspotDetails';
import { DropTaskList } from './DropTaskList';
import { IHotspotModel, IVehicleTypeListModel, IOnroadTaskModel } from 'app/models';

export interface IProps {
  hotspot: IHotspotModel;
  vehicleTypesList: IVehicleTypeListModel[];
  fetchDropTasks: (id: string) => void;
  tasks: IOnroadTaskModel[];
  isDropTasksLoading: boolean;
}

export const HotspotView: React.SFC<IProps> = ({
  hotspot,
  vehicleTypesList,
  fetchDropTasks,
  tasks,
  isDropTasksLoading,
}) => {
  useEffect(() => {
    fetchDropTasks(hotspot.id);
  }, [hotspot, fetchDropTasks]);
  return (
    <div>
      <HotspotDetails hotspot={hotspot} vehicleTypesList={vehicleTypesList} />
      <DropTaskList isLoading={isDropTasksLoading} tasks={tasks} />
    </div>
  );
};
