import { handleActions, Reducer } from 'redux-actions';
import { IRootState } from 'app/reducers/AppState';
import { IEmployeesType } from 'app/actions';
import { IEmployeesModel, IActivityModel, IEmployeeModel } from 'app/models';

const initialState: IEmployeesModel = {
  employeesList: [],
  activitiesList: [],
  isLoading: false,
  isAdding: false,
  isEditing: false,
  selectedEmployee: null,
  isDeleting: false,
};

// tslint:disable-next-line:typedef
export const employeesReducer: Reducer<IRootState.EmployeesState, IEmployeesModel> = handleActions<
  IRootState.EmployeesState,
  IEmployeesModel | IActivityModel[] | IEmployeeModel
>(
  {
    [IEmployeesType.REQUEST_EMPLOYEES_LIST]: (state = initialState): IRootState.EmployeesState => ({
      ...state,
      isLoading: true,
      employeesList: []
    }),
    [IEmployeesType.RECEIVE_EMPLOYEES_LIST]: (
      state = initialState,
      action,
    ): IRootState.EmployeesState => ({
      ...state,
      employeesList: (action.payload as IEmployeesModel).employeesList,
      isLoading: false,
    }),
    [IEmployeesType.FAILURE_EMPLOYEES_LIST]: (state = initialState): IRootState.EmployeesState => ({
      ...state,
      isLoading: false,
    }),

    [IEmployeesType.REQUEST_ACTIVITIES_LIST]: (
      state = initialState,
    ): IRootState.EmployeesState => ({
      ...state,
    }),
    [IEmployeesType.RECEIVE_ACTIVITIES_LIST]: (
      state = initialState,
      action,
    ): IRootState.EmployeesState => ({
      ...state,
      activitiesList: action.payload as IActivityModel[],
    }),
    [IEmployeesType.FAILURE_ACTIVITIES_LIST]: (
      state = initialState,
    ): IRootState.EmployeesState => ({
      ...state,
    }),

    [IEmployeesType.REQUEST_ADDING_NEW_EMPLOYEE]: (
      state = initialState,
    ): IRootState.EmployeesState => ({
      ...state,
      isAdding: true,
    }),
    [IEmployeesType.RECEIVE_ADDING_NEW_EMPLOYEE]: (
      state = initialState,
      action,
    ): IRootState.EmployeesState => ({
      ...state,
      isAdding: false,
    }),
    [IEmployeesType.FAILURE_ADDING_NEW_EMPLOYEE]: (
      state = initialState,
    ): IRootState.EmployeesState => ({
      ...state,
      isAdding: false,
    }),

    [IEmployeesType.OPEN_EMPLOYEE_MODAL]: (
      state = initialState,
      action,
    ): IRootState.EmployeesState => ({
      ...state,
      isEditing: true,
      selectedEmployee: action.payload as IEmployeeModel,
    }),

    [IEmployeesType.CLOSE_EMPLOYEE_MODAL]: (state = initialState): IRootState.EmployeesState => ({
      ...state,
      isEditing: false,
      selectedEmployee: null,
    }),

    [IEmployeesType.REQUEST_DELETE_EMPLOYEE]: (
      state = initialState,
    ): IRootState.EmployeesState => ({
      ...state,
      isDeleting: true,
    }),
    [IEmployeesType.RECEIVE_DELETE_EMPLOYEE]: (
      state = initialState,
    ): IRootState.EmployeesState => ({
      ...state,
      isDeleting: false,
    }),
    [IEmployeesType.FAILURE_DELETE_EMPLOYEE]: (
      state = initialState,
    ): IRootState.EmployeesState => ({
      ...state,
      isDeleting: false,
    }),
  },
  initialState,
);
