import { ErrorHandlerHelper } from 'app/helpers/error-handler-helper';
import { SuccessHandlerHelper } from 'app/helpers/success-handler-helper';
import { IMethods, ISupportedRoutes, ToastTypes, IResponseHandlerModel } from 'app/models';
import { store } from 'index';
import { push } from 'react-router-redux';
import { clearUserDetails, showToaster } from 'app/actions';

/**
 * ApiHelper Class - For making Api Requests
 */
export class ApiHelper {
  protected _portalGateway: string;

  constructor() {
    this._portalGateway = process.env.REACT_APP_API_URL!;
  }

  /**
   * Fetches from the Gateway defined by the instantiated object. Accepts <T> as output object.
   * @example <caption>"/Auth/UserAccount", "/GetCurrentUser", "GET", "JWT Content"</caption>
   * @param {service} service - to be access ex. "UserAuth/Auth"
   * @param {endpoint} endpoint - to call ex. "/Login"
   * @param {method} method - (GET, UPDATE, DELETE, POST)
   * @param authenticated - JSON Web Token (Optional)
   * @param queryOptions - query options for "GET" methods (Optional)
   * @param {body} body - JSON body for "UPDATE, DELETE and POST" methods (Optional)
   */
  public async FetchFromPortal<IResponseHandlerModel>(
    endpoint: string,
    method: IMethods,
    authenticated: boolean,
    queryOptions?: Object,
    body?: Object | FormData,
    noContentType?: boolean,
  ) {
    let options: RequestInit = { method: method };
    let url: string = this._portalGateway + endpoint;
    options.headers = new Headers();
    if (!noContentType) {
      options.headers.append('Content-Type', 'application/json; charset=utf-8');
    }
    if (authenticated) {
      options.headers.append('Authorization', localStorage.getItem('jwtToken')!);
    }

    // html query for "GET", json body for others.
    if (queryOptions && typeof queryOptions === 'object') {
      let queryParams = [] as string[];
      Object.keys(queryOptions).forEach((key: any) => {
        queryParams.push(`${key}=${(queryOptions as any)[key]}`);
      });
      url += `?${queryParams.join('&')}`;
    }

    if (body) {
      if (noContentType) {
        options.body = body as FormData;
      } else {
        options.body = JSON.stringify(body);
      }
    }

    try {
      const response: Response = await fetch(url, options);

      if (response.status === 401) {
        localStorage.clear();
        store.dispatch(push(ISupportedRoutes.LOGIN));
        store.dispatch(clearUserDetails());
      }
      const jsonResponse =
        response.status === 204
          ? null
          : await response.text().then((text) => (text && text.length ? JSON.parse(text) : null));
      if (
        response.status !== 409 &&
        (response.ok === false || response.status < 200 || response.status > 204)
      ) {
        throw JSON.parse(
          JSON.stringify({
            code: response.status,
            response,
            responseObject: jsonResponse,
          }),
        );
      }

      const data = new SuccessHandlerHelper(jsonResponse, response.status, response.headers);
      return data.responseModel;
    } catch (err) {
      const errorHelper = new ErrorHandlerHelper(err);
      showToaster({
        show: true,
        type: ToastTypes.ERROR,
        message:
          errorHelper.error.messages && errorHelper.error.messages.length
            ? errorHelper.error.messages[0]
            : 'Oops, An unexpected error occurred',
      })(store.dispatch);
      return errorHelper.error;
    }
  }

  public async fetchVehicleTypes(): Promise<IResponseHandlerModel> {
    return await this.FetchFromPortal('/vehicle-types', IMethods.GET, true);
  }

  public async fetchVehicleStates(): Promise<IResponseHandlerModel> {
    return await new ApiHelper().FetchFromPortal('/states', IMethods.GET, true);
  }

  public async fetchSpareParts(): Promise<IResponseHandlerModel> {
    return await new ApiHelper().FetchFromPortal('/spare-parts', IMethods.GET, true);
  }
}
