import * as React from 'react';
import clsx from 'clsx';

import { Typography, Divider } from '@material-ui/core';
import { NavigationList } from 'app/components/LeftNavigation/Navigation/Navigation';

import useRouter from 'app/helpers/use-router';

import { useStyles } from './SettingsNavigationStyles';
import navigationSettingsConfig from 'app/constants/NavigationConfig/navigationSettingsConfig';

export interface IProps {
  className?: string;
}

export const SettingsNavigation: React.SFC<IProps> = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const router = useRouter();

  return (
    <div className={classes.settingsNavigation}>
      {navigationSettingsConfig.map((list, index) => (
        <div {...rest} className={clsx(classes.root, className)} key={index}>
          {list.title && (
            <Typography variant="h6" component="h1" className={classes.title}>
              {list.title}
            </Typography>
          )}
          {list.title && <Divider className={classes.divider} />}
          <NavigationList depth={0} pages={list.pages} router={router} />
        </div>
      ))}
    </div>
  );
};
