import * as React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MenuItem from '@material-ui/core/MenuItem';
import { ListItemIcon, IconButton } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import {
  ITranslationModel,
  IVehicleTypeListModel,
  ISparePartModel,
  IAddSparePartModel,
  ILanguage,
} from 'app/models';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { ConfirmationModal } from '../ConfirmationModal/ConfirmationModal';
import { useStyles } from './AddSparePartsModalStyles';
import { languages } from 'app/constants/languages';
import { ModalHeader } from '../ModalHeader/ModalHeader';
import { ModalFooter } from '../ModalFooter/ModalFooter';

export interface IProps {
  showModal: boolean;
  handleClose: () => void;
  vehicleTypesList: IVehicleTypeListModel[];
  sparePartsList: ISparePartModel[];
  addSparePart: (sparePart: IAddSparePartModel) => void;
  selectedSparePart: ISparePartModel | null;
  isEditing: boolean;
  handleDeleteSparePart: () => void;
  isLoading: boolean;
  isDeleting: boolean;
}

export const AddSparePartsModal: React.SFC<IProps> = ({
  showModal,
  handleClose,
  vehicleTypesList,
  addSparePart,
  selectedSparePart,
  isEditing,
  handleDeleteSparePart,
  isLoading,
  isDeleting,
  sparePartsList,
}) => {
  const classes = useStyles();
  const [vehicleTypeId, setVehicleTypeId] = React.useState<string | null>(null);
  const [code, setCode] = React.useState('');
  const [translations, setTranslations] = React.useState<ITranslationModel>({ en: '' });
  const [validationError, setValidationError] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<any>(null);

  const [confirmModal, setConfirmModal] = React.useState<boolean>(false);
  const isExistId =
    sparePartsList.map((s) => s.code.toLowerCase()).indexOf(code.toLowerCase()) !== -1 &&
    (!isEditing || (selectedSparePart && selectedSparePart.code !== code));

  React.useEffect(() => {
    if (!showModal) {
      setVehicleTypeId(null);
      setCode('');
      setValidationError(false);
      setTranslations({ en: '' });
    }
    if (selectedSparePart && isEditing) {
      const { code, translations } = selectedSparePart;
      setCode(code);
      setVehicleTypeId(selectedSparePart.vehicleTypeId);
      setTranslations(translations);
    }
    if (!isDeleting) {
      setConfirmModal(false);
    }
  }, [showModal, selectedSparePart, isEditing, isDeleting]);

  const addNewSparePart = () => {
    if (!translations['en'] || !code || !vehicleTypeId || isExistId) {
      setValidationError(true);
      return;
    }
    const requestObject: IAddSparePartModel = {
      vehicleTypeId,
      code,
      translations,
    };
    addSparePart(requestObject);
  };

  const closeModal = () => {
    handleClose();
    setCode('');
    setValidationError(false);
    setTranslations({ en: '' });
    setVehicleTypeId(null);
  };

  const handleDelete = (item: string) => {
    const newTranslations = { ...translations };
    delete newTranslations[item];
    setTranslations(newTranslations);
  };

  const handleTranslationChange = (e: any, item: string) => {
    const newTranslations = { ...translations };
    newTranslations[item] = e.target.value;
    setTranslations(newTranslations);
  };

  const handleAddTransaltion = (value: string) => {
    if (Object.keys(translations).find((i) => i === value)) return;
    let newTranslations: ITranslationModel = { ...translations, ...{ [value]: '' } };
    setTranslations(newTranslations);
    handleToggle();
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleSelectClose = (event: any) => {
    if (anchorRef && anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const deleteSparePart = () => {
    if (selectedSparePart) {
      handleDeleteSparePart();
    }
  };

  return (
    <>
      <Dialog
        open={showModal}
        onClose={isLoading ? () => null : closeModal}
        aria-labelledby="form-dialog-title"
      >
        <ModalHeader
          id="form-dialog-title"
          title={
            isEditing && selectedSparePart ? `Edit ${selectedSparePart.code}` : 'Add New Spare Part'
          }
        />
        <DialogContent>
          <div className={classes.formRow}>
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel
                error={!vehicleTypeId && validationError}
                htmlFor="outlined-age-native-simple"
              >
                Vehicle Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                onChange={(e) => {
                  let vType = [...vehicleTypesList].find((v) => v.id === e.target.value);
                  if (vType) {
                    setVehicleTypeId(vType.id);
                  }
                }}
                value={vehicleTypeId ? vehicleTypeId : ''}
                defaultValue={vehicleTypeId ? vehicleTypeId : ''}
                error={!vehicleTypeId && validationError}
                inputProps={{
                  name: 'vehicelType',
                  id: 'outlined-age-native-simple',
                }}
              >
                {vehicleTypesList.map((v: IVehicleTypeListModel, index: number) => (
                  <MenuItem key={index} value={v.id}>
                    {v.name}
                  </MenuItem>
                ))}
              </Select>
              {!vehicleTypeId && validationError && (
                <FormHelperText style={{ color: '#e53935' }}>
                  Please select Vehicle Type
                </FormHelperText>
              )}
            </FormControl>

            <TextField
              margin="normal"
              id="id"
              label="Id"
              type="text"
              value={code}
              fullWidth
              error={(!code && validationError) || !!isExistId}
              variant="outlined"
              onChange={(e) => {
                setCode(e.target.value);
              }}
              className={classes.textField}
              helperText={
                isExistId
                  ? 'Id already exists'
                  : validationError && !code
                  ? 'Id is required!'
                  : undefined
              }
            />
          </div>
          <TextField
            margin="normal"
            id="name"
            label="Name in English"
            type="text"
            fullWidth
            error={!translations['en'] && validationError}
            variant="outlined"
            helperText={!translations['en'] && validationError ? 'Name is required!' : undefined}
            value={translations['en']}
            onChange={(e) => {
              let newTranslations = { ...translations };
              newTranslations['en'] = e.target.value;
              setTranslations(newTranslations);
            }}
          />
          <Button
            disabled={Object.keys(translations).length - 1 === languages.length}
            color="default"
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
            ref={anchorRef}
            style={{
              width: '40%',
              justifyContent: 'space-between',
              backgroundColor: '#eee',
              textTransform: 'unset',
              margin: '15px 0 0',
              padding: '6px 8px 6px 14px',
            }}
          >
            Add translation
            <ArrowDropDownIcon />
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={{ zIndex: 2, width: '36.5%' }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleSelectClose}>
                    <MenuList id="split-button-menu">
                      {[...languages]
                        .filter((i) => !Object.keys(translations).find((l) => l === i.value))
                        .map((language: ILanguage, index: number) => (
                          <MenuItem
                            key={index}
                            onClick={() => handleAddTransaltion(language.value)}
                            style={{ padding: 10, paddingLeft: 14 }}
                          >
                            {language.label}
                          </MenuItem>
                        ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          {Object.keys(translations)
            .filter((i) => i !== 'en')
            .map((item: string, index: number) => {
              return (
                <div className={classes.textFieldWrapper} key={index}>
                  <TextField
                    key={index}
                    margin="normal"
                    id={item}
                    label={[...languages].find((i) => i.value === item)!.label}
                    type="email"
                    value={translations[item]}
                    name={item}
                    fullWidth
                    variant="outlined"
                    onChange={(e) => handleTranslationChange(e, item)}
                  />
                  <ListItemIcon className={classes.deleteButton}>
                    <IconButton onClick={() => handleDelete(item)}>
                      <DeleteOutline />
                    </IconButton>
                  </ListItemIcon>
                </div>
              );
            })}
        </DialogContent>
        <ModalFooter
          isLoading={isLoading}
          isEditing={isEditing}
          hasDelete
          onContinueClick={addNewSparePart}
          onCancelClick={closeModal}
          onDeleteClick={() => setConfirmModal(true)}
        />
      </Dialog>
      {selectedSparePart && (
        <ConfirmationModal
          openModal={confirmModal}
          handleClose={() => setConfirmModal(false)}
          handleContinue={deleteSparePart}
          modalBody={
            <span>
              Do you want to remove <strong>{selectedSparePart.code}</strong>?
            </span>
          }
          isLoading={isDeleting}
        />
      )}
    </>
  );
};
