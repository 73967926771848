import moment from 'moment';

export const makeDateRelative = (date: Date): string => {
  const currentDate: moment.Moment = moment();
  const hours: number = currentDate.diff(date, 'hours');
  if (Math.abs(moment().diff(date)) < 1000) {
    return 'just now';
  }
  if (hours <= 24) {
    if (hours <= 24 && hours > 21) {
      return hours + ' hours ago';
    }
    return moment(date).fromNow();
  }
  return moment(date).format('Do MMM') + ' at ' + moment(date).format('h:mm a');
};
