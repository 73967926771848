import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { Settings } from 'app/views';
import { IRootState } from 'app/reducers/AppState';
import { ISettingsModel, IRegion } from 'app/models';
import {
  SaveIntegrationFunction,
  ChangeAutoLockingRuleFunction,
  SaveIntegrationMappingFunction,
  DeleteIntegrationMappingFunction,
} from '../../models/SettingsModel';
import {
  fetchIntegrationInfo,
  saveIntegrationInfo,
  fetchIntegrationLocalVehicleTypes,
  fetchIntegrationVehicleTypes,
  fetchIntegrationLocalVehicleStates,
  fetchIntegrationVehicleStates,
  fetchIntegrationRegions,
  fetchIntegrationHistory,
  fetchAutoLockingRules,
  changeAutoLockingRule,
  saveIntegrationMapping,
  deleteIntegrationMapping,
  resetIntegrationErrors,
} from 'app/actions';

interface StateFromProps {
  settings: ISettingsModel;
  regions: IRegion[];
}

interface DispatchFromProps {
  fetchIntegrationInfo: () => void;
  saveIntegrationInfo: SaveIntegrationFunction;
  fetchIntegrationLocalVehicleTypes: () => void;
  fetchIntegrationVehicleTypes: () => void;
  fetchIntegrationLocalVehicleStates: () => void;
  fetchIntegrationVehicleStates: () => void;
  fetchIntegrationRegions: () => void;
  fetchIntegrationHistory: () => void;
  fetchAutoLockingRules: () => void;
  changeAutoLockingRule: ChangeAutoLockingRuleFunction;
  saveIntegrationMapping: SaveIntegrationMappingFunction;
  deleteIntegrationMapping: DeleteIntegrationMappingFunction;
  resetIntegrationErrors: () => void;
}

const mapStateToProps = (state: IRootState): StateFromProps => ({
  settings: state.settings,
  regions: state.userDetails.regions,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => ({
  fetchIntegrationInfo: bindActionCreators(fetchIntegrationInfo, dispatch),
  saveIntegrationInfo: bindActionCreators(saveIntegrationInfo, dispatch),
  fetchIntegrationLocalVehicleTypes: bindActionCreators(
    fetchIntegrationLocalVehicleTypes,
    dispatch,
  ),
  fetchIntegrationVehicleTypes: bindActionCreators(fetchIntegrationVehicleTypes, dispatch),
  fetchIntegrationLocalVehicleStates: bindActionCreators(
    fetchIntegrationLocalVehicleStates,
    dispatch,
  ),
  fetchIntegrationVehicleStates: bindActionCreators(fetchIntegrationVehicleStates, dispatch),
  fetchIntegrationRegions: bindActionCreators(fetchIntegrationRegions, dispatch),
  fetchIntegrationHistory: bindActionCreators(fetchIntegrationHistory, dispatch),
  fetchAutoLockingRules: bindActionCreators(fetchAutoLockingRules, dispatch),
  changeAutoLockingRule: bindActionCreators(changeAutoLockingRule, dispatch),
  saveIntegrationMapping: bindActionCreators(saveIntegrationMapping, dispatch),
  deleteIntegrationMapping: bindActionCreators(deleteIntegrationMapping, dispatch),
  resetIntegrationErrors: bindActionCreators(resetIntegrationErrors, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Settings);
