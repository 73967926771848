/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import validate from 'validate.js';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, TextField, CircularProgress, Theme } from '@material-ui/core';

import { ILoginCredentials } from 'app/models';

interface IProps {
  className: string;
  login: (loginDetails: ILoginCredentials) => void;
  isLoading: boolean;
}

interface IFormState {
  isValid: boolean;
  values: {
    [key: string]: any;
  };
  touched: {
    [key: string]: any;
  };
  errors: {
    [key: string]: string;
  };
}

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
  },
};

// FIX: create interface for theme
const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  fields: {
    margin: theme.spacing(-1),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
}));

const LoginForm = (props: IProps) => {
  const { className, login, isLoading } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState<IFormState>({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formStateValue: IFormState) => ({
      ...formStateValue,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();

    setFormState((formStateValue: IFormState) => ({
      ...formStateValue,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    login(formState.values as ILoginCredentials);
  };

  const hasError = (field: string) => !!(formState.touched[field] && formState.errors[field]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <form className={clsx(classes.root, className)} onSubmit={handleSubmit}>
      <div className={classes.fields}>
        <TextField
          error={hasError('email')}
          fullWidth
          helperText={hasError('email') ? formState.errors.email[0] : null}
          label="Email address"
          name="email"
          onChange={handleChange}
          value={formState.values.email || ''}
          variant="outlined"
        />
        <TextField
          error={hasError('password')}
          fullWidth
          helperText={hasError('password') ? formState.errors.password[0] : null}
          label="Password"
          name="password"
          onChange={handleChange}
          type="password"
          value={formState.values.password || ''}
          variant="outlined"
        />
      </div>
      <Button
        className={classes.submitButton}
        color="primary"
        disabled={!formState.isValid || isLoading}
        size="large"
        type="submit"
        variant="contained"
      >
        {isLoading ? 'Signing In...' : 'Sign in'}
        {''}
        {isLoading && <CircularProgress disableShrink size={18} thickness={4} />}
      </Button>
    </form>
  );
};

export default LoginForm;
