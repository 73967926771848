import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { VehicleTypes } from 'app/views';
import { IRootState } from 'app/reducers/AppState';
import { IVehicleTypesModel, IVehicleTypeListModel } from 'app/models';
import { handleEditVehicle, revertAddVehicleType, fetchVehiclesTypesList } from 'app/actions';

interface StateFromProps {
  vehicleTypes: IVehicleTypesModel;
}

interface DispatchFromProps {
  fetchVehicleTypesList: () => void;
  handleEditVehicleType: (vehicle: IVehicleTypeListModel) => void;
  cancelAddOrEditVehicleType: () => void;
}

const mapStateToProps = (state: IRootState): StateFromProps => ({
  vehicleTypes: state.vehicleTypes,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => ({
  fetchVehicleTypesList: bindActionCreators(fetchVehiclesTypesList, dispatch),
  handleEditVehicleType: bindActionCreators(handleEditVehicle, dispatch),
  cancelAddOrEditVehicleType: bindActionCreators(revertAddVehicleType, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VehicleTypes);
