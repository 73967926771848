import * as React from 'react';
import clsx from 'clsx';

import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Paper,
  Divider,
} from '@material-ui/core';

import {
  ServeedoTableHeader,
  ITableHeaderCells,
  ServeedoTableToolbar,
  NoData,
  ServeedoMenu,
  ServeedoTableLoading,
} from 'app/components/common';
import { IUserDetail, CompanyRoleType } from 'app/models';

import { getSorting, stableSort } from 'app/helpers/common';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { makeDateRelative } from 'app/helpers/time-helper';

const useStyles = makeStyles((theme: Theme) => ({
  tableMenu: {
    display: 'inline-block',
    float: 'right',
  },
  inActive: {
    color: '#c4c4c4',
  },
}));

const headerCells: ITableHeaderCells[] = [
  { id: 'fullname', numeric: false, disablePadding: false, label: 'Full Name' },
  { id: 'email', numeric: true, disablePadding: false, label: 'Email Address' },
  { id: 'companyRole', numeric: true, disablePadding: false, label: 'Role' },
  { id: 'createdAt', numeric: true, disablePadding: false, label: 'Added' },
];

export interface IProps {
  usersList: IUserDetail[];
  isLoading: boolean;
  updateUser: (user: IUserDetail) => void;
  handleOpenUserModal: (user: IUserDetail) => void;
}

export const UsersManagementList: React.SFC<IProps> = ({
  usersList,
  isLoading,
  updateUser,
  handleOpenUserModal,
}) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState<any>('asc');
  const [orderBy, setOrderBy] = React.useState('fullname');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  const handleRequestSort = (event: any, property: string) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper>
      <ServeedoTableToolbar title={'All Users'} />
      <div className="table-wrapper">
        <Table aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table">
          <ServeedoTableHeader
            numSelected={0}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={0}
            headerCells={headerCells}
            selecting={false}
          />
          <TableBody>
            {isLoading ? (
              <ServeedoTableLoading numberOfColumns={4} />
            ) : (
              stableSort<IUserDetail>(usersList, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((user: IUserDetail, index: number) => {
                  const options = [
                    {
                      name: user.active ? 'De-activate user' : 'Re-activate user',
                      onClick: () => updateUser({ ...user, active: !user.active }),
                    },
                  ];
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      onClick={() => handleOpenUserModal(user)}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className={clsx({ [classes.inActive]: !user.active })}
                      >
                        {user.fullname}
                      </TableCell>
                      <TableCell className={clsx({ [classes.inActive]: !user.active })}>
                        {user.email}
                      </TableCell>
                      <TableCell className={clsx({ [classes.inActive]: !user.active })}>
                        {CompanyRoleType[user.companyRole]}
                      </TableCell>
                      <TableCell className={clsx({ [classes.inActive]: !user.active })}>
                        {makeDateRelative(user.createdAt)}
                        <div
                          className={classes.tableMenu}
                          onClick={(e: any) => e.stopPropagation()}
                        >
                          <ServeedoMenu options={options} />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
      </div>
      {!isLoading && !usersList.length && (
        <>
          <NoData />
          <Divider />
        </>
      )}
      <TablePagination
        rowsPerPageOptions={[50, 100, 250]}
        component="div"
        count={usersList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
