import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import { IResponseHandlerModel, IMethods, IUserDetail } from 'app/models';
import { ApiHelper } from 'app/helpers/api-helper';
import { IRootState } from 'app/reducers/AppState';

export enum IUsersManagementType {
  REQUEST_USERS_LIST = 'REQUEST_USERS_LIST',
  RECEIVE_USERS_LIST = 'RECEIVE_USERS_LIST',
  FAILURE_USERS_LIST = 'FAILURE_USERS_LIST',

  REQUEST_MODIFY_USER = 'REQUEST_MODIFY_USER',
  RECEIVE_MODIFY_USER = 'RECEIVE_MODIFY_USER',
  FAILURE_MODIFY_USER = 'FAILURE_MODIFY_USER',

  REQUEST_DELETE_USER = 'REQUEST_DELETE_USER',
  RECEIVE_DELETE_USER = 'RECEIVE_DELETE_USER',
  FAILURE_DELETE_USER = 'FAILURE_DELETE_USER',

  HANDLE_OPEN_MODAL = 'HANDLE_OPEN_MODAL',
  HANDLE_CLOSE_MODAL = 'HANDLE_CLOSE_MODAL',
}

// tslint:disable:typedef

export const requestUsersList = createAction(IUsersManagementType.REQUEST_USERS_LIST);
export const receiveUsersList = createAction<IUserDetail[]>(
  IUsersManagementType.RECEIVE_USERS_LIST,
);
export const failureUsersList = createAction(IUsersManagementType.FAILURE_USERS_LIST);

export const requestModifyUser = createAction(IUsersManagementType.REQUEST_MODIFY_USER);
export const receiveModifyUser = createAction<IUserDetail[]>(
  IUsersManagementType.RECEIVE_MODIFY_USER,
);
export const failureModifyUser = createAction(IUsersManagementType.FAILURE_MODIFY_USER);

export const requestDeleteUser = createAction(IUsersManagementType.REQUEST_DELETE_USER);
export const receiveDeleteUser = createAction<IUserDetail[]>(
  IUsersManagementType.RECEIVE_DELETE_USER,
);
export const failureDeleteUser = createAction(IUsersManagementType.FAILURE_DELETE_USER);

export const hanldeOpenModal = createAction<IUserDetail>(IUsersManagementType.HANDLE_OPEN_MODAL);
export const hanldeCloseModal = createAction(IUsersManagementType.HANDLE_CLOSE_MODAL);

export const fetchUsersList = () => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    dispatch(requestUsersList());
    try {
      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        '/users',
        IMethods.GET,
        true,
      );

      if (response.isError) {
        dispatch(failureUsersList());
        return;
      }
      const usersList = response.data.data as IUserDetail[];
      dispatch(receiveUsersList(usersList));
    } catch (e) {
      dispatch(failureUsersList());
    }
  };
};

export const addOrUpdateUser = (user: IUserDetail) => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    const {
      usersManagement: { usersList },
    } = getState();
    dispatch(requestModifyUser());
    try {
      let response: IResponseHandlerModel;
      let usersListState = [...usersList];
      if (user.id) {
        response = await new ApiHelper().FetchFromPortal(
          '/users/' + user.id,
          IMethods.PUT,
          true,
          undefined,
          user,
        );
        if (response.isError) {
          dispatch(failureUsersList());
          return;
        }
        const updatedUser = response.data.data as IUserDetail;
        const index = usersListState.findIndex((u) => u.id === user.id);
        usersListState[index] = updatedUser;
        dispatch(receiveModifyUser(usersListState));
      } else {
        response = await new ApiHelper().FetchFromPortal('/users', IMethods.POST, true, undefined, {
          fullname: user.fullname,
          email: user.email,
          companyRole: user.companyRole,
        });
        if (response.isError) {
          dispatch(failureModifyUser());
          return;
        }
        const newUser = response.data.data as IUserDetail;
        usersListState.push(newUser);
        dispatch(receiveModifyUser(usersListState));
      }
    } catch (e) {
      dispatch(failureModifyUser());
    }
  };
};

/**
 * Triggers the edit state updates
 * @param user user to edit
 */
export const openUserModal = (user: IUserDetail) => {
  return (dispatch: Dispatch) => {
    dispatch(hanldeOpenModal(user));
  };
};

export const closeUserModal = () => {
  return (dispatch: Dispatch) => {
    dispatch(hanldeCloseModal());
  };
};

export const deleteUser = () => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    const {
      usersManagement: { selectedUser, usersList },
    } = getState();
    if (!selectedUser) return;
    const usersListState = [...usersList];
    dispatch(requestDeleteUser());
    try {
      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        '/users/' + selectedUser.id,
        IMethods.DELETE,
        true,
      );

      if (response.isError) {
        dispatch(failureDeleteUser());
        return;
      }
      const index = usersListState.findIndex((u) => u.id === selectedUser.id);
      usersListState.splice(index, 1);
      dispatch(receiveDeleteUser(usersListState));
    } catch (e) {
      dispatch(failureDeleteUser());
    }
  };
};
