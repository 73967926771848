import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

import { days } from 'app/constants/days';
import { DayModel, IPriorityPeriod } from 'app/models';
import { TimeField } from 'app/components/common';
import { ModalFooter } from '../ModalFooter/ModalFooter';
import { ModalHeader } from '../ModalHeader/ModalHeader';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    flex: 2,
    marginTop: 16,
    marginBottom: 8,
  },
}));

export interface IProps {
  openModal: boolean;
  handleClose: () => void;
  handleContinue: (priorityPeriod: IPriorityPeriod) => void;
  selectedPriorityPeriod: IPriorityPeriod | null;
  isEditing?: boolean;
}

export const AddPriorityPeriodModal: React.SFC<IProps> = ({
  openModal,
  handleClose,
  handleContinue,
  isEditing,
  selectedPriorityPeriod,
}) => {
  const classes = useStyles();

  const [startDay, setStartDay] = useState(1);
  const [startTime, setStartTime] = useState('0:00');
  const [endDay, setEndDay] = useState(7);
  const [endTime, setEndTime] = useState('23:59');
  const [priority, setPriority] = useState(0);

  useEffect(() => {
    if (selectedPriorityPeriod) {
      setStartDay(selectedPriorityPeriod.start_day);
      setEndDay(selectedPriorityPeriod.end_day);
      setStartTime(
        `${('0' + selectedPriorityPeriod.start_hour).slice(-2)}:${(
          '0' + selectedPriorityPeriod.start_minute
        ).slice(-2)}`,
      );
      setEndTime(
        `${('0' + selectedPriorityPeriod.end_hour).slice(-2)}:${(
          '0' + selectedPriorityPeriod.end_minute
        ).slice(-2)}`,
      );
      setPriority(selectedPriorityPeriod.priority);
    }
    if (!openModal) {
      setTimeout(() => {
        setStartDay(1);
        setEndDay(7);
        setStartTime('00:00');
        setEndTime('23:59');
        setPriority(0);
      }, 400);
    }
  }, [selectedPriorityPeriod, openModal]);

  const onStartTimeChange = (event: any, value: any) => {
    const newTime = value.replace(/-/g, ':');
    const time = newTime.substr(0, 5);
    setStartTime(time);
  };

  const onEndTimeChange = (event: any, value: any) => {
    const newTime = value.replace(/-/g, ':');
    const time = newTime.substr(0, 5);
    setEndTime(time);
  };

  const handleAddOrUpdate = () => {
    const priorityPeriod = {
      start_day: startDay,
      start_hour: Number(startTime.split(':')[0]),
      start_minute: Number(startTime.split(':')[1]),
      end_day: endDay,
      end_hour: Number(endTime.split(':')[0]),
      end_minute: Number(endTime.split(':')[1]),
      priority,
    };
    handleContinue(priorityPeriod);
  };

  return (
    <Dialog
      open={openModal}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <ModalHeader
        id={'form-dialog-title'}
        title={`${isEditing ? 'Edit' : 'Add'} period priority`}
      />
      <DialogContent>
        <Grid container spacing={1}>
          <Grid container item xs={12} md={3}>
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel htmlFor="outlined-age-native-simple">Start day</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                inputProps={{
                  name: 'startDay',
                  id: 'outlined-age-native-simple',
                }}
                value={startDay}
                onChange={(e) => setStartDay(Number(e.target.value))}
              >
                {days.map((day: DayModel, index: number) => {
                  return (
                    <MenuItem key={index} value={day}>
                      {DayModel[day]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid container item xs={12} md={2}>
            <TimeField
              showSeconds={false}
              value={startTime}
              onChange={onStartTimeChange}
              style={{ width: 88 }}
              input={
                <TextField
                  label="Start time"
                  value={startTime}
                  variant="outlined"
                  margin="normal"
                />
              }
            />
          </Grid>
          <Grid container item xs={12} md={3}>
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel htmlFor="outlined-age-native-simple">End day</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                inputProps={{
                  name: 'endDay',
                  id: 'outlined-age-native-simple',
                }}
                value={endDay}
                onChange={(e) => setEndDay(Number(e.target.value))}
              >
                {days.map((day: DayModel, index: number) => {
                  return (
                    <MenuItem key={index} value={day}>
                      {DayModel[day]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid container item xs={12} md={2}>
            <TimeField
              showSeconds={false}
              value={endTime}
              onChange={onEndTimeChange}
              style={{ width: 88 }}
              input={
                <TextField label="End time" value={endTime} variant="outlined" margin="normal" />
              }
            />
          </Grid>

          <Grid container item xs={12} md={2}>
            <TextField
              key={'priority'}
              type={'number'}
              id="priority-required"
              label="Priority"
              margin="normal"
              variant="outlined"
              value={priority}
              onChange={(e) => {
                let value = Number(e.target.value);
                if (value > 5) {
                  value = 5;
                }
                if (value < 0) {
                  value = 0;
                }
                setPriority(value);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <ModalFooter
        isEditing={isEditing}
        onContinueClick={handleAddOrUpdate}
        onCancelClick={handleClose}
      />
    </Dialog>
  );
};
