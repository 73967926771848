import * as React from 'react';
import clsx from 'clsx';
import { Typography, Grid, Paper } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    borderRadius: 0,
    marginBottom: 0,
  },
  clickAble: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#eee',
    },
  },
}));
interface IProps {
  title: number | string;
  value: number | string;
  onClick?: () => void;
}

export const IntegrationTableRow: React.SFC<IProps> = (props) => {
  const classes = useStyles();
  return (
    <Paper
      className={clsx(classes.root, { [classes.clickAble]: props.onClick })}
      onClick={props.onClick}
    >
      <Grid container xs={12} item>
        <Grid item xs={9}>
          <Typography variant="body1" noWrap>
            {props.title}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body1" noWrap>
            {props.value}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};
