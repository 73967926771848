import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  formControl: {
    padding: '8px 0',
  },
  formControlLabel: {
    marginTop: 12,
    marginBottom: 10,
    color: '#90A4AE',
  },
  activity: {
    textTransform: 'capitalize',
  },
  error: {
    color: '#e53935',
    fontSize: 11,
  },
}));
