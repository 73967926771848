import { handleActions, Reducer } from 'redux-actions';
import { IRootState } from 'app/reducers/AppState';
import { IUsersManagementModel, IUserDetail } from 'app/models';
import { IUsersManagementType } from 'app/actions';

const initialState: IUsersManagementModel = {
  usersList: [],
  selectedUser: null,
  isLoading: false,
  isProcessing: false,
  isDeleting: false,
};

// tslint:disable-next-line:typedef
export const usersManagementReducer: Reducer<
  IRootState.UsersManagementState,
  IUsersManagementModel
> = handleActions<
  IRootState.UsersManagementState,
  IUsersManagementModel | IUserDetail[] | IUserDetail
>(
  {
    [IUsersManagementType.REQUEST_USERS_LIST]: (
      state = initialState,
    ): IRootState.UsersManagementState => ({
      ...state,
      isLoading: true,
      selectedUser: null,
    }),
    [IUsersManagementType.RECEIVE_USERS_LIST]: (
      state = initialState,
      action,
    ): IRootState.UsersManagementState => ({
      ...state,
      usersList: action.payload as IUserDetail[],
      isLoading: false,
    }),
    [IUsersManagementType.FAILURE_USERS_LIST]: (
      state = initialState,
      action,
    ): IRootState.UsersManagementState => ({
      ...state,
      isLoading: false,
    }),
    [IUsersManagementType.REQUEST_MODIFY_USER]: (
      state = initialState,
    ): IRootState.UsersManagementState => ({
      ...state,
      isProcessing: true,
    }),
    [IUsersManagementType.RECEIVE_MODIFY_USER]: (
      state = initialState,
      action,
    ): IRootState.UsersManagementState => ({
      ...state,
      usersList: action.payload as IUserDetail[],
      isProcessing: false,
      selectedUser: null,
    }),
    [IUsersManagementType.FAILURE_MODIFY_USER]: (
      state = initialState,
    ): IRootState.UsersManagementState => ({
      ...state,
      isProcessing: false,
    }),
    [IUsersManagementType.HANDLE_OPEN_MODAL]: (
      state = initialState,
      action,
    ): IRootState.UsersManagementState => ({
      ...state,
      selectedUser: action.payload as IUserDetail,
    }),
    [IUsersManagementType.HANDLE_CLOSE_MODAL]: (
      state = initialState,
    ): IRootState.UsersManagementState => ({
      ...state,
      selectedUser: null,
    }),
    [IUsersManagementType.REQUEST_DELETE_USER]: (
      state = initialState,
    ): IRootState.UsersManagementState => ({
      ...state,
      isDeleting: true,
    }),
    [IUsersManagementType.RECEIVE_DELETE_USER]: (
      state = initialState,
      action,
    ): IRootState.UsersManagementState => ({
      ...state,
      usersList: action.payload as IUserDetail[],
      isDeleting: false,
    }),
    [IUsersManagementType.FAILURE_DELETE_USER]: (
      state = initialState,
    ): IRootState.UsersManagementState => ({
      ...state,
      isDeleting: false,
    }),
  },
  initialState,
);
