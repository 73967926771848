import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { IRootState } from 'app/reducers/AppState';
import { Fleet } from 'app/views';
import { fetchFleetList, fetchVehiclesTypesList, getFleetStates } from 'app/actions';
import { IFleetModel, IVehicleTypeListModel, IRegion } from 'app/models';

interface StateFromProps {
  fleet: IFleetModel;
  vehicleTypesList: IVehicleTypeListModel[];
  selectedRegion: IRegion | null;
  regions: IRegion[];
}

interface DispatchFromProps {
  fetchFleetList: () => void;
  fetchVehiclesList: () => void;
  getFleetStates: () => void;
}

const mapStateToProps = (state: IRootState): StateFromProps => ({
  fleet: state.fleet,
  vehicleTypesList: state.vehicleTypes.vehicleTypesList,
  selectedRegion: state.userDetails.selectedRegion,
  regions: state.userDetails.regions,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => ({
  fetchFleetList: bindActionCreators(fetchFleetList, dispatch),
  fetchVehiclesList: bindActionCreators(fetchVehiclesTypesList, dispatch),
  getFleetStates: bindActionCreators(getFleetStates, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Fleet);
