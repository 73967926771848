import palette from '../palette';

export default {
  root: {
    '&$focused .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.primary.main,
    },
    '&$error .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.error.main,
    },
    multiline: {
      minHeight: '100%',
    },
  },
  inputMultiline: {
    minHeight: '100px !important',
  },
  notchedOutline: {
    borderColor: 'rgba(0,0,0,0.15)',
  },
};
