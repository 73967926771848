import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { IRootState } from 'app/reducers/AppState';
import { SpareParts } from 'app/views';
import { DeleteItemsFunction } from '../../models/RepairGuidesModel';
import {
  ISparePartsModel,
  IVehicleTypeListModel,
  ISparePartModel,
  IAddSparePartModel,
} from 'app/models';
import {
  addNewSparePart,
  openEditSparePartModal,
  closeEditSparePartModal,
  deleteSparePart,
  fetchSparePartsList,
  fetchVehiclesTypesList,
  deleteSpareParts,
} from 'app/actions';

interface StateFromProps {
  spareParts: ISparePartsModel;
  vehicleTypesList: IVehicleTypeListModel[];
}

interface DispatchFromProps {
  fetchVehicleTypes: () => void;
  fetchSparePartsList: () => void;
  addSparePart: (sparePart: IAddSparePartModel) => void;
  openEditSparePartModal: (sparePart: ISparePartModel) => void;
  closeEditSparePartModal: () => void;
  handleDeleteSparePart: () => void;
  deleteSpareParts: DeleteItemsFunction;
}

const mapStateToProps = (state: IRootState): StateFromProps => ({
  spareParts: state.spareParts,
  vehicleTypesList: state.vehicleTypes.vehicleTypesList,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => ({
  fetchVehicleTypes: bindActionCreators(fetchVehiclesTypesList, dispatch),
  fetchSparePartsList: bindActionCreators(fetchSparePartsList, dispatch),
  addSparePart: bindActionCreators(addNewSparePart, dispatch),
  openEditSparePartModal: bindActionCreators(openEditSparePartModal, dispatch),
  closeEditSparePartModal: bindActionCreators(closeEditSparePartModal, dispatch),
  handleDeleteSparePart: bindActionCreators(deleteSparePart, dispatch),
  deleteSpareParts: bindActionCreators(deleteSpareParts, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SpareParts);
