import React from 'react';
import { TableCell, TableRow, Checkbox } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

interface IProps {
  numberOfColumns: number;
  hasCheckbox?: boolean;
}

export const ServeedoTableLoading: React.SFC<IProps> = ({ numberOfColumns, hasCheckbox }) => {
  return (
    <>
      {[1, 2, 3].map((i) => (
        <TableRow key={i} role="checkbox">
          {hasCheckbox && (
            <TableCell padding="checkbox">
              <Checkbox inputProps={{ 'aria-labelledby': '' }} disabled />
            </TableCell>
          )}
          {Array.from(Array(numberOfColumns).keys()).map((item, index) => (
            <TableCell key={item} padding={index === 0 && hasCheckbox ? 'none' : 'default'}>
              <React.Fragment>
                <Skeleton height={10} width="60%" style={{ marginTop: 6, marginBottom: 6 }} />
              </React.Fragment>
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};
