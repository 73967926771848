import * as React from 'react';
import { WidgetHeader } from 'app/components/WidgetHeader/WidgetHeader';
import { PartsStockList } from 'app/components/PartsStockList/PartsStockList';
import {
  ISparePartModel,
  IVehicleTypeListModel,
  IPartsStockModel,
  IUserDetail,
  ISupportedRoutes,
  IRegion,
} from 'app/models';
import { RouteComponentProps } from 'react-router';

export interface IProps extends RouteComponentProps<any> {
  partsStock: IPartsStockModel;
  sparePartsList: ISparePartModel[];
  vehicleTypesList: IVehicleTypeListModel[];
  fetchPartsStockList: () => void;
  fetchSparePartsList: () => void;
  fetchVehicleList: () => void;
  userDetails: IUserDetail | null;
  handleUpdateSpareStock: (id: string, amount: number) => void;
  selectedRegion: IRegion | null;
}
export interface IState {}
class PartsStock extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { selectedRegion } = this.props;
    if (selectedRegion && selectedRegion.key !== 'all') {
      this.props.fetchPartsStockList();
    }
    this.props.fetchVehicleList();
  }

  componentDidUpdate(prevProps: IProps) {
    const { selectedRegion } = this.props;
    if (prevProps.selectedRegion !== selectedRegion && selectedRegion) {
      this.props.fetchPartsStockList();
      if (selectedRegion.key === 'all') return;
      const newUrl = '/' + selectedRegion.key + ISupportedRoutes.PARTS_STOCK;
      this.props.history.push(newUrl);
    }
  }

  render(): JSX.Element {
    const {
      partsStock: { partsStockList, isLoading, isUpdating },
      vehicleTypesList,
      sparePartsList,
      userDetails,
      handleUpdateSpareStock,
      selectedRegion,
    } = this.props;
    return (
      <div className={'container'}>
        <WidgetHeader
          headerText={'Parts Stock'}
          headerSmallText={selectedRegion ? selectedRegion.name.toUpperCase() : 'All'}
        />
        <PartsStockList
          regionId={selectedRegion ? selectedRegion.id : undefined}
          partsStockList={partsStockList}
          vehicleTypesList={vehicleTypesList}
          isLoading={isLoading}
          sparePartsList={sparePartsList}
          userDetails={userDetails}
          handleUpdateSpareStock={handleUpdateSpareStock}
          isUpdating={isUpdating}
        />
      </div>
    );
  }
}

export default PartsStock;
