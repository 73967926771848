import * as React from 'react';
import { matchPath } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { List, Typography, Divider, Theme } from '@material-ui/core';

import useRouter from 'app/helpers/use-router';
import { NavigationListItem } from './NavigationListItem/NavigationListItem';
import { LocationDropdown } from '../LocationDropdown/LocationDropdown';
import { IRegion, IMaintainer } from 'app/models';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    paddingBottom: theme.spacing(1.5),
  },
  divider: {
    marginBottom: theme.spacing(1.5),
  },
}));

export const NavigationList: React.SFC<any> = (props) => {
  const { pages, ...rest } = props;

  return (
    <List>
      {pages.reduce((items: any, page: any) => reduceChildRoutes({ items, page, ...rest }), [])}
    </List>
  );
};

const reduceChildRoutes: React.SFC<any> = (props) => {
  const { router, items, page, depth } = props;

  if (page.children) {
    const open = matchPath(router.location.pathname, {
      path: page.href,
      exact: false,
    });

    items.push(
      <NavigationListItem
        depth={depth}
        icon={page.icon}
        key={page.title}
        label={page.label}
        open={Boolean(open)}
        title={page.title}
        href={page.href}
      >
        <NavigationList depth={depth + 1} pages={page.children} router={router} />
      </NavigationListItem>,
    );
  } else {
    items.push(
      <NavigationListItem
        depth={depth}
        href={page.href}
        icon={page.icon}
        key={page.title}
        label={page.label}
        title={page.title}
      />,
    );
  }

  return items;
};

export interface IProps {
  title?: string;
  pages: any;
  regions: IRegion[];
  onChangeRegion: (region: IRegion, maintainer?: IMaintainer) => void;
  selectedRegion: IRegion | null;
  className?: string;
  component: any;
  maintainers?: IMaintainer[] | null;
  selectedMaintainer: IMaintainer | null;
}

export const Navigation: React.SFC<IProps> = (props) => {
  const {
    title,
    pages,
    regions,
    onChangeRegion,
    selectedRegion,
    className,
    component: Component,
    maintainers,
    selectedMaintainer,
    ...rest
  } = props;

  const classes = useStyles();
  const router = useRouter();

  return (
    <Component {...rest} className={clsx(classes.root, className)}>
      <Divider className={classes.divider} />
      {title && <Typography variant="overline">{title}</Typography>}
      {title && title === 'Location' && selectedRegion && (
        <LocationDropdown
          regions={regions}
          onChangeRegion={onChangeRegion}
          selectedRegion={selectedRegion}
          maintainers={maintainers}
          selectedMaintainer={selectedMaintainer}
        />
      )}
      <NavigationList depth={0} pages={pages} router={router} />
    </Component>
  );
};
