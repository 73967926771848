import * as React from 'react';

interface IProps {
  message?: string;
}

export const NoData: React.SFC<IProps> = ({ message }) => {
  return (
    <div
      style={{
        textAlign: 'center',
        padding: '24px 0',
        color: 'rgba(0, 0, 0, 0.23)',
      }}
    >
      <h3>{message ? message : 'No data found'}</h3>
    </div>
  );
};
