import { handleActions, Reducer } from 'redux-actions';
import { IRootState } from 'app/reducers/AppState';
import { IDevelopmentActionsType } from 'app/actions';
import { IDevelopmentModel } from 'app/models';

const initialState: IDevelopmentModel = {
  isLoading: false,
};

// tslint:disable-next-line:typedef
export const developmentReducer: Reducer<
  IRootState.DevelopmentState,
  IDevelopmentModel
> = handleActions<IRootState.DevelopmentState, IDevelopmentModel>(
  {
    [IDevelopmentActionsType.REQUEST_DEVELOPMENT]: (
      state = initialState,
    ): IRootState.DevelopmentState => ({
      ...state,
      isLoading: true,
    }),
  },
  initialState,
);
