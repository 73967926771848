import * as React from 'react';
import { WidgetHeader } from 'app/components/WidgetHeader/WidgetHeader';
import { AccountForm, History } from 'app/components/Settings';
import {
  ISettingsModel,
  IRegion,
  SaveIntegrationFunction,
  IRemoteMapping121,
  IMappingType,
  SaveIntegrationMappingFunction,
  DeleteIntegrationMappingFunction,
} from 'app/models';
import { IntegrationMappingList } from './IntegrationMappingList';
import {
  IntegrationMappingModal,
  IDropdownItem,
} from './IntegrationMappingModal/IntegrationMappingModal';

export interface IProps {
  settings: ISettingsModel;
  regions: IRegion[];
  fetchIntegrationInfo: () => void;
  saveIntegrationInfo: SaveIntegrationFunction;
  fetchIntegrationLocalVehicleTypes: () => void;
  fetchIntegrationVehicleTypes: () => void;
  fetchIntegrationLocalVehicleStates: () => void;
  fetchIntegrationVehicleStates: () => void;
  fetchIntegrationRegions: () => void;
  fetchIntegrationHistory: () => void;
  saveIntegrationMapping: SaveIntegrationMappingFunction;
  deleteIntegrationMapping: DeleteIntegrationMappingFunction;
  resetIntegrationErrors: () => void;
}

export interface IState {
  apiKey: string;
  apiUrl: string;
  hasEditedAccount: boolean;
  openModal: boolean;
  mappingModalItems: IDropdownItem[] | null;
  selectedMap: IRemoteMapping121 | null;
  selectedMappingType: IMappingType;
}

class Integration extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      apiKey: props.settings.wunderApiKey,
      apiUrl: props.settings.wunderApiUrl,
      hasEditedAccount: false,
      openModal: false,
      mappingModalItems: null,
      selectedMap: null,
      selectedMappingType: IMappingType.NONE,
    };
  }

  componentDidMount() {
    this.props.fetchIntegrationInfo();
    this.props.fetchIntegrationLocalVehicleTypes();
    this.props.fetchIntegrationVehicleTypes();
    this.props.fetchIntegrationLocalVehicleStates();
    this.props.fetchIntegrationVehicleStates();
    this.props.fetchIntegrationRegions();
    this.props.fetchIntegrationHistory();
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.settings.wunderApiKey !== this.props.settings.wunderApiKey) {
      this.setState({
        apiKey: this.props.settings.wunderApiKey,
        apiUrl: this.props.settings.wunderApiUrl,
      });
    }

    if (
      prevProps.settings.isProcessingModal !== this.props.settings.isProcessingModal &&
      !this.props.settings.isProcessingModal /* &&
      !this.props.settings.isError*/
    ) {
      this.setState({
        openModal: false,
        mappingModalItems: null,
        selectedMap: null,
        selectedMappingType: IMappingType.NONE,
      });
    }
  }

  didEditAccountInfo = (apiKey: string, apiUrl: string) => {
    this.setState({
      apiKey,
      apiUrl,
      hasEditedAccount: true,
    });
  };

  saveAccount = () => {
    this.props.saveIntegrationInfo(this.state.apiKey, this.state.apiUrl);
  };

  promptAddVehicleTypeMapping = (vehicleType?: IRemoteMapping121) => {
    const mappingModalItems: IDropdownItem[] = this.props.settings.localVehicleTypes.map(
      (type) => ({
        localId: type.id,
        label: type.name,
      }),
    );
    this.setState({
      openModal: true,
      selectedMappingType: IMappingType.VEHICLE,
      mappingModalItems,
      selectedMap: vehicleType || null,
    });
  };

  promptAddStateMapping = (state?: IRemoteMapping121) => {
    const mappingModalItems: IDropdownItem[] = this.props.settings.localVehicleStates.map(
      (state) => ({
        localId: state.id,
        label: state.translations.en,
      }),
    );
    this.setState({
      openModal: true,
      selectedMappingType: IMappingType.STATE,
      mappingModalItems,
      selectedMap: state || null,
    });
  };

  promptAddRegionMapping = (region?: IRemoteMapping121) => {
    const mappingModalItems: IDropdownItem[] = this.props.regions
      .filter((region) => region.id !== 'all')
      .map((region) => ({
        localId: region.id,
        label: region.name,
      }));
    this.setState({
      openModal: true,
      selectedMappingType: IMappingType.REGION,
      mappingModalItems,
      selectedMap: region || null,
    });
  };

  getVehicleTypeName = (id: string): string => {
    const localTypes = this.props.settings.localVehicleTypes;
    if (localTypes.length > 0) {
      const localType = localTypes.find((t) => t.id === id);
      if (localType) {
        return localType.name;
      }
    }
    return id;
  };

  getVehicleStateName = (id: number): string => {
    const localStates = this.props.settings.localVehicleStates;
    if (localStates.length > 0) {
      const localState = localStates.find((t) => t.id === id);
      if (localState) {
        return localState.translations.en;
      }
    }
    return 'Unknown';
  };

  getRegionName = (id: string): string => {
    const localRegion = this.props.regions.find((r) => r.id === id);
    if (localRegion) {
      return localRegion.name;
    }
    return id;
  };

  render(): JSX.Element {
    const vehicleTypes = this.props.settings.remoteVehicleTypes.map((type) => {
      type.name = this.getVehicleTypeName(type.localId as string);
      return type;
    });

    const vehicleStates = this.props.settings.remoteVehicleStates.map((type) => {
      type.name = this.getVehicleStateName(type.localId as number);
      return type;
    });

    const regions = this.props.settings.remoteRegions.map((region) => {
      region.name = this.getRegionName(region.localId as string);
      return region;
    });

    return (
      <React.Fragment>
        <WidgetHeader headerText={'Wunder Integration'} headerSmallText={'SETTINGS'} />
        <AccountForm
          apiKey={this.state.apiKey}
          apiUrl={this.state.apiUrl}
          canEdit={!this.props.settings.isSavingIntegrationInfo}
          onEdit={this.didEditAccountInfo}
          canSave={this.state.hasEditedAccount}
          onSave={this.saveAccount}
          error={this.props.settings.accountError}
        />
        <IntegrationMappingList
          title="Vehicles"
          subtitle="Vehicle Type"
          addButtonLabel="Add Vehicle"
          items={vehicleTypes}
          onAdd={this.promptAddVehicleTypeMapping}
        />
        <IntegrationMappingList
          title="States"
          subtitle="States"
          addButtonLabel="Add State"
          items={vehicleStates}
          onAdd={this.promptAddStateMapping}
        />
        <IntegrationMappingList
          title="Locations"
          subtitle="Locations"
          addButtonLabel="Add Location"
          items={regions}
          onAdd={this.promptAddRegionMapping}
        />
        <History items={this.props.settings.integrationHistory} />
        {this.state.openModal && this.state.mappingModalItems && (
          <IntegrationMappingModal
            openModal={this.state.openModal}
            mappingModalItems={this.state.mappingModalItems}
            selectedMappingType={this.state.selectedMappingType}
            selectedMap={this.state.selectedMap}
            onClose={() =>
              this.setState(
                {
                  openModal: false,
                  mappingModalItems: null,
                  selectedMappingType: IMappingType.NONE,
                  selectedMap: null,
                },
                () => {
                  this.props.resetIntegrationErrors();
                },
              )
            }
            onContinue={(localId, remoteId) => {
              const id = this.state.selectedMap ? this.state.selectedMap.id : null;
              this.props.saveIntegrationMapping(
                this.state.selectedMappingType,
                localId,
                remoteId,
                id,
              );
            }}
            onDelete={() => {
              const id = this.state.selectedMap!.id;
              this.props.deleteIntegrationMapping(this.state.selectedMappingType, id);
            }}
            error={this.props.settings.modalError}
          />
        )}
      </React.Fragment>
    );
  }
}

export default Integration;
