import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
  },

  imageWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 250,
    background: '#E6E6E6',
    height: 150,
    position: 'relative',
    overflow: 'hidden',
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  bgDark: {
    background: '#202123',
  },
  clearIcon: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  uploadIcon: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  icon: {
    background: '#fff',
    '&:hover': {
      color: '#fff',
      background: 'rgba(0, 0, 0, 0.5)',
    },
  },
  image: {
    maxWidth: '100%',
    height: '100%',
  },
  input: {
    display: 'none',
  },
}));
