import * as React from 'react';

import { TextField, Divider, Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';

import { IAddVehicleTypeModel } from 'app/models';

import { useStyles } from './styles';
import { ImageUpload } from 'app/components/common/ImageUpload/ImageUpload';

export interface IProps {
  handleChange: (vehicleType: IAddVehicleTypeModel) => void;
  vehicleType: IAddVehicleTypeModel;
  vehicleTypeError: boolean | undefined;
  handleChangePhoto: (file: File | null) => void;
  handleChangeDiagrams: (files: File | null) => void;
}

export const AddVehicleTypeForm: React.SFC<IProps> = ({
  handleChange,
  vehicleType,
  vehicleTypeError,
  handleChangePhoto,
  handleChangeDiagrams,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.addVehicleTypeForm}>
      <Toolbar>
        <Typography variant="h4" noWrap>
          Info
        </Typography>
      </Toolbar>
      <Divider />
      <form className={classes.container} autoComplete="off" onSubmit={() => console.log('okkk')}>
        <TextField
          key={'name'}
          error={!vehicleType.name && vehicleTypeError}
          id="outlined-required-name"
          label="Name"
          value={vehicleType.name}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          helperText={
            vehicleTypeError && !vehicleType.name
              ? 'Vehicle name is required!'
              : 'Use a short name, like 1 word.'
          }
          onChange={(e) => handleChange({ ...vehicleType, name: e.target.value })}
        />
        <TextField
          key={'description'}
          error={!vehicleType.description && vehicleTypeError}
          id="outlined-required-description"
          label="Description"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={vehicleType.description}
          onChange={(e) => handleChange({ ...vehicleType, description: e.target.value })}
          helperText={
            !vehicleType.description && vehicleTypeError
              ? 'Vehicle description is required!'
              : undefined
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={vehicleType.hasSwappableBattery}
              onChange={(e) =>
                handleChange({ ...vehicleType, hasSwappableBattery: e.target.checked })
              }
              name="hasSwappableBattery"
            />
          }
          label="Has swappable battery"
          labelPlacement="end"
        />
        <div className={classes.vehicleMedia}>
          <div className={classes.media}>
            <Typography variant="subtitle2" gutterBottom className={classes.title}>
              Photo (optional)
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              Transparent background recommended.
            </Typography>
            <ImageUpload
              name={'vehicleTypePhoto'}
              onChange={(file) => handleChangePhoto(file as File)}
              onRemove={() => {
                handleChangePhoto(null);
                handleChange({ ...vehicleType, imageUrl: null });
              }}
              defaultImage={vehicleType.imageUrl}
            />
          </div>
          <div className={classes.media}>
            <Typography variant="subtitle2" gutterBottom className={classes.title}>
              Diagrams (optional)
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              White on transparent or dark background recommended.
            </Typography>
            <ImageUpload
              name={'vehicleTypeDiagrams'}
              onChange={(files) => handleChangeDiagrams(files as File)}
              onRemove={() => {
                handleChangeDiagrams(null);
                handleChange({ ...vehicleType, diagramImageUrl: null });
              }}
              defaultImage={vehicleType.diagramImageUrl}
            />
          </div>
        </div>
      </form>
    </div>
  );
};
