import * as React from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Paper,
  Divider,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import {
  ServeedoTableHeader,
  ITableHeaderCells,
  ServeedoTableToolbar,
  FilterMenuItem,
  ITableFilter,
  NoData,
} from 'app/components/common';
import { ISystemErrorModel, IVehicleTypeListModel } from 'app/models';

import { getSorting, stableSort } from 'app/helpers/common';
import { makeDateRelative } from 'app/helpers/time-helper';

const headerCells: ITableHeaderCells[] = [
  { id: 'code', numeric: false, disablePadding: false, label: 'Code' },
  { id: 'vehicleTypeId', numeric: false, disablePadding: false, label: 'Vehicle Type' },
  { id: 'createdAt', numeric: false, disablePadding: false, label: 'Added' },
];

export interface IProps {
  systemErrorsList: ISystemErrorModel[];
  vehicleTypesList: IVehicleTypeListModel[];
  handleEditSystemError: (systemError: ISystemErrorModel) => void;
  isLoading: boolean;
}

export const SystemErrorsList: React.SFC<IProps> = ({
  systemErrorsList,
  handleEditSystemError,
  vehicleTypesList,
  isLoading,
}) => {
  const [order, setOrder] = React.useState<any>('asc');
  const [orderBy, setOrderBy] = React.useState('code');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [selectedOption, setSelectedOption] = React.useState<FilterMenuItem>({
    label: 'All',
    value: 'all',
  });

  const handleRequestSort = (event: any, property: string) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  let list = stableSort<ISystemErrorModel>(systemErrorsList, getSorting(order, orderBy)).slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  const tableFilter: ITableFilter[] = [
    {
      menuTitle: 'Vehicle Types',
      items: [
        { label: 'All', value: 'all' },
        ...vehicleTypesList.map((v) => {
          const item = {
            label: v.name,
            value: v.id,
          };
          return item;
        }),
      ],
      onSelect: (value) => setSelectedOption(value),
      selectedOption,
    },
  ];

  return (
    <Paper>
      <ServeedoTableToolbar title={'All Errors'} tableFilter={tableFilter} />
      <div className="table-wrapper">
        <Table aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table">
          <ServeedoTableHeader
            numSelected={0}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={0}
            headerCells={headerCells}
            selecting={false}
          />
          <TableBody>
            {isLoading
              ? [1, 2, 3].map((item) => (
                  <TableRow key={item}>
                    <TableCell component="th" scope="row">
                      <React.Fragment>
                        <Skeleton
                          height={10}
                          width="50%"
                          style={{ marginTop: 6, marginBottom: 6 }}
                        />
                      </React.Fragment>
                    </TableCell>
                    <TableCell>
                      <React.Fragment>
                        <Skeleton
                          height={10}
                          width="60%"
                          style={{ marginTop: 6, marginBottom: 6 }}
                        />
                      </React.Fragment>
                    </TableCell>
                    <TableCell>
                      <React.Fragment>
                        <Skeleton
                          height={10}
                          width="90%"
                          style={{ marginTop: 6, marginBottom: 6 }}
                        />
                      </React.Fragment>
                    </TableCell>
                  </TableRow>
                ))
              : list
                  .filter((s) =>
                    selectedOption.value !== 'all'
                      ? selectedOption.value === s.vehicleTypeId
                      : true,
                  )
                  .map((systemError: ISystemErrorModel, index: number) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                        onClick={() => handleEditSystemError(systemError)}
                      >
                        <TableCell component="th" scope="row">
                          {systemError.code}
                        </TableCell>
                        <TableCell>
                          {vehicleTypesList.length ? (
                            [...vehicleTypesList].find((v) => v.id === systemError.vehicleTypeId)!
                              .name
                          ) : (
                            <Skeleton
                              height={10}
                              width="60%"
                              style={{ marginTop: 6, marginBottom: 6 }}
                            />
                          )}
                        </TableCell>
                        <TableCell>{makeDateRelative(systemError.createdAt)}</TableCell>
                      </TableRow>
                    );
                  })}
          </TableBody>
        </Table>
      </div>
      {!isLoading &&
        !list.filter((s) =>
          selectedOption.value !== 'all' ? selectedOption.value === s.vehicleTypeId : true,
        ).length && (
          <>
            <NoData />
            <Divider />
          </>
        )}
      <TablePagination
        rowsPerPageOptions={[50, 100, 250]}
        component="div"
        count={
          list.filter((s) =>
            selectedOption.value !== 'all' ? selectedOption.value === s.vehicleTypeId : true,
          ).length
        }
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
