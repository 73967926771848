import * as React from 'react';
import { WidgetHeader } from 'app/components/WidgetHeader/WidgetHeader';
import {
  ISupportedRoutes,
  IRegion,
  IHotspotsModel,
  IVehicleTypeListModel,
  IHotspotModel,
  IUserDetail,
  IUserRole,
} from 'app/models';
import { RouteComponentProps } from 'react-router';
import { ServeedoButton } from 'app/components/common';

import AddIcon from '@material-ui/icons/Add';
import { HotspotsList } from 'app/components/HotspotsList/HotspotsList';

export interface IProps extends RouteComponentProps<any> {
  hotspots: IHotspotsModel;
  selectedRegion: IRegion | null;
  vehicleTypesList: IVehicleTypeListModel[];
  fetchHotspots: () => void;
  fetchVehicleTypesList: () => void;
  handleEditHotSpot: (hotspot: IHotspotModel) => void;
  userDetails: IUserDetail | null;
  gotoHotspotView: (hotspot: IHotspotModel) => void;
}
export interface IState {}
class Hotspots extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { selectedRegion } = this.props;
    if (selectedRegion && selectedRegion.key !== 'all') {
      this.props.fetchHotspots();
    }
    this.props.fetchVehicleTypesList();
  }

  componentDidUpdate(prevProps: IProps) {
    const { selectedRegion } = this.props;
    if (prevProps.selectedRegion !== selectedRegion && selectedRegion) {
      if (selectedRegion.key === 'all') {
        this.props.history.push(ISupportedRoutes.DASHBOARD);
      } else {
        const newUrl = '/' + selectedRegion.key + ISupportedRoutes.HOT_SPOTS;
        this.props.history.push(newUrl);
        this.props.fetchHotspots();
      }
    }
  }

  gotoNewHotspotPage = () => {
    const { selectedRegion } = this.props;
    this.props.history.push(
      `${selectedRegion && selectedRegion.key !== 'all' ? '/' + selectedRegion.key : ''}` +
        ISupportedRoutes.NEW_HOT_SPOTS,
    );
  };

  renderButton = () => {
    const { userDetails } = this.props;
    const hasAccess = userDetails && userDetails.role === IUserRole.FleetOwner;
    if (!hasAccess) {
      return undefined;
    }
    return (
      <ServeedoButton
        buttonText={'New Hotspot'}
        buttonIcon={<AddIcon />}
        onClick={this.gotoNewHotspotPage}
      />
    );
  };

  render(): JSX.Element {
    const {
      selectedRegion,
      hotspots: { hotspotsList, isLoading },
      vehicleTypesList,
      handleEditHotSpot,
      userDetails,
      gotoHotspotView,
    } = this.props;
    const hasAccess = userDetails && userDetails.role === IUserRole.FleetOwner;
    return (
      <div className={'container'}>
        <WidgetHeader
          headerText={'Hotspots'}
          headerSmallText={selectedRegion ? selectedRegion.name.toUpperCase() : 'All'}
          rightPanel={this.renderButton()}
        />
        <HotspotsList
          hotspotsList={hotspotsList}
          vehicleTypesList={vehicleTypesList}
          isLoading={isLoading}
          selectedRegion={selectedRegion}
          handleEditHotSpot={handleEditHotSpot}
          isReadOnly={!hasAccess}
          gotoHotspotView={gotoHotspotView}
        />
      </div>
    );
  }
}

export default Hotspots;
