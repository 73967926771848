import * as React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider as StoreProvider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { Store } from 'redux';

import { configureStore } from 'app/store';
import App from 'app/App';
import theme from 'assets/theme';

import * as serviceWorker from './serviceWorker';

import { ThemeProvider } from '@material-ui/styles';

import 'assets/index.css';

/** Prepare store */
const history = createBrowserHistory();
export const store: Store = configureStore(history);

ReactDOM.render(
  <StoreProvider store={store}>
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <App />
      </Router>
    </ThemeProvider>
  </StoreProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
