import * as React from 'react';
import AddIcon from '@material-ui/icons/Add';

import { WidgetHeader } from 'app/components/WidgetHeader/WidgetHeader';
import { ServeedoButton } from 'app/components/common';
import {
  ISparePartsModel,
  IVehicleTypeListModel,
  ISparePartModel,
  IAddSparePartModel,
} from 'app/models';
import { SparePartsList } from 'app/components/SparePartsList/SparePartsList';
import { AddSparePartsModal } from 'app/components/Modals/AddSparePartsModal/AddSparePartsModal';
import { DeleteItemsFunction } from '../../models/RepairGuidesModel';

export interface IProps {
  spareParts: ISparePartsModel;
  vehicleTypesList: IVehicleTypeListModel[];
  fetchVehicleTypes: () => void;
  fetchSparePartsList: () => void;
  addSparePart: (sparePart: IAddSparePartModel) => void;
  openEditSparePartModal: (sparePart: ISparePartModel) => void;
  closeEditSparePartModal: () => void;
  handleDeleteSparePart: () => void;
  deleteSpareParts: DeleteItemsFunction;
}
export interface IState {
  openModal: boolean;
}

class SpareParts extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      openModal: false,
    };
  }

  componentDidMount() {
    this.props.fetchVehicleTypes();
    this.props.fetchSparePartsList();
  }

  componentDidUpdate(prevProps: IProps) {
    const {
      spareParts: { isAdding, isDeleting },
      closeEditSparePartModal,
    } = this.props;
    if (
      (prevProps.spareParts.isAdding !== isAdding && !isAdding) ||
      (prevProps.spareParts.isDeleting !== isDeleting && !isDeleting)
    ) {
      this.setState({
        openModal: false,
      });
      closeEditSparePartModal();
    }
  }

  editSparepart = (sparePart: ISparePartModel) => {
    this.setState({
      openModal: true,
    });
    this.props.openEditSparePartModal(sparePart);
  };

  renderButton = (): JSX.Element => {
    return (
      <ServeedoButton
        buttonText={'NEW SPARE PART'}
        buttonIcon={<AddIcon />}
        onClick={() => this.setState({ openModal: true })}
      />
    );
  };

  render(): JSX.Element {
    const {
      spareParts: {
        sparePartsList,
        selectedSparePart,
        isEditing,
        isLoading,
        isAdding,
        isDeleting,
        removingPartsIds,
      },
      vehicleTypesList,
      addSparePart,
      closeEditSparePartModal,
      handleDeleteSparePart,
      deleteSpareParts,
    } = this.props;
    const { openModal } = this.state;
    return (
      <div className={'container'}>
        <WidgetHeader
          headerText={'Spare Parts'}
          headerSmallText={'GENERAL'}
          rightPanel={this.renderButton()}
        />
        <SparePartsList
          sparePartsList={sparePartsList}
          vehicleTypesList={vehicleTypesList}
          editSparePart={this.editSparepart}
          isLoading={isLoading}
          deleteSpareParts={deleteSpareParts}
          deletingPartsIds={removingPartsIds}
        />
        <AddSparePartsModal
          showModal={openModal}
          handleClose={() => {
            if (isEditing) {
              closeEditSparePartModal();
            }
            this.setState({ openModal: false });
          }}
          vehicleTypesList={vehicleTypesList}
          addSparePart={addSparePart}
          selectedSparePart={selectedSparePart}
          isEditing={isEditing}
          handleDeleteSparePart={handleDeleteSparePart}
          isLoading={isAdding}
          isDeleting={isDeleting}
          sparePartsList={sparePartsList}
        />
      </div>
    );
  }
}

export default SpareParts;
