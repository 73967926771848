import * as React from 'react';
import { Paper, Toolbar, Typography, Grid } from '@material-ui/core';

import { useStyles } from './AutoLockingStyles';
import { NoData } from 'app/components/common';
import { IAutoLockingRule } from 'app/models';
import { ChangeAutoLockingRuleFunction } from '../../../models/SettingsModel';
import Switch from '@material-ui/core/Switch';

interface IProps {
  rules: IAutoLockingRule[];
  onChange: ChangeAutoLockingRuleFunction;
}

export const AutoLockingList: React.SFC<IProps> = (props) => {
  const classes = useStyles();

  const handleChange = (e: any) => {
    props.onChange(parseInt(e.target.value, 10), e.target.checked);
  };

  return (
    <Paper style={{ marginTop: 24 }}>
      <Toolbar className={classes.toolBar}>
        <Typography variant="h4" noWrap>
          Auto Locking/Unlocking
        </Typography>
      </Toolbar>
      <Toolbar className={classes.tableToolbar}>
        <Grid container item xs={12}>
          <Grid item xs={10}>
            <Typography variant="body2" component="h4" noWrap className={classes.headerText}>
              State
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2" component="h4" noWrap className={classes.headerText}>
              Lock Vehicle
            </Typography>
          </Grid>
        </Grid>
      </Toolbar>
      {props.rules.length > 0 ? (
        props.rules.map((rule, index) => {
          return (
            <Paper className={classes.root} key={index}>
              <Grid container item xs={12} alignItems={'center'}>
                <Grid item xs={10}>
                  <Typography variant="body1" noWrap>
                    {rule.translations.en}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Switch
                    checked={rule.locked}
                    onChange={handleChange}
                    value={rule.id}
                    color="primary"
                    // inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </Grid>
              </Grid>
            </Paper>
          );
        })
      ) : (
        <NoData message={'No States found'} />
      )}
    </Paper>
  );
};
