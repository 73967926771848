import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '14px 24px',
    flexDirection: 'column',
  },
  textField: {
    width: '100%',
    backgroundColor: '#FFFFFF',
  },

  addVehicleTypeForm: {
    borderRadius: 4,
    marginTop: 24,
    background: '#ffffff',
    boxShadow: '0px 1px 3px rgba(63, 63, 68, 0.15), 0px 0px 0px rgba(63, 63, 68, 0.05)',
    marginBottom: 15,
  },

  vehicleMedia: {
    position: 'relative',
  },

  media: {
    display: 'inline-grid',
    marginRight: theme.spacing(1),
  },

  title: {
    color: '#37474F',
    marginBottom: 4,
  },
}));
