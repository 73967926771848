export interface IResponseHandlerModel {
  code: number;
  isError: boolean;
  timestamp: number;
  error?: string;
  messages?: string[];
  data?: any;
  pagination?: IPagination;
}

export enum IMethods {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PUT = 'PUT',
}

export interface IPagination {
  totalCount: number;
  pageCount: number;
  currentPage: number;
  perPage: number;
}
