import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 40,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  tableToolbar: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: '#FAFAFA',
    minHeight: 40,
  },
  headerText: {
    fontWeight: 600,
  },
  cardConent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
}));
