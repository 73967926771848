import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Customers } from 'app/views';
import { IRootState } from 'app/reducers/AppState';

interface StateFromProps {}

interface DispatchFromProps {}

const mapStateToProps = (state: IRootState): StateFromProps => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Customers);
