import * as React from 'react';
import { CircularProgress, Typography } from '@material-ui/core';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

export interface IProps {}

export const MapLoading: React.SFC<any> = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress disableShrink size={20} thickness={2} variant="indeterminate" />
      <Typography variant="caption" display="block" gutterBottom>
        Loading...
      </Typography>
    </div>
  );
};
