import { combineReducers, AnyAction, Reducer } from 'redux';
import { routerReducer } from 'react-router-redux';
import { IRootState } from 'app/reducers/AppState';
import { loginReducer } from 'app/reducers/LoginReducer';
import { userDetailsReducer } from 'app/reducers/UserDetailsReducer';
import { employeesReducer } from 'app/reducers/EmployeesReducer';
import { vehicleTypesReducer } from './VehicleTypesReducer';
import { systemErrorsReducer } from './SystemErrorsReducer';
import { sparePartsReducer } from './SparePartsReducer';
import { partsStockReducer } from './PartsStockReducer';
import { repairGuidesReducer } from './RepairGuidesReducer';
import { dashboardReducer } from './DashboardReducer';
import { fleetReducer } from './FleetReducer';
import toastReducer from './ToastReducer';
import { hotspotsReducer } from './HotspotsReducer';
import { settingsReducer } from './SettingsReducer';
import { developmentReducer } from './DevelopmentReducer';
import { usersManagementReducer } from './UsersManagementReducer';
import { onRoadTasksReducer } from './OnRoadTasksReducer';

// note: current type definition of Reducer in "react-router-redux" and "redux-actions" module
// doesn"t go well with redux@4
export const rootReducer: Reducer<IRootState, AnyAction> = combineReducers<IRootState>({
  router: routerReducer as any,
  userDetails: userDetailsReducer as any,
  login: loginReducer as any,
  employees: employeesReducer as any,
  vehicleTypes: vehicleTypesReducer as any,
  systemErrors: systemErrorsReducer as any,
  spareParts: sparePartsReducer as any,
  partsStock: partsStockReducer as any,
  repairGuides: repairGuidesReducer as any,
  dashboard: dashboardReducer as any,
  fleet: fleetReducer as any,
  toasts: toastReducer as any,
  hotspots: hotspotsReducer as any,
  settings: settingsReducer as any,
  development: developmentReducer as any,
  usersManagement: usersManagementReducer as any,
  onRoadTasks: onRoadTasksReducer as any
});
