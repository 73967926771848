import HomeOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import InputIcon from '@material-ui/icons/Input';
// import CreditCardIcon from '@material-ui/icons/CreditCard';
import DriveEtaOutlinedIcon from '@material-ui/icons/DriveEtaOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import CommuteOutlinedIcon from '@material-ui/icons/CommuteOutlined';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import InboxOutlinedIcon from '@material-ui/icons/InboxOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import CodeOutlinedIcon from '@material-ui/icons/CodeOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { ISupportedRoutes, IConfigRoutesModel } from 'app/models';

const nav: IConfigRoutesModel[] = [
  {
    title: 'Location',
    pages: [
      {
        title: 'Overview',
        href: ISupportedRoutes.DASHBOARD,
        icon: HomeOutlinedIcon,
      },
      {
        title: 'Fleet',
        href: ISupportedRoutes.FLEET,
        icon: DriveEtaOutlinedIcon,
      },
      {
        title: 'Parts Stock',
        href: ISupportedRoutes.PARTS_STOCK,
        icon: InboxOutlinedIcon,
      },
      {
        title: 'Employees',
        href: ISupportedRoutes.EMPLOYEES,
        icon: PeopleAltOutlinedIcon,
      },
      {
        title: 'Hotspots',
        href: ISupportedRoutes.HOT_SPOTS,
        icon: LocationOnOutlinedIcon,
      },
      {
        title: 'Onroad Tasks',
        href: ISupportedRoutes.TASKS,
        icon: CheckCircleOutlineIcon,
      },
    ],
  },
  {
    title: 'General',
    pages: [
      {
        title: 'Vehicle Types',
        href: ISupportedRoutes.VEHICLES_TYPES,
        icon: CommuteOutlinedIcon,
      },
      {
        title: 'Spare parts',
        href: ISupportedRoutes.SPARE_PARTS,
        icon: BuildOutlinedIcon,
      },
      {
        title: 'Repair Guides',
        href: ISupportedRoutes.REPAIR_GUIDES,
        icon: LibraryBooksOutlinedIcon,
      },
    ],
  },
  {
    title: 'Account',
    pages: [
      {
        title: 'Settings',
        href: ISupportedRoutes.SETTINGS,
        icon: SettingsOutlinedIcon,
      },
      {
        title: 'Users',
        href: ISupportedRoutes.USERS,
        icon: PeopleOutlineOutlinedIcon,
        onlyCompanyAdmin: true,
      },
      {
        title: 'Development',
        href: ISupportedRoutes.DEVELOPMENT,
        icon: CodeOutlinedIcon,
      },
      /*{
        title: 'Billing',
        href: ISupportedRoutes.BILLING,
        icon: CreditCardIcon,
      },*/
      {
        title: 'Sign Out',
        href: ISupportedRoutes.LOGIN,
        icon: InputIcon,
      },
    ],
  },
];

export default nav;
