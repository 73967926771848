import { IResponseHandlerModel, IPagination } from 'app/models';

const getPaginationHeader = (headers: Headers, name: string): number => {
  const headerName = `Pagination-${name}`;
  const value = headers.get(headerName) || headers.get(headerName.toLowerCase());
  if (value) {
    return parseInt(value, 10);
  }
  return 0;
};

/**
 * SuccessHandlerHelper Class - For managing successful responses.
 */
export class SuccessHandlerHelper {
  constructor(response: any, status: number, headers: Headers) {
    this.responseModel.code = status;
    this.responseModel.data = status === 204 ? '' : response;
    if (headers.get('pagination-total-count') || headers.get('Pagination-Total-Count')) {
      const pagination: IPagination = {
        totalCount: getPaginationHeader(headers, 'Total-Count'),
        pageCount: getPaginationHeader(headers, 'Page-Count'),
        currentPage: getPaginationHeader(headers, 'Current-Page'),
        perPage: getPaginationHeader(headers, 'Per-Page'),
      };
      this.responseModel.pagination = pagination;
    }
  }

  public responseModel: IResponseHandlerModel = {
    code: 0,
    isError: false,
    timestamp: Date.now(),
  };
}
