import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import { IRootState } from 'app/reducers/AppState';
import {
  ISparePartModel,
  IResponseHandlerModel,
  IMethods,
  IAddSparePartModel,
  ToastTypes,
} from 'app/models';
import { ApiHelper } from 'app/helpers/api-helper';
import { showToaster } from './ToastActions';
import { DeleteItemsFunction } from '../models/RepairGuidesModel';

export enum ISparePartsActionsType {
  REQUEST_SPARE_PARTS_LIST = 'REQUEST_SPARE_PARTS_LIST',
  RECEIVE_SPARE_PARTS_LIST = 'RECEIVE_SPARE_PARTS_LIST',
  FAILURE_SPARE_PARTS_LIST = 'FAILURE_SPARE_PARTS_LIST',

  REQUEST_ADD_SPARE_PART = 'REQUEST_ADD_SPARE_PART',
  RECEIVE_ADD_SPARE_PART = 'RECEIVE_ADD_SPARE_PART',
  FAILURE_ADD_SPARE_PART = 'FAILURE_ADD_SPARE_PART',

  RECEIVE_UPDATE_SPARE_PART = 'RECEIVE_UPDATE_SPARE_PART',

  REQUEST_DELETE_SPARE_PART = 'REQUEST_DELETE_SPARE_PART',
  RECEIVE_DELETE_SPARE_PART = 'RECEIVE_DELETE_SPARE_PART',
  FAILURE_DELETE_SPARE_PART = 'FAILURE_DELETE_SPARE_PART',

  REQUEST_DELETE_SPARE_PARTS = 'REQUEST_DELETE_SPARE_PARTS',
  RECEIVE_DELETE_SPARE_PARTS = 'RECEIVE_DELETE_SPARE_PARTS',
  FAILURE_DELETE_SPARE_PARTS = 'FAILURE_DELETE_SPARE_PARTS',

  OPEN_SPARE_PART_MODAL = 'OPEN_SPARE_PART_MODAL',
  CLOSE_SPARE_PART_MODAL = 'CLOSE_SPARE_PART_MODAL',
}

// tslint:disable:typedef

export const requestSparePartsList = createAction(ISparePartsActionsType.REQUEST_SPARE_PARTS_LIST);
export const receiveSparePartsList = createAction<ISparePartModel[]>(
  ISparePartsActionsType.RECEIVE_SPARE_PARTS_LIST,
);
export const failureSparePartsList = createAction(ISparePartsActionsType.FAILURE_SPARE_PARTS_LIST);

export const requestAddSparePart = createAction(ISparePartsActionsType.REQUEST_ADD_SPARE_PART);
export const receiveAddSparePart = createAction(ISparePartsActionsType.RECEIVE_ADD_SPARE_PART);
export const failureAddSparePart = createAction(ISparePartsActionsType.FAILURE_ADD_SPARE_PART);
export const receiveUpdateSparePart = createAction<ISparePartModel[]>(
  ISparePartsActionsType.RECEIVE_UPDATE_SPARE_PART,
);

export const requestDeleteSparePart = createAction(
  ISparePartsActionsType.REQUEST_DELETE_SPARE_PART,
);
export const receiveDeleteSparePart = createAction(
  ISparePartsActionsType.RECEIVE_DELETE_SPARE_PART,
);
export const failureDeleteSparePart = createAction(
  ISparePartsActionsType.FAILURE_DELETE_SPARE_PART,
);

export const requestDeleteSpareParts = createAction(
  ISparePartsActionsType.REQUEST_DELETE_SPARE_PARTS,
);
export const receiveDeleteSpareParts = createAction(
  ISparePartsActionsType.RECEIVE_DELETE_SPARE_PARTS,
);
export const failureDeleteSpareParts = createAction(
  ISparePartsActionsType.FAILURE_DELETE_SPARE_PARTS,
);

export const openSparePartModal = createAction<ISparePartModel>(
  ISparePartsActionsType.OPEN_SPARE_PART_MODAL,
);
export const closeSparePartModal = createAction(ISparePartsActionsType.CLOSE_SPARE_PART_MODAL);

export const fetchSparePartsList = () => {
  return async (dispatch: Dispatch) => {
    dispatch(requestSparePartsList());

    try {
      const response = await new ApiHelper().fetchSpareParts();
      if (response.isError) {
        dispatch(failureSparePartsList());
        return;
      }
      const sparePartsList = response.data.data as ISparePartModel[];
      dispatch(receiveSparePartsList(sparePartsList));
    } catch (e) {
      dispatch(failureSparePartsList());
    }
  };
};

export const addNewSparePart = (sparePart: IAddSparePartModel) => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    const {
      spareParts: { selectedSparePart, isEditing },
      userDetails: { userDetails },
    } = getState();
    dispatch(requestAddSparePart());

    try {
      if (selectedSparePart && isEditing) {
        const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
          '/spare-parts/' + selectedSparePart.id,
          IMethods.PUT,
          true,
          undefined,
          sparePart,
        );
        if (response.isError) {
          dispatch(failureAddSparePart());
          return;
        }
        showToaster({
          show: true,
          type: ToastTypes.SUCCESS,
          message:
            response.data && response.data.message
              ? response.data.message
              : 'Spare part updated successfully',
        })(dispatch);
        dispatch(receiveAddSparePart());
        fetchSparePartsList()(dispatch);
      } else {
        const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
          '/spare-parts',
          IMethods.POST,
          true,
          undefined,
          {
            ...sparePart,
            ...(userDetails && userDetails.company && { ownerId: userDetails.company.id }),
          },
        );
        if (response.isError) {
          dispatch(failureAddSparePart());
          return;
        }
        showToaster({
          show: true,
          type: ToastTypes.SUCCESS,
          message:
            response.data && response.data.message
              ? response.data.message
              : 'Spare part created successfully',
        })(dispatch);
        dispatch(receiveAddSparePart());
        fetchSparePartsList()(dispatch);
      }
    } catch (e) {
      dispatch(failureAddSparePart());
    }
  };
};

/**
 * Triggers the edit state updates
 * @param employee Employee to edit
 */
export const openEditSparePartModal = (sparePart: ISparePartModel) => {
  return (dispatch: Dispatch) => {
    dispatch(openSparePartModal(sparePart));
  };
};

export const closeEditSparePartModal = () => {
  return (dispatch: Dispatch) => {
    dispatch(closeSparePartModal());
  };
};

export const deleteSparePart = () => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    dispatch(requestDeleteSparePart());
    const { selectedSparePart } = getState().spareParts;
    try {
      if (selectedSparePart) {
        const response = await new ApiHelper().FetchFromPortal(
          '/spare-parts/' + selectedSparePart.id,
          IMethods.DELETE,
          true,
        );
        if (response.isError) {
          dispatch(failureDeleteSparePart());
          return;
        }
        showToaster({
          show: true,
          type: ToastTypes.SUCCESS,
          message:
            response.data && response.data.message
              ? response.data.message
              : 'Spare part deleted successfully',
        })(dispatch);

        dispatch(receiveDeleteSparePart());
        fetchSparePartsList()(dispatch);
      }
    } catch (e) {
      dispatch(failureDeleteSparePart());
    }
  };
};

export const deleteSpareParts: DeleteItemsFunction = (ids: string[]) => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    try {
      dispatch(requestDeleteSpareParts({ removingPartsIds: ids }));
      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        '/spare-parts/',
        IMethods.DELETE,
        true,
        undefined,
        {
          spareParts: ids,
        },
      );
      if (response.isError) {
        dispatch(failureDeleteSpareParts());
        return;
      }
      dispatch(receiveDeleteSpareParts());
    } catch (e) {
      dispatch(failureDeleteSpareParts());
    }
  };
};
