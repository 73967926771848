import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 40,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  textField: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
}));
