import * as React from 'react';

import { Card, CardHeader, CardContent } from '@material-ui/core';

import { useStyles } from './EndpointsStyles';
import { IUserDetail } from 'app/models';
import { Endpoint } from './Endpoint';

interface IProps {
  userDetails: IUserDetail | null;
}

export const Endpoints: React.SFC<IProps> = ({ userDetails }) => {
  const classes = useStyles();

  let apiKey = 'nADAwK77tYhO81YzxjbxwO3REmC3HOCL';
  if (userDetails && userDetails.company && userDetails.company!.apiKey) {
    apiKey = userDetails.company.apiKey;
  }

  return (
    <Card style={{ marginTop: 24 }}>
      <CardHeader title={'Endpoints'} />
      <CardContent className={classes.cardContent}>
        <Endpoint
          title="Get vehicles events"
          description="Returns all the vehicles events."
          parameters={[
            {
              name: 'start',
              description: 'start time (Unix timestamp)',
            },
            {
              name: 'end',
              description: 'end time (Unix timestamp)',
            },
          ]}
          type="GET"
          path="/export/vehiclesLogs"
          request={`curl 'https://api.serveedo.com/v1/export/vehiclesLogs' \\
  --header 'Authorization: bearer ${apiKey}'`}
          response={`[
  {
    "type": "REPAIR_GUIDE.STARTED",
    "occurredOn": "2020-01-20T11:17:46.443Z",
    "data": {
      "repairGuideId": "8adf0d79-3804-4cef-8e17-1067cc0aea3c"
    },
    "employee": {
      "id": "AAAbCC1"
    },
    "vehicle": {
      "id": 0,
      "vehicleType": "eScooter",
      "stateId": 2,
      "locationId": 6,
      "location": {
        "lat": 11.1,
        "long": 5.01
      },
      "mileage": 77.5,
      "fuelLevel": 95,
      "stateDescription": null,
      "createdAt": "2020-01-20T11:17:46.432Z",
      "updatedAt": "2020-01-20T11:17:46.432Z"
    },
  },
  // ... other logs
]`}
        />

        <Endpoint
          title="Get onroad tasks events"
          description="Returns all the onroad tasks events."
          parameters={[
            {
              name: 'start',
              description: 'start time (Unix timestamp)',
            },
            {
              name: 'end',
              description: 'end time (Unix timestamp)',
            },
          ]}
          type="GET"
          path="/export/onroadTasksLogs"
          request={`curl 'https://api.serveedo.com/v1/export/onroadTasksLogs' \\
  --header 'Authorization: bearer ${apiKey}'`}
          response={`[
  {
    "locationId": 1,
    "employeeId": "UaPW4yapM",
    "type": "TASK.COMPLETED",
    "taskId": "02114f17-9910-47f3-a4b9-06e66044b59c",
    "taskType": "drop",
    "vehicleTypeId": "00dc98e4-f762-4f0d-a8a2-b13bed224c0b",
    "quantity": 0,
    "vehicleIds": [],
    "employeeLocation": {
      "lat": 41.8035529,
      "long": 2.8447156
    },
    "sequenceId": "02114f17-9910-47f3-a4b9-06e66044b59c",
    "sequenceOrder": 0,
    "data": {
      "success": true
    },
    "occurredOn": "2020-06-23T08:13:56.065Z"
  },
  // ... other logs
]`}
        />

        <Endpoint
          title="Get stocks level"
          description="Returns the current stock level."
          parameters={[
            {
              name: 'location',
              description: 'location id in Wunder (Integer)',
            },
            {
              name: 'partId',
              description: 'id of the spare part (string)',
            },
          ]}
          type="GET"
          path="/export/stocks"
          request={`curl 'https://api.serveedo.com/v1/export/stocks' \\
  --header 'Authorization: bearer ${apiKey}'`}
          response={`[
  {
    "vehicleType": "eScooter",
    "code": "BELL",
    "name": "Bell",
    "stocks": [
      {
        "amount": 406,
        "locationId": 6
      },
      // ... other locations
    ]
  },
  // ... other parts
]`}
        />

        <Endpoint
          title="Create Hotspots"
          description="Create a hotspots (can be used as form-data to upload files)"
          parameters={[
            {
              name: 'name',
              description: 'name of the hotspot (string)',
            },
            {
              name: 'capacity',
              description: 'amount of vehicles that could be host (integer)',
            },
            {
              name: 'location',
              description: 'geo location of the hotspot (object: lat,long)',
            },
            {
              name: 'description',
              description: 'instructions for the driver on where to leave the vehicle precisely',
            },
            {
              name: 'photoUrl',
              description:
                'URL of a foto reference for the driver to know how to leave the vehicles (use form-data and pass a file to upload it and let Serveedo set this)',
            },
            {
              name: 'needsProof',
              description:
                'require the driver to take a photo as evidence when leaving vehicles here',
            },
            {
              name: 'priority',
              description: 'priority of the hotspots (0-5, 0 equals to inactive)',
            },
            {
              name: 'priorityPeriods',
              description: 'priority overrides in certain time of the day of the week (object)',
              children: [
                {
                  name: 'start_day',
                  description: 'day of the week the period starts (1-7)',
                },
                {
                  name: 'start_hour',
                  description: 'hour of the day the period starts (0-23)',
                },
                {
                  name: 'start_minute',
                  description: 'minute of the hour the period starts (0-59)',
                },
                {
                  name: 'end_day',
                  description: 'day of the week the period ends (1-7)',
                },
                {
                  name: 'end_hour',
                  description: 'hour of the day the period ends (0-23)',
                },
                {
                  name: 'end_minute',
                  description: 'minute of the hour the period ends (0-59)',
                },
              ],
            },
            {
              name: 'regionId',
              description: 'id on Serveedo of the city',
            },
            {
              name: 'vehicleTypeId',
              description: 'id on Serveedo of the vehicle type',
            },
          ]}
          type="POST"
          path="/rest/hotspots"
          request={`curl -X POST 'https://api.serveedo.com/v1/rest/hotspots' \\
  --header 'Authorization: bearer ${apiKey}' \\
  --header 'Content-Type: text/plain' \\
  --data-raw '{
    "name": "HotSpot 1",
    "capacity": 10,
    "location": {
      "lat": 32.424,
      "long": 41.2313
    },
    "regionId": "9e23431e-8199-474c-895d-44bda0994698",
    "vehicleTypeId": "13461646-284f-42d7-9a58-998654f5049e",
    "priority": 2,
    "priorityPeriods": [{
      "start_day": 1,
      "start_hour": 8,
      "start_minute": 15,
      "end_hour": 9,
      "end_minute": 30,
      "end_day": 3,
      "priority": 5
    },{
      "start_day": 5,
      "start_hour": 3,
      "start_minute": 30,
      "end_hour": 12,
      "end_minute": 45,
      "end_day": 6,
      "priority": 3
    }]
  }'`}
          response={`{
  "data": {
    "id": "96e21459-8ee7-4f14-b3f0-9482362606d1",
    "name": "HotSpot 1",
    "capacity": 10,
    "nearbyVehicles": 0,
    "location": {
      "lat": 32.424,
      "long": 41.2313
    },
    "regionId": "9e23431e-8199-474c-895d-44bda0994698",
    "vehicleTypeId": "13461646-284f-42d7-9a58-998654f5049e",
    "description": null,
    "photoUrl": null,
    "needsProof": false,
    "priority": 2,
    "priorityPeriods": [
      {
        "end_day": 3,
        "end_hour": 9,
        "priority": 5,
        "start_day": 1,
        "end_minute": 30,
        "start_hour": 8,
        "start_minute": 15
      },
      {
        "end_day": 6,
        "end_hour": 12,
        "priority": 3,
        "start_day": 5,
        "end_minute": 45,
        "start_hour": 3,
        "start_minute": 30
      }
    ],
    "createdAt": "2020-05-29T12:46:58.499Z",
    "updatedAt": "2020-05-29T12:46:58.499Z"
  }
}`}
        />

        <Endpoint
          title="Update Hotspot"
          description="Updates a single hotspot. Parameters: same as creation (only need to pass what needs to change)"
          type="PUT"
          path="/rest/hotspots/HOTSPOT_ID"
          request={`curl -X PUT 'https://api.serveedo.com/v1/rest/hotspots/658c13c3-14b6-418f-b219-9191748e1cd6' \\
  --header 'Authorization: bearer ${apiKey}' \\
  --header 'Content-Type: text/plain' \\
  --data-raw '{
    "priority": 0
  }'`}
          response={`{
  "data": {
    "id": "96e21459-8ee7-4f14-b3f0-9482362606d1",
    "name": "HotSpot 1",
    "capacity": 10,
    "nearbyVehicles": 0,
    "location": {
      "lat": 32.424,
      "long": 41.2313
  },
    "regionId": "9e23431e-8199-474c-895d-44bda0994698",
    "vehicleTypeId": "13461646-284f-42d7-9a58-998654f5049e",
    "description": null,
    "photoUrl": null,
    "needsProof": false,
    "priority": 0,
    "createdAt": "2020-05-29T12:46:58.499Z",
    "updatedAt": "2020-05-29T12:46:58.499Z"
  }
}`}
        />

        <Endpoint
          title="Retrieve one hotspot"
          description="Retrieve a single hotspot by its Serveedo id"
          type="GET"
          path="/rest/hotspots/HOTSPOT_ID"
          request={`curl 'https://api.serveedo.com/v1/rest/hotspots/658c13c3-14b6-418f-b219-9191748e1cd6' \\
  --header 'Authorization: bearer ${apiKey}'`}
          response={`{
  "data":{
    "id":"658c13c3-14b6-418f-b219-9191748e1cd6",
    "name":"Arc De Triomf",
    "capacity":2,
    "nearbyVehicles":0,
    "priority":3,
    "location":{
      "lat":41.391096,
      "long":2.1788171,
      "address":"Ronda de San Pedro 68"
    },
    "regionId":"28ddfdc9-4de5-4ee3-b5dd-ef6dc5d07fcc",
    "vehicleTypeId":"5ab82420-893b-4ecf-9f82-38d43fd19c4c",
    "description":"Park in front of Mc Donald",
    "photoUrl":null,
    "needsProof":true,
    "createdAt":"2020-05-29T12:53:23.882Z",
    "updatedAt":"2020-05-29T12:53:23.882Z"
  }
}`}
        />

        <Endpoint
          title="Retrieve all hotspots"
          description="Retrieve all hotspots"
          type="GET"
          path="/rest/hotspots"
          request={`curl 'http://api.serveedo.com//v1/rest/hotspots' \\
  --header 'Authorization: bearer ${apiKey}'`}
          response={`{
  "data":[
    {
      "id":"658c13c3-14b6-418f-b219-9191748e1cd6",
      "name":"Arc De Triomf",
      "capacity":2,
      "nearbyVehicles":0,
      "priority":3,
      "location":{
        "lat":41.391096,
        "long":2.1788171,
        "address":"Ronda de San Pedro 68"
      },
      "regionId":"28ddfdc9-4de5-4ee3-b5dd-ef6dc5d07fcc",
      "vehicleTypeId":"5ab82420-893b-4ecf-9f82-38d43fd19c4c",
      "description":"Park in front of Mc Donald",
      "photoUrl":null,
      "needsProof":true,
      "createdAt":"2020-05-29T12:53:23.882Z",
      "updatedAt":"2020-05-29T12:53:23.882Z"
    },
    ...
  ]
}`}
        />

        <Endpoint
          title="Delete one hotspot"
          description="Delete a single hotspot by its Serveedo id"
          type="DELETE"
          path="/rest/hotspots/HOTSPOT_ID"
          request={`curl -X DELETE 'https://api.serveedo.com/v1/rest/hotspots/658c13c3-14b6-418f-b219-9191748e1cd6' \\
  --header 'Authorization: bearer ${apiKey}'`}
        />

        <Endpoint
          title="Delete multiple hotspots"
          description="Deletes multiple hotspots with a single request."
          parameters={[
            { name: 'hotspots', description: 'Array with the Serveedo ids of the hotspots.' },
          ]}
          type="DELETE"
          path="/rest/hotspots"
          request={`curl -X DELETE 'http://api.serveedo.com//v1/hotspots' \\
  --header 'Authorization: bearer ${apiKey}' \\
  --header 'Content-Type: text/plain' \\
  --data-raw '{
    "hotspots":   [
      "8ba14509-dd2d-4aa5-aea2-01d7a553d2bb",
      "e37c6ec9-853e-4b3e-b247-27cf8dc7e8de"
    ]
  }'`}
        />
      </CardContent>
    </Card>
  );
};
