import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
  },
  searchIcon: {
    position: 'absolute',
    left: 14,
    top: 20,
  },
  searchField: {
    minWidth: 270,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '& input': {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  clearSearchIcon: {
    position: 'absolute',
    top: 10,
    right: 6,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#d5d5d56e',
    },
  },
  disabled: {
    backgroundColor: 'transparent !important',
  },
}));
