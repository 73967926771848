import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 15,
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  textField: {
    width: '100%',
  },
}));
