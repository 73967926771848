import { ToastTypes, IToastModel } from 'app/models';
import { Dispatch } from 'redux';

export enum ToastActionsType {
  SHOW_TOAST = 'SHOW_TOAST',
  HIDE_TOAST = 'HIDE_TOAST',
}
export type ToastAction = {
  type: ToastActionsType.SHOW_TOAST | ToastActionsType.HIDE_TOAST;
  toast: IToastModel;
};

const showToast = (show: boolean, type: ToastTypes, message: string) => ({
  type: ToastActionsType.SHOW_TOAST,
  toast: {
    show,
    type,
    message,
    timestamp: Date.now(),
  },
});

export const hideToast = (toast: IToastModel): ToastAction => ({
  type: ToastActionsType.HIDE_TOAST,
  toast,
});

export const showToaster = (toast: IToastModel) => (dispatch: Dispatch): void => {
  const toastAction = showToast(toast.show, toast.type, toast.message);
  dispatch(showToast(toast.show, toast.type, toast.message));
  setTimeout(() => dispatch(hideToast(toastAction.toast)), 3000);
};
