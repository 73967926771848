import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import { IRootState } from 'app/reducers/AppState';
import { IResponseHandlerModel, IMethods, IPartStockModel, ISupportedRoutes } from 'app/models';
import { ApiHelper } from 'app/helpers/api-helper';
import { push } from 'react-router-redux';

export enum IPartsStockActionsType {
  REQUEST_PARTS_STOCK_LIST = 'REQUEST_PARTS_STOCK_LIST',
  RECEIVE_PARTS_STOCK_LIST = 'RECEIVE_PARTS_STOCK_LIST',
  FAILURE_PARTS_STOCK_LIST = 'FAILURE_PARTS_STOCK_LIST',

  REQUEST_UPDATE_SPARE_STOCK_AMOUNT = 'REQUEST_UPDATE_SPARE_STOCK_AMOUNT',
  RECEIVE_UPDATE_SPARE_STOCK_AMOUNT = 'RECEIVE_UPDATE_SPARE_STOCK_AMOUNT',
  FAILURE_UPDATE_SPARE_STOCK_AMOUNT = 'FAILURE_UPDATE_SPARE_STOCK_AMOUNT',
}

// tslint:disable:typedef

export const requestPartsStockList = createAction(IPartsStockActionsType.REQUEST_PARTS_STOCK_LIST);
export const receivePartsStockList = createAction<IPartStockModel[]>(
  IPartsStockActionsType.RECEIVE_PARTS_STOCK_LIST,
);
export const failurePartsStockList = createAction(IPartsStockActionsType.FAILURE_PARTS_STOCK_LIST);

export const requestUpdateSpareStockAmount = createAction(
  IPartsStockActionsType.REQUEST_UPDATE_SPARE_STOCK_AMOUNT,
);
export const receiveUpdateSpareStockAmount = createAction(
  IPartsStockActionsType.RECEIVE_UPDATE_SPARE_STOCK_AMOUNT,
);
export const failureUpdateSpareStockAmount = createAction(
  IPartsStockActionsType.FAILURE_UPDATE_SPARE_STOCK_AMOUNT,
);

export const fetchPartsStockList = () => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    const { selectedRegion } = getState().userDetails;
    if (selectedRegion && selectedRegion.key === 'all') {
      dispatch(push(ISupportedRoutes.DASHBOARD));
      return;
    }
    dispatch(requestPartsStockList());
    try {
      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        '/spare-stocks',
        IMethods.GET,
        true,
        { ...(selectedRegion && { region: selectedRegion.id }) },
      );
      if (response.isError) {
        dispatch(failurePartsStockList());
        return;
      }

      const partsStockList = response.data.data as IPartStockModel[];
      dispatch(receivePartsStockList(partsStockList));
    } catch (e) {
      dispatch(failurePartsStockList());
    }
  };
};

export const updateSpareStock = (id: string, amount: number) => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    dispatch(requestUpdateSpareStockAmount());

    try {
      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        '/spare-stocks/' + id,
        IMethods.PUT,
        true,
        undefined,
        { amount },
      );
      if (response.isError) {
        dispatch(failureUpdateSpareStockAmount());
        return;
      }

      dispatch(receiveUpdateSpareStockAmount());

      fetchPartsStockList()(dispatch, getState);
    } catch (e) {
      dispatch(failureUpdateSpareStockAmount());
    }
  };
};
