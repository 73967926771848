import * as React from 'react';

import AddIcon from '@material-ui/icons/Add';

import { WidgetHeader } from 'app/components/WidgetHeader/WidgetHeader';
import { ServeedoButton } from 'app/components/common';
import {
  ISystemErrorsModel,
  ISystemErrorModel,
  IVehicleTypeListModel,
  IAddSystemErrorModel,
} from 'app/models';
import { SystemErrorsList } from 'app/components/SystemErrorsList/SystemErrorsList';
import { SystemErrorModal } from 'app/components/Modals/SystemErrorModal/SystemErrorModal';
export interface IProps {
  systemErrors: ISystemErrorsModel;
  vehicleTypesList: IVehicleTypeListModel[];
  fetchSystemErrors: () => void;
  fetchVehicleTypes: () => void;
  addNewSystemError: (systemError: IAddSystemErrorModel) => void;
  handleOpenEditSystemError: (systemError: ISystemErrorModel) => void;
  handleCloseEditSystemError: () => void;
  handleUpdateSystemError: (systemError: IAddSystemErrorModel) => void;
  handleDeleteSystemError: () => void;
}

export interface IState {
  openModal: boolean;
}

class SystemErrors extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      openModal: false,
    };
  }

  componentDidMount() {
    this.props.fetchVehicleTypes();
    this.props.fetchSystemErrors();
  }

  componentDidUpdate(prevProps: IProps) {
    const {
      systemErrors: { isAdding, isDeleting },
    } = this.props;
    if (
      (prevProps.systemErrors.isAdding !== isAdding && !isAdding) ||
      (prevProps.systemErrors.isDeleting !== isDeleting && !isDeleting)
    ) {
      this.setState({
        openModal: false,
      });
    }
  }

  handleOpenModal = () => {
    this.setState({
      openModal: true,
    });
  };

  handleCloseModal = () => {
    const {
      systemErrors: { isEditing },
      handleCloseEditSystemError,
    } = this.props;
    if (isEditing) {
      handleCloseEditSystemError();
    }
    this.setState({
      openModal: false,
    });
  };

  handleEditSystemError = (systemError: ISystemErrorModel) => {
    const { handleOpenEditSystemError } = this.props;
    handleOpenEditSystemError(systemError);
    this.handleOpenModal();
  };

  renderButton = (): JSX.Element => {
    return (
      <ServeedoButton
        buttonText={'New Error'}
        onClick={this.handleOpenModal}
        buttonIcon={<AddIcon />}
      />
    );
  };

  render(): JSX.Element {
    const {
      systemErrors: {
        systemErrorsList,
        selectedSystemError,
        isEditing,
        isLoading,
        isAdding,
        isDeleting,
      },
      addNewSystemError,
      handleUpdateSystemError,
      handleDeleteSystemError,
      vehicleTypesList,
    } = this.props;
    return (
      <div className={'container'}>
        <WidgetHeader
          headerText={'System Errors'}
          headerSmallText={'GENERAL'}
          rightPanel={this.renderButton()}
        />
        <SystemErrorsList
          systemErrorsList={systemErrorsList}
          handleEditSystemError={this.handleEditSystemError}
          vehicleTypesList={vehicleTypesList}
          isLoading={isLoading}
        />
        <SystemErrorModal
          showModal={this.state.openModal}
          handleClose={this.handleCloseModal}
          onContinueClick={addNewSystemError}
          selectedSystemError={selectedSystemError}
          isEditing={isEditing}
          handleUpdateSystemError={handleUpdateSystemError}
          handleDeleteSystemError={handleDeleteSystemError}
          vehicleTypesList={vehicleTypesList}
          isLoading={isAdding}
          isDeleting={isDeleting}
        />
      </div>
    );
  }
}

export default SystemErrors;
