import * as React from 'react';
import clsx from 'clsx';
import { AppBar, Button, Toolbar } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import Logo from 'assets/images/Logo.svg';

import { useStyles } from './TopNavigationStyles';
import useRouter from 'app/helpers/use-router';
import { ISupportedRoutes } from 'app/models';

export interface IProps {
  className: string;
  handleDrawerToggle: () => void;
}

export const TopNavigation: React.SFC<IProps> = (props) => {
  const { className, handleDrawerToggle, ...rest } = props;

  const classes = useStyles();
  const router = useRouter();

  const handleLogo = () => {
    router.history.push(ISupportedRoutes.DASHBOARD);
  };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color="primary">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <Button className={classes.logoutButton} color="inherit" onClick={handleLogo}>
          <img src={Logo} className={classes.logo} alt="app-logo" />
          Serveedo
        </Button>
      </Toolbar>
    </AppBar>
  );
};
