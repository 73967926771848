import React from 'react';
import { Card, CardContent, Grid, Typography, makeStyles } from '@material-ui/core';

import Map from 'app/components/common/Map/Map';
import { IHotspotModel, IVehicleTypeListModel } from 'app/models';

const useStyles = makeStyles(() => ({
  keyValue: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 12,
  },
  label: {
    color: '#90A4AE',
    marginBottom: 4,
  },
  value: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: '#263238',
    fontSize: 14,
    fontWeight: 500,
  },
}));

export interface IProps {
  hotspot: IHotspotModel;
  vehicleTypesList: IVehicleTypeListModel[];
}

export const HotspotDetails: React.SFC<IProps> = ({
  hotspot: {
    location,
    vehicleTypeId,
    capacity,
    nearbyRadius,
    nearbyVehicles,
    incomingVehicles,
    priority,
    needsProof,
    photoUrl,
    description,
    location: { address },
  },
  vehicleTypesList,
}) => {
  const classes = useStyles();
  const vehicleType = vehicleTypesList.find((v) => v.id === vehicleTypeId);
  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid container item lg={8} md={9} xs={12}>
            <Grid item lg={3} md={4} xs={6}>
              <Typography variant="body2" className={classes.keyValue}>
                <span className={classes.label}>Vehicle Type</span>
                <span className={classes.value}>{vehicleType && vehicleType.name}</span>
              </Typography>
            </Grid>
            <Grid item lg={3} md={4} xs={6}>
              <Typography variant="body2" className={classes.keyValue}>
                <span className={classes.label}>Capacity</span>
                <span className={classes.value}>{capacity}</span>
              </Typography>
            </Grid>
            <Grid item lg={3} md={4} xs={6}>
              <Typography variant="body2" className={classes.keyValue}>
                <span className={classes.label}>Nearby ({nearbyRadius}m)</span>
                <span className={classes.value}>{nearbyVehicles}</span>
              </Typography>
            </Grid>
            <Grid item lg={3} md={4} xs={6}>
              <Typography variant="body2" className={classes.keyValue}>
                <span className={classes.label}>Incoming</span>
                <span className={classes.value}>{incomingVehicles}</span>
              </Typography>
            </Grid>
            <Grid item lg={3} md={4} xs={6}>
              <Typography variant="body2" className={classes.keyValue}>
                <span className={classes.label}>Location</span>
                <span className={classes.value}>
                  {address}
                  <br />
                  {location.lat}, {location.long}
                </span>
              </Typography>
            </Grid>
            <Grid item lg={3} md={4} xs={6}>
              <Typography variant="body2" className={classes.keyValue}>
                <span className={classes.label}>Priority</span>
                <span className={classes.value}>{priority}</span>
              </Typography>
            </Grid>
            <Grid item lg={3} md={4} xs={6}>
              <Typography variant="body2" className={classes.keyValue}>
                <span className={classes.label}>Photo reference</span>
                <span className={classes.value}>{photoUrl ? 'Yes' : 'No'}</span>
              </Typography>
            </Grid>
            <Grid item lg={3} md={4} xs={6}>
              <Typography variant="body2" className={classes.keyValue}>
                <span className={classes.label}>Requires proof</span>
                <span className={classes.value}>{needsProof ? 'Yes' : 'No'}</span>
              </Typography>
            </Grid>
            {description && (
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.keyValue}>
                  <span className={classes.label}>Instructions</span>
                  <span className={classes.value}>{description}</span>
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid container item lg={4} md={3} xs={12} justify="flex-end" direction="row">
            <div className={'fleet-details-map'} style={{ height: '100%', width: 280 }}>
              <Map location={location} />
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
