import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    paddingBottom: theme.spacing(1.5),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginBottom: theme.spacing(1.5),
  },
  settingsNavigation: {
    height: '100%',
    width: 240,
    top: 64,
    left: 240,
    backgroundColor: '#f9fafb',
    boxShadow: '0px 1px 3px rgba(63, 63, 68, 0.15), 0px 0px 0px rgba(63, 63, 68, 0.05)',
    padding: theme.spacing(2),
  },
}));
