import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  topBar: {
    zIndex: 4,
    position: 'absolute',
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  navBar: {
    marginTop: 60,
    zIndex: 3,
    width: '15%',
    minWidth: '15%',
    flex: '0 0 auto',
  },
  content: {
    position: 'relative',
    marginTop: 64,
    overflowY: 'auto',
    overflowX: 'hidden',
    flex: '1 1 auto',
  },
  contentChildren: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
}));
