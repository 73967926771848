import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';

import LoginForm from 'app/components/LoginForm/LoginForm';
import { ILoginCredentials, ILoginModel, IToastStateModel, IToastModel } from 'app/models';

import Logo from 'assets/images/Logo.svg';

import { useStyles } from './styles';
import { ServeedoToast } from 'app/components/common';

export interface IProps {
  loginData: ILoginModel;
  login: (loginDetails: ILoginCredentials) => void;
  toasts: IToastStateModel;
  hideToast: (toast: IToastModel) => void;
}

const Login: React.SFC<IProps> = ({ login, loginData: { isLoggingIn }, toasts, hideToast }) => {
  const classes = useStyles();

  const handleLogin = (loginDetails: ILoginCredentials) => {
    login(loginDetails);
  };

  return (
    <div className={classes.root} title="Login">
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <div className={classes.icon}>
            <img src={Logo} alt="app-logo" />
          </div>
          <Typography gutterBottom variant="h4">
            <span className={classes.title}>Sign into Serveedo</span>
          </Typography>
          <LoginForm className={classes.loginForm} login={handleLogin} isLoading={isLoggingIn} />
        </CardContent>
      </Card>
      <ServeedoToast toasts={toasts} hideToast={hideToast} />
    </div>
  );
};

export default Login;
