import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import {
  ISupportedRoutes,
  IMethods,
  IResponseHandlerModel,
  IOnroadTaskModel,
  IOnroadTaskStatusModel,
  IOnroadTasksFilter,
  IOnroadTasksListModel,
  IPagination,
} from 'app/models';
import { IRootState } from 'app/reducers/AppState';
import { push } from 'react-router-redux';
import { ApiHelper } from 'app/helpers/api-helper';

export enum IOnroadTaskType {
  REQUEST_ONROAD_TASKS = 'REQUEST_ONROAD_TASKS',
  RECEIVE_ONROAD_TASKS = 'RECEIVE_ONROAD_TASKS',
  FAILURE_ONROAD_TASKS = 'FAILURE_ONROAD_TASKS',

  REQUEST_FLEET_STATUS = 'REQUEST_FLEET_STATUS',
  RECEIVE_FLEET_STATUS = 'RECEIVE_FLEET_STATUS',
  FAILURE_FLEET_STATUS = 'FAILURE_FLEET_STATUS',

  REQUEST_UPDATE_TASKS = 'REQUEST_UPDATE_TASKS',
  RECEIVE_UPDATE_TASKS = 'RECEIVE_UPDATE_TASKS',
  FAILURE_UPDATE_TASKS = 'FAILURE_UPDATE_TASKS',

  UPDATE_ONROAD_TASKS_FILTER = 'UPDATE_ONROAD_TASKS_FILTER',
}

// tslint:disable:typedef

export const requestOnroadTasks = createAction(IOnroadTaskType.REQUEST_ONROAD_TASKS);
export const receiveOnroadTasks = createAction<IOnroadTasksListModel>(
  IOnroadTaskType.RECEIVE_ONROAD_TASKS,
);
export const failureOnroadTasks = createAction(IOnroadTaskType.FAILURE_ONROAD_TASKS);

export const requestFleetStatus = createAction(IOnroadTaskType.REQUEST_FLEET_STATUS);
export const receiveFleetStatus = createAction<IOnroadTaskStatusModel>(
  IOnroadTaskType.RECEIVE_FLEET_STATUS,
);
export const failureFleetStatus = createAction(IOnroadTaskType.FAILURE_FLEET_STATUS);

export const requestUpdateTasks = createAction(IOnroadTaskType.REQUEST_UPDATE_TASKS);
export const receiveUpdateTasks = createAction(IOnroadTaskType.RECEIVE_UPDATE_TASKS);
export const failureUpdateTasks = createAction(IOnroadTaskType.FAILURE_UPDATE_TASKS);

export const updateOnRoadTasksFilter = createAction<IOnroadTasksFilter>(
  IOnroadTaskType.UPDATE_ONROAD_TASKS_FILTER,
);

export const fetchOnRoadTasks = () => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    const {
      userDetails: { selectedRegion },
      onRoadTasks: {
        onRoadTasksFilter: { perPage, page, type, status },
      },
    } = getState();
    if (selectedRegion && selectedRegion.key === 'all') {
      dispatch(push(ISupportedRoutes.DASHBOARD));
      return;
    }

    try {
      dispatch(requestOnroadTasks());

      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        '/onroad-tasks',
        IMethods.GET,
        true,
        {
          perPage,
          page,
          ...(status.value !== 'all' && { status: status.value }),
          ...(type.value !== 'all' && { type: type.value }),
          ...(selectedRegion && { regionId: selectedRegion.id }),
        },
      );

      if (response.isError) {
        dispatch(failureOnroadTasks());
        return;
      }

      dispatch(
        receiveOnroadTasks({
          onRoadTasksList: response.data.data as IOnroadTaskModel[],
          pagination: response.pagination as IPagination,
        }),
      );
    } catch (e) {
      console.error(e);
      dispatch(failureOnroadTasks());
    }
  };
};

export const fetchFleetStatus = () => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    const {
      userDetails: { selectedRegion },
    } = getState();
    if (selectedRegion && selectedRegion.key === 'all') {
      dispatch(push(ISupportedRoutes.DASHBOARD));
      return;
    }

    try {
      dispatch(requestFleetStatus());

      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        '/onroad-tasks/status',
        IMethods.GET,
        true,
        { ...(selectedRegion && { regionId: selectedRegion.id }) },
      );
      const onRoadTaskStatus = response.data.data as IOnroadTaskStatusModel;
      dispatch(receiveFleetStatus(onRoadTaskStatus));
    } catch (e) {
      dispatch(failureFleetStatus());
    }
  };
};

export const updateTasks = () => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    const {
      userDetails: { selectedRegion },
    } = getState();
    if (selectedRegion && selectedRegion.key === 'all') {
      return;
    }

    try {
      dispatch(requestUpdateTasks());

      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        '/onroad-tasks/generate',
        IMethods.GET,
        true,
        { ...(selectedRegion && { regionId: selectedRegion.id }) },
      );
      if (response.isError) {
        dispatch(failureUpdateTasks());
        return;
      }
      dispatch(receiveUpdateTasks());
      fetchOnRoadTasks()(dispatch, getState);
    } catch (e) {
      dispatch(failureUpdateTasks());
    }
  };
};

export const changeOnRoadTaskFilter = (filter: IOnroadTasksFilter) => {
  return (dispatch: Dispatch, getState: () => IRootState) => {
    dispatch(updateOnRoadTasksFilter(filter));
  };
};
