export enum ToastTypes {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}
export enum ToastPositions {
  TOP_RIGHT = 'top-right',
  TOP_LEFT = 'top-left',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
  TOP_CENTER = 'top-center',
  BOTTOM_CENTER = 'bottom-center',
}
export interface IToastModel {
  show: boolean;
  type: ToastTypes;
  message: string;
  timestamp?: number;
}
export interface IToastStateModel {
  toasts: IToastModel[];
}
