import * as React from 'react';
import { Button, CircularProgress } from '@material-ui/core';

import { RouteComponentProps } from 'react-router';
import { WidgetHeader } from 'app/components/WidgetHeader/WidgetHeader';
import {
  ISupportedRoutes,
  IVehicleTypeListModel,
  IRegion,
  INewHotspotModel,
  IHotspotsModel,
  IUserDetail,
  IUserRole,
} from 'app/models';
import { AddHotspotForm } from 'app/components/AddHotspotForm/AddHotspotForm';
import {
  geoCoordinatesFromAddress,
  getFirstLineAddressFromCoordinates,
} from 'app/helpers/map-helper';
import { ConfirmationModal } from 'app/components/Modals/ConfirmationModal/ConfirmationModal';
import { HotspotView } from 'app/components/HotspotView/HotspotView';
import { Loader } from 'app/components/common';

// eslint-disable-next-line

export interface IProps extends RouteComponentProps<any> {
  selectedRegion: IRegion | null;
  vehicleTypesList: IVehicleTypeListModel[];
  hotspots: IHotspotsModel;
  fetchVehicleTypesList: () => void;
  fetchHotspotDetails: (id: string) => void;
  saveHotspot: (hotspot: INewHotspotModel, file: any) => void;
  deleteHotspot: () => void;
  fetchDropTasks: (id: string) => void;
  userDetails: IUserDetail | null;
}

export interface IState {
  hotspot: INewHotspotModel;
  hasChangedName: boolean;
  confirmModal: boolean;
  nameError: string | undefined;
  vehicleTypeError: string | undefined;
  file: any;
  isEditing: boolean;
}

export class AddHotspot extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      hotspot: {
        name: '',
        priority: 0,
        capacity: 0,
        location: {
          lat: 0,
          long: 0,
        },
        vehicleTypeId: '',
        priorityPeriods: [],
        needsProof: false,
      },
      hasChangedName:
        props.hotspots.selectedHotspot !== null || props.match.params.id !== undefined,
      confirmModal: false,
      nameError: undefined,
      vehicleTypeError: undefined,
      file: null,
      isEditing: false,
    };
  }

  async getInitialLocation() {
    const { selectedRegion } = this.props;
    if (!selectedRegion) {
      return;
    }
    const location = await geoCoordinatesFromAddress(
      `${selectedRegion.name}, ${selectedRegion.country}`,
    );
    const address = await getFirstLineAddressFromCoordinates(location);

    this.setState({
      hotspot: {
        ...this.state.hotspot,
        location: {
          ...location,
          address,
        },
        name: address,
      },
    });
  }

  componentDidMount() {
    const {
      vehicleTypesList,
      fetchVehicleTypesList,
      hotspots: { selectedHotspot },
      match: { params },
      fetchHotspotDetails,
    } = this.props;
    if (!vehicleTypesList.length) {
      fetchVehicleTypesList();
    }
    if (selectedHotspot) {
      return;
    }
    if (params.id && !selectedHotspot) {
      fetchHotspotDetails(params.id);
      return;
    }
    if (!selectedHotspot) {
      this.getInitialLocation();
    }
  }

  async componentDidUpdate(prevProps: IProps, prevState: IState) {
    const {
      selectedRegion,
      hotspots: { selectedHotspot, isSaving },
    } = this.props;
    if (
      prevProps.selectedRegion !== selectedRegion &&
      selectedRegion &&
      selectedRegion.key === 'all'
    ) {
      this.props.history.push(ISupportedRoutes.DASHBOARD);
      return;
    }
    if (
      prevProps.selectedRegion &&
      selectedRegion &&
      prevProps.selectedRegion.key !== 'all' &&
      prevProps.selectedRegion !== selectedRegion
    ) {
      this.props.history.push(`/${selectedRegion.key}${ISupportedRoutes.HOT_SPOTS}`);
      return;
    }

    const updateName = selectedHotspot !== null && !this.state.hasChangedName;

    const prevLocation = prevState.hotspot.location;
    const nextLocation = this.state.hotspot.location;

    if (prevLocation.lat !== nextLocation.lat || prevLocation.long !== nextLocation.long) {
      const address = await getFirstLineAddressFromCoordinates(nextLocation);
      this.setState({
        hotspot: {
          ...this.state.hotspot,
          name: updateName ? address : this.state.hotspot.name,
          location: {
            ...this.state.hotspot.location,
            address,
          },
        },
      });
    }

    if (prevProps.hotspots.isSaving !== isSaving && !isSaving) {
      this.cancelEditOrAddHotspot();
    }
  }

  handleChange = (newHotspot: INewHotspotModel) => {
    const hasChangedName = newHotspot.name !== this.state.hotspot.name;
    this.setState({
      hotspot: newHotspot,
      hasChangedName: this.state.hasChangedName || hasChangedName,
      nameError: undefined,
      vehicleTypeError: undefined,
    });
  };

  handleSubmit = () => {
    let nameError: string | undefined = undefined;
    let vehicleTypeError: string | undefined = undefined;
    if (this.state.hotspot.name.trim().length === 0) {
      nameError = 'Please give a name.';
    }
    if (this.state.hotspot.vehicleTypeId.trim() === '') {
      vehicleTypeError = 'Please select vehicle type.';
    }
    if (nameError || vehicleTypeError) {
      this.setState({
        nameError,
        vehicleTypeError,
      });
    } else {
      this.props.saveHotspot(this.state.hotspot, this.state.file);
    }
  };

  handleDelete = () => {
    this.props.deleteHotspot();
    this.setState({
      confirmModal: false,
    });
  };

  cancelEditOrAddHotspot = () => {
    const {
      hotspots: { selectedHotspot },
      selectedRegion,
    } = this.props;
    const { isEditing } = this.state;
    if (selectedHotspot && isEditing) {
      this.setState({
        isEditing: false,
      });
    } else {
      this.props.history.push(
        `${selectedRegion ? '/' + selectedRegion.key : ''}${ISupportedRoutes.HOT_SPOTS}`,
      );
    }
  };

  renderRightPanel = (): JSX.Element | undefined => {
    const {
      hotspots: { selectedHotspot, isSaving },
      location: { pathname },
      userDetails
    } = this.props;
    const { isEditing } = this.state;
    const hasAccess =
      userDetails &&
      userDetails.role === IUserRole.FleetOwner &&
      userDetails.company &&
      userDetails.company.handle !== 'hive';
    if (!hasAccess) {
      return undefined;
    }
    return (
      <div>
        {(isEditing || pathname.indexOf(ISupportedRoutes.NEW_HOT_SPOTS) !== -1) && (
          <Button
            variant="contained"
            size="medium"
            style={{ marginRight: 5 }}
            onClick={this.cancelEditOrAddHotspot}
          >
            CANCEL
          </Button>
        )}
        {selectedHotspot && !isEditing && (
          <>
            {' '}
            <Button
              onClick={() => this.setState({ confirmModal: true })}
              color="secondary"
              style={{ color: 'rgb(220, 0, 78)', marginRight: 5 }}
              variant="outlined"
            >
              DELETE
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              onClick={() => {
                this.setState({
                  hotspot: selectedHotspot,
                  isEditing: true,
                });
              }}
            >
              EDIT
            </Button>
          </>
        )}
        {(isEditing || pathname.indexOf(ISupportedRoutes.NEW_HOT_SPOTS) !== -1) && (
          <Button
            variant="contained"
            size="medium"
            color="primary"
            disabled={isSaving}
            startIcon={
              isSaving ? <CircularProgress disableShrink size={18} thickness={4} /> : undefined
            }
            onClick={this.handleSubmit}
          >
            {isSaving ? (isEditing ? 'Updating...' : 'Saving...') : isEditing ? 'Update' : 'Save'}
          </Button>
        )}
      </div>
    );
  };

  render(): JSX.Element {
    const {
      selectedRegion,
      vehicleTypesList,
      fetchDropTasks,
      hotspots: { selectedHotspot, isLoading, isDropTasksLoading, tasks },
    } = this.props;
    const { hotspot, confirmModal, nameError, vehicleTypeError, isEditing } = this.state;

    if (isLoading) {
      return <Loader />;
    }
    return (
      <div className={'container'}>
        <div className={`${isEditing ? 'medium-width' : 'wide-width'}`}>
          <WidgetHeader
            headerText={
              isEditing && selectedHotspot
                ? 'Edit ' + selectedHotspot.name
                : selectedHotspot
                ? selectedHotspot.name
                : 'Create New Hotspot'
            }
            headerSmallText={selectedRegion ? selectedRegion.name.toUpperCase() : 'All'}
            rightPanel={this.renderRightPanel()}
          />
          {!isEditing && selectedHotspot ? (
            <HotspotView
              hotspot={selectedHotspot}
              vehicleTypesList={vehicleTypesList}
              fetchDropTasks={fetchDropTasks}
              tasks={tasks}
              isDropTasksLoading={isDropTasksLoading}
            />
          ) : (
            <AddHotspotForm
              hotspot={hotspot}
              vehicleTypesList={vehicleTypesList}
              handleChange={this.handleChange}
              nameError={nameError}
              vehicleTypeError={vehicleTypeError}
              priorityPeriods={hotspot.priorityPeriods}
              handleChangeFile={(file) => this.setState({ file })}
            />
          )}
        </div>
        {selectedHotspot && (
          <ConfirmationModal
            openModal={confirmModal}
            handleClose={() =>
              this.setState({
                confirmModal: false,
              })
            }
            handleContinue={this.handleDelete}
            modalTitle={`Delete ${hotspot.name}?`}
            modalBody={
              <span>Are you sure you want to delete it? This operation cannot be undone.</span>
            }
          />
        )}
      </div>
    );
  }
}

export default AddHotspot;
