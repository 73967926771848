import { handleActions, Reducer } from 'redux-actions';
import { IRootState } from 'app/reducers/AppState';
import { ISystemErrorsType } from 'app/actions';
import { ISystemErrorsModel, ISystemErrorModel } from 'app/models';

const initialState: ISystemErrorsModel = {
  systemErrorsList: [],
  selectedSystemError: null,
  isLoading: false,
  isAdding: false,
  isEditing: false,
  isDeleting: false,
};

// tslint:disable-next-line:typedef
export const systemErrorsReducer: Reducer<
  IRootState.SystemErrorsState,
  ISystemErrorsModel
> = handleActions<
  IRootState.SystemErrorsState,
  ISystemErrorsModel | ISystemErrorModel | ISystemErrorModel[]
>(
  {
    [ISystemErrorsType.REQUEST_SYSTEM_ERRORS_LIST]: (
      state = initialState,
    ): IRootState.SystemErrorsState => ({
      ...state,
      isLoading: true,
    }),
    [ISystemErrorsType.RECEIVE_SYSTEM_ERRORS_LIST]: (
      state = initialState,
      action,
    ): IRootState.SystemErrorsState => ({
      ...state,
      isLoading: false,
      systemErrorsList: action.payload as ISystemErrorModel[],
    }),
    [ISystemErrorsType.FAILURE_SYSTEM_ERRORS_LIST]: (
      state = initialState,
    ): IRootState.SystemErrorsState => ({
      ...state,
      isLoading: false,
    }),

    [ISystemErrorsType.REQUEST_ADD_SYSTEM_ERROR]: (
      state = initialState,
    ): IRootState.SystemErrorsState => ({
      ...state,
      isAdding: true,
    }),
    [ISystemErrorsType.RECEIVE_ADD_SYSTEM_ERROR]: (
      state = initialState,
    ): IRootState.SystemErrorsState => ({
      ...state,
      isAdding: false,
    }),
    [ISystemErrorsType.FAILURE_ADD_SYSTEM_ERROR]: (
      state = initialState,
    ): IRootState.SystemErrorsState => ({
      ...state,
      isAdding: false,
    }),

    [ISystemErrorsType.REQUEST_DELETE_SYSTEM_ERROR]: (
      state = initialState,
    ): IRootState.SystemErrorsState => ({
      ...state,
      isDeleting: true,
    }),
    [ISystemErrorsType.RECEIVE_DELETE_SYSTEM_ERROR]: (
      state = initialState,
      action,
    ): IRootState.SystemErrorsState => ({
      ...state,
      isDeleting: false,
    }),
    [ISystemErrorsType.FAILURE_DELETE_SYSTEM_ERROR]: (
      state = initialState,
    ): IRootState.SystemErrorsState => ({
      ...state,
      isDeleting: false,
    }),

    [ISystemErrorsType.REQUEST_UPDATE_SYSTEM_ERROR]: (
      state = initialState,
    ): IRootState.SystemErrorsState => ({
      ...state,
      isAdding: true,
    }),
    [ISystemErrorsType.RECEIVE_UPDATE_SYSTEM_ERROR]: (
      state = initialState,
      action,
    ): IRootState.SystemErrorsState => ({
      ...state,
      isAdding: false,
    }),
    [ISystemErrorsType.FAILURE_UPDATE_SYSTEM_ERROR]: (
      state = initialState,
    ): IRootState.SystemErrorsState => ({
      ...state,
      isAdding: false,
    }),

    [ISystemErrorsType.OPEN_SYSTEM_ERROR_MODAL]: (
      state = initialState,
      action,
    ): IRootState.SystemErrorsState => ({
      ...state,
      selectedSystemError: action.payload as ISystemErrorModel,
      isEditing: true,
    }),

    [ISystemErrorsType.CLOSE_SYSTEM_ERROR_MODAL]: (
      state = initialState,
    ): IRootState.SystemErrorsState => ({
      ...state,
      selectedSystemError: null,
      isEditing: false,
    }),
  },
  initialState,
);
