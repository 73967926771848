import * as React from 'react';

import { Divider, Typography, Paper, Toolbar, TextField, Grid } from '@material-ui/core';

import { IAddRepairGuideModel, ILanguage } from 'app/models';

import { useStyles } from './ContentFormStyles';
import { SelectLanguageButton } from '../SelectLanguageButton/SelectLanguageButton';

export interface IProps {
  repairGuide: IAddRepairGuideModel;
  isError: boolean;
  handleChange: (repairGuide: IAddRepairGuideModel) => void;
  languages: ILanguage[];
  selectedLanguage: ILanguage;
  onChangeLanguage: (language: ILanguage) => void;
}

export const ContentForm: React.SFC<IProps> = ({
  repairGuide,
  handleChange,
  isError,
  languages,
  selectedLanguage,
  onChangeLanguage,
}) => {
  const classes = useStyles();

  return (
    <Paper style={{ marginTop: 24 }}>
      <Toolbar className={classes.toolBar}>
        <Typography variant="h4" noWrap>
          Content
        </Typography>
        <SelectLanguageButton
          languages={languages}
          onChangeLanguage={onChangeLanguage}
          selectedLanguage={selectedLanguage}
        />
      </Toolbar>
      <Divider />
      <form className={classes.container} autoComplete="off" onSubmit={() => console.log('okkk')}>
        <Grid container spacing={1}>
          <Grid container item xs={12}>
            <TextField
              key={'title'}
              error={isError && !repairGuide.translations['en'].title}
              id="outlined-required"
              label="Title"
              margin="normal"
              value={repairGuide.translations[selectedLanguage.value].title}
              onChange={(e) =>
                handleChange({
                  ...repairGuide,
                  translations: {
                    ...repairGuide.translations,
                    [selectedLanguage.value]: {
                      ...repairGuide.translations[selectedLanguage.value],
                      title: e.target.value,
                    },
                  },
                })
              }
              variant="outlined"
              className={classes.textField}
              helperText={
                isError && !repairGuide.translations['en'].title
                  ? 'English title is required!'
                  : undefined
              }
            />
          </Grid>
          <Grid container item xs={12}>
            <TextField
              id="outlined-multiline-flexible"
              label="Description"
              className={classes.textField}
              style={{ minHeight: 150 }}
              value={repairGuide.translations[selectedLanguage.value].description}
              onChange={(e) =>
                handleChange({
                  ...repairGuide,
                  translations: {
                    ...repairGuide.translations,
                    [selectedLanguage.value]: {
                      ...repairGuide.translations[selectedLanguage.value],
                      description: e.target.value,
                    },
                  },
                })
              }
              multiline
              variant="outlined"
            />
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};
