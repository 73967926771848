import * as React from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Paper,
  Divider,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import {
  ServeedoTableHeader,
  ITableHeaderCells,
  FilterMenuItem,
  ServeedoTableToolbar,
  ITableFilter,
  NoData,
} from 'app/components/common';
import {
  IVehicleTypeListModel,
  ISparePartModel,
  IPartStockModel,
  IUserDetail,
  IUserRole,
} from 'app/models';

import { PartStockModal } from '../Modals/PartStockModal/PartStockModal';
import { getSorting, stableSort } from 'app/helpers/common';

export interface IPartStockTableModel {
  id: string;
  code: string;
  name: string;
  vehicleType: string;
  vehicleTypeId: string;
  amount: number;
}

const headerCells: ITableHeaderCells[] = [
  { id: 'code', numeric: false, disablePadding: false, label: 'Id' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'vehicleType', numeric: true, disablePadding: false, label: 'Vehicle Type' },
  { id: 'amount', numeric: true, disablePadding: false, label: 'Available' },
];

export interface IProps {
  regionId?: string;
  partsStockList: IPartStockModel[];
  vehicleTypesList: IVehicleTypeListModel[];
  sparePartsList: ISparePartModel[];
  isLoading: boolean;
  userDetails: IUserDetail | null;
  handleUpdateSpareStock: (id: string, amount: number) => void;
  isUpdating: boolean;
}

export const PartsStockList: React.SFC<IProps> = ({
  regionId,
  partsStockList,
  vehicleTypesList,
  isLoading,
  userDetails,
  handleUpdateSpareStock,
  isUpdating,
}) => {
  const [order, setOrder] = React.useState<any>('asc');
  const [orderBy, setOrderBy] = React.useState('code');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [selectedOption, setSelectedOption] = React.useState<FilterMenuItem>({
    label: 'All',
    value: 'all',
  });
  const [selected, setSelected] = React.useState([]);

  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [selectedStock, setSeletedStock] = React.useState<IPartStockModel | null>(null);
  const [value, setValue] = React.useState<number>(0);

  React.useEffect(() => {
    if (!isUpdating) {
      setOpenModal(false);
      setSeletedStock(null);
      setValue(0);
    }
  }, [isUpdating]);

  const handleRequestSort = (event: any, property: string) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds: any = partsStockList.map((n) => n.amount);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const editPartStockAmount = (id: string) => {
    const stock = partsStockList.find((p) => p.id === id);
    if (stock) {
      setSeletedStock(stock);
      setValue(stock.amount);
      setOpenModal(true);
    }
  };

  const modifiedPartsStockList: IPartStockTableModel[] = partsStockList
    .map((p) => ({
      id: p.id,
      code: p.sparePart.code,
      name: p.sparePart.translations.en,
      vehicleType: vehicleTypesList.length
        ? [...vehicleTypesList].find((v) => v.id === p.sparePart.vehicleTypeId)!.name
        : 'Vehicle Type is not available',
      vehicleTypeId: p.sparePart.vehicleTypeId,
      amount: p.amount,
    }))
    .filter((s) =>
      selectedOption.value !== 'all' ? selectedOption.value === s.vehicleTypeId : true,
    );

  let list = stableSort<IPartStockTableModel>(
    modifiedPartsStockList,
    getSorting(order, orderBy),
  ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const tableFilter: ITableFilter[] = [
    {
      menuTitle: 'Vehicle Types',
      items: [
        { label: 'All', value: 'all' },
        ...vehicleTypesList.map((v) => {
          const item = {
            label: v.name,
            value: v.id,
          };
          return item;
        }),
      ],
      onSelect: (value) => setSelectedOption(value),
      selectedOption,
    },
  ];

  let canEditStock = false;
  if (userDetails) {
    if (userDetails.role === IUserRole.Maintainer) {
      canEditStock = true;
    } else if (userDetails.role === IUserRole.FleetOwner) {
      if (regionId && userDetails.company && userDetails.company.maintainers) {
        const maintainer = userDetails.company.maintainers.find((m) =>
          m.regionsIds.includes(regionId),
        );
        if (maintainer) {
          if (userDetails.company.handle === 'hive' && maintainer.handle === 'hive-ops') {
            canEditStock = true;
          }
        }
      }
    }
  }

  return (
    <>
      <Paper>
        <ServeedoTableToolbar title={'All Parts'} tableFilter={tableFilter} />
        <div className="table-wrapper">
          <Table aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table">
            <ServeedoTableHeader
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={partsStockList.length}
              headerCells={headerCells}
              selecting={false}
            />
            <TableBody>
              {isLoading
                ? [1, 2, 3].map((item) => (
                    <TableRow key={item} role="checkbox">
                      <TableCell component="th" scope="row">
                        <React.Fragment>
                          <Skeleton
                            height={10}
                            width="50%"
                            style={{ marginTop: 6, marginBottom: 6 }}
                          />
                        </React.Fragment>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <React.Fragment>
                          <Skeleton
                            height={10}
                            width="50%"
                            style={{ marginTop: 6, marginBottom: 6 }}
                          />
                        </React.Fragment>
                      </TableCell>
                      <TableCell>
                        <React.Fragment>
                          <Skeleton
                            height={10}
                            width="70%"
                            style={{ marginTop: 6, marginBottom: 6 }}
                          />
                        </React.Fragment>
                      </TableCell>
                      <TableCell>
                        <React.Fragment>
                          <Skeleton
                            height={10}
                            width="60%"
                            style={{ marginTop: 6, marginBottom: 6 }}
                          />
                        </React.Fragment>
                      </TableCell>
                    </TableRow>
                  ))
                : list.map((partStock: IPartStockTableModel, index: number) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                        style={{
                          cursor: canEditStock ? 'pointer' : 'default',
                        }}
                        onClick={
                          canEditStock ? () => editPartStockAmount(partStock.id) : () => null
                        }
                      >
                        <TableCell component="th" scope="row">
                          {partStock.code}
                        </TableCell>
                        <TableCell>{partStock.name}</TableCell>
                        <TableCell>{partStock.vehicleType}</TableCell>
                        <TableCell>{partStock.amount}</TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </div>
        {!isLoading && !modifiedPartsStockList.length && (
          <>
            <NoData />
            <Divider />
          </>
        )}
        <TablePagination
          rowsPerPageOptions={[50, 100, 250]}
          component="div"
          count={modifiedPartsStockList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <PartStockModal
        openModal={openModal}
        title={
          selectedStock ? `Edit ${selectedStock.sparePart.translations.en} Stock` : 'Edit Stock'
        }
        handleClose={() => {
          setOpenModal(false);
          setSeletedStock(null);
          setValue(0);
        }}
        handleContinue={() => {
          if (selectedStock) {
            handleUpdateSpareStock(selectedStock.id, value);
          }
        }}
        value={value}
        handleChangeValue={(value) => setValue(value)}
        isLoading={isUpdating}
      />
    </>
  );
};
