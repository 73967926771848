import * as React from 'react';
import { WidgetHeader } from 'app/components/WidgetHeader/WidgetHeader';
import { AutoLockingList } from './AutoLockingList';
import { ISettingsModel, ChangeAutoLockingRuleFunction } from '../../../models/SettingsModel';

export interface IProps {
  settings: ISettingsModel;
  fetchAutoLockingRules: () => void;
  changeAutoLockingRule: ChangeAutoLockingRuleFunction;
}
export interface IState {}

class AutoLocking extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.fetchAutoLockingRules();
  }

  render(): JSX.Element {
    return (
      <React.Fragment>
        <WidgetHeader headerText={'Auto-locking'} headerSmallText={'SETTINGS'} />
        <AutoLockingList
          rules={this.props.settings.autoLockingRules}
          onChange={this.props.changeAutoLockingRule}
        />
      </React.Fragment>
    );
  }
}

export default AutoLocking;
