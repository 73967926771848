/**
 * Supported Routes Typings
 */
export enum ISupportedRoutes {
  LOGIN = '/auth/login',
  DASHBOARD = '/overview',
  CUSTOMERS = '/customers',
  INVOICES = '/invoices',
  VANS = '/vans',
  EMPLOYEES = '/employees',
  SPARE_PARTS = '/spare-parts',
  FLEET = '/fleet',
  VIEW_FLEET = '/fleet/:id',
  SETTINGS = '/settings',
  INTEGRATION_SETTINGS = '/settings/integration',
  AUTO_LOCKING_SETTINGS = '/settings/auto-locking',
  VEHICLES_TYPES = '/vehicles-types',
  NEW_VEHICLE_TYPE = '/vehicles-types/new',
  UPDATE_VEHICLE_TYPE = '/vehicles-types/:id',
  MANUALS = '/manuals',
  MANUALS_ERRORS = '/manuals/errors',
  MANUALS_GUIDES = '/manuals/guides',
  REPAIR_GUIDES = '/repair-guides',
  NEW_REPAIR_GUIDES = '/repair-guides/new',
  EDIT_REPAIR_GUIDES = '/repair-guides/:id',
  BILLING = '/billing',
  OPERATORS = '/operators',
  PARTS_STOCK = '/parts-stock',
  HOT_SPOTS = '/hotspots',
  NEW_HOT_SPOTS = '/hotspots/new',
  UPDATE_HOT_SPOTS = '/hotspots/:id',
  DEVELOPMENT = '/development',
  USERS = '/users',
  TASKS = '/tasks',
}

export interface IConfigRoutesModel {
  title: string;
  pages: IPagesModel[];
}

export interface IPagesModel {
  title: string;
  href: ISupportedRoutes;
  icon: any;
  onlyCompanyAdmin?: boolean;
}
