import * as React from 'react';
import clsx from 'clsx';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  DialogActions,
  Button,
  CircularProgress,
  TextField,
} from '@material-ui/core';

import { useStyles } from './IntegrationMappingModalStyles';
import { IMappingType } from 'app/models';
import { IRemoteMapping121 } from '../../../../models/SettingsModel';
import { ContextualError } from 'app/components/common/Errors/ContextualError';

export interface IDropdownItem {
  localId: string | number;
  remoteId?: number;
  label?: string;
}

export interface IProps {
  openModal: boolean;
  mappingModalItems: IDropdownItem[];
  selectedMappingType: IMappingType;
  selectedMap: IRemoteMapping121 | null;
  onClose: () => void;
  onContinue: (localId: string | number, remoteId: string | number) => void;
  onDelete: () => void;
  isLoading?: boolean;
  isDeleting?: boolean;
  error?: string;
}

export const IntegrationMappingModal: React.SFC<IProps> = ({
  openModal,
  mappingModalItems,
  isLoading,
  onClose,
  onContinue,
  onDelete,
  selectedMappingType,
  selectedMap,
  error,
}) => {
  const classes = useStyles();

  const [dropdownItem, setDropdownItem] = React.useState<IDropdownItem | null>(null);
  const [wunderId, setWunderId] = React.useState<number>(0);

  React.useEffect(() => {
    if (selectedMap) {
      const selectedDropDownItem = mappingModalItems.find(
        (item) => item.localId === selectedMap.localId,
      );
      if (selectedDropDownItem) {
        setDropdownItem(selectedDropDownItem);
        setWunderId(selectedMap.remoteId);
      } else {
        console.error("Couldn't find mapped item");
      }
    }
  }, [mappingModalItems, selectedMap]);

  return (
    <>
      <Dialog
        open={openModal}
        onClose={!isLoading ? onClose : () => null}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="form-dialog-title" className={classes.title}>
          <span className={classes.titleText}>
            {selectedMap ? `Edit ${selectedMappingType}` : `Add New ${selectedMappingType}`}
          </span>
        </DialogTitle>
        <DialogContent className={classes.modalBody}>
          <div className={classes.formRow}>
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel htmlFor="outlined-age-native-simple" className={classes.label}>
                {selectedMappingType}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                inputProps={{
                  name: 'vehicelSTate',
                  id: 'outlined-age-native-simple',
                }}
                className={classes.select}
                value={dropdownItem ? dropdownItem.localId : ''}
                defaultValue={dropdownItem ? dropdownItem.localId : ''}
                onChange={(e: any) => {
                  const newDropdownItem = mappingModalItems.find(
                    (d) => d.localId === e.target.value,
                  );
                  if (newDropdownItem) {
                    setDropdownItem(newDropdownItem);
                  }
                }}
              >
                {mappingModalItems.map((v: IDropdownItem, index: number) => (
                  <MenuItem key={index} value={v.localId}>
                    {v.label}
                  </MenuItem>
                ))}
              </Select>
              {false && <FormHelperText style={{ color: '#e53935' }}>Please select</FormHelperText>}
            </FormControl>
            <TextField
              margin="normal"
              id="id"
              label="Wunder Id"
              type="number"
              fullWidth
              value={wunderId}
              error={false}
              onChange={(e: any) => setWunderId(e.target.value)}
              variant="outlined"
              className={classes.textField}
              helperText={false ? 'Wunder Id is required!' : undefined}
            />
          </div>
          {error && <ContextualError message={error} />}
        </DialogContent>
        <DialogActions className={clsx(classes.modalFooter, { [classes.footer]: selectedMap })}>
          {selectedMap && (
            <Button
              onClick={onDelete}
              color="secondary"
              className={classes.dltButton}
              disabled={isLoading}
            >
              DELETE
            </Button>
          )}
          <div>
            <Button onClick={onClose} variant="contained" disabled={isLoading}>
              Cancel
            </Button>{' '}
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                if (dropdownItem) {
                  onContinue(dropdownItem.localId, wunderId);
                }
              }}
              disabled={isLoading}
            >
              {isLoading
                ? selectedMap
                  ? 'Updating...'
                  : 'Saving...'
                : selectedMap
                ? 'Update'
                : 'Save'}
              {''}
              {isLoading && <CircularProgress disableShrink size={18} thickness={4} />}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};
