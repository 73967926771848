import * as React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Paper,
  Divider,
  Checkbox,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import {
  ServeedoTableHeader,
  ITableHeaderCells,
  FilterMenuItem,
  ServeedoTableToolbar,
  ITableFilter,
  NoData,
} from 'app/components/common';
import { IVehicleTypeListModel, ISparePartModel } from 'app/models';

import { getSorting, stableSort } from 'app/helpers/common';
import { ConfirmationModal } from '../Modals/ConfirmationModal/ConfirmationModal';
import { togglePresenceItem } from '../../helpers/common';
import { DeleteItemsFunction } from '../../models/RepairGuidesModel';
import { makeDateRelative } from 'app/helpers/time-helper';

export interface ISparePartTableModel {
  id: string;
  code: string;
  name: string;
  vehicleType: string;
  vehicleTypeId: string;
  added: string;
}

const headerCells: ITableHeaderCells[] = [
  { id: 'code', numeric: false, disablePadding: false, label: 'Id' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'vehicleType', numeric: true, disablePadding: false, label: 'Vehicle Type' },
  { id: 'createdAt', numeric: true, disablePadding: false, label: 'Added' },
];

export interface IProps {
  sparePartsList: ISparePartModel[];
  vehicleTypesList: IVehicleTypeListModel[];
  editSparePart: (sparePart: ISparePartModel) => void;
  isLoading: boolean;
  deleteSpareParts: DeleteItemsFunction;
  deletingPartsIds: string[];
}

export const SparePartsList: React.SFC<IProps> = ({
  sparePartsList,
  vehicleTypesList,
  editSparePart,
  isLoading,
  deleteSpareParts,
  deletingPartsIds,
}) => {
  const [order, setOrder] = React.useState<any>('asc');
  const [orderBy, setOrderBy] = React.useState('code');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [selectedOption, setSelectedOption] = React.useState<FilterMenuItem>({
    label: 'All',
    value: 'all',
  });
  const [selected, setSelected] = React.useState<string[]>([]);
  const [openConfirmModal, setOpenConfirmModal] = React.useState<boolean>(false);

  const handleRequestSort = (event: any, property: string) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (event: any, name: string) => {
    event.stopPropagation();

    setSelected(togglePresenceItem(selected, name));
  };

  const handleEditSparePart = (id: string) => {
    const sparePart = [...sparePartsList].find((s) => s.id === id)!;
    if (sparePart) {
      editSparePart(sparePart);
    }
  };

  // const handleSelectAllClick = (event: any) => {
  //   if (event.target.checked) {
  //     const newSelecteds: string[] = modfiedSparePartsList.map((n) => n.id);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  const modfiedSparePartsList: ISparePartTableModel[] = [...sparePartsList]
    .map((s) => ({
      id: s.id,
      code: s.code,
      name: s.translations['en'],
      vehicleType: [...vehicleTypesList].find((v) => v.id === s.vehicleTypeId)
        ? [...vehicleTypesList].find((v) => v.id === s.vehicleTypeId)!.name
        : 'Vehicle Type Deleted',
      vehicleTypeId: s.vehicleTypeId,
      added: makeDateRelative(s.createdAt),
    }))
    .filter((s) =>
      selectedOption.value !== 'all' ? selectedOption.value === s.vehicleTypeId : true,
    )
    .filter((s) => deletingPartsIds.indexOf(s.id) === -1);

  let list = stableSort<ISparePartTableModel>(
    modfiedSparePartsList,
    getSorting(order, orderBy),
  ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const tableFilter: ITableFilter[] = [
    {
      menuTitle: 'Vehicle Types',
      items: [
        { label: 'All', value: 'all' },
        ...vehicleTypesList.map((v) => {
          const item = {
            label: v.name,
            value: v.id,
          };
          return item;
        }),
      ],
      onSelect: (value) => setSelectedOption(value),
      selectedOption,
    },
  ];

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  return (
    <>
      <Paper>
        <ServeedoTableToolbar
          title={'All Parts'}
          tableFilter={tableFilter}
          numSelected={selected.length}
          onDelectButtonClick={() => setOpenConfirmModal(true)}
        />
        <div className="table-wrapper">
          <Table aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table">
            <ServeedoTableHeader
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={sparePartsList.length}
              headerCells={headerCells}
              selecting
              isLoading={isLoading}
            />
            <TableBody>
              {isLoading
                ? [1, 2, 3].map((item) => (
                    <TableRow key={item} role="checkbox">
                      <TableCell padding="checkbox">
                        <Checkbox inputProps={{ 'aria-labelledby': '' }} checked={false} disabled />
                      </TableCell>
                      <TableCell component="th" scope="row" padding="none">
                        <React.Fragment>
                          <Skeleton
                            height={10}
                            width="50%"
                            style={{ marginTop: 6, marginBottom: 6 }}
                          />
                        </React.Fragment>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <React.Fragment>
                          <Skeleton
                            height={10}
                            width="50%"
                            style={{ marginTop: 6, marginBottom: 6 }}
                          />
                        </React.Fragment>
                      </TableCell>
                      <TableCell>
                        <React.Fragment>
                          <Skeleton
                            height={10}
                            width="70%"
                            style={{ marginTop: 6, marginBottom: 6 }}
                          />
                        </React.Fragment>
                      </TableCell>
                      <TableCell>
                        <React.Fragment>
                          <Skeleton
                            height={10}
                            width="60%"
                            style={{ marginTop: 6, marginBottom: 6 }}
                          />
                        </React.Fragment>
                      </TableCell>
                    </TableRow>
                  ))
                : list.map((sparePart: ISparePartTableModel, index: number) => {
                    const isItemSelected = isSelected(sparePart.id);
                    const labelId = `enhanced-table-checkbox-${sparePart.id}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index}
                        onClick={() => handleEditSparePart(sparePart.id)}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                            onClick={(e) => handleClick(e, sparePart.id)}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {sparePart.code}
                        </TableCell>
                        <TableCell>{sparePart.name}</TableCell>
                        <TableCell>{sparePart.vehicleType}</TableCell>
                        <TableCell>{sparePart.added}</TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </div>
        {!isLoading && !modfiedSparePartsList.length && (
          <>
            <NoData />
            <Divider />
          </>
        )}
        <TablePagination
          rowsPerPageOptions={[50, 100, 250]}
          component="div"
          count={modfiedSparePartsList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <ConfirmationModal
        openModal={openConfirmModal}
        modalBody={
          <span>
            <strong>{selected.length}</strong> spare parts will be deleted
          </span>
        }
        handleClose={() => setOpenConfirmModal(false)}
        handleContinue={() => {
          setOpenConfirmModal(false);
          deleteSpareParts(selected);
          setSelected([]);
        }}
      />
    </>
  );
};
