import * as React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './ServeedoTableToolbarStyles';
import { ServeedoTableFilter, ITableFilter } from 'app/components/common';
import { ServeedoSearch } from '../ServeedoSearch/ServeedoSearch';
import { Button } from '@material-ui/core';

export interface IProps {
  title: string;
  tableFilter?: ITableFilter[];
  tableSearch?: boolean;
  searchFind?: (searchVal: string) => void;
  numSelected?: number;
  onDelectButtonClick?: () => void;
  searchInputPlaceholder?: string;
}

export const ServeedoTableToolbar: React.SFC<IProps> = ({
  title,
  tableFilter,
  tableSearch,
  searchFind,
  numSelected,
  onDelectButtonClick,
  searchInputPlaceholder
}) => {
  const classes = useStyles();

  return (
    <Toolbar className={classes.root}>
      <Typography className={classes.title} variant="h5" id="tableTitle">
        {title}
      </Typography>
      {tableSearch && <ServeedoSearch searchFind={searchFind} placeholder={searchInputPlaceholder || 'Search...'} />}
      {tableFilter && !(numSelected && numSelected > 0) && (
        <ServeedoTableFilter tableFilter={tableFilter} />
      )}
      {numSelected && numSelected > 0 ? (
        <Button
          variant="contained"
          onClick={onDelectButtonClick}
          style={{ backgroundColor: '#D32F2F', color: '#ffffff' }}
        >
          Delete
        </Button>
      ) : null}
    </Toolbar>
  );
};
