import { handleActions, Reducer } from 'redux-actions';
import { IRootState } from 'app/reducers/AppState';
import { IVehicleTypesActionsType } from 'app/actions';
import {
  IVehicleTypesModel,
  ICheckListItemModel,
  IVehicleTypeModel,
  IVehicleTypeListModel,
} from 'app/models';

const initialState: IVehicleTypesModel = {
  vehicleTypesList: [],
  isLoading: false,
  checklist: [],
  selectedVehicleType: null,
  isEditing: false,
  isAdding: false,
  isDeleting: false,
};

// tslint:disable-next-line:typedef
export const vehicleTypesReducer: Reducer<
  IRootState.VehicleTypesState,
  IVehicleTypesModel
> = handleActions<
  IRootState.VehicleTypesState,
  | IVehicleTypesModel
  | ICheckListItemModel[]
  | IVehicleTypeModel
  | IVehicleTypeListModel
  | IVehicleTypeListModel[]
>(
  {
    [IVehicleTypesActionsType.REQUEST_VEHICLE_TYPES_LIST]: (
      state = initialState,
    ): IRootState.VehicleTypesState => ({
      ...state,
      isLoading: true,
    }),
    [IVehicleTypesActionsType.RECEIVE_VEHICLE_TYPES_LIST]: (
      state = initialState,
      action,
    ): IRootState.VehicleTypesState => ({
      ...state,
      isLoading: false,
      vehicleTypesList: action.payload as IVehicleTypeListModel[],
    }),
    [IVehicleTypesActionsType.FAILURE_VEHICLE_TYPES_LIST]: (
      state = initialState,
    ): IRootState.VehicleTypesState => ({
      ...state,
      isLoading: false,
    }),

    [IVehicleTypesActionsType.REQUEST_ADD_VEHICLE_TYPE]: (
      state = initialState,
    ): IRootState.VehicleTypesState => ({
      ...state,
      isAdding: true,
    }),
    [IVehicleTypesActionsType.RECEIVE_ADD_VEHICLE_TYPE]: (
      state = initialState,
      action,
    ): IRootState.VehicleTypesState => ({
      ...state,
      isAdding: false,
    }),
    [IVehicleTypesActionsType.FAILURE_ADD_VEHICLE_TYPE]: (
      state = initialState,
    ): IRootState.VehicleTypesState => ({
      ...state,
      isAdding: false,
    }),

    [IVehicleTypesActionsType.ADD_CHECK_LIST_ITEM]: (
      state = initialState,
      action,
    ): IRootState.VehicleTypesState => ({
      ...state,
      checklist: action.payload as ICheckListItemModel[],
    }),

    [IVehicleTypesActionsType.HANDLE_EDIT_VEHICLE_TYPE]: (
      state = initialState,
      action,
    ): IRootState.VehicleTypesState => ({
      ...state,
      selectedVehicleType: action.payload as IVehicleTypeModel,
      isEditing: true,
    }),

    [IVehicleTypesActionsType.CANCEL_ADD_VEHICLE_TYPE]: (
      state = initialState,
    ): IRootState.VehicleTypesState => ({
      ...state,
      selectedVehicleType: null,
      isEditing: false,
      checklist: [],
    }),

    [IVehicleTypesActionsType.REQUEST_UPDATE_VEHICLE_TYPE]: (
      state = initialState,
    ): IRootState.VehicleTypesState => ({
      ...state,
      isAdding: true,
    }),
    [IVehicleTypesActionsType.RECEIVE_UPDATE_VEHICLE_TYPE]: (
      state = initialState,
      action,
    ): IRootState.VehicleTypesState => ({
      ...state,
      isAdding: false,
    }),
    [IVehicleTypesActionsType.FAILURE_UPDATE_VEHICLE_TYPE]: (
      state = initialState,
    ): IRootState.VehicleTypesState => ({
      ...state,
      isAdding: false,
    }),

    [IVehicleTypesActionsType.DELETE_CHECK_LIST_ITEM]: (
      state = initialState,
      action,
    ): IRootState.VehicleTypesState => ({
      ...state,
      checklist: action.payload as ICheckListItemModel[],
    }),

    [IVehicleTypesActionsType.REQUEST_DELETE_VEHICLE_TYPE]: (
      state = initialState,
    ): IRootState.VehicleTypesState => ({
      ...state,
      isDeleting: true,
    }),
    [IVehicleTypesActionsType.RECEIVE_DELETE_VEHICLE_TYPE]: (
      state = initialState,
    ): IRootState.VehicleTypesState => ({
      ...state,
      isDeleting: false,
    }),
    [IVehicleTypesActionsType.FAILURE_DELETE_VEHICLE_TYPE]: (
      state = initialState,
    ): IRootState.VehicleTypesState => ({
      ...state,
      isDeleting: false,
    }),

    [IVehicleTypesActionsType.REQUEST_FETCH_VEHICLE_TYPE]: (
      state = initialState,
    ): IRootState.VehicleTypesState => ({
      ...state,
      isLoading: true,
    }),
    [IVehicleTypesActionsType.RECEIVE_FETCH_VEHICLE_TYPE]: (
      state = initialState,
      action,
    ): IRootState.VehicleTypesState => ({
      ...state,
      isLoading: false,
      isEditing: true,
      selectedVehicleType: action.payload as IVehicleTypeModel,
    }),
    [IVehicleTypesActionsType.FAILURE_FETCH_VEHICLE_TYPE]: (
      state = initialState,
    ): IRootState.VehicleTypesState => ({
      ...state,
      isLoading: false,
      isEditing: false,
      selectedVehicleType: null,
    }),
    [IVehicleTypesActionsType.FAILURE_DELETE_VEHICLE_TYPE]: (
      state = initialState,
    ): IRootState.VehicleTypesState => ({
      ...state,
      isLoading: false,
      isEditing: false,
    }),
  },
  initialState,
);
