import React from 'react';
import { DialogTitle } from '@material-ui/core';

interface IProps {
  title: string;
  id?: string;
  className?: string;
}

export const ModalHeader: React.SFC<IProps> = (props) => {
  const { title, id, className } = props;
  return (
    <DialogTitle id={id} className={className} disableTypography>
      {title}
    </DialogTitle>
  );
};
