import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import AddIcon from '@material-ui/icons/Add';

import { WidgetHeader } from 'app/components/WidgetHeader/WidgetHeader';
import { ServeedoButton } from 'app/components/common';
import { IUsersManagementModel, IUserDetail } from 'app/models';
import { UsersManagementList } from 'app/components/UsersManagementList/UsersManagementList';
import { AddUserModal } from 'app/components/Modals/AddUserModal/AddUserModal';
import { SectionDescription } from 'app/components/common/SectionDescription';

export interface IProps extends RouteComponentProps<any> {
  usersManagement: IUsersManagementModel;
  fetchUsersList: () => void;
  addOrUpdateUser: (user: IUserDetail) => void;
  openUserModal: (user: IUserDetail) => void;
  closeUserModal: () => void;
  deleteUser: () => void;
}
export interface IState {
  openModal: boolean;
}

class UsersManagement extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      openModal: false,
    };
  }

  componentDidMount() {
    this.props.fetchUsersList();
  }

  componentDidUpdate(prevProps: IProps) {
    const {
      usersManagement: { isProcessing, selectedUser, isDeleting },
    } = this.props;
    if (
      (prevProps.usersManagement.isProcessing !== isProcessing && !isProcessing) ||
      (prevProps.usersManagement.isDeleting !== isDeleting && !isDeleting)
    ) {
      this.setState({
        openModal: false,
      });
      if (selectedUser) {
        setTimeout(() => {
          this.props.closeUserModal();
        }, 100);
      }
    }
  }

  renderButton = (): JSX.Element => {
    return (
      <ServeedoButton
        buttonText={'New User'}
        buttonIcon={<AddIcon />}
        onClick={() => this.setState({ openModal: true })}
      />
    );
  };

  render(): JSX.Element {
    const {
      usersManagement: { usersList, isLoading, isProcessing, selectedUser, isDeleting },
      addOrUpdateUser,
      openUserModal,
      closeUserModal,
      deleteUser,
    } = this.props;
    const { openModal } = this.state;
    return (
      <>
        <div className={'container'}>
          <WidgetHeader
            headerText={'Users'}
            headerSmallText={'ACCOUNT'}
            rightPanel={this.renderButton()}
          />
          <SectionDescription>
            Manage here the people who can access this backend. Only difference between{' '}
            <strong>Administrator</strong> and <strong>Collaborator</strong> role is that
            Administrators can access this page.
          </SectionDescription>
          <UsersManagementList
            usersList={usersList}
            isLoading={isLoading}
            updateUser={addOrUpdateUser}
            handleOpenUserModal={(user) => {
              openUserModal(user);
              this.setState({ openModal: true });
            }}
          />
          <AddUserModal
            showModal={openModal}
            handleClose={() => {
              this.setState({ openModal: false });
              if (selectedUser) {
                setTimeout(() => {
                  closeUserModal();
                }, 100);
              }
            }}
            addOrUpdateUser={addOrUpdateUser}
            isLoading={isProcessing}
            selectedUser={selectedUser}
            deleteUser={deleteUser}
            isDeleting={isDeleting}
          />
        </div>
      </>
    );
  }
}

export default UsersManagement;
