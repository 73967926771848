export interface IBaseModel {
  id: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface ILanguage {
  label: string;
  value: string;
}

export interface ILocation {
  lat: number;
  long: number;
  address?: string;
}

export enum DayModel {
  Monday = 1,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday,
}
