import * as React from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Paper,
  Divider,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import {
  ServeedoTableHeader,
  ITableHeaderCells,
  ServeedoTableToolbar,
  NoData,
} from 'app/components/common';
import { IVehicleTypeListModel } from 'app/models';

import { getSorting, stableSort } from 'app/helpers/common';
import { makeDateRelative } from 'app/helpers/time-helper';

const headerCells: ITableHeaderCells[] = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'description', numeric: true, disablePadding: false, label: 'Description' },
  { id: 'createdAt', numeric: true, disablePadding: false, label: 'Added' },
];

export interface IProps {
  vehicleTypesList: IVehicleTypeListModel[];
  handleEditVehicleType: (vehicleType: IVehicleTypeListModel) => void;
  isLoading: boolean;
}

export const VehicleTypesList: React.SFC<IProps> = ({
  vehicleTypesList,
  handleEditVehicleType,
  isLoading,
}) => {
  const [order, setOrder] = React.useState<any>('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  const handleRequestSort = (event: any, property: string) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper>
      <ServeedoTableToolbar title={'All Types'} />
      <div className="table-wrapper">
        <Table aria-labelledby="tableTitle" size={'medium'} aria-label="enhance table">
          <ServeedoTableHeader
            numSelected={0}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={0}
            headerCells={headerCells}
            selecting={false}
          />
          <TableBody>
            {isLoading
              ? [1, 2, 3].map((item) => (
                  <TableRow key={item}>
                    <TableCell component="th" scope="row">
                      <React.Fragment>
                        <Skeleton
                          height={10}
                          width="55%"
                          style={{ marginTop: 6, marginBottom: 6 }}
                        />
                      </React.Fragment>
                    </TableCell>
                    <TableCell>
                      <React.Fragment>
                        <Skeleton
                          height={10}
                          width="80%"
                          style={{ marginTop: 6, marginBottom: 6 }}
                        />
                      </React.Fragment>
                    </TableCell>
                    <TableCell>
                      <React.Fragment>
                        <Skeleton
                          height={10}
                          width="60%"
                          style={{ marginTop: 6, marginBottom: 6 }}
                        />
                      </React.Fragment>
                    </TableCell>
                  </TableRow>
                ))
              : stableSort<IVehicleTypeListModel>(vehicleTypesList, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((vehicleType: IVehicleTypeListModel, index: number) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                        onClick={() => handleEditVehicleType(vehicleType)}
                      >
                        <TableCell component="th" scope="row">
                          {vehicleType.name}
                        </TableCell>
                        <TableCell>{vehicleType.description}</TableCell>
                        <TableCell>{makeDateRelative(vehicleType.createdAt)}</TableCell>
                      </TableRow>
                    );
                  })}
          </TableBody>
        </Table>
      </div>
      {!isLoading && !vehicleTypesList.length && (
        <>
          <NoData />
          <Divider />
        </>
      )}
      <TablePagination
        rowsPerPageOptions={[50, 100, 250]}
        component="div"
        count={vehicleTypesList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
