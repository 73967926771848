import React from 'react';

import { DialogActions, Button, CircularProgress } from '@material-ui/core';

interface IProps {
  className?: string;
  isEditing?: boolean;
  isLoading?: boolean;
  hasDelete?: boolean;
  onContinueClick?: () => void;
  onCancelClick?: () => void;
  onDeleteClick?: () => void;
}

export const ModalFooter: React.FC<IProps> = (props) => {
  const {
    className,
    isLoading,
    hasDelete,
    isEditing,
    onContinueClick,
    onCancelClick,
    onDeleteClick,
  } = props;

  return (
    <DialogActions className={className}>
      <div>
        {isEditing && hasDelete && (
          <Button onClick={onDeleteClick} color="secondary">
            DELETE
          </Button>
        )}
      </div>

      <div>
        <Button onClick={onCancelClick} variant="contained" disabled={isLoading}>
          Cancel
        </Button>{' '}
        <Button
          onClick={onContinueClick}
          color={'primary'}
          variant="contained"
          disabled={isLoading}
        >
          {isLoading ? (isEditing ? 'Updating...' : 'Saving...') : isEditing ? 'Update' : 'Save'}
          {''}
          {isLoading && <CircularProgress disableShrink size={18} thickness={4} />}
        </Button>
      </div>
    </DialogActions>
  );
};
