import * as React from 'react';

import {
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ListItemIcon,
} from '@material-ui/core';
import { Check, ArrowDropDown } from '@material-ui/icons';

export interface ISelectButtonOption {
  label: string;
  value: string | number;
}

export enum ISelectButtonStyle {
  DEFAULT,
  SUCCESS,
}

interface IProps {
  options: ISelectButtonOption[];
  onSelect: (option: ISelectButtonOption) => void;
  selectedOption: ISelectButtonOption;
  buttonStyle: ISelectButtonStyle;
  leftIcon?: JSX.Element;
}

export const ServeedoSelectButton: React.SFC<IProps> = ({
  options,
  onSelect,
  selectedOption,
  buttonStyle,
  leftIcon,
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<any>(null);

  const handleMenuItemClick = (option: ISelectButtonOption) => {
    setOpen(false);
    onSelect(option);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef && anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const renderButtonStyling = (style: ISelectButtonStyle) => {
    const buttonStyles: React.CSSProperties = {
      minWidth: '130px',
      justifyContent: 'space-between',
      textTransform: 'unset',
      margin: '15px 0 0',
      padding: 6,
      fontWeight: 600,
      fontSize: 14,
      marginLeft: 10,
    };
    switch (style) {
      case ISelectButtonStyle.DEFAULT:
        return {
          ...buttonStyles,
          backgroundColor: '#e0e0e0',
          color: '#546E7A',
        };
      case ISelectButtonStyle.SUCCESS:
        return {
          ...buttonStyles,
          backgroundColor: '#009688',
          color: '#FFFFFF',
        };
    }
  };
  return (
    <div>
      <Button
        color="primary"
        size="small"
        aria-controls={open ? 'split-button-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-label="select merge strategy"
        aria-haspopup="menu"
        onClick={handleToggle}
        ref={anchorRef}
        style={renderButtonStyling(buttonStyle)}
      >
        <div style={{ display: 'flex' }}>
          {leftIcon} <span style={{ marginLeft: leftIcon ? 5 : 0 }}> {selectedOption.label}</span>
        </div>
        <ArrowDropDown />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 1, minWidth: '130px' }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option: ISelectButtonOption, index: number) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        if (selectedOption.value === option.value) return;
                        handleMenuItemClick(option);
                      }}
                      style={{ padding: 10, paddingLeft: 30 }}
                    >
                      {selectedOption.value === option.value && (
                        <ListItemIcon style={{ position: 'absolute', left: 5 }}>
                          <Check fontSize="small" />
                        </ListItemIcon>
                      )}
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
