import * as React from 'react';

import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Check } from '@material-ui/icons';
import { IRegion, IMaintainer, IDropdownItem } from 'app/models';

export interface IProps {
  regions: IRegion[];
  onChangeRegion: (region: IRegion, maintainer?: IMaintainer) => void;
  selectedRegion: IRegion;
  selectedMaintainer: IMaintainer | null;
  maintainers?: IMaintainer[] | null;
}

export const LocationDropdown: React.SFC<IProps> = ({
  regions,
  onChangeRegion,
  selectedRegion,
  maintainers,
  selectedMaintainer,
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<any>(null);

  const handleMenuItemClick = (regionId: string, maintainerId?: string) => {
    const region = regions.find((r) => r.id === regionId);
    const maintainer: IMaintainer | undefined = maintainers
      ? maintainers.find((m) => m.id === maintainerId)
      : undefined;
    setOpen(false);
    if (region) {
      onChangeRegion(region, maintainer);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef && anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  let options: IDropdownItem[] = [];

  regions.forEach((r) => {
    if (maintainers && maintainers.length) {
      maintainers.forEach((m) => {
        m.regionsIds.forEach((mr) => {
          if (mr === r.id) {
            options.push({
              regionId: r.id,
              maintainerId: m.id,
              regionName: r.name,
              maintainerName: m.name,
            });
          }
        });
      });
    } else if (r.id !== 'all') {
      options.push({ regionId: r.id, regionName: r.name });
    }
  });

  options = [
    ...((regions.length > 1 && [{ regionId: 'all', regionName: 'All' }]) || []),
    ...options,
  ];

  return (
    <div>
      <Button
        color="default"
        size="small"
        aria-controls={open ? 'split-button-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-label="select merge strategy"
        aria-haspopup="menu"
        onClick={handleToggle}
        ref={anchorRef}
        style={{
          width: '100%',
          justifyContent: 'space-between',
          backgroundColor: '#eee',
          margin: '15px 0 0',
          padding: 6,
          fontWeight: 600,
          fontSize: 14,
          textTransform: 'unset',
        }}
        disabled={regions.length === 1}
      >
        <div
          style={{ position: 'relative', display: 'flex', textAlign: 'left', overflow: 'hidden' }}
        >
          {selectedRegion.name}
          {selectedMaintainer && (
            <>
              <span style={{ marginLeft: 3 }}>(</span>
              <span
                style={{
                  maxWidth: '100%',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {selectedMaintainer.name}
              </span>
              <span>)</span>
            </>
          )}
          {''}
        </div>
        <ArrowDropDownIcon />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 1, width: '100%' }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option: IDropdownItem, index: number) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        handleMenuItemClick(option.regionId, option.maintainerId);
                      }}
                      style={{ padding: 10, paddingLeft: 30 }}
                    >
                      {selectedRegion.id === option.regionId && (
                        <ListItemIcon style={{ position: 'absolute', left: 5 }}>
                          <Check fontSize="small" />
                        </ListItemIcon>
                      )}
                      {option.regionName}
                      {option.maintainerName && (
                        <>
                          <span style={{ marginLeft: 5 }}>(</span>
                          <span
                            style={{
                              maxWidth: '100%',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                            }}
                          >
                            {option.maintainerName}
                          </span>
                          <span>)</span>
                        </>
                      )}
                      {''}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
