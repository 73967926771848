import React, { useState, useEffect } from 'react';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Typography, Button, Divider, IconButton, Fab } from '@material-ui/core';
import { Add, CheckCircleOutline, DeleteOutline, DragHandle } from '@material-ui/icons';
import Toolbar from '@material-ui/core/Toolbar';

import { ICheckListItemModel } from 'app/models';

import { List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import RootRef from '@material-ui/core/RootRef';
import { ConfirmationModal } from 'app/components/Modals/ConfirmationModal/ConfirmationModal';

import { NoData } from 'app/components/common';

import { useStyles } from './styles';

export interface IProps {
  checkListItems: ICheckListItemModel[];
  handleOpenModal: () => void;
  handleChangeListOrder: (list: ICheckListItemModel[]) => void;
  removeCheckListItem: (id: string) => void;
  editCheckListItem: (item: ICheckListItemModel) => void;
}

export const ServicesCheckList: React.SFC<IProps> = ({
  checkListItems,
  handleOpenModal,
  handleChangeListOrder,
  removeCheckListItem,
  editCheckListItem,
}) => {
  const classes = useStyles();

  const [items, setItems] = useState<ICheckListItemModel[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

  useEffect(() => {
    setItems(checkListItems);
  }, [checkListItems]);

  const openConfirmModal = (item: ICheckListItemModel) => {
    setSelectedItemId(item.key);
    setOpenModal(true);
  };

  const closeConfirmModal = () => {
    setSelectedItemId(null);
    setOpenModal(false);
  };

  const deleteSelectedItem = () => {
    if (selectedItemId) {
      removeCheckListItem(selectedItemId);
    }
    closeConfirmModal();
  };

  // a little function to help us with reordering the result
  const reorder = (list: ICheckListItemModel[], startIndex: number, endIndex: number) => {
    const result: ICheckListItemModel[] = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    const newResult = [...result].map((item: ICheckListItemModel, index: number) => ({
      ...item,
      order: index + 1,
    }));
    handleChangeListOrder(newResult);

    return result;
  };

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDragging && {
      background: 'rgb(235,235,235)',
    }),
  });

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const checkListItems: ICheckListItemModel[] = reorder(
      items,
      result.source.index,
      result.destination.index,
    );
    setItems(checkListItems);
  };
  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolBar}>
        <Typography variant="h4" noWrap>
          Ordinary Service Checklist
        </Typography>
        <Button variant="contained" size="medium" color="primary" onClick={handleOpenModal}>
          <Add /> NEW ITEM
        </Button>
      </Toolbar>
      <Divider />
      <div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided: any) => (
              <RootRef rootRef={provided.innerRef}>
                <List style={{ padding: 0 }}>
                  {items.map((item: ICheckListItemModel, index: number) => (
                    <Draggable key={index} draggableId={`${item.id} ${index}`} index={index}>
                      {(provided: any, snapshot: any) => (
                        <ListItem
                          ContainerComponent={'div'}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          className={classes.listItem}
                          onClick={() => editCheckListItem(item)}
                        >
                          <ListItemIcon>
                            <Fab className={classes.fabIcon} disabled>
                              <CheckCircleOutline fontSize="small" />
                            </Fab>
                          </ListItemIcon>
                          <ListItemText primary={item.translations.en} />
                          <ListItemIcon>
                            <IconButton
                              {...provided.dragHandleProps}
                              onClick={(e) => e.stopPropagation()}
                            >
                              <DragHandle fontSize="small" />
                            </IconButton>
                          </ListItemIcon>
                          <ListItemIcon>
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                openConfirmModal(item);
                              }}
                            >
                              <DeleteOutline fontSize="small" />
                            </IconButton>
                          </ListItemIcon>
                        </ListItem>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              </RootRef>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      {!items.length && <NoData message={'No items are added'} />}
      <ConfirmationModal
        openModal={openModal}
        handleClose={closeConfirmModal}
        handleContinue={deleteSelectedItem}
      />
    </div>
  );
};
