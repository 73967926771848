import * as React from 'react';
import { useStyles } from './EndpointsStyles';
import { Paper, Typography, Chip } from '@material-ui/core';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { tomorrowNight, arduinoLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';

interface IDocsParameter {
  name: string;
  description: string;
  children?: IDocsParameter[];
}

interface IProps {
  title: string;
  description: string;
  type: 'POST' | 'GET' | 'PUT' | 'DELETE';
  path: string;
  request: string;
  parameters?: IDocsParameter[];
  response?: string;
}

export const Endpoint: React.SFC<IProps> = (props) => {
  const classes = useStyles();

  return (
    <Paper className={classes.listItem}>
      <Typography variant="h4" component="h1">
        {props.title}
      </Typography>
      <div className={classes.url}>
        <Chip className={classes.methodBadge} size="small" label={props.type} />
        <Typography variant="subtitle1">{process.env.REACT_APP_API_URL + props.path}</Typography>
      </div>
      <Typography variant="body1" style={{ color: '#607D8B' }}>
        <p>{props.description}</p>
        {props.parameters && (
          <React.Fragment>
            <p>Parameters:</p>
            <ul>
              {props.parameters.map((p) => (
                <li>
                  <strong>{p.name}: </strong>
                  {p.description}
                  {p.children && (
                    <ul>
                      {p.children.map((pc) => (
                        <li>
                          <strong>{pc.name}: </strong>
                          {pc.description}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </React.Fragment>
        )}
      </Typography>
      <br />
      <Typography variant="overline" component="h5" style={{ color: '#607D8B', fontWeight: 600 }}>
        REQUEST EXAMPLE
      </Typography>
      <Paper className={classes.requestSample}>
        <SyntaxHighlighter
          language="bash"
          style={tomorrowNight}
          customStyle={{
            backgroundColor: 'black',
          }}
        >
          {props.request}
        </SyntaxHighlighter>
      </Paper>
      {props.response && (
        <React.Fragment>
          <br />
          <Typography
            variant="overline"
            component="h5"
            style={{ color: '#607D8B', fontWeight: 600 }}
          >
            RESPONSE EXAMPLE
          </Typography>
          <Paper className={classes.responseSample}>
            <SyntaxHighlighter
              language="javascript"
              style={arduinoLight}
              customStyle={{
                backgroundColor: '#e6e6e6',
              }}
            >
              {props.response}
            </SyntaxHighlighter>
          </Paper>
        </React.Fragment>
      )}
    </Paper>
  );
};
