import * as React from 'react';
import {
  Toolbar,
  Typography,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Button,
} from '@material-ui/core';

import { useStyles } from './IntegrationMappingStyle';
import { NoData } from 'app/components/common';
import { IRemoteMapping121 } from 'app/models';
import { IntegrationTableRow } from './IntegrationTableRow';

interface IProps {
  title: string;
  subtitle: string;
  addButtonLabel: string;
  items: IRemoteMapping121[];
  onAdd: (item?: IRemoteMapping121) => void;
}

export const IntegrationMappingList: React.SFC<IProps> = (props) => {
  const classes = useStyles();

  return (
    <Card style={{ marginTop: 24 }}>
      <CardHeader
        title={props.title}
        action={
          <Button variant="contained" color="default" onClick={() => props.onAdd()}>
            {props.addButtonLabel}
          </Button>
        }
      />
      <Toolbar className={classes.tableToolbar}>
        <Grid container item xs={12}>
          <Grid item xs={9}>
            <Typography variant="body2" component="h4" noWrap className={classes.headerText}>
              {props.subtitle}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" component="h4" noWrap className={classes.headerText}>
              Wunder Id
            </Typography>
          </Grid>
        </Grid>
      </Toolbar>
      <CardContent className={classes.cardConent}>
        {props.items.length > 0 ? (
          props.items.map((item, index) => {
            return (
              <IntegrationTableRow
                title={item.name || item.localId}
                value={item.remoteId}
                key={index}
                onClick={() => props.onAdd(item)}
              />
            );
          })
        ) : (
          <NoData message={`No ${props.title} found`} />
        )}
      </CardContent>
    </Card>
  );
};
