import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { UsersManagement } from 'app/views';
import { IRootState } from 'app/reducers/AppState';
import {
  fetchUsersList,
  addOrUpdateUser,
  openUserModal,
  closeUserModal,
  deleteUser,
} from 'app/actions';
import { IUsersManagementModel, IUserDetail } from 'app/models';

interface StateFromProps {
  usersManagement: IUsersManagementModel;
}

interface DispatchFromProps {
  fetchUsersList: () => void;
  addOrUpdateUser: (user: IUserDetail) => void;
  openUserModal: (user: IUserDetail) => void;
  closeUserModal: () => void;
  deleteUser: () => void;
}

const mapStateToProps = (state: IRootState): StateFromProps => ({
  usersManagement: state.usersManagement,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => ({
  fetchUsersList: bindActionCreators(fetchUsersList, dispatch),
  addOrUpdateUser: bindActionCreators(addOrUpdateUser, dispatch),
  openUserModal: bindActionCreators(openUserModal, dispatch),
  closeUserModal: bindActionCreators(closeUserModal, dispatch),
  deleteUser: bindActionCreators(deleteUser, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UsersManagement);
